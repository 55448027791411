import PropTypes from 'prop-types';
import {
  compose,
  descend,
  filter,
  find,
  path,
  propEq,
  reverse,
  sortWith,
  split,
  tail,
} from 'ramda';
import React, { Component } from 'react';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Image from 'react-bootstrap/lib/Image';
import { FormattedMessage, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { readEndpoint } from 'redux-json-api';
import { createSelector } from 'reselect';

import getPropTypeScheme from '../../lib/PropTypes';
import { readEndpointDedup } from '../../modules/actions/api';
import Constants from '../../modules/constants';
import AlbertusConstants from '../../../projects/albertus/app/constants';
import Author from '../Author';
import Comment from '../Comment';
import {
  renderContentWithLinks,
  renderAbstractWithBadges,
  replaceFileLinks,
} from '../Forms/helpers';

import './Article.less';

const messages = defineMessages({
  hasMoreComments: {
    id: 'app.components.Article.hasMoreComments',
    description: 'Article \'has more comments\' message.',
    defaultMessage: `There {count, plural,
      one {is}
      other {are}
    } {count, number} older {count, plural,
      one {comment}
      other {comments}
    }.`,
  },
  noComments: {
    id: 'app.components.Article.noComments',
    description: 'Article \'no comments\' message.',
    defaultMessage: 'No comments yet',
  },
});

class Article extends Component {
  static propTypes = {
    // own props
    attributes: getPropTypeScheme('ArticleAttributes'),
    id: PropTypes.string.isRequired,
    onRendered: PropTypes.func.isRequired,
    setModalData: PropTypes.func.isRequired,

    // state props
    articleTypeId: PropTypes.string,
    comments: PropTypes.arrayOf(getPropTypeScheme('Comment')),
    group: PropTypes.oneOfType([
      PropTypes.bool,
      getPropTypeScheme('Group'),
    ]),
    groupId: PropTypes.string,
    user: getPropTypeScheme('User'),
    userId: PropTypes.string,

    // dispatch props
    readEndpoint: PropTypes.func.isRequired,
    readEndpointDedup: PropTypes.func.isRequired,
    replaceFileLinks: PropTypes.func,
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    const {
      id,
      group, groupId, user, userId,
      readEndpoint, readEndpointDedup,
    } = this.props;

    readEndpointDedup('form?page_size=100');

    const waitFor = [];

    if (group === undefined) waitFor.push(readEndpointDedup(`group/${groupId}`));
    if (user === undefined) waitFor.push(readEndpointDedup(`user/${userId}`));

    let commentEndpoint = 'comment';
    commentEndpoint += '?filter[content-type__model__exact]=article';
    commentEndpoint += '&filter[content-type__app-label__exact]=ocii_articles';
    commentEndpoint += `&page_size=${Constants.COMMENT_PREVIEW_PAGE_SIZE}`;
    commentEndpoint += `&filter[object-pk__exact]=${id}`;
    commentEndpoint += '&order[created]=DESC';

    waitFor.push(readEndpoint(commentEndpoint));

    Promise.all(waitFor).then(() => this.setState({ loading: false }));
  }

  renderHeader() {
    const {
      attributes: { cover, title }, articleTypeId,
    } = this.props;

    const isFacebook = articleTypeId === Constants.ARTICLE_TYPE_FACEBOOK;

    let iconType = articleTypeId === '1' ? 'file' : 'exclamation';
    iconType = isFacebook ? 'facebook' : iconType;
    let iconColor = articleTypeId === '1' ? 'notice' : 'warn';
    iconColor = isFacebook ? 'facebook' : iconColor;

    return (
      <div className={`article-header${isFacebook ? ' facebook' : ''}`}>
        <div className={`article-title${isFacebook ? ' facebook' : ''}`}>
          <h2>{title}</h2>
        </div>
        <div className="article-meta">
          {cover ? (
            <Image responsive src={cover} />
          ) : (
            <Glyphicon bsClass="fa" className={iconColor} glyph={iconType} />
          )}

          <hr />
        </div>
      </div>
    );
  }

  renderComments() {
    const {
      attributes: { numberOfComments }, comments,
    } = this.props;

    /**
     * Use the newest three comments to show.
     */
    const commentsToShow = reverse((comments ? sortWith([
      descend(compose(d => new Date(d), path(['attributes', 'created']))),
    ])(comments) : []).slice(0, Constants.COMMENT_PREVIEW_PAGE_SIZE));
    const remainingCommentsCount = numberOfComments - commentsToShow.length;

    return (
      <div className="article-comments">
        {remainingCommentsCount > 0 && (
          <div className="has-more-comments">
            <FormattedMessage
              {...messages.hasMoreComments}
              values={{ count: remainingCommentsCount }}
            />
          </div>
        )}

        {numberOfComments > 0 ? (
          <div className="has-comments">
            {commentsToShow.map(props =>
              <Comment {...props} key={props.id} />)}
          </div>
        ) : (
          <div className="no-comments">
            <FormattedMessage {...messages.noComments} />
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      attributes: { abstract, additionalData, allowComments },
      articleTypeId,
      setModalData,
      replaceFileLinks,
    } = this.props;
    const { loading } = this.state;

    let fbPostId = null;
    if (JSON.parse(additionalData).fb_id) {
      fbPostId = split('_', (JSON.parse(additionalData).fb_id));
      fbPostId = tail(fbPostId);
    }

    const isFacebook = articleTypeId === Constants.ARTICLE_TYPE_FACEBOOK;

    return (
      loading ? null :
      <article
        className="well article-container"
        onClick={() => {
          if (isFacebook) {
            window.open(`${AlbertusConstants.FB_PAGE_URL}posts/${fbPostId}`, '_blank');
          } else {
            setModalData('modalArticle', this.props);
          }
        }}
      >
        {this.renderHeader()}
        {isFacebook ?
          <div className="article-body">{renderContentWithLinks(abstract)}</div>
          :
          <div className="article-body">{renderAbstractWithBadges(replaceFileLinks(abstract), { image: false })}</div>
        }
        <hr />
        <Author {...this.props} />
        {allowComments ? this.renderComments() : null}
        {this.props.onRendered()}
      </article>
    );
  }
}
const selectGroups = state => path(['api', 'group', 'data'], state) || [];
const selectGroupId = (state, ownProps) => path(['relationships', 'group', 'data', 'id'], ownProps);
const selectGroup = createSelector([selectGroups, selectGroupId], (groups, groupId) => {
  if (groupId === undefined) return false;
  return find(propEq('id', String(groupId)))(groups);
});

const selectUsers = state => path(['api', 'user', 'data'], state) || [];
const selectUserId = (state, ownProps) => path(['relationships', 'user', 'data', 'id'], ownProps);
const selectUser = createSelector([selectUsers, selectUserId], (users, userId) => {
  if (userId === undefined) return false;
  return find(propEq('id', String(userId)))(users);
});

const selectArticleId = (state, ownProps) => path(['id'], ownProps);
const selectComments = state => path(['api', 'comment', 'data'], state) || [];
const selectArticleComments = createSelector(
  [selectComments, selectArticleId],
  (comments, articleId) =>
    filter(comment => path(['attributes', 'objectPk'], comment) === articleId, comments),
);

const selectArticleTypeId = (state, ownProps) => path(['relationships', 'articleType', 'data', 'id'], ownProps);

const mapStateToProps = (state, ownProps) => ({
  group: selectGroup(state, ownProps),
  groupId: selectGroupId(state, ownProps),

  user: selectUser(state, ownProps),
  userId: selectUserId(state, ownProps),

  comments: selectArticleComments(state, ownProps),

  articleTypeId: selectArticleTypeId(state, ownProps),
});

const mapDispatchToProps = {
  readEndpoint,
  readEndpointDedup,
  replaceFileLinks,
};

export default connect(mapStateToProps, mapDispatchToProps)(Article);
