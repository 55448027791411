import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Alert, Button, ControlLabel, FormControl, FormGroup, HelpBlock, Table,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';

import Pagination from '../../../../../../app/components/Pagination';
import { loadingContentItems, loadingContentItemsDone, setContentItems } from '../actions';
import { get } from '../../../lib/driveApi';
import { getContentItemsSortedByUpdatedAt, isLoadingContentItems } from '../selectors';
import { contentItem as contentItemShape } from '../types';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import genericMessages from '../../../../../../app/lib/genericMessages';

const styles = {
  footerWrapper: {
    display: 'flex',
  },
  footerInnerWrapper: {
    width: '50%',
  },
  footerInnerWrapperRight: {
    textAlign: 'right',
  },
  footerAddButton: {
    margin: '20px 0',
  },
};

const messages = defineMessages({
  titleFitler: {
    id: 'projects.arriva.app.modules.Arrivideo.components.Broadcasts.titleFilter',
    description: 'filter on title message',
    defaultMessage: 'filter on title',
  },
  search: {
    id: 'projects.arriva.app.modules.EmailEstablishment.components.Establishments.search',
    description: 'Search helper message',
    defaultMessage: 'Search is not case sensitive',
  },
  titleSearch: {
    id: 'projects.arriva.app.modules.Arrivideo.components.Broadcasts.titleSearch',
    description: 'Search on title message',
    defaultMessage: 'Enter a title to search',
  },
});

class ContentItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      pageSize: 10,
      page: 1,
      pages: 1,
      searchTitle: undefined,
    };
  }

  componentDidMount() {
    this.fetchContentItems();
  }

  onSelectPage(page) {
    this.setState({ page }, () => this.fetchContentItems());
  }

  async fetchContentItems() {
    const {
      get, loadingContentItems, loadingContentItemsDone,
      setContentItems,
    } = this.props;
    const { page, pageSize, searchTitle } = this.state;

    await loadingContentItems();

    let url = `arrivideo/content?page=${page}&page_size=${pageSize}`;

    if (searchTitle) url = `arrivideo/content?page=1&page_size=${pageSize}&filter[title]=${searchTitle}`;

    get({ path: url, parallel: false })
      .then(({ pages, contentItems }) => {
        this.setState({ pages }, async () => {
          await setContentItems(contentItems);
          await loadingContentItemsDone();
        });
      })
      .catch((err) => this.setState({ error: err, page: 1, pages: 0 }));
  }

  renderError() {
    const { intl: { formatMessage } } = this.props;
    const { error } = this.state;

    if (!error) return null;

    const dismissError = () => this.setState({ error: undefined });

    return (
      <Alert bsStyle="danger" onDismiss={dismissError}>
        <h4>{formatMessage(genericMessages.nothingFound)}</h4>
        <p>{error.toString()}</p>
        <p>
          <Button onClick={dismissError}>{formatMessage(genericMessages.closeNotification)}</Button>
        </p>
      </Alert>
    );
  }

  renderSearch() {
    const { intl: { formatMessage } } = this.props;
    const { searchTitle } = this.state;

    return (
      <div className="well">
        <form>
          <FormGroup controlId="searchFormTitle">
            <ControlLabel>{formatMessage(messages.titleFitler)}</ControlLabel>
            <FormControl
              style={{ width: 450 }}
              type="text"
              value={searchTitle}
              placeholder={formatMessage(messages.titleSearch)}
              onChange={(e) => this.setState({ searchTitle: e.target.value }, () =>
                this.fetchContentItems())}
            />
            <HelpBlock>{formatMessage(messages.search)}</HelpBlock>
          </FormGroup>
        </form>
      </div>
    );
  }

  renderContentItem(contentItem) {
    return (
      <tr key={`content-item-${contentItem.id}`}>
        <td>
          <Link to={`/arrivideo/contentitem/edit/${contentItem.id}`}>{contentItem.title}</Link>
        </td>
        <td>{contentItem.type}</td>
        <td>{moment(contentItem.updatedAt).format('LLL')}</td>
      </tr>
    );
  }

  render() {
    const { contentItems, isLoadingContentItems, intl: { formatMessage } } = this.props;
    const { page, pages } = this.state;

    return (
      <div>
        {this.renderError()}
        {this.renderSearch()}

        {(contentItems && contentItems.length === 0) && (
          <strong>{formatMessage(genericMessages.nothingFound)}</strong>
        )}

        {isLoadingContentItems && (
          <strong>{formatMessage(genericMessages.loadingInProgress)}</strong>
        )}

        {(contentItems && contentItems.length > 0 && !isLoadingContentItems) && (
          <Table responsive>
            <thead>
              <tr>
                <td>{formatMessage(genericMessages.title)}</td>
                <td style={{ width: 100 }}>Type</td>
                <td style={{ width: 200 }}>{formatMessage(genericMessages.updatedAt)}</td>
              </tr>
            </thead>
            <tbody>
              {contentItems.map((contentItem) => this.renderContentItem(contentItem))}
            </tbody>
          </Table>
        )}

        <div style={styles.footerWrapper}>
          <div style={styles.footerInnerWrapper}>
            <Pagination
              first
              prev
              last
              next
              ellipsis
              boundaryLinks
              maxButtons={5}
              bsSize="small"
              onSelect={(page) => this.onSelectPage(page)}
              page={page}
              pages={pages}
            />
          </div>

          <div style={{ ...styles.footerInnerWrapper, ...styles.footerInnerWrapperRight }}>
            <Button
              bsStyle="primary"
              href="/arrivideo/contentitem/add"
              style={styles.footerAddButton}
            >
              {formatMessage(genericMessages.add)}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

ContentItems.propTypes = {
  // Dispatch props
  get: PropTypes.func.isRequired,
  loadingContentItems: PropTypes.func.isRequired,
  loadingContentItemsDone: PropTypes.func.isRequired,
  setContentItems: PropTypes.func.isRequired,

  // State props
  contentItems: PropTypes.arrayOf(contentItemShape),
  isLoadingContentItems: PropTypes.bool.isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
};

const mapStateToProps = (state) => ({
  contentItems: getContentItemsSortedByUpdatedAt(state),
  isLoadingContentItems: isLoadingContentItems(state),
});

const mapDispatchToProps = {
  get,
  loadingContentItems,
  loadingContentItemsDone,
  setContentItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ContentItems));
