import PropTypes from 'prop-types';
import React from 'react';
// import FormControl from 'react-bootstrap/lib/FormControl';
// import FormGroup from 'react-bootstrap/lib/FormGroup';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Nav from 'react-bootstrap/lib/Nav';
import Navbar from 'react-bootstrap/lib/Navbar';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import NavItem from 'react-bootstrap/lib/NavItem';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
// noinspection ES6CheckImport
import { withRouter } from 'react-router-dom';

import getPropTypeScheme from '../../../../../app/lib/PropTypes';
import { setLocale } from '../../../../../app/modules/actions/locale';
import { getLocale } from '../../../../../app/modules/selectors/locale';
import { selectUser } from '../../../../../app/modules/selectors/session';

import { localeNames, supportedLocales } from '../../../common';
import { localeFlags } from '../../../common_flags';
import ArrivaLogo from '../../../img/arriva_logo.png';

import './Header.less';

const messages = defineMessages({
  logout: {
    id: 'projects.arriva.app.components.Header.logout',
    description: 'Header dropdown-menu logout option.',
    defaultMessage: 'Logout',
  },
  languages: {
    id: 'projects.arriva.app.components.Header.languages',
    description: 'Header dropdown-menu languages sub-menu header.',
    defaultMessage: 'Languages',
  },
  support: {
    id: 'projects.arriva.app.components.Header.support',
    description: 'Header drop-down menu support menu item label.',
    defaultMessage: 'Support',
  },
});

const Header = ({ currentLocale, setLocale, user }) => (
  <Navbar id="header_navbar" fluid inverse fixedTop collapseOnSelect>
    <Navbar.Header>
      <Navbar.Brand>
        <LinkContainer to="/" exact>
          <img src={ArrivaLogo} alt="Arriva" />
        </LinkContainer>
      </Navbar.Brand>

      <Navbar.Toggle />
    </Navbar.Header>

    <Navbar.Collapse>
      {/* <Navbar.Form pullLeft>
        <FormGroup>
          <FormControl type="text" placeholder="Search" />
        </FormGroup>
      </Navbar.Form> */}

      <Nav pullRight>
        <NavDropdown
          id="header_avatar_dropdown"
          title={<Glyphicon glyph="menu-hamburger" />}
          noCaret
        >
          <LinkContainer to="/support" exact>
            <NavItem eventKey="support">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...messages.support} />
            </NavItem>
          </LinkContainer>

          {user && (
            <LinkContainer to="/logout" exact>
              <NavItem eventKey="logout">
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <FormattedMessage {...messages.logout} />
              </NavItem>
            </LinkContainer>
          )}

          <MenuItem divider />

          <MenuItem header>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <FormattedMessage {...messages.languages} />
          </MenuItem>

          {supportedLocales.map((locale) => (
            <MenuItem
              key={locale}
              eventKey={locale}
              active={currentLocale === locale}
              onClick={() => setLocale(locale)}
            >
              <img src={localeFlags[locale]} alt={localeNames[locale]} />
              {` ${localeNames[locale]}`}
            </MenuItem>
          ))}
        </NavDropdown>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

Header.propTypes = {
  // react-router props
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,

  // state props
  currentLocale: PropTypes.string.isRequired,
  user: getPropTypeScheme('User'),

  // dispatch props
  setLocale: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentLocale: getLocale(state),
  user: selectUser(state),
});

const mapDispatchToProps = { setLocale };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Header)));
