const initialState = null;

/**
 * The `userAgentReducer` is dumb, because the state is wholly determined by
 * the `UAParser()` call done based on `req.headers['user-agent']` of the
 * initial request. This never changes, except in Chrome's Device Mode when you
 * switch between devices. A reload of the page is then required.
 */
const userAgentReducer = (state = initialState) => state;

export default userAgentReducer;
