import PropTypes from 'prop-types';
import React from 'react';
import {
  Button, ButtonToolbar, Col, ControlLabel, DropdownButton, Form,
  FormControl, FormGroup, HelpBlock, MenuItem, Row,
} from 'react-bootstrap';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { selectUserMetaByKey } from '../../../../../../app/modules/selectors/api';
import genericMessages from '../../../../../../app/lib/genericMessages';
import UploadBase64 from '../../../../../../app/components/UploadBase64';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import SummernoteInput from '../../../../../../app/components/Summernote';
import { vState } from '../../../../../../app/lib/utils';
import VimeoUpload from '../../../../../../app/components/VimeoUpload';

const messages = defineMessages({
  content: {
    id: 'projects.arriva.app.modules.ArriVideo.components.ContentItemForm.content',
    description: 'Content item content label.',
    defaultMessage: 'Content',
  },
  title: {
    id: 'projects.arriva.app.modules.ArriVideo.components.ContentItemForm.title',
    description: 'Content item title label.',
    defaultMessage: 'Title',
  },
  type: {
    id: 'projects.arriva.app.modules.ArriVideo.components.ContentItemForm.type',
    description: 'Content item type label.',
    defaultMessage: 'Type',
  },
  typeHtml: {
    id: 'projects.arriva.app.modules.ArriVideo.components.ContentItemForm.typeHtml',
    description: 'Content item type html label.',
    defaultMessage: 'HTML',
  },
  typeImage: {
    id: 'projects.arriva.app.modules.ArriVideo.components.ContentItemForm.typeImage',
    description: 'Content item type image label.',
    defaultMessage: 'Image',
  },
  typeVideo: {
    id: 'projects.arriva.app.modules.ArriVideo.components.ContentItemForm.typeVideo',
    description: 'Content item type video label.',
    defaultMessage: 'Video',
  },
  upload: {
    id: 'projects.arriva.app.modules.ArriVideo.components.ContentItemForm.uploadButtonText',
    description: 'Upload image button text.',
    defaultMessage: 'Upload image',
  },
});

const ContentItemForm = ({
  handleChange,
  handleSubmit,
  errors,
  intl,
  onClickDelete,
  isSubmitting,
  setFieldValue,
  summernoteProps,
  values,
  vimeoAccessToken,
  vimeoArriVideoProjectId,
}) => (
  <Form horizontal onSubmit={handleSubmit}>
    <Row>
      <Col xs={12} md={9} lg={9}>
        <FormGroup controlId="title" validationState={vState(errors, 'title')}>
          <ControlLabel>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <FormattedMessage {...messages.title} />
          </ControlLabel>

          <FormControl type="text" autoComplete="off" value={values.title} onChange={handleChange} />

          {errors.title && <HelpBlock>{errors.title}</HelpBlock>}
        </FormGroup>
      </Col>
    </Row>

    <Row>
      <Col xs={12} md={9} lg={9}>
        <FormGroup controlId="type" validationState={vState(errors, 'type')}>
          <ControlLabel>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <FormattedMessage {...messages.type} />
          </ControlLabel>

          <br />

          <DropdownButton
            id="typeDropdown"
            title={
              values.type
                ? intl.formatMessage(messages[`type${values.type.charAt(0).toUpperCase() + values.type.slice(1)}`])
                : intl.formatMessage(messages.type)
            }
          >
            {['html', 'image', 'video'].map((type) => (
              <MenuItem
                key={`type-${type}`}
                onSelect={() => {
                  setFieldValue('content', '');
                  setFieldValue('type', type);
                }}
              >
                {intl.formatMessage(messages[`type${type.charAt(0).toUpperCase() + type.slice(1)}`])}
              </MenuItem>
            ))}
          </DropdownButton>

          {errors.type && <HelpBlock>{errors.type}</HelpBlock>}
        </FormGroup>
      </Col>
    </Row>

    <Row>
      <Col xs={12} md={9} lg={9}>
        <FormGroup controlId="content" validationState={vState(errors, 'content')}>
          <ControlLabel>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <FormattedMessage {...messages.content} />
          </ControlLabel>

          {/* eslint-disable react/jsx-props-no-spreading */}
          {values.type === 'html' && (
            <SummernoteInput
              value={values.content}
              onChange={(content) => setFieldValue('content', content)}
              fileUploadHandler={(file, result) => new Promise((resolve) => {
                resolve({ file: result, name: file.name });
              })}
              {...summernoteProps}
            />
          )}
          {/* eslint-enable react/jsx-props-no-spreading */}

          {values.type === 'image' && (
            <div style={{ width: 250 }}>
              {(values.type === 'image' && values.content) && (
                <img
                  alt="thumbnail"
                  src={`data:image/png;base64,${values.content}`}
                  height={256}
                  style={{ marginBottom: 10 }}
                />
              )}

              <UploadBase64
                useCropper
                removeBase64DataUriPrefix
                buttonText={intl.formatMessage(messages.upload)}
                cropperProps={{
                  aspectRatio: 16 / 9,
                  scale: [455, 256],
                }}
                onFileReady={(data) => setFieldValue('content', data)}
              />
            </div>
          )}

          {(values.type === 'video' && vimeoAccessToken) && (
            <VimeoUpload
              accessToken={vimeoAccessToken}
              projectId={vimeoArriVideoProjectId}
              onUploadDone={(vimeoVideoId) => setFieldValue('content', vimeoVideoId)}
              videoId={values.content}
              title={values.title}
            />
          )}

          {errors.content && <HelpBlock>{errors.content}</HelpBlock>}
        </FormGroup>
      </Col>
    </Row>

    <Row>
      <Col xs={12} md={9} lg={9}>
        <FormGroup controlId="formActions">
          <ButtonToolbar>
            {values.id && (
              <Button bsStyle="danger" onClick={() => onClickDelete()}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <FormattedMessage {...genericMessages.delete} />
              </Button>
            )}

            <Button type="submit" disabled={isSubmitting || Object.keys(errors).length > 0}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...genericMessages.formSubmitLabel} />
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Col>
    </Row>
  </Form>
);

ContentItemForm.propTypes = {
  // Own props
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  summernoteProps: PropTypes.shape({}),

  errors: PropTypes.shape({
    content: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
  }),

  values: PropTypes.shape({
    content: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
  locale: PropTypes.string.isRequired,

  // state props
  vimeoAccessToken: PropTypes.string.isRequired,
  vimeoArriVideoProjectId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  vimeoAccessToken: selectUserMetaByKey(state, 'vimeoAccessToken'),
  vimeoArriVideoProjectId: selectUserMetaByKey(state, 'vimeoProjectIdArriVideo'),
});

export default connect(mapStateToProps)(injectIntl(ContentItemForm));
