import {
  find,
  path, propEq,
} from 'ramda';
import { createSelector } from 'reselect';

// Loading selectors
export const isLoadingMalfunctions = (state) => path(['malfunction', 'loadingMalfunctions'], state);
export const isLoadingMalfunctionsCategories = (state) => path(['malfunction', 'loadingMalfunctionsCategories'], state);

// Data selectors
export const selectMalfunctions = (state) => path(['malfunction', 'malfunctions'], state);
export const selectVehicleNumber = (state) => path(['malfunction', 'vehicleNumber'], state);
export const selectMalfunctionsCategories = (state) => path(['malfunction', 'malfunctionsCategories'], state);
export const selectIdentifier = (state, props) => path(['match', 'params', 'identifier'], props);

// Memoizing selectors
export const getMalfunctions = createSelector(
  [selectMalfunctions], (malfunctions) => {
    if (!malfunctions) return [];

    return malfunctions;
  });
export const getMalfunctionsCategories = createSelector(
  [selectMalfunctionsCategories], (malfunctionsCategories) => {
    if (!malfunctionsCategories) return [];

    return malfunctionsCategories;
  });

export const getCategoryByIdentifier = createSelector(
  [selectIdentifier, selectMalfunctionsCategories], (categoryId, categories) => {
    if (!categories || categories.length === 0) return false;
    if (!categoryId) return undefined;

    return find(propEq('id', categoryId))(categories);
  });

export const getVehicleNumber = createSelector(
  [selectVehicleNumber], (vehicleNumber) => {
    if (!vehicleNumber) return null;

    return vehicleNumber;
  });
