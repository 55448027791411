// Loaders
export const ESTABLISHMENTDATA_LOADING = 'ESTABLISHMENTDATA_LOADING';
export const ESTABLISHMENTDATA_LOADING_DONE = 'ESTABLISHMENTDATA_LOADING_DONE';

// Adders / Setters
export const ESTABLISHMENTDATA_SET_ESTABLISHMENTDATA = 'ESTABLISHMENTDATA_SET_ESTABLISHMENTDATA';

// Loading actions
export const loadingEstablishmentData = () => ({ type: ESTABLISHMENTDATA_LOADING });
export const loadingEstablishmentDataDone = () => ({ type: ESTABLISHMENTDATA_LOADING_DONE });

// Actions
export const setEstablishmentData = (data) => (dispatch) => dispatch({
  type: ESTABLISHMENTDATA_SET_ESTABLISHMENTDATA,
  payload: data,
});
