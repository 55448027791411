import axios from 'axios';
import { path } from 'ramda';

import { FetchError, ForbiddenError, NotFoundError } from './errors/fetch';

/**
 * Fetches a secure file from the API server using an authenticated CORS
 * request, and returns the result as a Blob.
 *
 * @example
 * fetchSecureFileAsBlob('https://api.openintranet.nl/smedia/files/filer_public/11/aa/11aa-bb-cc33/henk.jpg__avatar.jpg',
 * 'eyJ0e...abcdef')
 *   .then(blob => console.log(blob))
 *   .catch(error => console.error(error));
 *
 * @param {String} url The URL of the secure file
 * @param {String} token The JWT for authentication
 * @returns {Promise} A Promise that resolves with the Blob
 */
export const fetchSecureFileAsBlob = (url, token) => {
  const options = {
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  };

  const promise = new Promise((resolve, reject) => {
    if (!url) {
      console.error(`[OI] fetchSecureFileAsBlob ERROR: url=${url}`);

      return reject(new NotFoundError(`File not found: ${url}`));
    }

    axios(url, options)
      .then((response) => {
        if (response.statusText !== 'OK') {
          let error;

          switch (response.status) {
            case 403: {
              error = new ForbiddenError(`File forbidden: ${url}`);
              break;
            }
            case 404: {
              error = new NotFoundError(`File not found: ${url}`);
              break;
            }
            default: {
              error = new FetchError(`Fetch failed: ${response.statusText}`);
            }
          }

          return reject(error);
        }

        return resolve({
          data: response.data,
          type: response.headers['content-type'],
        });
      })
      .catch((error) => {
        console.error(`[OI] Fetch failed for ${url}: ${error.message}`);
        reject(new FetchError(`Fetch failed: ${error.message}`));
      });
  });

  return promise;
};

/**
 * Fetches a secure file from the API server using an authenticated CORS
 * request, and returns the resulting data as Base64-encoded string.
 *
 * @example
 * fetchSecureFileAsBase64('https://api.openintranet.nl/smedia/files/filer_public/11/aa/11aa-bb-cc33/henk.jpg__avatar.jpg',
 * 'eyJ0e...abcdef')
 *   .then(result => this.setState({ image: <img src={result} /> }))
 *   .catch(error => console.error(error));
 *
 * @param {String} url The URL of the secure file
 * @param {String} token The JWT for authentication
 * @returns {Promise} A Promise that resolves with the file data in Base64
 */
export const fetchSecureFileAsBase64 = (url, token) => {
  const promise = new Promise((resolve, reject) => {
    fetchSecureFileAsBlob(url, token)
      .then(({ data, type }) => {
        if (process.env.BUILD_TARGET === 'server') {
          const base64 = Buffer.from(data).toString('base64');

          resolve(`data:${type};base64,${base64}`);
        } else {
          const reader = new window.FileReader();

          reader.readAsDataURL(data);
          reader.onloadend = () => resolve(reader.result);
        }
      })
      .catch((error) => reject(error));
  });

  return promise;
};

/**
 * Upload a file to the API server using an authenticated CORS request, and
 * returns the resulting file or image object.
 *
 * @example
 * createSecureFileFromBase64('data:image/jpeg;base64,...', 'henk.jpg',
 * 'https://api.openintranet.nl/api/', 'Bearer eyJ0e...abcdef', 1)
 *   .then({ id, object: { attributes: { file, height, width } }, name } => {
 *     console.log(arguments);
 *   })
 *   .catch(error => console.error(error));
 *
 * @param {String} base64 Base64 file data
 * @param {String} name The file name
 * @param {String} baseURL Base URL of the API
 * @param {String} token Axios Authorization header contents
 * @param {Number} folderId Optional folder id to put the file in
 * @returns {Promise} A Promise that resolves with the JSON:API file object
 */
export const createSecureFileFromBase64 = (base64, name, baseURL, token, folderId = null) =>
  new Promise((resolve, reject) => {
    const isImage = /^data:image\/(jpeg|jpg|png|gif)/.test(base64);
    const resource = {
      type: isImage ? 'image' : 'file',
      attributes: {
        file: base64,
        name,
        originalFilename: name,
      },
    };

    if (folderId) resource.attributes.folder = `${baseURL}/folder/${folderId}`;

    const options = {
      baseURL,
      data: JSON.stringify({ data: resource }),
      headers: {
        Authorization: token,
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      },
      method: 'POST',
      url: isImage ? 'image' : 'file',
    };

    axios(options)
      .then((response) => {
        const id = path(['data', 'data', 'id'], response);
        const object = path(['data', 'data'], response);

        if (id) resolve({ id, object, name });
        else throw new Error('Server response is missing a unique id');
      })
      .catch((error) => {
        console.error(`[OI] createSecureFileFromBase64 error=${error}`);
        reject(new FetchError(`Create failed: ${error.message}`));
      });
  });

export const humanFileSize = (size) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / (1024 ** i)).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

/**
 * Fetch content-types anonymously from the API.
 *
 * @param {String} baseURL The base URL to fetch from.
 * @returns {Promise} A Promise that resolves with the JSON data of the
 * response.
 */
export const fetchContentTypes = (baseURL) => {
  const options = {
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return new Promise((resolve, reject) => {
    axios(`${baseURL}/content-type?page_size=100`, options)
      .then(({ data }) => resolve(data))
      .catch((error) => {
        console.error(`[OI] fetchContentTypes failed: ${error}`);
        reject();
      });
  });
};

/**
 * Fetch an API endpoint directly using a token.
 *
 * @param {String} endpoint The endpoint to fetch from the API.
 * @param {String} token The JWT to authenticate with.
 * @returns {Promise} A Promise that resolves with the JSON data of the
 * response.
 */
export const fetchEndpoint = (endpoint, token) => {
  const options = {
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
      Authorization: `Bearer ${token}`,
    },
  };

  return new Promise((resolve, reject) => {
    axios(endpoint, options)
      .then(({ data }) => resolve(data))
      .catch((error) => {
        console.error(`[OI] fetchEndpoint error=${error}`);
        reject(error);
      });
  });
};

/**
 * Fetch an API endpoint directly anonymously.
 *
 * @param {String} endpoint The endpoint to fetch from the API.
 * @returns {Promise} A Promise that resolves with the JSON data of the
 * response.
 */
export const fetchEndpointAnonymous = (endpoint) => {
  const options = {
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return new Promise((resolve, reject) => {
    axios(endpoint, options)
      .then(({ data }) => resolve(data))
      .catch((error) => {
        console.error(`[OI] fetchEndpointAnonymous endpoint=${endpoint} error=${error}`);
        reject(error);
      });
  });
};
