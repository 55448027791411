import { filter, find, path, propEq } from 'ramda';
import { createSelector } from 'reselect';

/**
 * GENERIC REUSABLE SELECTORS
 */
export const selectId = (state, id) => id;
export const findById = (items, id) => {
  if (!id || !items || items.length === 0) return;

  return find(propEq('id', String(id)))(items);
};
export const findByPathId = filterPath => (items, id) => {
  if (!id || !items || items.length === 0 || !filterPath) return;

  return find(item => Number(path(filterPath, item)) === Number(id))(items);
};
export const filterByPathId = (empty, filterPath) => (items, id) => {
  if (!id || !items || items.length === 0 || !filterPath) return empty;

  return filter(item => Number(path(filterPath, item)) === Number(id))(items);
};

/**
 * GROUP FOLDERS
 */
const emptyGroupFolders = [];
export const selectGroupFolders = state => path(['api', 'group-folder', 'data'], state) || emptyGroupFolders;
export const selectGroupFolderById = createSelector([selectGroupFolders, selectId], findById);
export const selectGroupFolderByGroupId = createSelector([selectGroupFolders, selectId], findByPathId(['relationships', 'group', 'data', 'id']));
export const selectGroupFoldersByGroupId = createSelector(
  [selectGroupFolders, selectId],
  filterByPathId(emptyGroupFolders, ['relationships', 'group', 'data', 'id']),
);

/**
 * FOLDERS
 */
const emptyFolders = [];
export const selectFolders = state => path(['api', 'folder', 'data'], state) || emptyFolders;
export const selectFolderById = createSelector([selectFolders, selectId], findById);
export const selectFoldersByParentId = createSelector(
  [selectFolders, selectId],
  filterByPathId(emptyFolders, ['relationships', 'parent', 'data', 'id']),
);

/**
 * FILES
 */
const emptyFiles = [];
export const selectFiles = state => path(['api', 'file', 'data'], state) || emptyFiles;
export const selectFileById = createSelector([selectFiles, selectId], findById);
export const selectFilesByFolderId = createSelector(
  [selectFiles, selectId],
  filterByPathId(emptyFiles, ['relationships', 'folder', 'data', 'id']),
);
export const selectFilesWithoutFolder = createSelector(
  [selectFiles],
  (files) => {
    if (!files || files.length === 0) return emptyFiles;

    return filter(f => path(['relationships', 'folder', 'data'], f) === null)(files);
  },
);
