import { get } from '../lib/driveApi';

export const ARRIVA_SESSION_DESTROY = 'ARRIVA_SESSION_DESTROY';
export const ARRIVA_SET_DRIVE_TOKEN = 'ARRIVA_SET_DRIVE_TOKEN';
export const ARRIVA_SET_PERMISSIONS = 'ARRIVA_SET_PERMISSIONS';
export const ARRIVA_SET_PERMISSIONS_FETCHED = 'ARRIVA_SET_PERMISSIONS_FETCHED';

export const destroy = () => (dispatch) => dispatch({ type: ARRIVA_SESSION_DESTROY });
export const setDriveAccessToken = (token) => (dispatch) =>
  dispatch({ type: ARRIVA_SET_DRIVE_TOKEN, payload: token });
export const setDrivePermissions = (permissions) => (dispatch) =>
  dispatch({ type: ARRIVA_SET_PERMISSIONS, payload: permissions });
export const setDrivePermissionsFetched = () => (dispatch) =>
  dispatch({ type: ARRIVA_SET_PERMISSIONS_FETCHED });

export const hasDrivePermissions = (resourceType, resourceKey) => (dispatch, getState) => {
  const state = getState();

  if ('arriva' in state && 'permissions' in state.arriva) {
    for (let i = 0; i < state.arriva.permissions.length; i += 1) {
      const permission = state.arriva.permissions[i];

      if (
        'resourceType' in permission && permission.resourceType === resourceType
        && 'resourceKey' in permission && permission.resourceKey === resourceKey
      ) {
        // User is authorized for this combination of resource type and key, no need to check
        // further permissions.
        return true;
      }
    }
  }

  // The user is not authorized.
  return false;
};

export const fetchDrivePermissions = () => (dispatch) => {
  dispatch(get({ path: 'account/user', parallel: false }))
    .then((result) => {
      if ('permissions' in result) {
        dispatch(setDrivePermissions(result.permissions));
      }
      dispatch(setDrivePermissionsFetched());
    });
};
