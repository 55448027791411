/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HeaderBar from '../header-bar';


export default class Camera extends Component {
  static propTypes = {
    _onDestroy: PropTypes.func,
    data: PropTypes.shape({
      label: PropTypes.string,
      pageBreakBefore: PropTypes.bool,
      inline: PropTypes.bool,
    }),
    defaultValue: PropTypes.shape({}),
    editModeOn: PropTypes.func,
    error: PropTypes.string,
    parent: PropTypes.shape({}),
    mutable: PropTypes.bool,
    onEdit: PropTypes.func,
    readOnly: PropTypes.bool,
    handleChange: PropTypes.func,
  };

  state = {
    image: null,
  };

  getSubmissionValue(field) {
    return field.value;
  }

  displayImage(e) {
    var self = this;
    var target = e.target;
    var file, reader;

    if(target.files && target.files.length) {
      file = target.files[0];
      reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = function() {
        self.setState({
          img: reader.result
        });
      }
    }
  }

  clearImage() {
    this.setState({
      img: null
    })
  }

  render() {

    let baseClasses = 'rfb-item';
    if (this.props.data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }

    return (
      <div className={baseClasses}>
        { !this.props.mutable &&
        <div>
          { this.props.data.pageBreakBefore &&
          <div className="preview-page-break">Page Break</div>
          }
          <HeaderBar parent={this.props.parent} editModeOn={this.props.editModeOn} data={this.props.data} onDestroy={this.props._onDestroy} onEdit={this.props.onEdit} static={this.props.data.static} required={this.props.data.required} />
        </div>
        }
        <div className="form-group">
          <label>
            {this.props.data.label}
            { (this.props.data.hasOwnProperty('required') && this.props.data.required === true  && !this.props.readOnly) &&
            <span className="label-required label label-danger">Verplicht</span>
            }
          </label>
          <div className="image-upload-container">

            { !this.state.img &&
            <div>
              <input type="file" accept="image/*" capture="camera" className="image-upload" onChange={this.displayImage} />
              <div className="image-upload-control">
                <div className="btn btn-default btn-school"><i className="fa fa-camera"></i> Upload Photo</div>
                <p>Selecteer een afbeelding van uw computer of apparaat.</p>
              </div>
            </div>
            }

            { this.state.img &&
            <div>
              <img alt="" src={ this.state.img } height="100" className="image-upload-preview" /><br />
              <div className="btn btn-school btn-image-clear" onClick={this.clearImage}>
                <i className="fa fa-times"></i> Clear Photo
              </div>
            </div>
            }

          </div>
        </div>
      </div>
    );
  }
}
