import PropTypes from 'prop-types';
// noinspection ES6CheckImport
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { path } from 'ramda';
import { get, post } from '../../../lib/driveApi';
import ConfirmRegistrationModal from './ConfirmRegistrationModal';
import UserSelection from './UserSelection';

// Strings used in the AHS response.
const AHS_MSG_STATUS_SUCCESS = 'Success';

class TabletRegistration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apiError: false,
      canRegister: false,
      identifier: null,
      loadingDevice: true,
      loadingRegistration: false,
      loadingUsers: false,
      message: '',
      registrationSucceeded: null,
      selectedUser: null,
      showRegistrationModal: false,
      users: null,
    };
  }

  /**
   * Gets the device data for the given identifier. This determines whether the device may be
   * registered to a user.
   */
  componentDidMount = () => {
    const { match: { params: { identifier } }, get } = this.props;

    // Store the identifier in the state.
    this.setState({ identifier, loadingDevice: true });

    // Fetch the current status for the given device identifier.
    get({ path: `tablet-registration/check-status/${identifier}` })
      .then((result) => {
        const canRegister = path(['status'], result) === AHS_MSG_STATUS_SUCCESS;
        const message = path(['message'], result);
        this.setState({ loadingDevice: false, canRegister, message });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingDevice: false, apiError: true });
      });
  }

  /**
   * Handle the input from the user search form. Asks the Drive! API to give suggestions.
   *
   * @param value The input provided by the user.
   */
  onChangeNameInputHandler = (value) => {
    const { get } = this.props;

    // Only search when there's at least 3 characters.
    if (value.length < 3) {
      this.setState({ users: null });
      return;
    }

    this.setState({ loadingUsers: true, selectedUser: null });

    get({ path: `tablet-registration/suggest/${value}` })
      .then((result) => {
        const users = path(['User'], result);
        this.setState({ loadingUsers: false, users });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingUsers: false, apiError: true });
      });
  }

  /**
   * Sets the currently selected user
   *
   * @param user
   */
  onSelectUserHandler = (user) => {
    this.setState({ selectedUser: user, showRegistrationModal: true });
  }

  /**
   * Executes the registration by POSTing all required data to the Drive! API, which proxies the
   * data to the AHS API via the koppelserver.
   */
  onExecuteRegistrationHandler = () => {
    const { post } = this.props;
    const { identifier, selectedUser } = this.state;

    this.setState({ loadingRegistration: true });

    post({ path: 'tablet-registration/register-device', body: { identifier, userId: selectedUser.personnelNumber } })
      .then((result) => {
        // Result contains something like this:
        // {
        //   "status": "Success",
        //   "message": {
        //     "Gebruiker": "pieter.gootjes",
        //     "Editor": "marc.diepenmaat",
        //     "Tablet": "49594",
        //     "Simkaart": "47270"
        //   }
        // }
        this.setState({
          loadingRegistration: false,
          message: path(['message'], result),
          registrationSucceeded: path(['status'], result) === AHS_MSG_STATUS_SUCCESS,
          showRegistrationModal: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          apiError: true,
          loadingRegistration: false,
          showRegistrationModal: false,
        });
      });
  }

  /**
   * Closes the registration confirmation modal
   */
  onCloseModalHandler = () => {
    this.setState({ showRegistrationModal: false });
  }

  /**
   * Render method
   */
  render() {
    const {
      apiError,
      canRegister,
      identifier,
      loadingDevice,
      loadingRegistration,
      loadingUsers,
      message,
      registrationSucceeded,
      selectedUser,
      showRegistrationModal,
      users,
    } = this.state;

    let content;
    // Entry point for this component: get the device data.
    if (loadingDevice) {
      content = <p>Gegevens worden opgehaald voor CI-nummer {identifier}...</p>; // eslint-disable-line

    // There was an API error, but unclear what it was. This should have been logged to the console.
    } else if (apiError) {
      content = <p>Er is een onbekende fout opgetreden! Probeer het later nog eens...</p>;

    // Successful registration, yay!
    } else if (registrationSucceeded) {
      content = (
        <>
          {/* eslint-disable-next-line max-len,react/jsx-one-expression-per-line */}
          <p>Het CI-nummer <code>{identifier}</code> is met succes gekoppeld aan gebruiker {selectedUser.displayName}!</p>
          {message && (
            <div className="well">
              <p>{message}</p>
            </div>
          )}
        </>
      );

    // Something went wrong in the registration call. Output the received error message to the user.
    } else if (canRegister && registrationSucceeded === false) {
      content = (
        <>
          <p>Registratie is mislukt. De opgegeven reden is:</p>
          <div className="well">
            <p>{message}</p>
          </div>
        </>
      );

      //
    } else if (!canRegister) {
      content = (
        <>
          <p>Registratie is niet mogelijk. De opgegeven reden is:</p>
          <div className="well">
            <p>{message}</p>
          </div>
        </>
      );

    // Device is known and a user can be registered to it.
    } else if (canRegister) {
      content = (
        <>
          {/* eslint-disable-next-line */}
          <h5>{message}</h5>
          <p>Zoek een medewerker om te koppelen met CI-nummer <code>{identifier}</code>.</p>
          <UserSelection
            changeInput={this.onChangeNameInputHandler}
            loading={loadingUsers}
            selectUser={this.onSelectUserHandler}
            users={users}
          />
          <ConfirmRegistrationModal
            close={this.onCloseModalHandler}
            identifier={identifier}
            loading={loadingRegistration}
            register={this.onExecuteRegistrationHandler}
            showRegistrationModal={showRegistrationModal}
            user={selectedUser}
          />
        </>
      );
    }

    return (
      <div className="well">
        <h2>Device uitgeven</h2>
        {content}
      </div>
    );
  }
}

TabletRegistration.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,

  // Dispatch props
  get: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = { get, post };

export default connect(mapStateToProps, mapDispatchToProps)(TabletRegistration);
