import Modal from 'react-bootstrap/lib/Modal';
import PropTypes from 'prop-types';
import React, { Children, cloneElement, Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'react-bootstrap/lib/Button';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Tooltip from 'react-bootstrap/lib/Tooltip';

import 'animate.css';

import genericMessages from '../../lib/genericMessages';

import './Modal.less';

class ModalWindow extends Component {
  static propTypes = {
    // Own props.
    onHide: PropTypes.func, // Callback function that's run when hidden.

    // State props.
    title: PropTypes.string,
    children: PropTypes.object, // eslint-disable-line
  };

  state = {
    show: true,
  };

  componentWillReceiveProps() {
    this.setState({ show: true });
  }

  onHide() {
    const { onHide } = this.props;

    this.setState({ show: false });

    if (onHide) onHide();
  }

  render() {
    const { title, children } = this.props;
    const { show } = this.state;

    return (
      <Modal
        className="animated zoomInUp"
        animation={false}
        show={show}
        onHide={() => this.onHide()}
        bsSize="large"
      >
        <Modal.Body>
          <div className="modal-title navbar navbar-inverse">
            {title}
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-modal-cancel">
                  <FormattedMessage {...genericMessages.cancel} />
                </Tooltip>
              }
            >
              <Button bsSize="xsmall" className="modal-close-button" onClick={() => this.onHide()}>
                <Glyphicon glyph="remove" />
              </Button>
            </OverlayTrigger>
          </div>
          <div className="modal-inner-body">
            {Children.map(children, (child =>
              cloneElement(child, { closeModal: () => this.onHide() })
            ))}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalWindow;
