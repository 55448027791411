import PropTypes from 'prop-types';

export const notification = PropTypes.shape({
  // Default props
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  sendAt: PropTypes.string,
  pushNotification: PropTypes.bool.isRequired,

  // Updated and created dates
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
});
