import { contains, filter, find, isNil, path, pathEq, propEq } from 'ramda';
import { createSelector } from 'reselect';

import { selectUserId as selectCurrentUserId } from './session';

/**
 * Basic API configuration selectors.
 */
export const selectBaseURL = state => path(['api', 'endpoint', 'axiosConfig', 'baseURL'], state);

/**
 * Static empty arrays as defaults for API state selectors.
 */
const emptyGroups = [];
const emptyGroupMetas = [];
const emptyUsers = [];
const emptyUserMetas = [];
const emptyContentTypes = [];

/**
 * GROUPS, GROUPMETA, USERS, USERMETA
 */
export const selectGroups = state => (path(['api', 'group', 'data'], state) || emptyGroups);
export const selectGroupMetas = state => (path(['api', 'group-meta', 'data'], state) || emptyGroupMetas);
export const selectUsers = state => (path(['api', 'user', 'data'], state) || emptyUsers);
export const selectUserMetas = state => (path(['api', 'user-meta', 'data'], state) || emptyUserMetas);

export const selectGroupId = (state, groupId) => groupId;
export const selectUserId = (state, userId) => userId;
export const selectUsername = (state, username) => username;

export const selectGroupByGroupId = createSelector(
  [selectGroups, selectGroupId],
  (groups, groupId) => {
    if (!groupId) return;
    if (groups.length === 0) return;

    return find(propEq('id', String(groupId)))(groups);
  },
);

export const selectGroupMetasByGroupId = createSelector(
  [selectGroupMetas, selectGroupId],
  (groupmetas, groupId) => {
    if (!groupId || groupmetas.length === 0) return emptyGroupMetas;

    return filter(gm => Number(gm.relationships.group.data.id) === Number(groupId))(groupmetas);
  },
);

export const selectUserByUserId = createSelector(
  [selectUsers, selectUserId],
  (users, userId) => {
    if (!userId) return;
    if (users.length === 0) return;

    return find(propEq('id', String(userId)))(users);
  },
);

export const selectUserByCurrentUserId = createSelector(
  [selectUsers, selectCurrentUserId],
  (users, userId) => {
    if (!userId) return;
    if (users.length === 0) return;

    return find(propEq('id', String(userId)))(users);
  },
);

export const selectUserByUsername = createSelector(
  [selectUsers, selectUsername],
  (users, username) => {
    if (!username || users.length === 0) return;

    return find(user => path(['attributes', 'username'], user) === username)(users);
  },
);

export const selectUsersByGroupId = createSelector(
  [selectUsers, selectGroupId],
  (users, groupId) => {
    if (!groupId) return [];
    if (users.length === 0) return emptyUsers;

    const isUserInGroup = (user) => {
      const userGroups = path(['relationships', 'groups', 'data'], user) || [];

      return !!(find(propEq('id', String(groupId)))(userGroups));
    };

    return filter(isUserInGroup)(users);
  },
);

export const selectGroupsByUserId = createSelector(
  [selectGroups, selectUserByUserId],
  (groups, user) => {
    if (!user || groups.length === 0) return emptyGroups;

    const groupIds = (path(['relationships', 'groups', 'data'], user) || []).map(g => Number(g.id));

    return filter(g => contains(Number(g.id), groupIds))(groups);
  },
);

export const selectGroupsByCurrentUserId = createSelector(
  [selectGroups, selectUserByCurrentUserId],
  (groups, user) => {
    if (!user || groups.length === 0) return emptyGroups;

    const groupIds = (path(['relationships', 'groups', 'data'], user) || []).map(g => Number(g.id));

    return filter(g => contains(Number(g.id), groupIds))(groups);
  },
);

export const selectGroupsByUsername = createSelector(
  [selectGroups, selectUserByUsername],
  (groups, user) => {
    if (!user || groups.length === 0) return emptyGroups;

    const groupIds = (path(['relationships', 'groups', 'data'], user) || []).map(g => Number(g.id));

    return filter(g => contains(Number(g.id), groupIds))(groups);
  },
);

export const selectUserMetasByUserId = createSelector(
  [selectUserMetas, selectUserId],
  (usermetas, userId) => {
    if (!userId || usermetas.length === 0) return emptyUserMetas;

    return filter(um => Number(um.relationships.user.data.id) === Number(userId))(usermetas);
  },
);

export const selectUserMetasByCurrentUserId = createSelector(
  [selectUserMetas, selectCurrentUserId],
  (usermetas, userId) => {
    if (!userId || usermetas.length === 0) return emptyUserMetas;

    return filter(um => Number(um.relationships.user.data.id) === Number(userId))(usermetas);
  },
);

export const selectUserMetasByUsername = createSelector(
  [selectUserMetas, selectUserByUsername],
  (usermetas, user) => {
    if (!user || usermetas.length === 0) return emptyUserMetas;

    return filter(um => Number(um.relationships.user.data.id) === Number(user.id))(usermetas);
  },
);

export const selectUserMetasByUsernameAsMap = createSelector(
  [selectUserMetasByUsername],
  (usermetas) => {
    const map = new Map();

    if (usermetas.length === 0) return map;

    usermetas.forEach((um) => {
      const key = um.attributes.metaKey;
      const value = um.attributes.metaValue;

      map.set(key, value);
    });

    return map;
  },
);

export const selectUserMetaByKey = createSelector(
  [selectUserMetas, (state, key) => key], (userMetas, key) => {
    if (!userMetas || !key) return undefined;

    return path(['attributes', 'metaValue'], find(pathEq(['attributes', 'metaKey'], key))(userMetas));
  },
);

/**
 * GALLERIES
 */
export const selectGalleries = state => path(['api', 'gallery', 'data'], state) || [];

export const selectGalleryId = (state, galleryId) => galleryId;

export const selectGalleryById = createSelector(
  [selectGalleries, selectGalleryId],
  (galleries, galleryId) => {
    if (!galleryId || galleries.length === 0) return;
    return find(propEq('id', String(galleryId)))(galleries);
  },
);

export const selectGalleriesByGroupId = createSelector(
  [selectGalleries, selectGroupId],
  (galleries, groupId) => {
    if (!groupId) return;
    if (galleries.length === 0) return;
    const isGalleryInGroup = (gallery) => {
      const galleryGroup = [path(['relationships', 'group', 'data'], gallery)] || [];
      return !!(find(propEq('id', String(groupId)))(galleryGroup));
    };

    return filter(isGalleryInGroup)(galleries);
  },
);

/**
 * FILES
 */
export const selectFiles = state => (
  path(['api', 'file', 'data'], state) || undefined
);

export const selectFolderPermissions = state => (
  path(['api', 'folder-permission', 'data'], state) || []
);

export const selectFolderId = (state, folderId) => folderId;

export const selectFolderPermissionsByFolderId = createSelector(
  [selectFolderPermissions, selectFolderId],
  (folderPermissions, folderId) => {
    if (!folderId) return [];
    if (folderPermissions.length === 0) return [];

    const isFolderPermissionForFolder = (folderPermission) => {
      const thisFolderId = path(
        ['relationships', 'folder', 'data', 'id'],
        folderPermission,
      );

      return thisFolderId === String(folderId);
    };

    return filter(isFolderPermissionForFolder)(folderPermissions);
  },
);

/**
 * CONTENT-TYPES
 */
export const selectContentTypes = state => (
  path(['api', 'content-type', 'data'], state) || emptyContentTypes
);

export const selectModelName = (state, modelName) => modelName;

export const selectContentTypeByModelName = createSelector(
  [selectContentTypes, selectModelName],
  (contentTypes, modelName) => {
    if (!modelName) return;
    if (!contentTypes || contentTypes.length === 0) return;

    return find(ct => path(['attributes', 'model'], ct) === modelName)(contentTypes);
  },
);

/**
 * MENUS
 */
const emptyMenus = [];
export const selectMenus = state => path(['api', 'menu', 'data'], state) || emptyMenus;
export const selectMenuId = (state, menuId) => menuId;
export const selectMenuIdentifier = (state, menuIdentifier) => menuIdentifier;
export const selectMenuById = createSelector(
  [selectMenus, selectMenuId],
  (menus, menuId) => {
    if (!menuId || menus.length === 0) return;

    return find(propEq('id', String(menuId)))(menus);
  },
);
export const selectMenuByIdentifier = createSelector(
  [selectMenus, selectMenuIdentifier],
  (menus, menuIdentifier) => {
    if (!menuIdentifier || menus.length === 0) return;

    return find(pathEq(['attributes', 'identifier'], String(menuIdentifier)))(menus);
  },
);

/**
 * ARTICLES
 */
const emptyArticles = [];
export const selectArticles = state => path(['api', 'article', 'data'], state) || emptyArticles;
export const selectArticleTypeId = (state, articleTypeId) => articleTypeId;
export const selectArticlesByTypeId = createSelector(
  [selectArticles, selectArticleTypeId],
  (articles, articleTypeId) => {
    if (isNil(articleTypeId) || articles.length === 0) return emptyArticles;

    return filter(
      pathEq(['relationships', 'articleType', 'data', 'id'], String(articleTypeId)),
    )(articles);
  },
);
