/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { connect } from 'react-redux';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import FormElementsEdit from './form-elements-edit';
import * as FormElements from './form-elements';
import { addElement, deleteElement, updateOrder } from '../reducerActions';

class Preview extends Component {
  static propTypes = {
    _onDestroy: PropTypes.func,
    className: PropTypes.string,
    data: PropTypes.shape({
      field_name: PropTypes.string,
      label: PropTypes.string,
      pageBreakBefore: PropTypes.bool,
      inline: PropTypes.bool,
    }),
    deleteElement: PropTypes.func,
    editElement: PropTypes.func,
    editMode: PropTypes.bool,
    editModeOn: PropTypes.func,
    error: PropTypes.string,
    files: PropTypes.array,
    parent: PropTypes.shape({}),
    manualEditModeOff: PropTypes.func,
    mutable: PropTypes.bool,
    onEdit: PropTypes.func,
    read_only: PropTypes.bool,
    handleChange: PropTypes.func,
    showCorrectColumn: PropTypes.bool,
    updateOrder: PropTypes.func,
    variables: PropTypes.shape([]),
  };

  constructor(props) {
    super(props);

    this.state = {
      answerData: {},
    };
  }

  _setValue(text) {
    return text.replace(/[^A-Z0-9]+/ig, '_').toLowerCase();
  }

  updateElement(element) {
    const { data: { fields }, updateOrder } = this.props;
    let found = false;

    for (let i = 0, len = fields.length; i < len; i += 1) {
      if (element.id === fields[i].id) {
        fields[i] = element;
        found = true;
        break;
      }
    }

    if (found) {
      updateOrder(fields);
    }
  }

  _onChange(data) {
    const answerData = {};

    data.forEach((item) => {
      if (item.readOnly && this.props.variables[item.variableKey]) {
        answerData[item.field_name] = this.props.variables[item.variableKey];
      }
    });

    this.setState({ data, answerData });
  }

  _onDestroy(item) {
    const { deleteElement } = this.props;

    deleteElement(item);
  }

  handleSort({ oldIndex, newIndex }) {
    const { data: { fields }, updateOrder } = this.props;

    const sorted = arrayMove(fields, oldIndex, newIndex);

    updateOrder(sorted);
  }

  render() {
    const {
      data: {
        fields,
      },
      parent,
      editMode,
      editModeOn,
      editElement,
      className,
      showCorrectColumn,
      files,
      manualEditModeOff,
    } = this.props;

    const { answerData } = this.state;

    let classes = className;
    if (editMode) classes += ' is-editing';

    const items = fields.map((item) => {
      const FormElement = FormElements[item.element];

      const props = {
        mutable: false,
        parent: parent,
        editModeOn: editModeOn,
        isDraggable: true,
        key: item.id,
        sortData: item.id,
        data: item,
        _onDestroy: this._onDestroy.bind(this),
        read_only: item.readOnly,
        defaultValue: answerData[item.field_name],
      };

      return <FormElement {...props} />;
    });

    // We need a div or other html tag around the element
    // otherwise connected component will be refused
    const SortableItem = SortableElement(({ value }) => <div className="sortable-element">{value}</div>);
    const SortableList = SortableContainer(({ items }) => {
      return (
        <div className="Sortable">
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} value={value} />
          ))}
        </div>
      );
    });

    return (
      <div className={classes}>
        <div className="edit-form">
          {editElement !== null &&
            <FormElementsEdit
              showCorrectColumn={showCorrectColumn}
              files={files}
              manualEditModeOff={manualEditModeOff}
              preview={this}
              element={editElement}
              updateElement={this.updateElement}
            />
          }
        </div>

        {items && items.length === 0 &&
          <div className="no-forms">
            Voeg elementen toe door in de sidebar op het gewenste element te klikken
          </div>
        }

        <SortableList items={items} onSortEnd={this.handleSort.bind(this)} distance={20} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  data: path(['forms', 'formData'], state),
  showCorrectColumn: path(['showCorrectColumn'], props) || false,
  files: path(['files'], props) || [],
  editMode: path(['editMode'], props) || false,
  editElement: path(['editElement'], props) || null,
  className: path(['className'], props) || 'react-form-builder-preview pull-left',
});

const mapDispatchToProps = {
  addElement,
  deleteElement,
  updateOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(Preview);

