import {
  MALFUNCTIONS_LOADING_MALFUNCTIONS,
  MALFUNCTIONS_LOADING_MALFUNCTIONS_DONE,
  MALFUNCTIONS_SET_MALFUNCTIONS,
  MALFUNCTIONS_LOADING_MALFUNCTIONS_CATEGORIES,
  MALFUNCTIONS_LOADING_MALFUNCTIONS_CATEGORIES_DONE,
  MALFUNCTIONS_SET_MALFUNCTIONS_CATEGORIES,
  MALFUNCTIONS_SET_VEHICLE_NUMBER,
} from './actions';

import { registerWithRootReducer } from '../../../../../app/modules/reducers/projects';

const ACTION_HANDLERS = {
  // Loaders
  [MALFUNCTIONS_LOADING_MALFUNCTIONS]: (state) => ({ ...state, loadingMalfunctions: true }),
  [MALFUNCTIONS_LOADING_MALFUNCTIONS_DONE]: (state) => ({
    ...state,
    loadingMalfunctions: false,
  }),
  [MALFUNCTIONS_LOADING_MALFUNCTIONS_CATEGORIES]: (state) => ({
    ...state,
    loadingMalfunctionsCategories: true,
  }),
  [MALFUNCTIONS_LOADING_MALFUNCTIONS_CATEGORIES_DONE]: (state) => ({
    ...state,
    loadingMalfunctionsCategories: false,
  }),

  // Adders / Setters
  [MALFUNCTIONS_SET_MALFUNCTIONS]: (state, action) => ({
    ...state,
    malfunctions: action.payload,
  }),
  [MALFUNCTIONS_SET_MALFUNCTIONS_CATEGORIES]: (state, action) => ({
    ...state,
    malfunctionsCategories: action.payload,
  }),
  [MALFUNCTIONS_SET_VEHICLE_NUMBER]: (state, action) => ({
    ...state,
    vehicleNumber: action.payload,
  }),
};

const initialState = {
  malfunctions: [],
  loadingMalfunctions: false,
  malfunctionsCategories: [],
  loadingMalfunctionsCategories: false,
  vehicleNumber: null,
};

registerWithRootReducer('malfunction', (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
});
