import React, { useState } from 'react';
import {
  Button,
  ButtonToolbar,
  Col, Form, FormControl, FormGroup, Row,
} from 'react-bootstrap';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from '../../../../lib/driveApi';
import {
  loadingMalfunctions, loadingMalfunctionsDone, setMalfunctions, setVehicleNumber,
} from '../../actions';
import IntlShape from '../../../../../../../app/lib/PropTypes/IntlShape';
import { getVehicleNumber } from '../../selectors';

const messages = defineMessages({
  submitVehicleNumber: {
    id: 'projects.arriva.app.modules.Malfunctions.components.VehicleForm.submit',
    description: 'Submit label',
    defaultMessage: 'Fetch pending malfunctions',
  },
  enterVehicleNumber: {
    id: 'projects.arriva.app.modules.Malfunctions.components.VehicleForm.enter',
    description: 'Enter label',
    defaultMessage: 'Enter vehicle-number',
  },
});

const VehicleForm = ({
  get,
  vehicleNumber,
  setMalfunctions,
  setVehicleNumber,
  intl: { formatMessage },
  loadingMalfunctions,
  loadingMalfunctionsDone,
}) => {
  const [error, setError] = useState(undefined);

  function fetchMalfunctions() {
    loadingMalfunctions();
    get({ path: `malfunctions/${vehicleNumber}/open` })
      .then((result) => {
        setMalfunctions(result);
      })
      .catch((error) => {
        console.error(`Malfunctions: fetching data failed: ${error}`);
        setError(error);
      })
      .finally(() => loadingMalfunctionsDone());
  }

  const handleSubmit = async () => {
    await fetchMalfunctions(vehicleNumber);
  };

  const handleChange = (event) => {
    setVehicleNumber(event.target.value);
  };

  return (
    <Form horizontal>
      <Row>
        <Col xs={6} md={4} lg={4}>
          <FormGroup controlId="vehicleNumber">
            <FormControl
              autoComplete="off"
              onChange={handleChange}
              placeholder={formatMessage(messages.enterVehicleNumber)}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup controlId="formActions">
            <ButtonToolbar>
              <Button
                bsStyle="primary"
                onClick={() => handleSubmit()}
              >
                <FormattedMessage {...messages.submitVehicleNumber} />
              </Button>
            </ButtonToolbar>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

VehicleForm.propTypes = {
  // Own props
  vehicleNumber: PropTypes.string,

  // Dispatch props
  setMalfunctions: PropTypes.func.isRequired,
  setVehicleNumber: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  loadingMalfunctions: PropTypes.func.isRequired,
  loadingMalfunctionsDone: PropTypes.func.isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
};

const mapStateToProps = (state) => ({
  vehicleNumber: getVehicleNumber(state),
});

const mapDispatchToProps = {
  get,
  setMalfunctions,
  setVehicleNumber,
  loadingMalfunctions,
  loadingMalfunctionsDone,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(VehicleForm));
