import React from 'react';
import { Alert } from 'react-bootstrap';

const NotAuthorized = () => (
  <Alert bsStyle="danger">
    <h4>Geen toegang</h4>
    <p>U heeft geen toegang tot dit onderdeel.</p>
  </Alert>
);

export default NotAuthorized;
