import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { find, propEq } from 'ramda';

import HeaderBar from '../header-bar';
import { myxss } from '../form-elements';

class RadioButtons extends Component {
  static propTypes = {
    _onDestroy: PropTypes.func,
    data: PropTypes.shape({
      field_name: PropTypes.string.isRequired,
      label: PropTypes.string,
      pageBreakBefore: PropTypes.bool,
      inline: PropTypes.bool,
      static: PropTypes.bool,
      required: PropTypes.bool,
      options: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    defaultValue: PropTypes.shape({}),
    editModeOn: PropTypes.func,
    error: PropTypes.string,
    parent: PropTypes.shape({}),
    mutable: PropTypes.bool,
    onEdit: PropTypes.func,
    readOnly: PropTypes.bool,
    handleChange: PropTypes.func,
  };

  render() {
    const {
      _onDestroy,
      data,
      defaultValue = '',
      editModeOn,
      error,
      handleChange,
      parent,
      mutable,
      onEdit,
      readOnly,
    } = this.props;

    let classNames = 'radio-wrapper';
    if (data.inline) classNames += ' option-inline';

    let baseClasses = 'rfb-item';
    if (error) baseClasses += ' error';
    if (data.pageBreakBefore) baseClasses += ' alwaysbreak';

    return (
      <div className={baseClasses}>
        {!mutable &&
          <div>
            {data.pageBreakBefore && <div className="preview-page-break">Page Break</div>}

            <HeaderBar
              parent={parent}
              editModeOn={editModeOn}
              data={data}
              onDestroy={_onDestroy}
              onEdit={onEdit}
              static={data.static}
              required={data.required}
            />
          </div>
        }
        <div className="form-group">
          <label className="form-label" htmlFor={data.field_name}>
            <span dangerouslySetInnerHTML={{ __html: myxss.process(data.label) }} />{
            }{(data.hasOwnProperty('required') && data.required && !readOnly) &&
              <span className="label-required label label-danger">Verplicht</span>
            }
          </label>

          {data.options.map((option) => {
            const thisKey = `preview_${option.key}`;
            const props = {};
            props.name = data.field_name;
            props.id = option.key;

            props.type = 'radio';
            props.value = option.value;

            if (mutable) {
              props.defaultChecked =
                (defaultValue !== undefined && defaultValue.indexOf(option.value) > -1);
              props.ref = `child_ref_${option.key}`;
            }

            props.onClick = e => handleChange(data.field_name, e.target.value);

            return (
              <div className={classNames} key={thisKey}>
                <input {...props} />&nbsp;
                <label htmlFor={option.key}>{option.text}</label>
              </div>
            );
          })}

          {error && <div className="form-group-error">{error}</div>}
        </div>
      </div>
    );
  }
}

const getSubmissionValue = (field) => {
  const chosen = find(propEq('value', field.value))(field.options);

  return chosen.text;
};

export {
  RadioButtons as default,
  getSubmissionValue,
};
