import {
  find,
  path,
  prop,
  propEq,
  reverse,
  sortBy,
} from 'ramda';
import { createSelector } from 'reselect';

// Loading selectors
export const isLoadingSoftwarePackages = (state) => path(['softwarePackage', 'loadingSoftwarePackages'], state);

// Data selectors
export const selectSoftwarePackages = (state) => path(['softwarePackage', 'softwarePackages'], state);
export const selectIdentifier = (state, props) => path(['match', 'params', 'identifier'], props);

// Memoizing selectors
export const getSoftwarePackageByIdentifier = createSelector(
  [selectIdentifier, selectSoftwarePackages], (softwarePackageId, softwarePackages) => {
    if (!softwarePackages || softwarePackages.length === 0) return false;
    if (!softwarePackageId) return undefined;

    return find(propEq('id', softwarePackageId))(softwarePackages);
  });

export const getSoftwarePackagesSortedByUpdatedAt = createSelector(
  [selectSoftwarePackages], (softwarePackages) => {
    if (!softwarePackages) return [];

    return reverse(sortBy(prop('updatedAt'))(softwarePackages));
  },
);
