// Loaders
export const MALFUNCTIONS_LOADING_MALFUNCTIONS = 'MALFUNCTIONS_LOADING_MALFUNCTIONS';
export const MALFUNCTIONS_LOADING_MALFUNCTIONS_DONE = 'MALFUNCTIONS_LOADING_MALFUNCTIONS_DONE';
export const MALFUNCTIONS_LOADING_MALFUNCTIONS_CATEGORIES = 'MALFUNCTIONS_LOADING_MALFUNCTIONS_CATEGORIES';
export const MALFUNCTIONS_LOADING_MALFUNCTIONS_CATEGORIES_DONE = 'MALFUNCTIONS_LOADING_MALFUNCTIONS_CATEGORIES_DONE';

// Adders / Setters
export const MALFUNCTIONS_SET_MALFUNCTIONS = 'MALFUNCTIONS_SET_MALFUNCTIONS';
export const MALFUNCTIONS_SET_MALFUNCTIONS_CATEGORIES = 'MALFUNCTIONS_SET_MALFUNCTIONS_CATEGORIES';
export const MALFUNCTIONS_SET_VEHICLE_NUMBER = 'MALFUNCTIONS_SET_VEHICLE_NUMBER';

// Loading actions
export const loadingMalfunctions = () => (dispatch) =>
  dispatch({ type: MALFUNCTIONS_LOADING_MALFUNCTIONS });
export const loadingMalfunctionsDone = () => (dispatch) =>
  dispatch({ type: MALFUNCTIONS_LOADING_MALFUNCTIONS_DONE });
export const loadingMalfunctionsCategories = () => (dispatch) =>
  dispatch({ type: MALFUNCTIONS_LOADING_MALFUNCTIONS_CATEGORIES });
export const loadingMalfunctionsCategoriesDone = () => (dispatch) =>
  dispatch({ type: MALFUNCTIONS_LOADING_MALFUNCTIONS_CATEGORIES_DONE });

// Actions
export const setMalfunctions = (data) => (dispatch) =>
  dispatch({ type: MALFUNCTIONS_SET_MALFUNCTIONS, payload: data });
export const setMalfunctionsCategories = (data) => (dispatch) =>
  dispatch({ type: MALFUNCTIONS_SET_MALFUNCTIONS_CATEGORIES, payload: data });
export const setVehicleNumber = (data) => (dispatch) =>
  dispatch({ type: MALFUNCTIONS_SET_VEHICLE_NUMBER, payload: data });
