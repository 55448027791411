import {
  SESSION_ACCESS_TOKEN_SET,
  SESSION_ACCESS_TOKEN_REFRESH, SESSION_ACCESS_TOKEN_REFRESH_DONE,
  SESSION_CURRENT_USER_SET, SESSION_CURRENT_USER_UNSET,
  SESSION_DESTROY,
} from '../actions/session';

const ACTION_HANDLERS = {
  [SESSION_ACCESS_TOKEN_SET]: (state, action) => ({
    ...state,
    accessToken: action.payload,
  }),
  [SESSION_ACCESS_TOKEN_REFRESH]: (state, action) => ({
    ...state,
    refreshAccessTokenPromise: action.payload,
  }),
  [SESSION_ACCESS_TOKEN_REFRESH_DONE]: state => ({
    ...state,
    refreshAccessTokenPromise: undefined,
  }),
  [SESSION_CURRENT_USER_SET]: (state, action) => ({
    ...state,
    user: action.payload,
  }),
  [SESSION_CURRENT_USER_UNSET]: state => ({
    ...state,
    user: undefined,
  }),
  [SESSION_DESTROY]: state => ({
    ...state,
    accessToken: undefined,
    user: undefined,
  }),
};

const initialState = {
  accessToken: undefined,
  refreshAccessTokenPromise: undefined,
  user: undefined,
};

const sessionReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default sessionReducer;
