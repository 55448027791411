import PropTypes from 'prop-types';

export const broadcast = PropTypes.shape({
  // Default props
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,

  // From and to dates
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,

  // Action props
  actionContent: PropTypes.string,
  actionType: PropTypes.oneOf(['call', 'mail', 'site']),

  // Content items
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
  }),

  // Notification props
  notification: PropTypes.bool.isRequired,
  notificationSendAt: PropTypes.string,
  notificationText: PropTypes.string,

  // Updated and created dates
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
});

export const contentItem = PropTypes.shape({
  // Default props
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['html', 'image', 'video']).isRequired,
  content: PropTypes.string.isRequired,

  // Updated and created dates
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
});

export const folder = PropTypes.shape({
  // Default props
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,

  // Updated date
  updatedAt: PropTypes.string.isRequired,
});
