import { ESTABLISHMENTDATA_LOADING, ESTABLISHMENTDATA_LOADING_DONE, ESTABLISHMENTDATA_SET_ESTABLISHMENTDATA } from './actions';

import { registerWithRootReducer } from '../../../../../app/modules/reducers/projects';

const ACTION_HANDLERS = {
  // Loaders
  [ESTABLISHMENTDATA_LOADING]: (state) => ({ ...state, loadingEstablishments: true }),
  [ESTABLISHMENTDATA_LOADING_DONE]: (state) => ({ ...state, loadingEstablishments: false }),

  // Adders / Setters
  [ESTABLISHMENTDATA_SET_ESTABLISHMENTDATA]: (state, action) => ({
    ...state,
    establishments: action.payload,
  }),
};

const initialState = {
  establishments: [],
  loadingEstablishments: false,
};

registerWithRootReducer('establishment', (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
});
