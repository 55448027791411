import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Alert, Button, Col, ControlLabel, Form, FormControl,
  FormGroup, HelpBlock, Row, Table,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { defineMessages, injectIntl } from 'react-intl';
import Pagination from '../../../../../../app/components/Pagination';
import { loadingBroadcasts, loadingBroadcastsDone, setBroadcasts } from '../actions';
import { get } from '../../../lib/driveApi';
import {
  getBroadcastsSortedByUpdatedAt, getFolders, isLoadingBroadcasts, isLoadingFolders,
} from '../selectors';
import { broadcast as broadcastShape, folder as folderShape } from '../types';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import genericMessages from '../../../../../../app/lib/genericMessages';
import { ErrorMessage } from '../../../../../../app/components/ErrorMessage';

const styles = {
  footerWrapper: {
    display: 'flex',
  },
  footerInnerWrapper: {
    width: '50%',
  },
  footerInnerWrapperRight: {
    textAlign: 'right',
  },
  footerAddButton: {
    margin: '20px 0',
  },
  test: {
    color: 'red',
    backgroundColor: 'red',
    marginColor: 'red',
    width: 30,
  },
};

const messages = defineMessages({
  selectFolder: {
    id: 'projects.arriva.app.modules.ArriVideo.components.Broadcasts.selectFolder',
    description: 'Select a folder',
    defaultMessage: 'Select a folder',
  },
  noFolder: {
    id: 'projects.arriva.app.modules.ArriVideo.components.Broadcasts.noFolder',
    description: 'No folder label.',
    defaultMessage: 'No folder',
  },
  titleFitler: {
    id: 'projects.arriva.app.modules.Arrivideo.components.Broadcasts.titleFilter',
    description: 'filter on title message',
    defaultMessage: 'filter on title',
  },
  folderFilter: {
    id: 'projects.arriva.app.modules.Arrivideo.components.Broadcasts.folderFilter',
    description: 'filter on folder message',
    defaultMessage: 'filter on folder',
  },
  search: {
    id: 'projects.arriva.app.modules.EmailEstablishment.components.Establishments.search',
    description: 'Search helper message',
    defaultMessage: 'Search is not case sensitive',
  },
  titleSearch: {
    id: 'projects.arriva.app.modules.Arrivideo.components.Broadcasts.titleSearch',
    description: 'Search on title message',
    defaultMessage: 'Enter a title to search',
  },
  to: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.to',
    description: 'To message',
    defaultMessage: 'To',
  },
  action: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.action',
    description: 'Action label',
    defaultMessage: 'Action',
  },
  folder: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.folder',
    description: 'Folder label',
    defaultMessage: 'Folder',
  },
});

class Broadcasts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      pageSize: 10,
      page: 1,
      pages: 1,
      searchTitle: undefined,
      searchFolder: undefined,
    };
  }

  componentDidMount() {
    this.fetchBroadcasts();
  }

  onSelectPage(page) {
    this.setState({ page }, () => this.fetchBroadcasts());
  }

  async fetchBroadcasts() {
    const {
      get, loadingBroadcasts, loadingBroadcastsDone,
      setBroadcasts,
    } = this.props;
    const {
      page, pageSize, searchTitle, searchFolder,
    } = this.state;

    await loadingBroadcasts();

    let url = `arrivideo/broadcast?page=${page}&page_size=${pageSize}`;

    if (searchTitle || searchFolder) {
      const urlRoot = `arrivideo/broadcast?page=1&page_size=${pageSize}`;

      if (searchTitle && !searchFolder) {
        url = `${urlRoot}&filter[title]=${searchTitle}`;
      }

      if (searchFolder && !searchTitle) {
        url = `${urlRoot}&filter[arriVideoFolderId]=${searchFolder}`;
      }

      if (searchTitle && searchFolder) {
        url = `${urlRoot}&filter[arriVideoFolderId]=${searchFolder}&filter[title]=${searchTitle}`;
      }
    }

    get({ path: url, parallel: false })
      .then(({ pages, broadcasts }) => {
        this.setState({ pages }, async () => {
          await setBroadcasts(broadcasts);
          await loadingBroadcastsDone();
        });
      })
      .catch((err) => this.setState({ error: err, page: 1, pages: 0 }));
  }

  renderError() {
    const { intl: { formatMessage } } = this.props;
    const { error } = this.state;

    if (!error) return null;

    const dismissError = () => this.setState({ error: undefined });

    return (
      <Alert bsStyle="danger" onDismiss={dismissError}>
        <h4>{formatMessage(genericMessages.error)}</h4>
        <p>{error.toString()}</p>
        <p>
          <Button onClick={dismissError}>{formatMessage(genericMessages.closeNotification)}</Button>
        </p>
      </Alert>
    );
  }

  renderSearch() {
    const { searchTitle } = this.state;
    const { folders, intl: { formatMessage } } = this.props;

    const folderOptions = [{
      value: -1, label: formatMessage(messages.noFolder),
    }];

    if (folders && folders.length > 0) {
      folders.map((folder) => (
        folderOptions.push({
          value: folder.id,
          label: folder.title,
        })
      ));
    }

    return (
      <div className="well">
        <Form horizontal>
          <Row>
            <Col xs={12} md={8} lg={8}>
              <FormGroup controlId="searchFormTitle">
                <ControlLabel>{formatMessage(messages.titleFitler)}</ControlLabel>
                <FormControl
                  style={{ width: 450 }}
                  type="text"
                  value={searchTitle}
                  placeholder={formatMessage(messages.titleSearch)}
                  onChange={(e) => this.setState({ searchTitle: e.target.value }, () =>
                    this.fetchBroadcasts())}
                />
                <HelpBlock>{formatMessage(messages.search)}</HelpBlock>
              </FormGroup>
            </Col>

            {(folderOptions && folderOptions.length > 1) && (
              <Col xs={12} md={3} lg={3} mdOffset={1} lgOffset={1}>
                <FormGroup controlId="searchFormTitle">
                  <ControlLabel>{formatMessage(messages.folderFilter)}</ControlLabel>

                  <Select
                    isClearable
                    placeholder={formatMessage(messages.selectFolder)}
                    options={folderOptions}
                    onChange={(option) => this.setState({
                      searchFolder: option === null ? undefined : option.value,
                    }, () =>
                      this.fetchBroadcasts())}
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </Form>
      </div>
    );
  }

  renderBroadcast(broadcast) {
    const { intl: { formatMessage } } = this.props;
    return (
      <tr key={`broadcast-${broadcast.id}`}>
        <td>
          <Link to={`/arrivideo/broadcast/edit/${broadcast.id}`}>{broadcast.title}</Link>
        </td>
        <td>{moment(broadcast.from).format('LLL')}</td>
        <td>{moment(broadcast.to).format('LLL')}</td>
        <td>{broadcast.actionType}</td>
        <td>
          {broadcast.folder.title || formatMessage(messages.noFolder)}
        </td>
        <td>{moment(broadcast.updatedAt).format('LLL')}</td>
      </tr>
    );
  }

  render() {
    const {
      broadcasts, isLoadingBroadcasts, isLoadingFolders, intl: { formatMessage },
    } = this.props;
    const { error, page, pages } = this.state;

    return (
      <div>
        <ErrorMessage error={error} />
        {this.renderSearch()}

        {broadcasts.length === 0 && <strong>{formatMessage(genericMessages.nothingFound)}</strong>}

        {(isLoadingBroadcasts || isLoadingFolders)
          && <strong>{formatMessage(genericMessages.loadingInProgress)}</strong>}

        {(broadcasts.length > 0 && !isLoadingBroadcasts && !isLoadingFolders) && (
          <Table responsive>
            <thead>
              <tr>
                <td>{formatMessage(genericMessages.title)}</td>
                <td>{formatMessage(genericMessages.from)}</td>
                <td>{formatMessage(messages.to)}</td>
                <td>{formatMessage(messages.action)}</td>
                <td>{formatMessage(messages.folder)}</td>
                <th>{formatMessage(genericMessages.updatedAt)}</th>
              </tr>
            </thead>
            <tbody>
              {broadcasts.map((broadcast) => this.renderBroadcast(broadcast))}
            </tbody>
          </Table>
        )}

        <div style={styles.footerWrapper}>
          <div style={styles.footerInnerWrapper}>
            <Pagination
              first
              prev
              last
              next
              ellipsis
              boundaryLinks
              maxButtons={5}
              bsSize="small"
              onSelect={(page) => this.onSelectPage(page)}
              page={page}
              pages={pages}
            />
          </div>

          <div style={{ ...styles.footerInnerWrapper, ...styles.footerInnerWrapperRight }}>
            <Button
              bsStyle="primary"
              href="/arrivideo/broadcast/add"
              style={styles.footerAddButton}
            >
              {formatMessage(genericMessages.add)}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

Broadcasts.propTypes = {
  // Dispatch props
  get: PropTypes.func.isRequired,
  loadingBroadcasts: PropTypes.func.isRequired,
  loadingBroadcastsDone: PropTypes.func.isRequired,
  setBroadcasts: PropTypes.func.isRequired,

  // State props
  broadcasts: PropTypes.arrayOf(broadcastShape),
  isLoadingBroadcasts: PropTypes.bool.isRequired,
  isLoadingFolders: PropTypes.bool.isRequired,
  folders: PropTypes.arrayOf(folderShape),

  // react-intl props
  intl: IntlShape.isRequired,
};

const mapStateToProps = (state) => ({
  broadcasts: getBroadcastsSortedByUpdatedAt(state),
  isLoadingBroadcasts: isLoadingBroadcasts(state),
  isLoadingFolders: isLoadingFolders(state),
  folders: getFolders(state),
});

const mapDispatchToProps = {
  get,
  loadingBroadcasts,
  loadingBroadcastsDone,
  setBroadcasts,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Broadcasts));
