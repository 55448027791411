import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import { connect } from 'react-redux';

import QuickMenu from '../../../../app/components/Menu/QuickMenu';
import { hasDrivePermissions } from '../modules/actions';

const menuItems = [{
  link: '/arrivideo',
  name: 'arriVideo',
  icon: 'film',
  resourceType: 'ARRIVIDEO',
  resourceKey: 'isAdministrator',
}, {
  link: '/tablet-registration',
  name: 'tabletRegistration',
  icon: 'phone',
  resourceType: 'TABLET_REGISTRATION',
  resourceKey: 'isAdministrator',
}, {
  link: '/url-entry',
  name: 'urlEntry',
  icon: 'link',
  resourceType: 'URLENTRY',
  resourceKey: 'isAdministrator',
}, {
  link: '/software-package',
  name: 'softwarePackage',
  icon: 'tags',
  resourceType: 'SOFTWARE_PACKAGE',
  resourceKey: 'isAdministrator',
}, {
  link: '/message',
  name: 'notifications',
  icon: 'envelope',
  resourceType: 'SOFTWARE_PACKAGE',
  resourceKey: 'isAdministrator',
}, {
  link: '/malfunction-form',
  name: 'malfunctions',
  icon: 'flash',
  resourceType: 'MALFUNCTIONS',
  resourceKey: 'isAdministrator',
}, {
  link: '/email-establishment',
  name: 'establishments',
  icon: 'pencil',
  resourceType: 'EMAIL_ESTABLISHMENT',
  resourceKey: 'isAdministrator',
}, {
  link: '/notifications',
  name: 'usersNotifications',
  icon: 'comment',
  resourceType: 'USER_NOTIFICATIONS',
  resourceKey: 'isAdministrator',
},
];

const BaseLayout = ({
  childComponent: ChildComponent,
  childProps,
  componentOptions,
  ...additionalProps
}) => {
  const { hasDrivePermissions } = additionalProps;

  const authorizedMenuItems = menuItems.filter(
    (menuItem) => hasDrivePermissions(menuItem.resourceType, menuItem.resourceKey),
  );

  if (authorizedMenuItems.length === 0) {
    return null;
  }

  return (
    <Row>
      <Col sm={3} md={2} lg={2} className="hidden-print sidebar-left">
        <QuickMenu menuItems={authorizedMenuItems} />
      </Col>

      <Col
        sm={10}
        md={10}
        lg={10}
        smPull={0}
        mdPull={0}
        lgPull={0}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <ChildComponent {...childProps} {...additionalProps} />
      </Col>
    </Row>
  );
};

BaseLayout.propTypes = {
  componentOptions: PropTypes.object, // eslint-disable-line
  childComponent: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
  ]),
  childProps: PropTypes.shape({}),
};

BaseLayout.defaultProps = {
  childProps: {},
};

const mapDispatchToProps = {
  hasDrivePermissions,
};

export default connect(null, mapDispatchToProps)(BaseLayout);
