import React, { Component } from 'react';
import { Alert, Button, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { loadingFolders, loadingFoldersDone, setFolders } from '../actions';
import { get } from '../../../lib/driveApi';
import { folder as folderShape } from '../types';
import { getFolders, isLoadingFolders } from '../selectors';
import Pagination from '../../../../../../app/components/Pagination';
import genericMessages from '../../../../../../app/lib/genericMessages';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';

const styles = {
  footerWrapper: {
    display: 'flex',
  },
  footerInnerWrapper: {
    width: '50%',
  },
  footerInnerWrapperRight: {
    textAlign: 'right',
  },
  footerAddButton: {
    margin: '20px 0',
  },
};

const messages = defineMessages({
  broadcasts: {
    id: 'projects.arriva.app.modules.ArriVideo.components.Dashboard.broadcasts',
    description: 'Broadcasts label',
    defaultMessage: 'Broadcasts',
  },
});

class Folders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      pageSize: 10,
      page: 1,
      pages: 1,
    };
  }

  componentDidMount() {
    this.fetchFolders();
  }

  onSelectPage(page) {
    this.setState({ page }, () => this.fetchFolders());
  }

  async fetchFolders() {
    const {
      get, loadingFolders, loadingFoldersDone,
      setFolders,
    } = this.props;
    const { page, pageSize } = this.state;

    await loadingFolders();

    const url = `arrivideo/folders?page=${page}&page_size=${pageSize}`;

    get({ path: url, parallel: false })
      .then(({ pages, folders }) => {
        this.setState({ pages }, async () => {
          await setFolders(folders);
        });
      })
      .catch((err) => this.setState({ error: err, page: 1, pages: 0 }))
      .finally(async () => {
        await loadingFoldersDone();
      });
  }

  renderError() {
    const { intl: { formatMessage } } = this.props;
    const { error } = this.state;

    if (!error) return null;

    const dismissError = () => this.setState({ error: undefined });

    return (
      <Alert bsStyle="danger" onDismiss={dismissError}>
        <h4>{formatMessage(genericMessages.errorMessage)}</h4>
        <p>{error.toString()}</p>
        <p>
          <Button onClick={dismissError}>{formatMessage(genericMessages.errorMessage)}</Button>
        </p>
      </Alert>
    );
  }

  renderFolders = (folder) => (
    <tr key={`content-item-${folder.id}`}>
      <td>
        <Link to={`/arrivideo/folder/edit/${folder.id}`}>{folder.title}</Link>
      </td>
      <td>{folder.nrBroadcasts}</td>
      <td>{moment(folder.updatedAt).format('LLL')}</td>
    </tr>
  );

  render() {
    const { folders, isLoadingFolders, intl: { formatMessage } } = this.props;
    const { page, pages } = this.state;

    return (
      <div>
        {this.renderError()}

        {(folders && folders.length === 0)
          && <strong>{formatMessage(genericMessages.nothingFound)}</strong>}

        {isLoadingFolders && <strong>{formatMessage(genericMessages.loadingInProgress)}</strong>}

        {(folders && folders.length > 0 && !isLoadingFolders) && (
          <Table>
            <thead>
              <tr>
                <td>{formatMessage(genericMessages.title)}</td>
                <td style={{ width: 150 }}>{formatMessage(messages.broadcasts)}</td>
                <td style={{ width: 200 }}>{formatMessage(genericMessages.updatedAt)}</td>
              </tr>
            </thead>
            <tbody>
              {folders.map((contentFolder) => this.renderFolders(contentFolder))}
            </tbody>
          </Table>
        )}

        <div style={styles.footerWrapper}>
          <div style={styles.footerInnerWrapper}>
            <Pagination
              first
              prev
              last
              next
              ellipsis
              boundaryLinks
              maxButtons={5}
              bsSize="small"
              onSelect={(page) => this.onSelectPage(page)}
              page={page}
              pages={pages}
            />
          </div>

          <div style={{ ...styles.footerInnerWrapper, ...styles.footerInnerWrapperRight }}>
            <Button
              bsStyle="primary"
              href="/arrivideo/folder/add"
              style={styles.footerAddButton}
            >
              {formatMessage(genericMessages.add)}
            </Button>
          </div>
        </div>
      </div>
    );
  };
}

Folders.propTypes = {
  // Dispatch props
  get: PropTypes.func.isRequired,
  loadingFolders: PropTypes.func.isRequired,
  loadingFoldersDone: PropTypes.func.isRequired,
  setFolders: PropTypes.func.isRequired,

  // react-intl props
  intl: IntlShape.isRequired,

  // State props
  folders: PropTypes.arrayOf(folderShape),
  isLoadingFolders: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  folders: getFolders(state),
  isLoadingFolders: isLoadingFolders(state),
});

const mapDispatchToProps = {
  get,
  loadingFolders,
  loadingFoldersDone,
  setFolders,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Folders));
