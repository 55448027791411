import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import constants from '../../../lib/drive-constants';
import {
  get, patch, post, remove,
} from '../../../lib/driveApi';
import {
  loadingEstablishmentData,
  loadingEstablishmentDataDone,
  setEstablishmentData,
} from '../actions';
import ID from '../../../../../../app/components/Forms/FormBuilder/UUID';
import { establishment as establishmentShape } from './types';
import {
  isLoadingEstablishments,
  selectIdentifier,
  getEstablishmentByIdentifier,
} from '../selectors';
import EstablishmentForm from './EstablishmentForm';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import genericMessages from '../../../../../../app/lib/genericMessages';
import { getLocale } from '../../../../../../app/modules/selectors/locale';

const messages = defineMessages({
  establishment: {
    id: 'projects.arriva.app.modules.EmailAddressEstablishment.components.EstablishmentDetail.establishment',
    description: 'Establishment label.',
    defaultMessage: 'Establishment',
  },
});

class EstablishmentDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
    };
  }

  async componentDidMount() {
    const {
      setEstablishmentData,
      establishmentId,
      get,
      establishment,
      loadingEstablishmentData,
      loadingEstablishmentDataDone,
    } = this.props;

    await loadingEstablishmentData();

    // Always load the individual establishment to ensure we have the establishment
    // with all its included data (content, audiences, etc.).
    // XXX calling `setestablishments([data])` will replace the existing list of
    // establishments in the redux store in its entirety.
    if (establishment === false && establishmentId) {
      get({ path: `establishment/establishment/${establishmentId}` })
        .then((data) => setEstablishmentData([data]))
        .catch((err) => this.setState({ error: err }))
        .finally(() => loadingEstablishmentDataDone());
    } else {
      loadingEstablishmentDataDone();
    }
  }

  renderForm() {
    const {
      establishment, history, locale, patch, post,
    } = this.props;

    const initialValues = {
      ...establishment,
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          (establishment.id ? patch : post)({
            path: `establishment/establishment/${
              establishment.id ? establishment.principalId : ''
            }`,
            body: establishment.id
              ? values
              : { ...values, id: ID.uuid(), principalType: 'Establishment' },
          })
            .then((result) => {
              if (result.message === constants.MSG_SUCCESS) {
                setSubmitting(false);

                history.goBack();
                return;
              }

              Sentry.captureException(
                new Error(
                  `Something went wrong: establishmentForm post/patch-> ${result}`,
                ),
              );
              this.setState({
                error: `Er ging iets fout bij het opslaan: ${result}`,
              });
            })
            .catch((error) => {
              console.error(error);
              Sentry.captureException(error);

              setSubmitting(false);
            });
        }}
        validate={(values) => {
          const errors = {};
          const required = ['emailAddress'];

          // Make sure the required fields are there
          required.forEach((k) => {
            if (!values[k]) errors[k] = 'Verplicht';
          });

          if (
            values.emailAddress
            && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              values.emailAddress,
            )
          ) {
            errors.emailAddress = 'Geen geldig e-mailadres';
          }

          return errors;
        }}
      >
        {(props) => (
          /* eslint-disable react/jsx-props-no-spreading */
          <EstablishmentForm {...props} locale={locale} />
        )}
      </Formik>
    );
  }

  render() {
    const {
      establishment,
      establishmentId,
      isLoadingEstablishments,
      intl: { formatMessage },
    } = this.props;
    const { error, loading } = this.state;

    if (loading) {
      return <strong>{formatMessage(genericMessages.loadingInProgress)}</strong>;
    }

    if (error && establishment) {
      // eslint-disable-next-line react/jsx-one-expression-per-line
      return <strong>{formatMessage(genericMessages.errorMessage)} ({error})</strong>;
    }

    if (error && !establishment) {
      // eslint-disable-next-line react/jsx-one-expression-per-line
      return <strong>{formatMessage(genericMessages.nothingFound)}</strong>;
    }
    if (!establishment && establishmentId) {
      return <strong>{formatMessage(genericMessages.nothingFound)}</strong>;
    }

    return (
      <div className="well">
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        <h2>
          {formatMessage(messages.establishment)}
          {' '}
          {establishment
            ? formatMessage(genericMessages.edit).toLowerCase()
            : formatMessage(genericMessages.add).toLowerCase()}
        </h2>

        {isLoadingEstablishments && (
          <strong>{formatMessage(genericMessages.loadingInProgress)}</strong>
        )}

        {!isLoadingEstablishments && <>{this.renderForm()}</>}
      </div>
    );
  }
}

EstablishmentDetail.propTypes = {
  // Own props
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),

  // react-intl props
  intl: IntlShape.isRequired,

  // Dispatch props
  get: PropTypes.func.isRequired,
  loadingEstablishmentData: PropTypes.func.isRequired,
  loadingEstablishmentDataDone: PropTypes.func.isRequired,
  patch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  setEstablishmentData: PropTypes.func.isRequired,

  //   State props
  establishment: PropTypes.oneOfType([establishmentShape, PropTypes.bool]),
  establishmentId: PropTypes.string,
  locale: PropTypes.string.isRequired,
  isLoadingEstablishments: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => ({
  establishment: getEstablishmentByIdentifier(state, props),
  establishmentId: selectIdentifier(state, props),
  locale: getLocale(state),
  isLoadingEstablishments: isLoadingEstablishments(state),
});

const mapDispatchToProps = {
  get,
  loadingEstablishmentData,
  loadingEstablishmentDataDone,
  patch,
  post,
  remove,
  setEstablishmentData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(EstablishmentDetail));
