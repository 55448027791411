import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  ButtonToolbar,
  Col,
  ControlLabel, DropdownButton,
  Form,
  FormControl,
  FormGroup,
  HelpBlock, MenuItem,
  Row,
} from 'react-bootstrap';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import genericMessages from '../../../../../../../app/lib/genericMessages';
import IntlShape from '../../../../../../../app/lib/PropTypes/IntlShape';
import { vState } from '../../../../../../../app/lib/utils';

const messages = defineMessages({
  code: {
    id: 'projects.arriva.app.modules.Malfunctions.components.CategoriesList.code',
    description: 'Malfunctions code',
    defaultMessage: 'Code',
  },
  description: {
    id: 'projects.arriva.app.modules.Malfunctions.components.CategoriesList.description',
    description: 'Malfunctions description',
    defaultMessage: 'Description',
  },
  type: {
    id: 'projects.arriva.app.modules.Malfunctions.components.CategoryForm.type',
    description: 'Content item type label.',
    defaultMessage: 'Vehicle type',
  },
  typeAll: {
    id: 'projects.arriva.app.modules.Malfunctions.components.CategoryForm.typeAll',
    description: 'Category type label.',
    defaultMessage: 'All',
  },
  typeBus: {
    id: 'projects.arriva.app.modules.Malfunctions.components.CategoryForm.typeBus',
    description: 'Category type bus label.',
    defaultMessage: 'Bus',
  },
  typeTrain: {
    id: 'projects.arriva.app.modules.Malfunctions.components.CategoryForm.typeTrain',
    description: 'Category type image label.',
    defaultMessage: 'Train',
  },
});

const CategoryForm = ({
  handleChange,
  handleSubmit,
  errors,
  isSubmitting,
  onClickDelete,
  values,
  intl: { formatMessage },
  setFieldValue,
}) => (
  <Form horizontal onSubmit={handleSubmit}>
    <Row>
      <Col xs={12} md={8} lg={8}>
        <FormGroup controlId="value" validationState={vState(errors, 'value')}>
          <ControlLabel>
            <FormattedMessage {...messages.code} />
          </ControlLabel>

          <FormControl type="text" autoComplete="off" value={values.value} onChange={handleChange} />

          {errors.value && <HelpBlock>{errors.value}</HelpBlock>}
        </FormGroup>
        <FormGroup controlId="label" validationState={vState(errors, 'label')}>
          <ControlLabel>
            <FormattedMessage {...messages.description} />
          </ControlLabel>

          <FormControl type="text" autoComplete="off" value={values.label} onChange={handleChange} />

          {errors.label && <HelpBlock>{errors.label}</HelpBlock>}
        </FormGroup>
        <FormGroup controlId="type" validationState={vState(errors, 'type')}>
          <ControlLabel>
            <FormattedMessage {...messages.type} />
          </ControlLabel>
          <br />

          <DropdownButton
            id="typeDropdown"
            title={
                values && values.vehicleType
                  ? values.vehicleType.charAt(0).toUpperCase()
                  + values.vehicleType.slice(1).toLowerCase()
                  : formatMessage(messages.type)
              }
          >
            {['all', 'bus', 'train'].map((type) => (
              <MenuItem
                key={`vehicleType-${type}`}
                onSelect={() => {
                  setFieldValue('vehicleType', type.toUpperCase());
                }}
              >
                {formatMessage(messages[`type${type.charAt(0).toUpperCase() + type.slice(1)}`])}
              </MenuItem>
            ))}
          </DropdownButton>

          {errors.type && <HelpBlock>{errors.type}</HelpBlock>}
        </FormGroup>
        <hr />

        <FormGroup controlId="formActions">
          <ButtonToolbar>
            {values.id && (
            <Button bsStyle="danger" onClick={() => onClickDelete()}>
              <FormattedMessage {...genericMessages.delete} />
            </Button>
            )}

            <Button type="submit" disabled={isSubmitting || Object.keys(errors).length > 0}>
              <FormattedMessage {...genericMessages.save} />
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Col>
    </Row>
  </Form>
);

CategoryForm.propTypes = {
  // Own props
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,

  errors: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    vehicleType: PropTypes.string,
  }),

  values: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    vehicleType: PropTypes.string,
  }).isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
  locale: PropTypes.string.isRequired,
};

export default injectIntl(CategoryForm);
