import moment from 'moment';
import PropTypes from 'prop-types';
import {
  concat, filter, find, groupBy, path, pathEq, propEq, uniq,
} from 'ramda';
import React, { useEffect, useState } from 'react';
import {
  Button, ButtonToolbar, Checkbox, Col, ControlLabel, DropdownButton, Form,
  FormControl, FormGroup, HelpBlock, InputGroup, Jumbotron, ListGroup, ListGroupItem, MenuItem, Row,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';

import { get } from '../../../lib/driveApi';
import DriveSuggest, { translateLabels } from '../../../components/DriveSuggestFormElement';
import genericMessages from '../../../../../../app/lib/genericMessages';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import { contentItem as contentItemShape, folder as folderShape } from '../types';
import UploadBase64 from '../../../../../../app/components/UploadBase64';
import { vState } from '../../../../../../app/lib/utils';

import {
  getContentsByBroadcastId,
  getFolders,
  isLoadingContentItems,
  isLoadingFolders,
} from '../selectors';

export const messages = defineMessages({
  action: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.action',
    description: 'Broadcast action label.',
    defaultMessage: 'Action',
  },
  actionContent: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.actionContent',
    description: 'Broadcast action content label.',
    defaultMessage: 'Content',
  },
  actionTypeCall: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.actionTypeCall',
    description: 'Broadcast action type call label.',
    defaultMessage: 'Call',
  },
  actionTypeMail: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.actionTypeMail',
    description: 'Broadcast action type mail label.',
    defaultMessage: 'Mail',
  },
  actionTypeNone: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.actionTypeNone',
    description: 'Broadcast action type none label.',
    defaultMessage: 'No action',
  },
  actionTypeSite: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.actionTypeSite',
    description: 'Broadcast action type site label.',
    defaultMessage: 'Website',
  },
  audiences: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.audiences',
    description: 'Broadcast audiences label.',
    defaultMessage: 'Audiences',
  },
  contentItems: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.contentItems',
    description: 'Broadcast contentItems label.',
    defaultMessage: 'Content items',
  },
  contentItemsSelectPlaceholder: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.contentItemsSelectPlaceholder',
    description: 'Broadcast contentItems select placeholder label.',
    defaultMessage: 'Search here for content items...',
  },
  title: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.title',
    description: 'Broadcast title label.',
    defaultMessage: 'Title',
  },
  fromDateTime: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.from',
    description: 'Broadcast from label.',
    defaultMessage: 'From',
  },
  noAudiences: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.noAudiences',
    description: 'Broadcast no audiences label.',
    defaultMessage: 'No groups or users paired',
  },
  notification: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.notification',
    description: 'Broadcast notification label.',
    defaultMessage: 'Notification',
  },
  notificationSend: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.notificationSend',
    description: 'Broadcast notification send label.',
    defaultMessage: 'Send a notification when the broadcast is published',
  },
  notificationText: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.notificationText',
    description: 'Broadcast notification text label.',
    defaultMessage: 'Notification text',
  },
  notificationTextHelp: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.notificationTextHelp',
    description: 'Broadcast notification text help label.',
    defaultMessage: `Default notification text will only the title of the broadcast.".
Override the default notification text the user will receive.
Use '{'title'}' to automatically insert the broadcast title.`,
  },
  thumbnail: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.thumbnail',
    description: 'Broadcast thumbnail label.',
    defaultMessage: 'Thumbnail',
  },
  toDateTime: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.to',
    description: 'Broadcast to label.',
    defaultMessage: 'To',
  },
  timeCaption: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.timeCaption',
    description: 'Time caption label.',
    defaultMessage: 'Time',
  },
  todayButton: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.todayButton',
    description: 'Today button label.',
    defaultMessage: 'Today',
  },
  upload: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.uploadButtonText',
    description: 'Upload image button text.',
    defaultMessage: 'Upload image',
  },
  folder: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.folder',
    description: 'Folder label.',
    defaultMessage: 'Folder',
  },
  noFolder: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastForm.noFolder',
    description: 'No folder label.',
    defaultMessage: 'No folder',
  },
});

const styles = {
  thumbnail: {
    marginBottom: 10,
    width: '100%',
    height: 'auto',
  },
};

const BroadcastForm = ({
  contentItems,
  get,
  handleChange,
  handleSubmit,
  errors,
  intl,
  isSubmitting,
  loadingContentItems,
  locale,
  onClickDelete,
  setFieldValue,
  values,
  loadingFolders,
  folders,
}) => {
  // Make sure to wait for the loading contentItems state
  // If the contentItems are empty at this point, and still loading
  // the useState(contentItems) will not update anymore and have the wrong content
  if (loadingContentItems || loadingFolders) return null;

  const [audiences, setAudiences] = useState(values.audiences || []);
  const [contentItemsOrdered, setContentItemsOrdered] = useState(contentItems || []);

  useEffect(() => {
    setFieldValue('audiences', audiences);

    if (!contentItemsOrdered) return;

    setFieldValue('contentItems', contentItemsOrdered.map((c, idx) => ({
      id: c.id,
      order: idx,
    })));
  }, [audiences, contentItemsOrdered]);

  // Method that will handle the sorting for the contentItems
  const handleSorting = (direction, index) => {
    const ordered = [...contentItemsOrdered];
    const tmp = ordered[index];

    if (direction === 'up') {
      ordered[index] = ordered[index - 1];
      ordered[index - 1] = tmp;
    } else {
      ordered[index] = ordered[index + 1];
      ordered[index + 1] = tmp;
    }

    setContentItemsOrdered(ordered);
  };

  // Group the audiences
  const groupedAudiences = groupBy((a) => translateLabels[path(['value', 'resourceType'], a)] || 'Onbekend')(audiences);

  return (
    <Form horizontal onSubmit={handleSubmit}>
      <Row>
        <Col xs={12} md={8} lg={8}>
          <FormGroup controlId="title" validationState={vState(errors, 'title')}>
            <ControlLabel>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...messages.title} />
            </ControlLabel>

            <FormControl type="text" autoComplete="off" value={values.title} onChange={handleChange} />

            {errors.title && <HelpBlock>{errors.title}</HelpBlock>}
          </FormGroup>

          <FormGroup controlId="notification" validationState={vState(errors, 'notification')}>
            <ControlLabel>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...messages.notification} />
            </ControlLabel>

            <Checkbox
              checked={values.notification}
              onChange={(e) => setFieldValue('notification', e.target.checked)}
            >
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...messages.notificationSend} />
            </Checkbox>

            {errors.notification && <HelpBlock>{errors.notification}</HelpBlock>}
          </FormGroup>

          <FormGroup controlId="notificationText" validationState={vState(errors, 'notificationText')}>
            <ControlLabel>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...messages.notificationText} />
            </ControlLabel>

            <FormControl
              type="text"
              autoComplete="off"
              value={values.notificationText}
              onChange={handleChange}
              placeholder={values.title}
            />
            <HelpBlock>
              <p style={{ whiteSpace: 'pre' }}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <FormattedMessage {...messages.notificationTextHelp} />
              </p>
            </HelpBlock>

            {errors.notificationText && <HelpBlock>{errors.notificationText}</HelpBlock>}
          </FormGroup>

          <FormGroup controlId="action" validationState={vState(errors, 'action')}>
            <ControlLabel>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...messages.action} />
            </ControlLabel>

            <InputGroup>
              <DropdownButton
                id="actionDropdown"
                componentClass={InputGroup.Button}
                title={
                  values.actionType
                    ? intl.formatMessage(messages[`actionType${values.actionType.charAt(0).toUpperCase() + values.actionType.slice(1)}`])
                    : intl.formatMessage(messages.action)
                }
              >
                {['call', 'mail', 'site'].map((type) => (
                  <MenuItem key={`action-type-${type}`} onSelect={() => setFieldValue('actionType', type)}>
                    {intl.formatMessage(messages[`actionType${type.charAt(0).toUpperCase() + type.slice(1)}`])}
                  </MenuItem>
                ))}
                <MenuItem divider />
                <MenuItem
                  key="action-type-none"
                  onSelect={() => {
                    setFieldValue('actionType', '');
                    setFieldValue('actionContent', '');
                  }}
                >
                  {intl.formatMessage(messages.actionTypeNone)}
                </MenuItem>
              </DropdownButton>

              <FormControl
                disabled={!values.actionType}
                type="text"
                autoComplete="off"
                value={values.actionContent}
                onChange={(e) => setFieldValue('actionContent', e.target.value)}
              />
            </InputGroup>

            {errors.action && <HelpBlock>{errors.action}</HelpBlock>}
          </FormGroup>

          <FormGroup controlId="contentItems" validationState={vState(errors, 'contentItems')}>
            <ControlLabel>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...messages.contentItems} />
            </ControlLabel>

            <Jumbotron style={{ backgroundColor: '#E9EAE9', padding: 20, marginBottom: 0 }}>
              <AsyncSelect
                placeholder={intl.formatMessage(messages.contentItemsSelectPlaceholder)}
                name="content-items-select"
                loadOptions={(q) => get({ path: `arrivideo/content?filter[title]=${q}`, parallel: false })
                  .then(({ contentItems }) => contentItems.map((c) => ({
                    label: c.title,
                    value: c.id,
                    isDisabled: !!find(propEq('id', c.id))(contentItemsOrdered),
                  })))}
                onChange={(v) => {
                  const newList = [...contentItemsOrdered, {
                    id: v.value,
                    title: v.label,
                    order: contentItemsOrdered.length,
                  }];

                  setContentItemsOrdered(newList);
                }}
              />

              <br />

              {(contentItemsOrdered && contentItemsOrdered.length > 0) && (
                <ListGroup>
                  {contentItemsOrdered.map((contentItem, idx) => (
                    <ListGroupItem
                      key={`content-item-${contentItem.id}`}
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      {contentItem.title}
                      <ButtonToolbar>
                        <Button
                          bsSize="small"
                          disabled={idx === 0}
                          onClick={() => handleSorting('up', idx)}
                        >
                          &#9650;
                        </Button>

                        <Button
                          bsSize="small"
                          disabled={idx === contentItemsOrdered.length - 1}
                          onClick={() => handleSorting('down', idx)}
                        >
                          &#9660;
                        </Button>

                        <Button
                          bsSize="small"
                          bsStyle="danger"
                          onClick={() => {
                            const newList = concat(
                              contentItemsOrdered.slice(0, idx),
                              contentItemsOrdered.slice(idx + 1, contentItemsOrdered.length),
                            );

                            setContentItemsOrdered(newList);
                          }}
                        >
                          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                          <FormattedMessage {...genericMessages.delete} />
                        </Button>
                      </ButtonToolbar>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              )}
            </Jumbotron>

            {errors.contentItems && <HelpBlock>{errors.contentItems}</HelpBlock>}
          </FormGroup>

          <FormGroup controlId="audiences" validationState={vState(errors, 'audiences')}>
            <ControlLabel>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...messages.audiences} />
            </ControlLabel>

            <Jumbotron style={{ backgroundColor: '#E9EAE9', padding: 20, marginBottom: 0 }}>
              <DriveSuggest
                onSelect={(audience) => {
                  // Make sure we don't add duplicates
                  if (!find(pathEq(['value', 'id'], path(['value', 'id'], audience)))(audiences)) {
                    const audienceArray = [...audiences, audience];

                    setFieldValue('audiences', audienceArray);
                    setAudiences([...audiences, audience]);
                  }
                }}
              />

              <hr />

              {(!audiences || audiences.length === 0) && (
                <strong>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <FormattedMessage {...messages.noAudiences} />
                </strong>
              )}

              {(audiences && audiences.length > 0) && (
                <div>
                  {uniq(Object.values(translateLabels)).map((resourceType) => {
                    if (!groupedAudiences[resourceType]) return null;

                    return [
                      <strong>{resourceType}</strong>,
                      <ListGroup>
                        {groupedAudiences[resourceType].map((audience) => (
                          <ListGroupItem
                            key={`audience-${audience.id}`}
                            style={{ display: 'flex', justifyContent: 'space-between' }}
                          >
                            {audience.label}
                            <Button
                              bsSize="small"
                              bsStyle="danger"
                              onClick={() => setAudiences(
                                filter((a) => path(['value', 'id'], a) !== path(['value', 'id'], audience))(audiences),
                              )}
                            >
                              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                              <FormattedMessage {...genericMessages.delete} />
                            </Button>
                          </ListGroupItem>
                        ))}
                      </ListGroup>,
                    ];
                  })}
                </div>
              )}
            </Jumbotron>

            {errors.audiences && <HelpBlock>{errors.audiences}</HelpBlock>}
          </FormGroup>

          <hr />

          <FormGroup controlId="formActions">
            <ButtonToolbar>
              {values.id && (
                <Button bsStyle="danger" onClick={() => onClickDelete()}>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <FormattedMessage {...genericMessages.delete} />
                </Button>
              )}

              <Button type="submit" disabled={isSubmitting || Object.keys(errors).length > 0}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <FormattedMessage {...genericMessages.formSubmitLabel} />
              </Button>
            </ButtonToolbar>
          </FormGroup>
        </Col>

        <Col xs={12} md={3} lg={3} mdOffset={1} lgOffset={1}>
          <FormGroup controlId="fromDateTime" validationState={vState(errors, 'from')}>
            <ControlLabel>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...messages.fromDateTime} />
            </ControlLabel>

            <br />

            <DatePicker
              className="form-control"
              selected={moment(values.from).toDate()}
              onChange={(dateTime) => setFieldValue('from', dateTime)}
              showTimeSelect
              dateFormat="MMMM d, yyyy HH:mm"
              timeFormat="HH:mm"
              timeCaption={intl.formatMessage(messages.timeCaption)}
              timeIntervals={15}
              todayButton={intl.formatMessage(messages.todayButton)}
              locale={locale}
            />

            {errors.from && <HelpBlock>{errors.from}</HelpBlock>}
          </FormGroup>

          <FormGroup controlId="toDateTime" validationState={vState(errors, 'to')}>
            <ControlLabel>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...messages.toDateTime} />
            </ControlLabel>

            <br />

            <DatePicker
              className="form-control"
              selected={moment(values.to).toDate()}
              onChange={(dateTime) => setFieldValue('to', dateTime)}
              showTimeSelect
              dateFormat="MMMM d, yyyy HH:mm"
              timeFormat="HH:mm"
              timeCaption={intl.formatMessage(messages.timeCaption)}
              timeIntervals={15}
              todayButton={intl.formatMessage(messages.todayButton)}
              locale={locale}
            />

            {errors.to && <HelpBlock>{errors.to}</HelpBlock>}
          </FormGroup>

          <FormGroup controlId="folder" validationState={vState(errors, 'folder')}>
            <ControlLabel>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...messages.folder} />
            </ControlLabel>

            <DropdownButton
              id="folderDropdown"
              componentClass={InputGroup.Button}
              title={values.folder?.title || intl.formatMessage(messages.noFolder)}
            >
              {folders.map((folder) => (
                <MenuItem key={folder.id} onSelect={() => setFieldValue('folder', { id: folder.id, title: folder.title })}>
                  {folder.title}
                </MenuItem>
              ))}
              <MenuItem divider />
              <MenuItem
                key={-1}
                onSelect={() => setFieldValue('folder', {
                  id: '-1', title: intl.formatMessage(messages.noFolder),
                })}
              >
                {intl.formatMessage(messages.noFolder)}
              </MenuItem>
            </DropdownButton>

            {errors.folder && <HelpBlock>{errors.folder}</HelpBlock>}
          </FormGroup>

          <FormGroup controlId="thumbnail" validationState={vState(errors, 'thumbnail')}>
            <ControlLabel>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...messages.thumbnail} />
            </ControlLabel>

            <div style={{ width: 250 }}>
              {values.thumbnail && (
                <img
                  alt="thumbnail"
                  src={`data:image/png;base64,${values.thumbnail}`}
                  height={256}
                  style={styles.thumbnail}
                />
              )}

              <UploadBase64
                useCropper
                removeBase64DataUriPrefix
                buttonText={intl.formatMessage(messages.upload)}
                cropperProps={{
                  aspectRatio: 16 / 9,
                  scale: [455, 256],
                }}
                onFileReady={(data) => setFieldValue('thumbnail', data)}
              />
            </div>

            {errors.thumbnail && <HelpBlock>{errors.thumbnail}</HelpBlock>}
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

BroadcastForm.propTypes = {
  // Own props
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,

  errors: PropTypes.shape({
    action: PropTypes.string,
    audiences: PropTypes.string,
    contentItems: PropTypes.string,
    from: PropTypes.string,
    notification: PropTypes.string,
    notificationText: PropTypes.string,
    thumbnail: PropTypes.string,
    title: PropTypes.string,
    to: PropTypes.string,
    folder: PropTypes.string,
  }),

  values: PropTypes.shape({
    actionContent: PropTypes.string,
    actionType: PropTypes.string,
    audiences: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      principalId: PropTypes.string.isRequired,
      principalType: PropTypes.string.isRequired,
    })),
    contentItems: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
    })),
    from: PropTypes.string,
    id: PropTypes.string,
    notification: PropTypes.bool,
    notificationText: PropTypes.string,
    thumbnail: PropTypes.string,
    folder: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
    to: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,

  // State props
  contentItems: PropTypes.arrayOf(contentItemShape),
  loadingContentItems: PropTypes.bool.isRequired,
  loadingFolders: PropTypes.bool.isRequired,
  folders: PropTypes.arrayOf(folderShape),

  // Dispatch props
  get: PropTypes.func.isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => ({
  contentItems: getContentsByBroadcastId(state, path(['values', 'id'], props)),
  loadingContentItems: isLoadingContentItems(state),
  loadingFolders: isLoadingFolders(state),
  folders: getFolders(state),
});

const mapDispatchToProps = {
  get,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(BroadcastForm));
