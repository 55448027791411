import PropTypes from 'prop-types';
import React from 'react';
import {
  Button, ButtonToolbar, Col, ControlLabel, Form,
  FormControl, FormGroup, HelpBlock, Row,
} from 'react-bootstrap';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import genericMessages from '../../../../../../app/lib/genericMessages';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import { vState } from '../../../../../../app/lib/utils';

const messages = defineMessages({
  title: {
    id: 'projects.arriva.app.modules.ArriVideo.components.ContentItemForm.title',
    description: 'Content item title label.',
    defaultMessage: 'Title',
  },
});

const FolderForm = ({
  handleChange,
  handleSubmit,
  errors,
  values,
  onClickDelete,
  isSubmitting,
}) => (
  <Form horizontal onSubmit={handleSubmit}>
    <Row>
      <Col xs={12} md={9} lg={9}>
        <FormGroup controlId="title" validationState={vState(errors, 'title')}>
          <ControlLabel>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <FormattedMessage {...messages.title} />
          </ControlLabel>

          <FormControl type="text" autoComplete="off" value={values.title} onChange={handleChange} />

          {errors.title && <HelpBlock>{errors.title}</HelpBlock>}
        </FormGroup>
      </Col>
    </Row>

    <Row>
      <Col xs={12} md={9} lg={9}>
        <FormGroup controlId="formActions">
          <ButtonToolbar>
            {values.id && (
            <Button bsStyle="danger" onClick={() => onClickDelete()}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...genericMessages.delete} />
            </Button>
            )}

            <Button type="submit" disabled={isSubmitting || Object.keys(errors).length > 0}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...genericMessages.formSubmitLabel} />
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Col>
    </Row>
  </Form>
);

FolderForm.propTypes = {
  // Own props
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClickDelete: PropTypes.func.isRequired,

  errors: PropTypes.shape({
    title: PropTypes.string,
  }),

  values: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(injectIntl(FolderForm));
