import {
  find, path, prop, propEq, reverse, sortBy,
} from 'ramda';
import { createSelector } from 'reselect';

// Loading selectors
export const isLoadingUrlEntries = (state) => path(['urlEntry', 'loadingUrlEntries'], state);

// Prop selectors
export const selectPropAsValue = (state, props) => props;
export const selectIdentifier = (state, props) => path(['match', 'params', 'identifier'], props);

// Data selectors
export const selectUrlEntries = (state) => path(['urlEntry', 'urlEntries'], state);

// Memoizing selectors
export const getUrlEntryByIdentifier = createSelector(
  [selectIdentifier, selectUrlEntries], (urlEntryId, urlEntries) => {
    if (!urlEntries || urlEntries.length === 0) return false;
    if (!urlEntryId) return undefined;

    return find(propEq('id', urlEntryId))(urlEntries);
  });

export const getUrlEntriesSortedByUpdatedAt = createSelector(
  [selectUrlEntries], (urlEntries) => {
    if (!urlEntries) return [];

    return reverse(sortBy(prop('updatedAt'))(urlEntries));
  },
);
