import PropTypes from 'prop-types';

export const UserAttributes = PropTypes.shape({
  username: PropTypes.string.isRequired,
  email: PropTypes.string,
  dateJoined: PropTypes.string,
  lastLogin: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  isSuperuser: PropTypes.bool,
  isStaff: PropTypes.bool,
  isActive: PropTypes.bool,
});

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  attributes: UserAttributes,
});
