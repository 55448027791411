import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HeaderBar from '../header-bar';

export default class Image extends Component {
  static propTypes = {
    _onDestroy: PropTypes.func,
    data: PropTypes.shape({
      field_name: PropTypes.string.isRequired,
      label: PropTypes.string,
      pageBreakBefore: PropTypes.bool,
      inline: PropTypes.bool,
      center: PropTypes.bool,
      required: PropTypes.bool,
      src: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    editModeOn: PropTypes.func,
    parent: PropTypes.shape({}),
    mutable: PropTypes.bool,
    onEdit: PropTypes.func,
  };

  render() {
    const {
      data,
      mutable,
      parent,
      editModeOn,
      onEdit,
      _onDestroy,
    } = this.props;

    const style = data.center ? { textAlign: 'center' } : {};

    let baseClasses = 'rfb-item image-element';
    if (data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }

    return (
      <div className={baseClasses} style={style}>
        {!mutable &&
          <HeaderBar
            parent={parent}
            editModeOn={editModeOn}
            data={data}
            onDestroy={_onDestroy}
            onEdit={onEdit}
            required={data.required}
          />
        }

        {data.src &&
          <img
            alt={`Image_${data.field_name}`}
            src={data.src}
            width={data.width}
            height={data.height}
          />
        }

        {!data.src && <div className="no-image">Geen afbeelding</div>}
      </div>
    );
  }
}
