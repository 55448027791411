import React, { Component } from 'react';
import {
  Button, ButtonToolbar, Col, ControlLabel, Form, FormControl, FormGroup, Row,
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

class TabletRegistrationLanding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceId: '',
      doRedirect: false,
    };
  }

  /**
   * Sets the device ID in the state.
   */
  onChangeDeviceIdInputHandler = (event) => {
    this.setState({
      deviceId: event.target.value,
    });
  }

  /**
   * Determines whether the user may be redirected to the registration page.
   */
  onSubmitDeviceIdHandler = (event) => {
    event.preventDefault();

    const { deviceId } = this.state;
    if (deviceId !== '') {
      this.setState({
        doRedirect: true,
      });
    }
  }

  /**
   * Renders a simple form that allows the user to enter a single device ID (called a CI number).
   */
  render() {
    const { deviceId, doRedirect } = this.state;

    // Determine whether the user should be redirected.
    let redirect = null;
    if (doRedirect) {
      redirect = <Redirect to={`/tablet-registration/${deviceId}`} />;
    }

    return (
      <>
        {redirect}
        <div className="well">
          <h2>Device uitgeven</h2>
          <Form inline onSubmit={this.onSubmitDeviceIdHandler}>
            <Row>
              <Col xs={12} md={8} lg={8}>
                <FormGroup controlId="title" style={{ marginRight: 8 }}>
                  <ControlLabel style={{ marginRight: 8 }}>CI-nummer:</ControlLabel>

                  <FormControl type="text" autoComplete="off" value={deviceId} onChange={this.onChangeDeviceIdInputHandler} />
                </FormGroup>

                <FormGroup controlId="formActions">
                  <ButtonToolbar>
                    <Button type="submit">Registreren</Button>
                  </ButtonToolbar>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export default TabletRegistrationLanding;
