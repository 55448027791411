import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { filter, indexOf, join, propSatisfies } from 'ramda';

import HeaderBar from '../header-bar';
import { myxss } from '../form-elements';

class Checkboxes extends Component {
  static propTypes = {
    _onDestroy: PropTypes.func,
    data: PropTypes.shape({
      field_name: PropTypes.string.isRequired,
      label: PropTypes.string,
      pageBreakBefore: PropTypes.bool,
      inline: PropTypes.bool,
      static: PropTypes.bool,
      required: PropTypes.bool,
      options: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    defaultValue: PropTypes.shape({}),
    editModeOn: PropTypes.func,
    error: PropTypes.string,
    parent: PropTypes.shape({}),
    mutable: PropTypes.bool,
    onEdit: PropTypes.func,
    readOnly: PropTypes.bool,
    handleChange: PropTypes.func,
  };

  state = {
    checkedOptions: [],
  };

  handleChange(event) {
    const { data, handleChange } = this.props;
    const { checkedOptions } = this.state;
    const { target: { value, checked } } = event;

    const optionChecked = indexOf(value, checkedOptions);
    if (optionChecked !== -1 && !checked) {
      checkedOptions.splice(optionChecked, 1);
    } else {
      checkedOptions.push(value);
    }

    this.setState({ checkedOptions });

    handleChange(data.field_name, checkedOptions);
  }

  render() {
    const {
      _onDestroy,
      data,
      defaultValue = '',
      editModeOn,
      error,
      parent,
      mutable,
      onEdit,
      readOnly,
    } = this.props;

    const self = this;
    let classNames = 'checkbox-wrapper';
    if (data.inline) classNames += ' option-inline';

    let baseClasses = 'rfb-item';
    if (error) baseClasses += ' error';
    if (data.pageBreakBefore) baseClasses += ' alwaysbreak';

    return (
      <div className={baseClasses}>
        { !mutable &&
        <div>
          {data.pageBreakBefore && <div className="preview-page-break">Page Break</div>}

          <HeaderBar
            parent={parent}
            editModeOn={editModeOn}
            data={data}
            onDestroy={_onDestroy}
            onEdit={onEdit}
            static={data.static}
            required={data.required}
          />
        </div>
        }
        <div className="form-group">
          <label className="form-label" htmlFor={data.field_name}>
            <span dangerouslySetInnerHTML={{ __html: myxss.process(data.label) }} />{
            // eslint-disable-next-line camelcase
          }{ (data.hasOwnProperty('required') && data.required && !readOnly) &&
            <span className="label-required label label-danger">Verplicht</span>
          }
          </label>
          {data.options.map((option) => {
            const thisKey = `preview_${option.key}`;
            const props = {};

            props.name = `option_${option.key}`;
            props.value = option.value;
            props.id = option.key;

            if (self.props.mutable) {
              props.defaultChecked = defaultValue.indexOf(option.value) > -1;
              props.ref = `child_ref_${option.key}`;
            }

            props.onClick = e => this.handleChange(e);

            return (
              <div className={classNames} key={thisKey}>
                <input type="checkbox" {...props} />&nbsp;
                <label htmlFor={option.key}>{option.text}</label>
              </div>
            );
          })}

          {error && <div className="form-group-error">{error}</div>}
        </div>
      </div>
    );
  }
}

const getSubmissionValue = (field) => {
  const checked = filter(propSatisfies(v => indexOf(v, field.value) > -1, 'value'))(field.options);

  return join(', ')(checked.map(c => c.text));
};

export {
  Checkboxes as default,
  getSubmissionValue,
};
