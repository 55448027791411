import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import React, { Component } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import constants from '../../../lib/drive-constants';
import {
  get, patch, post, remove,
} from '../../../lib/driveApi';

import { setFolders } from '../actions';
import { getFolderByIdentifier, selectIdentifier } from '../selectors';
import { folder as folderShape } from '../types';

import FolderForm from './FolderForm';
import { getLocale } from '../../../../../../app/modules/selectors/locale';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import genericMessages from '../../../../../../app/lib/genericMessages';

const messages = defineMessages({
  noDelete: {
    id: 'projects.arriva.app.modules.ArriVideo.components.FolderDetail.noDelete',
    description: 'No remove label',
    defaultMessage: 'You can\'t delete a folder which is not empty.',
  },
  deleteFolder: {
    id: 'projects.arriva.app.modules.ArriVideo.components.FolderDetail.deleteFolder',
    description: 'Delete folder label',
    defaultMessage: 'Delete folder',
  },
})

class FolderDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      showDeleteModal: false,
      showWarningModal: false,
    };
  }

  componentDidMount() {
    const {
      folder, folderId, get, setFolders,
    } = this.props;

    // Check if the folder is false, if so, there are no broadcasts at all
    // and the user probably got here by using the direct url
    // So, load the folder
    if (folder === false && folderId) {
      get({ path: `arrivideo/folder/${folderId}` })
        .then((data) => setFolders([data]))
        .catch((err) => this.setState({ error: err }));
    }
  }

  renderForm() {
    const {
      folder, history, locale, patch, post, intl: { formatMessage }
    } = this.props;

    const initialValues = {
      ...folder,
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          (folder.id ? patch : post)({
            path: `arrivideo/folder/${folder.id ? folder.id : ''}`,
            body: omit(['createdAt', 'id', 'updatedAt'], values),
          })
            .then((result) => {
              if (result.message === constants.MSG_SUCCESS) {
                setSubmitting(false);

                history.push('/arrivideo#folders');
                return;
              }

              Sentry.captureException(new Error(`Something went wrong: folder post/patch-> ${result}`));
              this.setState({ error: `Er ging iets fout bij het opslaan: ${result}` });
            })
            .catch((error) => {
              console.error(error);
              Sentry.captureException(error);

              setSubmitting(false);
            });
        }}
        validate={(values) => {
          const errors = {};

          const required = 'title';
          // Make sure the required fields are there
          if (!values[required]) errors[required] = formatMessage(genericMessages.required)

          return errors;
        }}
      >
        {(props) => (
          /* eslint-disable react/jsx-props-no-spreading */
          <FolderForm
            {...props}
            locale={locale}
            onClickDelete={() => this.setState({ showDeleteModal: true })}
          />
          /* eslint-enable react/jsx-props-no-spreading */
        )}
      </Formik>
    );
  }

  renderDeleteModal() {
    const { folder, history, remove,  intl: { formatMessage } } = this.props;
    const { showDeleteModal } = this.state;

    const close = () => this.setState({ showDeleteModal: false });

    const removeFolder = () => {
      if (folder.nrBroadcasts > 0) {
        this.setState({ showDeleteModal: false, showWarningModal: true });
      } else {
        remove({ path: `arrivideo/folder/${folder.id}` })
          .catch((err) => {
            this.setState({ error: err });

            Sentry.captureException(
              new Error(`Something went wrong: /arrivideo/folder/${folder.id} DELETE -> ${err}`),
            );
          })
          .then(() => history.push('/arrivideo#folders'));
      }
    };

    return (
      <Modal show={showDeleteModal} onHide={close}>
        <Modal.Header>
          <Modal.Title>{formatMessage(messages.deleteFolder)}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p style={{ padding: 15 }}>
            {`U kunt een map alleen verwijderen als deze leeg is.
            Weet u zeker dat u de map: '${folder.title}' wilt verwijderen?`}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={close}>Sluiten</Button>
          <Button bsStyle="danger" onClick={() => removeFolder()}>Verwijderen</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderWarningModal() {
    const { intl: { formatMessage } } = this.props;
    const { showWarningModal } = this.state;

    if (showWarningModal) {
      return (
        <Alert bsStyle="danger">
          <h4>{formatMessage(genericMessages.error)}</h4>
          <p>{formatMessage(messages.noDelete)}</p>
        </Alert>
      );
    }
  };

  render() {
    const { folder, folderId } = this.props;
    const { error } = this.state;

    if (error) {
      // eslint-disable-next-line react/jsx-one-expression-per-line
      return <strong>Er ging iets fout! ({error})</strong>;
    }

    if (!folder && folderId) {
      return <strong>Kon het content item niet vinden!</strong>;
    }

    return (
      <div className="well">
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        <h2>Map {folder ? 'bewerken' : 'aanmaken'}</h2>

        {this.renderForm()}
        {this.renderDeleteModal()}
        {this.renderWarningModal()}
      </div>
    );
  }
}

FolderDetail.propTypes = {
  // Own props
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),

  // react-intl props
  intl: IntlShape.isRequired,

  // Dispatch props
  get: PropTypes.func.isRequired,
  patch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  setFolders: PropTypes.func.isRequired,

  // State props
  folder: PropTypes.oneOfType([
    folderShape,
    PropTypes.bool,
  ]),
  folderId: PropTypes.string,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => ({
  folder: getFolderByIdentifier(state, props),
  folderId: selectIdentifier(state, props),
  locale: getLocale(state),
});

const mapDispatchToProps = {
  get,
  patch,
  post,
  remove,
  setFolders,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FolderDetail));
