/* eslint react/jsx-no-bind:0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class HeaderBar extends Component {
  static propTypes = {
    data: PropTypes.shape({
      text: PropTypes.string,
      element: PropTypes.string,
    }),
    editModeOn: PropTypes.func.isRequired,
    parent: PropTypes.shape({}),
    onDestroy: PropTypes.func,
  };

  render() {
    const {
      data,
      editModeOn,
      parent,
      onDestroy,
    } = this.props;

    return (
      <div className="toolbar-header">
        <span className="label label-default">{data.text}</span>

        <div className="toolbar-header-buttons">
          {data.element !== 'LineBreak' &&
            <div className="btn is-isolated btn-school" onClick={editModeOn ? editModeOn.bind(parent, data) : null}>
              <i className="is-isolated fa fa-pencil-square-o" />
            </div>
          }
          <div className="btn is-isolated btn-school" onClick={onDestroy ? onDestroy.bind(this, data) : null}>
            <i className="is-isolated fa fa-trash-o" />
          </div>
        </div>
      </div>
    );
  }
}
