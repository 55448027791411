import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HeaderBar from '../header-bar';
import { myxss } from '../form-elements';

export default class Label extends Component {
  static propTypes = {
    _onDestroy: PropTypes.func,
    data: PropTypes.shape({
      label: PropTypes.string,
      pageBreakBefore: PropTypes.bool,
      inline: PropTypes.bool,
      content: PropTypes.string,
      bold: PropTypes.bool,
      italic: PropTypes.bool,
      static: PropTypes.bool,
      required: PropTypes.bool,
    }),
    editModeOn: PropTypes.func,
    parent: PropTypes.shape({}),
    mutable: PropTypes.bool,
    onEdit: PropTypes.func,
  };

  render() {
    const {
      data,
      mutable,
      parent,
      editModeOn,
      _onDestroy,
      onEdit,
    } = this.props;

    let classNames = 'static';
    if (data.bold) { classNames += ' bold'; }
    if (data.italic) { classNames += ' italic'; }

    let baseClasses = 'rfb-item';
    if (data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }

    return (
      <div className={baseClasses}>
        {!mutable &&
          <div>
            {data.pageBreakBefore && <div className="preview-page-break">Page Break</div>}

            <HeaderBar
              parent={parent}
              editModeOn={editModeOn}
              data={data}
              onDestroy={_onDestroy}
              onEdit={onEdit}
              static={data.static}
              required={data.required}
            />
          </div>
        }
        {
          // eslint-disable-next-line jsx-a11y/label-has-for
        }<label
          className={classNames}
          dangerouslySetInnerHTML={{ __html: myxss.process(data.content) }}
        />
      </div>
    );
  }
}
