import PropTypes from 'prop-types';
import { omit } from 'ramda';
import { Formik } from 'formik';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import {
  get, patch, post, remove,
} from '../../../lib/driveApi';
import { setMessages } from '../actions';
import { getMessageByIdentifier, selectIdentifier } from '../selector';
import { message as messageType } from '../types';
import constants from '../../../lib/drive-constants';

import { getLocale } from '../../../../../../app/modules/selectors/locale';
import NotificationMessageForm from './NotificationMessageForm';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import genericMessages from '../../../../../../app/lib/genericMessages';

const messages = defineMessages({
  deleteMessageConfirmation: {
    id: 'app.projects,arriva.app.modules.UserNotification.components.ContentDetails.deleteMessageConfirmation',
    description: 'Delete message confirmation',
    defaultMessage: 'Are you sure you want to delete this message?',
  },
  deleteNotification: {
    id: 'app.projects,arriva.app.modules.UserNotification.components.ContentDetails.deleteNotification',
    description: 'Delete notification',
    defaultMessage: 'Delete Notification',
  },
  editNotification: {
    id: 'app.projects,arriva.app.modules.UserNotification.components.ContentDetails.editNotification',
    description: 'Edit notification',
    defaultMessage: 'Edit notification',
  },
  createNotification: {
    id: 'app.projects,arriva.app.modules.UserNotification.components.ContentDetails.createNotification',
    description: 'Create notification',
    defaultMessage: 'Create notification',
  },
  errorMessage: {
    id: 'projects.arriva.app.modules.UserNotifications.components.NotificationDetails.errorMessage',
    description: 'Error message fetching vehicle the notification',
    defaultMessage: 'Something went wrong!',
  },
});

class NotificationDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      showDeleteModal: false,
    };
  }

  componentDidMount() {
    const {
      message, messageId, get, setMessages,
    } = this.props;
    if (message === false && messageId) {
      get({ path: `notifications/notification/${messageId}` })
        .then(({ messages }) => {
          setMessages(messages);
        })
        .catch((err) => this.setState({ error: err }));
    }
  }

  renderDeleteModal() {
    const {
      message, history, remove, intl: { formatMessage },
    } = this.props;
    const { showDeleteModal } = this.state;

    const close = () => this.setState({ showDeleteModal: false });

    const removeMessage = () => {
      remove({ path: `notifications/notification/${message.id}` })
        .catch((err) => {
          this.setState({ error: err });

          Sentry.captureException(
            new Error(`Something went wrong: notifications/notification/${message.id} DELETE -> ${err}`),
          );
        })
        .then(() => history.push('/notifications'));
    };

    return (
      <Modal show={showDeleteModal} onHide={close}>
        <Modal.Header>
          <Modal.Title>{formatMessage(messages.deleteNotification)}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p style={{ padding: 15 }}>
            {formatMessage(messages.deleteMessageConfirmation)}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={close}>{formatMessage(genericMessages.close)}</Button>
          <Button bsStyle="danger" onClick={() => removeMessage()}>{formatMessage(genericMessages.delete)}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderForm() {
    const {
      message, history, locale, patch, post,
    } = this.props;

    const initialValues = {
      ...message,
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          const body = {
            ...omit(['createdAt', 'id', 'updatedAt'], values),
          };

          (message.id ? patch : post)({ path: `notifications/notification/${message.id ? message.id : ''}`, body })
            .then((result) => {
              if (result.message === constants.MSG_SUCCESS) {
                setSubmitting(false);
                history.replace('/notifications');
                return;
              }

              Sentry.captureException(new Error(`Something went wrong: urlEntryForm post/patch-> ${result}`));
              this.setState({ error: `Er ging iets fout bij het opslaan: ${result}` });
            })
            .catch((error) => {
              console.log('error', error);
              Sentry.captureException(error);

              setSubmitting(false);
            });
        }}
        validate={(values) => {
          const errors = {};

          const required = ['notificationText', 'startDate', 'endDate'];
          // Make sure the required fields are there
          required.forEach((k) => {
            if (!values[k]) errors[k] = 'Verplicht';
          });

          return errors;
        }}
      >
        {(props) => (
          <NotificationMessageForm
            {...props}
            locale={locale}
            onClickDelete={() => this.setState({ showDeleteModal: true })}
          />
        )}

      </Formik>
    );
  }

  render() {
    const { message, messageId, intl: { formatMessage } } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <strong>
          {formatMessage(messages.errorMessage)}
          {' '}
        </strong>
      );
    }

    if (!message && messageId) {
      return <strong>Kon de notificatie niet vinden!!</strong>;
    }
    return (
      <div className="well">
        <h2>
          {message
            ? formatMessage(messages.editNotification)
            : formatMessage(messages.createNotification) }
        </h2>

        {this.renderForm()}
        {this.renderDeleteModal()}
      </div>
    );
  }
}

NotificationDetails.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  // Dispatch props
  get: PropTypes.func.isRequired,
  patch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  setMessages: PropTypes.func.isRequired,
  // State props
  message: PropTypes.oneOfType([messageType, PropTypes.bool]),
  messageId: PropTypes.string,
  locale: PropTypes.string.isRequired,
  intl: IntlShape.isRequired,
};

const mapStateToProps = (state, props) => ({
  message: getMessageByIdentifier(state, props),
  messageId: selectIdentifier(state, props),
  locale: getLocale(state),
});

const mapDispatchToProps = {
  get,
  patch,
  post,
  remove,
  setMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(NotificationDetails));
