import {
  find,
  path,
  propEq,
} from 'ramda';
import { createSelector } from 'reselect';

// Loading selectors
export const isLoadingEstablishments = (state) => path(['establishment', 'loadingEstablishments'], state);

// Data selectors
export const selectEstablishments = (state) => path(['establishment', 'establishments'], state);
export const selectIdentifier = (state, props) => path(['match', 'params', 'identifier'], props);

// Memoizing selectors
export const getEstablishmentByIdentifier = createSelector(
  [selectIdentifier, selectEstablishments], (establishmentId, establishments) => {
    if (!establishments || establishments.length === 0) return false;
    if (!establishmentId) return undefined;

    return find(propEq('principalId', establishmentId))(establishments);
  });

export const getEstablishments = createSelector(
  [selectEstablishments], (establishments) => {
    if (!establishments) return [];

    return establishments;
  },
);
