import xss from 'xss';

import Header from './elements/Header';
import Paragraph from './elements/Paragraph';
import Label from './elements/Label';
import LineBreak from './elements/LineBreak';
import TextInput, { getSubmissionValue as getTextInputValue } from './elements/TextInput';
import NumberInput, { getSubmissionValue as getNumberInputValue } from './elements/NumberInput';
import TextArea, { getSubmissionValue as getTextAreaValue } from './elements/TextArea';
import Dropdown, { getSubmissionValue as getDropdownValue } from './elements/Dropdown';
import Signature from './elements/Signature';
import Checkboxes, { getSubmissionValue as getCheckboxesValue } from './elements/Checkboxes';
import DatePicker, { getSubmissionValue as getDatePickerValue } from './elements/DatePicker';
import RadioButtons, { getSubmissionValue as getRadioButtonsValue } from './elements/RadioButtons';
import Image from './elements/Image';
import Rating from './elements/Rating';
import Tags from './elements/Tags';
import HyperLink from './elements/HyperLink';
import Download from './elements/Download';
import Camera from './elements/Camera';
import Range from './elements/Range';
import Upload, { getSubmissionValue as getUploadValue } from './elements/Upload';

export const myxss = new xss.FilterXSS({
  whiteList: {
    u: [],
    br: [],
    b: [],
    i: [],
    ol: ['style'],
    ul: ['style'],
    li: [],
    p: ['style'],
    sub: [],
    sup: [],
    div: ['style'],
    em: [],
    strong: [],
    span: ['style'],
  },
});

export {
  Header,
  Paragraph,
  Label,
  LineBreak,
  TextInput,
  NumberInput,
  TextArea,
  Dropdown,
  Signature,
  Checkboxes,
  DatePicker,
  RadioButtons,
  Image,
  Rating,
  Tags,
  HyperLink,
  Download,
  Camera,
  Range,
  Upload,

  // Submission value getter functions
  getTextInputValue,
  getNumberInputValue,
  getTextAreaValue,
  getDropdownValue,
  getCheckboxesValue,
  getDatePickerValue,
  getRadioButtonsValue,
  getUploadValue,
};
