import { Mutex } from '../../../../app/lib/utils';

// milliseconds, minimum time the server should response in, otherwise an error is trigger
const FETCH_TIMEOUT = 20000;

// Create a new Mutex
const mutex = new Mutex();

/**
 * Method that will log the network failure reason and report it to Sentry
 *
 * @param apiEndpoint
 * @param method
 * @param error
 * @returns {boolean}
 */
const logNetworkFailure = ({ apiEndpoint, method, error }) => {
  const errorString = `Could not connect to server: ${apiEndpoint}`;

  if (process.env.NODE_ENV === 'development') {
    console.error(errorString, method, error.message);
  } else {
    if (error.message === 'Network request failed') return false;

    Sentry.withScope((scope) => {
      scope.setTag('reducer', 'DriveAPI');
      scope.setExtra('method', method);

      Sentry.captureException(error);
    });
  }
};

/**
 * A wrapper for fetch which handles timeouts
 *
 * @param apiEndpoint
 * @param fetchParams
 * @returns {Promise<unknown>}
 */
const fetchTimeoutWrapper = (apiEndpoint, fetchParams) => {
  let didTimeOut = false;

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      didTimeOut = true;
      reject(new Error('Request timed out'));
    }, FETCH_TIMEOUT);

    fetch(apiEndpoint, fetchParams)
      .then((response) => {
        // Clear the timeout as cleanup
        clearTimeout(timeout);

        if (!didTimeOut) resolve(response);
      })
      .catch((err) => {
        // Rejection already happened with setTimeout
        if (didTimeOut) return;

        // Reject with error
        reject(err);
      });
  });
};

/**
 * Method that will handle the request to the Drive! API
 *
 * @param path
 * @param body
 * @param method
 * @param parallel
 * @param internal
 * @returns {function(...[*]=)}
 */
const request = ({
  path, body, method, parallel, internal = false
}) => async (dispatch, getState) => {
  const endpoint = internal ? process.env.RAZZLE_DRIVE_API_HOST_INTERNAL : process.env.RAZZLE_DRIVE_API_HOST;

  let unlock;

  if (!parallel) {
    unlock = await mutex.lock();
  }

  if (!endpoint) {
    return Promise.reject(new Error(internal
      ? 'No Drive! Internal API endpoint defined, define: RAZZLE_DRIVE_API_HOST_INTERNAL in env file'
      : 'No Drive! API endpoint defined, define: RAZZLE_DRIVE_API_HOST in env file'
    ));
  }

  if (!method) {
    return Promise.reject(new Error('No method param given to request'));
  }

  const state = await getState();
  const { arriva: { driveToken } } = state;
  const apiEndpoint = `${endpoint}${path}`;

  const fetchParams = {
    headers: {
      Authorization: `Bearer ${driveToken}`,
      'Content-Type': 'application/json',
    },
  };

  fetchParams.method = method;

  if (method === 'POST' || method === 'PATCH') {
    if (!body) {
      return Promise.reject(new Error('no body param given to request'));
    }

    fetchParams.body = JSON.stringify(body);
  }

  return new Promise((resolve, reject) => {
    const wrappedRequest = async () => {
      try {
        const response = await fetchTimeoutWrapper(apiEndpoint, fetchParams);

        const result = await response.json();

        if (result.error) {
          const err = new Error(result.error);

          if (result.message) {
            err.original_message = result.message;
          }

          return reject(err);
        }

        return resolve(result);
      } catch (error) {
        logNetworkFailure({ apiEndpoint, method, error });

        return reject(error);
      }
    };

    return wrappedRequest()
      .finally(() => {
        if (!parallel) unlock();
      });
  });
};

/**
 * GET method
 *
 * @param path
 * @param parallel
 * @returns {function(...[*]=)}
 */
export const get = ({ path, parallel = true, internal = false }) => request({
  path, method: 'GET', parallel, internal
});

/**
 * POST method
 *
 * @param path
 * @param body
 * @param parallel
 * @returns {function(...[*]=)}
 */
export const post = ({ path, body, parallel = true, internal = false }) => request({
  path, body, method: 'POST', parallel, internal
});

/**
 * PATCH method
 *
 * @param path
 * @param body
 * @param parallel
 * @returns {function(...[*]=)}
 */
export const patch = ({ path, body, parallel = true, internal = false }) => request({
  path, body, method: 'PATCH', parallel, internal
});

/**
 * DELETE method
 * 'delete' is a reserved keyword, so we named it 'remove'
 *
 * @param path
 * @returns {function(...[*]=)}
 */
export const remove = ({ path, internal = false }) => request({ path, method: 'DELETE', internal });
