import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Alert,
  Button,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Row,
  Table,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import { establishment as establishmentShape } from './types';
import Pagination from '../../../../../../app/components/Pagination';
import {
  loadingEstablishmentData,
  loadingEstablishmentDataDone,
  setEstablishmentData,
} from '../actions';
import { get } from '../../../lib/driveApi';
import { isLoadingEstablishments, getEstablishments } from '../selectors';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import genericMessages from '../../../../../../app/lib/genericMessages';

const styles = {
  footerWrapper: {
    display: 'flex',
  },
  footerInnerWrapper: {
    width: '50%',
  },
  footerInnerWrapperRight: {
    textAlign: 'right',
  },
  footerAddButton: {
    margin: '20px 0',
  },
};

const messages = defineMessages({
  filter: {
    id: 'projects.arriva.app.modules.EmailEstablishment.components.Establishments.filter',
    description: 'Filter label',
    defaultMessage: 'Filter on name',
  },
  placeholder: {
    id: 'projects.arriva.app.modules.EmailEstablishment.components.Establishments.placeholder',
    description: 'Placeholder filter label',
    defaultMessage: 'Enter a name',
  },
  search: {
    id: 'projects.arriva.app.modules.EmailEstablishment.components.Establishments.search',
    description: 'Search helper label',
    defaultMessage: 'Search is not case sensitive',
  },
  email: {
    id: 'projects.arriva.app.modules.EmailEstablishment.components.Establishments.email',
    description: 'Email label',
    defaultMessage: 'Email address',
  },
  noEmail: {
    id: 'projects.arriva.app.modules.EmailEstablishment.components.Establishments.noEmail',
    description: 'No email set message',
    defaultMessage: 'Email address not set',
  },
});

class Establishments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      pageSize: 10,
      page: 1,
      pages: 1,
      searchTitle: undefined,
    };
  }

  componentDidMount() {
    this.fetchEstablishments();
  }

  onSelectPage(page) {
    this.setState({ page }, () => this.fetchEstablishments());
  }

  async fetchEstablishments() {
    const {
      get,
      loadingEstablishmentData,
      loadingEstablishmentDataDone,
      setEstablishmentData,
    } = this.props;
    const { page, pageSize, searchTitle } = this.state;

    await loadingEstablishmentData();

    let url = `establishment/establishments?page=${page}&page_size=${pageSize}`;

    if (searchTitle) {
      url = `establishment/establishments?page=1&page_size=${pageSize}&filter[EST_NAME]=${searchTitle}`;
    }

    get({ path: url, parallel: false })
      .then(({ pages, establishments }) => {
        this.setState({ pages }, async () => {
          await setEstablishmentData(establishments);
          await loadingEstablishmentDataDone();
        });
      })
      .catch((err) => this.setState({ error: err, page: 1, pages: 0 }));
  }

  renderError() {
    const { intl: { formatMessage } } = this.props;
    const { error } = this.state;

    if (!error) return null;

    const dismissError = () => this.setState({ error: undefined });

    return (
      <Alert bsStyle="danger" onDismiss={dismissError}>
        <h4>{formatMessage(genericMessages.errorMessage)}</h4>
        <p>{error.toString()}</p>
        <p>
          <Button onClick={dismissError}>{formatMessage(genericMessages.closeNotification)}</Button>
        </p>
      </Alert>
    );
  }

  renderSearch() {
    const { intl: { formatMessage } } = this.props;
    const { searchTitle } = this.state;

    return (
      <div className="well">
        <Form horizontal>
          <Row>
            <Col xs={12} md={8} lg={8}>
              <FormGroup controlId="searchFormTitle">
                <ControlLabel>{formatMessage(messages.filter)}</ControlLabel>
                <FormControl
                  style={{ width: 450 }}
                  type="text"
                  value={searchTitle}
                  placeholder={formatMessage(messages.placeholder)}
                  onChange={(e) =>
                    this.setState({ searchTitle: e.target.value }, () =>
                      this.fetchEstablishments(),
                    )}
                />
                <HelpBlock>{formatMessage(messages.search)}</HelpBlock>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }

  renderEstablishments(establishment) {
    const { intl: { formatMessage } } = this.props;

    if (!establishment.principalId || !establishment.name) {
      return null;
    }

    return (
      <tr key={`establishment-${establishment.principalId}`}>
        <td>
          <Link to={`/email-establishment/establishment/edit/${establishment.principalId}`}>
            {establishment.principalId}
          </Link>
        </td>
        <td>{establishment.name}</td>
        <td>{establishment.emailAddress || formatMessage(messages.noEmail)}</td>
      </tr>
    );
  }

  render() {
    const { establishments, isLoadingEstablishments, intl: { formatMessage } } = this.props;
    const { page, pages } = this.state;

    return (
      <div>
        {this.renderError()}
        {this.renderSearch()}

        {establishments.length === 0 && (
          <strong>{formatMessage(genericMessages.nothingFound)}</strong>
        )}

        {isLoadingEstablishments && (
          <strong>{formatMessage(genericMessages.loadingInProgress)}</strong>
        )}

        {establishments.length > 0 && !isLoadingEstablishments && (
          <Table responsive>
            <thead>
              <tr>
                <td>link</td>
                <td>{formatMessage(genericMessages.name)}</td>
                <td>{formatMessage(messages.email)}</td>
              </tr>
            </thead>
            <tbody>
              {establishments.map((establishment) => this.renderEstablishments(establishment))}
            </tbody>
          </Table>
        )}

        <div style={styles.footerWrapper}>
          <div style={styles.footerInnerWrapper}>
            <Pagination
              first
              prev
              last
              next
              ellipsis
              boundaryLinks
              maxButtons={5}
              bsSize="small"
              onSelect={(page) => this.onSelectPage(page)}
              page={page}
              pages={pages}
            />
          </div>

          <div
            style={{
              ...styles.footerInnerWrapper,
              ...styles.footerInnerWrapperRight,
            }}
          />
        </div>
      </div>
    );
  }
}

Establishments.propTypes = {
  // Dispatch props
  get: PropTypes.func.isRequired,
  loadingEstablishmentData: PropTypes.func.isRequired,
  loadingEstablishmentDataDone: PropTypes.func.isRequired,
  setEstablishmentData: PropTypes.func.isRequired,
  messages: PropTypes.shape({}),

  // State props
  establishments: PropTypes.arrayOf(establishmentShape).isRequired,
  isLoadingEstablishments: PropTypes.bool.isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
};

const mapStateToProps = (state) => ({
  establishments: getEstablishments(state),
  isLoadingEstablishments: isLoadingEstablishments(state),
});

const mapDispatchToProps = {
  get,
  loadingEstablishmentData,
  loadingEstablishmentDataDone,
  setEstablishmentData,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Establishments));
