import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import Col from 'react-bootstrap/lib/Col';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Form from 'react-bootstrap/lib/Form';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Image from 'react-bootstrap/lib/Image';
import Jumbotron from 'react-bootstrap/lib/Jumbotron';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Radio from 'react-bootstrap/lib/Radio';
import Row from 'react-bootstrap/lib/Row';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import DatePicker from 'react-datepicker';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import IntlShape from '../../lib/PropTypes/IntlShape';
import genericMessages from '../../lib/genericMessages';
import { vState } from '../../lib/utils';
import Constants from '../../modules/constants';

import SummernoteInput from '../Summernote';

const messages = defineMessages({
  coverPhoto: {
    id: 'app.components.Article.WriteForm.coverPhoto',
    description: 'WriteForm \'Cover photo\' field label.',
    defaultMessage: 'Cover photo',
  },
  coverPhotoAdd: {
    id: 'app.components.Article.WriteForm.coverPhotoAdd',
    description: 'WriteForm \'Add cover photo\' button label.',
    defaultMessage: 'Add cover photo',
  },
  coverPhotoChange: {
    id: 'app.components.Article.WriteForm.coverPhotoChange',
    description: 'WriteForm \'Change cover photo\' button label.',
    defaultMessage: 'Change cover photo',
  },
  coverPhotoNotSet: {
    id: 'app.components.Article.WriteForm.coverPhotoNotSet',
    description: 'WriteForm \'Cover photo not set\' message.',
    defaultMessage: 'This article currently has no cover photo.',
  },
  coverPhotoRemove: {
    id: 'app.components.Article.WriteForm.coverPhotoRemove',
    description: 'WriteForm \'Remove cover photo\' button label.',
    defaultMessage: 'Remove cover photo',
  },
  onlineFrom: {
    id: 'app.components.Article.WriteForm.onlineFrom',
    description: '\'Online from\' label.',
    defaultMessage: 'Online from',
  },
  onlineUntil: {
    id: 'app.components.Article.WriteForm.onlineUntil',
    description: '\'Online until\' label.',
    defaultMessage: 'Online until',
  },
  postArticle: {
    id: 'app.components.Article.WriteForm.postArticle',
    description: '\'Post message\' label.',
    defaultMessage: 'Post article',
  },
  postAs: {
    id: 'app.components.Article.WriteForm.postAs',
    description: '\'Post as\' label.',
    defaultMessage: 'Post as',
  },
  postNewsArticle: {
    id: 'app.components.Article.WriteForm.postNewsArticle',
    description: '\'Post newsarticle\' label.',
    defaultMessage: 'Post newsarticle',
  },
  postIn: {
    id: 'app.components.Article.WriteForm.PostIn',
    description: '\'Post in\' label.',
    defaultMessage: 'Post in',
  },
  postInDefaultOption: {
    id: 'app.components.Article.WriteForm.postInDefaultOption',
    description: '\'Post in\' default option label.',
    defaultMessage: 'Select a group...',
  },
  postInterningArticle: {
    id: 'app.components.Article.WriteForm.postInterningArticle',
    description: '\'Post interning article\' label.',
    defaultMessage: 'Post interning article',
  },
});

const WriteForm = ({
  errors, groups, handleChange,
  handleSubmit, setFieldValue, values,
  summernoteProps,
  userCanManageArticle, onDelete, isAdmin,
  onCoverPhotoClear, onCoverPhotoClick, onCoverPhotoRef,
  intl: { formatMessage },
}) => (
  <Form className="form-profile" horizontal onSubmit={handleSubmit}>
    {errors.name && <div>{errors.name}</div>}

    <Row>
      <Col xs={9} md={2} className="article-write-left">
        <FormGroup controlId="articleType" validationState={vState(errors, 'articleType')}>
          <Radio
            name="articleType"
            onChange={handleChange}
            value={Constants.ARTICLE_TYPE_DEFAULT}
            checked={values.articleType === Constants.ARTICLE_TYPE_DEFAULT}
          >
            <FormattedMessage {...messages.postArticle} />
          </Radio>

          {isAdmin && (
            <Radio
              name="articleType"
              onChange={handleChange}
              value={Constants.ARTICLE_TYPE_NEWS}
              checked={values.articleType === Constants.ARTICLE_TYPE_NEWS}
            >
              <FormattedMessage {...messages.postNewsArticle} />
            </Radio>
          )}

          <Radio
            name="articleType"
            onChange={handleChange}
            value={Constants.ARTICLE_TYPE_HOSPITALITY}
            checked={values.articleType === Constants.ARTICLE_TYPE_HOSPITALITY}
          >
            <FormattedMessage {...messages.postInterningArticle} />
          </Radio>
        </FormGroup>

        <FormGroup controlId="postAs" validationState={vState(errors, 'postAs')}>
          <ControlLabel>
            <FormattedMessage {...messages.postAs} />
          </ControlLabel>

          <Radio
            name="postAs"
            value={Constants.ARTICLE_POST_AS_GROUP}
            onChange={handleChange}
            checked={values.postAs === Constants.ARTICLE_POST_AS_GROUP}
          >
            <FormattedMessage {...genericMessages.group} />
          </Radio>

          <Radio
            name="postAs"
            value={Constants.ARTICLE_POST_AS_USER}
            onChange={handleChange}
            checked={values.postAs === Constants.ARTICLE_POST_AS_USER}
          >
            <FormattedMessage {...genericMessages.user} />
          </Radio>
        </FormGroup>
      </Col>

      <Col xs={9} md={7}>
        <FormGroup controlId="title" validationState={vState(errors, 'title')}>
          <ControlLabel>
            <FormattedMessage {...genericMessages.title} />
          </ControlLabel>
          <FormControl
            type="text"
            onChange={handleChange}
            value={values.title}
          />
        </FormGroup>

        <FormGroup controlId="content" validationState={vState(errors, 'content')}>
          <ControlLabel>
            <FormattedMessage {...genericMessages.article} />
          </ControlLabel>
          <SummernoteInput
            value={values.content}
            onChange={content => setFieldValue('content', content)}
            {...summernoteProps}
          />
        </FormGroup>

        <FormGroup controlId="cover" validationState={vState(errors, 'cover')}>
          <ControlLabel>
            <FormattedMessage {...messages.coverPhoto} />
          </ControlLabel>

          <Jumbotron className="jumbotron-coverphoto text-align-center">
            {values.cover ? (
              <Image responsive src={values.cover} />
            ) : (
              <div className="no-coverphoto-text">
                <FormattedMessage {...messages.coverPhotoNotSet} />
                <div className="no-coverphoto-button">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-article-photo-add">
                        <FormattedMessage {...messages.coverPhotoAdd} />
                      </Tooltip>
                    }
                  >
                    <Button bsStyle="primary" onClick={onCoverPhotoClick}>
                      <Glyphicon glyph="plus" />
                    </Button>
                  </OverlayTrigger>
                </div>
              </div>
            )}
            {values.cover && (
              <ButtonToolbar className="toolbar-coverphoto">
                <ButtonGroup style={{ float: 'right' }}>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-article-photo-add">
                        <FormattedMessage {...messages.coverPhotoChange} />
                      </Tooltip>
                    }
                  >
                    <Button bsStyle="primary" onClick={onCoverPhotoClick}>
                      <Glyphicon glyph="pencil" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-article-photo-delete">
                        <FormattedMessage {...messages.coverPhotoRemove} />
                      </Tooltip>
                    }
                  >
                    <Button
                      bsStyle="primary"
                      onClick={() => {
                        setFieldValue('cover', false);
                        onCoverPhotoClear();
                      }}
                    >
                      <Glyphicon glyph="trash" />
                    </Button>
                  </OverlayTrigger>
                </ButtonGroup>
              </ButtonToolbar>
            )}
          </Jumbotron>

          <input
            type="file"
            style={{ display: 'none' }}
            accept="image/jpeg,image/jpg,image/png,image/gif"
            ref={onCoverPhotoRef}
            onChange={({ target: { files } }) => {
              if (files.length > 0) {
                const file = files[0];
                const reader = new FileReader();

                reader.onload = () => setFieldValue('cover', reader.result);
                reader.onerror = (error) => {
                  // TODO: user feedback, Sentry
                  console.error(`[OI] WriteForm readAsDataURL file=${file.name} error=${error}`);
                };

                reader.readAsDataURL(file);
              }
            }}
          />
        </FormGroup>

      </Col>

      <Col xs={9} md={3} className="article-write-right">
        <FormGroup controlId="groupId" validationState={vState(errors, 'groupId')}>
          <ControlLabel>
            <FormattedMessage {...messages.postIn} />
          </ControlLabel>
          <FormControl
            name="groupId"
            componentClass="select"
            onChange={handleChange}
            value={values.groupId}
          >
            <option>
              {formatMessage(messages.postInDefaultOption)}
            </option>

            {groups.map(({ id, attributes: { name } }) => (
              <option key={`group_${id}`} value={id}>{name}</option>
            ))}
          </FormControl>
        </FormGroup>

        <FormGroup controlId="activateDate" validationState={vState(errors, 'activateDate')}>
          <ControlLabel>
            <FormattedMessage {...messages.onlineFrom} />
          </ControlLabel>
          <DatePicker
            className="form-control"
            name="activateDate"
            value={moment(values.activateDate).format('DD/MM/YYYY')}
            onChange={date => setFieldValue('activateDate', date.toDate())}
          />
        </FormGroup>

        <FormGroup controlId="deactivateDate" validationState={vState(errors, 'deactivateDate')}>
          <ControlLabel>
            <FormattedMessage {...messages.onlineUntil} />
          </ControlLabel>
          <DatePicker
            className="form-control"
            name="deactivateDate"
            value={values.deactivateDate ?
              moment(values.deactivateDate).format('DD/MM/YYYY')
              : ''}
            onChange={date => setFieldValue('deactivateDate', date.toDate())}
          />
        </FormGroup>
      </Col>
    </Row>
    <hr />
    <Row>
      <Col xs={9} md={12}>
        <ButtonToolbar className="options" style={{ float: 'right' }}>
          {userCanManageArticle && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-article-delete">
                  <FormattedMessage {...genericMessages.deleteArticle} />
                </Tooltip>
              }
            >
              <Button onClick={() => onDelete && onDelete()}>
                <Glyphicon glyph="trash" />
              </Button>
            </OverlayTrigger>
          )}

          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="tooltip-article-submit">
                <FormattedMessage {...genericMessages.post} />
              </Tooltip>
            }
          >
            <Button bsStyle="primary" type="submit">
              <Glyphicon glyph="ok" />
            </Button>
          </OverlayTrigger>
        </ButtonToolbar>
      </Col>
    </Row>
  </Form>
);

WriteForm.propTypes = {
  // react-intl props
  intl: IntlShape.isRequired, // eslint-disable-line react/no-typos

  // Formik props
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  userCanManageArticle: PropTypes.bool,
  onDelete: PropTypes.func,
  errors: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    articleType: PropTypes.string,
    postAs: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    cover: PropTypes.string,
    activateDate: PropTypes.string,
    deactivateDate: PropTypes.string,
    groupId: PropTypes.string,
  }).isRequired,
  summernoteProps: PropTypes.shape({}),

  // own props.
  groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  onCoverPhotoClear: PropTypes.func.isRequired,
  onCoverPhotoClick: PropTypes.func.isRequired,
  onCoverPhotoRef: PropTypes.func.isRequired,
};

export default injectIntl(WriteForm);
