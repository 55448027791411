export const INTERFACE_MODAL_CREATE_PAGE_OPEN = 'INTERFACE_MODAL_CREATE_PAGE_OPEN';
export const INTERFACE_MODAL_CREATE_PAGE_CLOSE = 'INTERFACE_MODAL_CREATE_PAGE_CLOSE';

/* eslint-disable */

export const uiModalCreatePageOpen = () => {
  return async (dispatch) => {
    return dispatch({ type: INTERFACE_MODAL_CREATE_PAGE_OPEN, payload: true });
  };
};

export const uiModalCreatePageClose = () => {
  return async (dispatch) => {
    return dispatch({ type: INTERFACE_MODAL_CREATE_PAGE_CLOSE, payload: false });
  };
};
