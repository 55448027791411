/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
// noinspection ES6CheckImport
import {
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';

import Home from '../../../app/layouts/Home';
import { /* isAdmin, */selectUser } from '../../../app/modules/selectors/session';
import Logout from '../../../app/layouts/Logout';
import User from '../../../app/lib/PropTypes/User';

import ConnectedIntlProvider from './ConnectedIntlProvider';
import Header from './components/Header';
import NotAuthorized from './components/NotAuthorized';
import TopBar from './components/Menu/TopBar';
import AppLayout from './layouts/AppLayout';
import BaseLayout from './layouts/BaseLayout';
import Login from './layouts/Login';
import VehicleStatuses from './layouts/VehicleStatuses';

// Modules
import ArriVideoDashboard from './modules/ArriVideo/components/Dashboard';
import ArriVideoBroadcastDetail from './modules/ArriVideo/components/BroadcastDetail';
import ArriVideoContentItemDetail from './modules/ArriVideo/components/ContentItemDetail';
import ArriVideoFolderDetail from './modules/ArriVideo/components/FolderDetail';
import SupportLayout from './modules/Support/layouts/SupportLayout';

import TabletRegistration from './modules/TabletRegistration/components/TabletRegistration';
import TabletRegistrationLanding from './modules/TabletRegistration/components/TabletRegistrationLanding';
import UrlEntryDashBoard from './modules/UrlEntry/components/Dashboard';
import UrlEntryUrlEntryDetail from './modules/UrlEntry/components/UrlEntryDetail';
import SoftwarePackageDashboard from './modules/SoftwarePackage/components/Dashboard';
import SoftwarePackageDetail from './modules/SoftwarePackage/components/SoftwarePackageDetail';
import SoftwareNotificationDashboard from './modules/SoftwareNotification/components/Dashboard';
import SoftwareNotificationDetail from './modules/SoftwareNotification/components/NotificationDetail';
import MalfunctionsDashboard from './modules/Malfunctions/components/Dashboard';
import MalfunctionsList from './modules/Malfunctions/components/Malfunctions/MalfunctionsList';
import CategoryDetail from './modules/Malfunctions/components/Categories/CategoryDetail';
import MalfunctionsDetail from './modules/Malfunctions/components/Malfunctions/MalfunctionsDetail';
import Confirmation from './modules/Malfunctions/components/Malfunctions/Confirmation';

import EmailEstablishmentDashboard from './modules/EmailAddressEstablishment/components/Dashboard';
import EstablishmentDetail from './modules/EmailAddressEstablishment/components/EstablishmentDetail';
import NotificationsDashboard from './modules/UserNotifications/components/Dashboard';
import NotificationDetails from './modules/UserNotifications/components/NotificationDetails';

import '../styles/main.less';
import { hasDrivePermissions } from './modules/actions';
import SpinnerImage from '../img/spinner.gif';
import MalfunctionsDetailOpen from './modules/Malfunctions/components/Malfunctions/MalfunctionsDetailOpen';
import Malfunctions from './components/Malfunctions';
import VehicleWashStatuses from './layouts/VehicleWashStatuses';

const PrivateRoute = ({
  component: Component,
  componentOptions,
  childComponent,
  childProps,
  resourceType,
  resourceKey,
  user,
  ...rest
}) => {
  const permissionsFetched = useSelector((state) => 'arriva' in state && state.arriva.permissionsFetched);
  const dispatch = useDispatch();

  return (
    <Route
      {...rest}
      render={(props) => {
        // No user is logged in, redirect the user to the login page.
        if (user === undefined) {
          return (
            <Redirect
              to={{
                // eslint-disable-next-line react/prop-types
                pathname: '/login',
                // eslint-disable-next-line react/prop-types
                search: `?from=${props.location.pathname}`,
                // eslint-disable-next-line react/prop-types
                state: { from: props.location },
              }}
            />
          );
        }

        let displayedChildComponent = childComponent;

        if (resourceType && resourceKey) {
          const userIsAuthorized = dispatch(hasDrivePermissions(resourceType, resourceKey));
          if (!userIsAuthorized) {
            if (!permissionsFetched) {
              // Permissions are not yet available, just display a spinner.
              return (
                <div className="infinite-loading">
                  <div className="arriva-spinner">
                    <img src={SpinnerImage} alt="" />
                  </div>
                </div>
              );
            }
            // Permissions are available, and the user is not authorized. Display the component that
            // informs the user of this.
            displayedChildComponent = NotAuthorized;
          }
        }

        return (
          <Component
            {...props}
            componentOptions={componentOptions}
            childComponent={displayedChildComponent}
            childProps={childProps}
          />
        );
      }}
    />
  );
};
PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
  ]).isRequired,
  componentOptions: PropTypes.func,
  childComponent: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
  ]),
  childProps: PropTypes.node,
  resourceType: PropTypes.string,
  resourceKey: PropTypes.string,
  user: User,
};

const AdminRoute = ({
  component: Component,
  componentOptions,
  childComponent,
  childProps,
  isAdmin,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      isAdmin ? (
        <Component
          {...props}
          componentOptions={componentOptions}
          childComponent={childComponent}
          childProps={childProps}
        />
      ) : (
        <Redirect
          to={{
            // eslint-disable-next-line react/prop-types
            pathname: '/login',
            // eslint-disable-next-line react/prop-types
            search: `?from=${props.location.pathname}`,
            // eslint-disable-next-line react/prop-types
            state: { from: props.location },
          }}
        />
      )
    )}
  />
);
AdminRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
  ]).isRequired,
  componentOptions: PropTypes.func,
  childComponent: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
  ]),
  childProps: PropTypes.node,
  isAdmin: PropTypes.bool,
};

// Only logged in users can access these routes,
// otherwise the user will be redirect to the login page
const ConnectedPrivateRoute = withRouter(connect((state) =>
  ({ user: selectUser(state) }))(PrivateRoute));

// Only admins can access these routes,
// otherwise the user will be redirect to the login page
/* const ConnectedAdminRoute = withRouter(connect((state) =>
  ({ isAdmin: isAdmin(state) }))(AdminRoute)); */

const App = () => (
  <ConnectedIntlProvider>
    <Switch>
      <AppLayout Header={Header} TopBar={TopBar}>
        <ConnectedPrivateRoute
          path="/"
          exact
          component={BaseLayout}
          childComponent={Home}
        />

        {/**
          * ARRIVIDEO
          */}
        <ConnectedPrivateRoute
          exact
          path="/arrivideo"
          component={BaseLayout}
          childComponent={ArriVideoDashboard}
          resourceType="ARRIVIDEO"
          resourceKey="isAdministrator"
        />
        <ConnectedPrivateRoute
          exact
          path="/arrivideo/broadcast/add"
          component={BaseLayout}
          childComponent={ArriVideoBroadcastDetail}
          resourceType="ARRIVIDEO"
          resourceKey="isAdministrator"
        />
        <ConnectedPrivateRoute
          exact
          path="/arrivideo/broadcast/edit/:identifier"
          component={BaseLayout}
          childComponent={ArriVideoBroadcastDetail}
          resourceType="ARRIVIDEO"
          resourceKey="isAdministrator"
        />
        <ConnectedPrivateRoute
          exact
          path="/arrivideo/contentitem/add"
          component={BaseLayout}
          childComponent={ArriVideoContentItemDetail}
          resourceType="ARRIVIDEO"
          resourceKey="isAdministrator"
        />
        <ConnectedPrivateRoute
          exact
          path="/arrivideo/contentitem/edit/:identifier"
          component={BaseLayout}
          childComponent={ArriVideoContentItemDetail}
          resourceType="ARRIVIDEO"
          resourceKey="isAdministrator"
        />
        <ConnectedPrivateRoute
          exact
          path="/arrivideo/folder/add"
          component={BaseLayout}
          childComponent={ArriVideoFolderDetail}
          resourceType="ARRIVIDEO"
          resourceKey="isAdministrator"
        />
        <ConnectedPrivateRoute
          exact
          path="/arrivideo/folder/edit/:identifier"
          component={BaseLayout}
          childComponent={ArriVideoFolderDetail}
          resourceType="ARRIVIDEO"
          resourceKey="isAdministrator"
        />

        {/**
         * TABLET REGISTRATION
         */}
        <ConnectedPrivateRoute
          exact
          path="/tablet-registration"
          component={BaseLayout}
          childComponent={TabletRegistrationLanding}
          resourceType="TABLET_REGISTRATION"
          resourceKey="isAdministrator"
        />
        <ConnectedPrivateRoute
          exact
          path="/tablet-registration/:identifier"
          component={BaseLayout}
          childComponent={TabletRegistration}
          resourceType="TABLET_REGISTRATION"
          resourceKey="isAdministrator"
        />

        {/**
         * URLENTRY
         */}
        <ConnectedPrivateRoute
          exact
          path="/url-entry"
          component={BaseLayout}
          childComponent={UrlEntryDashBoard}
          resourceType="URLENTRY"
          resourceKey="isAdministrator"
        />
        <ConnectedPrivateRoute
          exact
          path="/url-entry/url-entry/add"
          component={BaseLayout}
          childComponent={UrlEntryUrlEntryDetail}
          resourceType="URLENTRY"
          resourceKey="isAdministrator"
        />
        <ConnectedPrivateRoute
          exact
          path="/url-entry/url-entry/edit/:identifier"
          component={BaseLayout}
          childComponent={UrlEntryUrlEntryDetail}
          resourceType="URLENTRY"
          resourceKey="isAdministrator"
        />

        {/**
         * USER Notifications
         */}
        <ConnectedPrivateRoute
          exact
          path="/notifications"
          component={BaseLayout}
          childComponent={NotificationsDashboard}
          resourceType="USER_NOTIFICATIONS"
          resourceKey="isAdministrator"
        />
        <ConnectedPrivateRoute
          exact
          path="/notifications/notification/add"
          component={BaseLayout}
          childComponent={NotificationDetails}
          resourceType="USER_NOTIFICATIONS"
          resourceKey="isAdministrator"
        />

        <ConnectedPrivateRoute
          exact
          path="/notifications/notification/edit/:identifier"
          component={BaseLayout}
          childComponent={NotificationDetails}
          resourceType="USER_NOTIFICATIONS"
          resourceKey="isAdministrator"
        />

        {/**
         * SOFTWARE PACKAGE
         */}
        <ConnectedPrivateRoute
          exact
          path="/software-package"
          component={BaseLayout}
          childComponent={SoftwarePackageDashboard}
          resourceType="SOFTWARE_PACKAGE"
          resourceKey="isAdministrator"
        />
        <ConnectedPrivateRoute
          exact
          path="/software-package/package/add"
          component={BaseLayout}
          childComponent={SoftwarePackageDetail}
          resourceType="SOFTWARE_PACKAGE"
          resourceKey="isAdministrator"
        />
        <ConnectedPrivateRoute
          exact
          path="/software-package/package/edit/:identifier"
          component={BaseLayout}
          childComponent={SoftwarePackageDetail}
          resourceType="SOFTWARE_PACKAGE"
          resourceKey="isAdministrator"
        />

        {/**
         * SOFTWARE NOTIFICATIONS
         */}
        <ConnectedPrivateRoute
          exact
          path="/message"
          component={BaseLayout}
          childComponent={SoftwareNotificationDashboard}
          resourceType="SOFTWARE_PACKAGE"
          resourceKey="isAdministrator"
        />
        <ConnectedPrivateRoute
          exact
          path="/message/package-notification/add"
          component={BaseLayout}
          childComponent={SoftwareNotificationDetail}
          resourceType="SOFTWARE_PACKAGE"
          resourceKey="isAdministrator"
        />
        <ConnectedPrivateRoute
          path="/message/package-notification/edit/:identifier"
          component={BaseLayout}
          childComponent={SoftwareNotificationDetail}
          resourceType="SOFTWARE_PACKAGE"
          resourceKey="isAdministrator"
        />

        {/**
         * EMAIL-ADDRESS ESTABLISMENT
         */}
        <ConnectedPrivateRoute
          exact
          path="/email-establishment"
          component={BaseLayout}
          childComponent={EmailEstablishmentDashboard}
          resourceType="EMAIL_ESTABLISHMENT"
          resourceKey="isAdministrator"
        />
        <ConnectedPrivateRoute
          exact
          path="/email-establishment/establishment/edit/:identifier"
          component={BaseLayout}
          childComponent={EstablishmentDetail}
          resourceType="EMAIL_ESTABLISHMENT"
          resourceKey="isAdministrator"
        />

        {/**
         * MALFUNCTIONS
         */}
        <ConnectedPrivateRoute
          exact
          path="/malfunction-form"
          component={BaseLayout}
          childComponent={MalfunctionsDashboard}
          resourceType="MALFUNCTIONS"
          resourceKey="write"
        />
        <ConnectedPrivateRoute
          exact
          path="/malfunctions/categories"
          component={BaseLayout}
          childComponent={MalfunctionsList}
          resourceType="MALFUNCTIONS"
          resourceKey="read"
        />
        <ConnectedPrivateRoute
          exact
          path="/malfunctions/categories/add"
          component={BaseLayout}
          childComponent={CategoryDetail}
          resourceType="MALFUNCTIONS"
          resourceKey="isAdministrator"
        />
        <ConnectedPrivateRoute
          exact
          path="/malfunctions/categories/edit/:identifier"
          component={BaseLayout}
          childComponent={CategoryDetail}
          resourceType="MALFUNCTIONS"
          resourceKey="isAdministrator"
        />
        <ConnectedPrivateRoute
          exact
          path="/malfunctions/pending-malfunctions/add/:identifier"
          component={BaseLayout}
          childComponent={MalfunctionsDetail}
          resourceType="MALFUNCTIONS"
          resourceKey="isAdministrator"
        />
        <ConnectedPrivateRoute
          exact
          path="/malfunctions/malfunctions-confirmation"
          component={BaseLayout}
          childComponent={Confirmation}
          resourceType="MALFUNCTIONS"
          resourceKey="isAdministrator"
        />

        <Route path="/vehicle-status/:identifier" component={VehicleStatuses} />
        <Route path="/vehicle-wash-status/:identifier" component={VehicleWashStatuses} />
        <Route exact path="/malfunctions/open/:identifier" component={Malfunctions} />
        <Route exact path="/malfunctions/open-internal/:identifier" component={Malfunctions} />
        <Route exact path="/malfunctions/open-route/confirmation" component={Confirmation} />
        <Route exact path="/malfunctions/open/pending-malfunctions/add/:identifier" component={MalfunctionsDetailOpen} />
        <Route path="/logout" component={Logout} />
        <Route path="/login" component={Login} />
        <Route exact path="/support" component={SupportLayout} />
      </AppLayout>
    </Switch>
  </ConnectedIntlProvider>
);

export default App;
