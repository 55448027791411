import {
  filter, find, path, prop, propEq, reverse, sortBy,
} from 'ramda';
import { createSelector } from 'reselect';

// Loading selectors
export const isLoadingBroadcasts = (state) => path(['arrivideo', 'loadingBroadcasts'], state);
export const isLoadingContentItems = (state) => path(['arrivideo', 'loadingContentItems'], state);
export const isLoadingFolders = (state) => path(['arrivideo', 'loadingFolders'], state);

// Prop selectors
export const selectPropAsValue = (state, props) => props;
export const selectIdentifier = (state, props) => path(['match', 'params', 'identifier'], props);

// Data selectors
export const selectBroadcasts = (state) => path(['arrivideo', 'broadcasts'], state);
export const selectContentItems = (state) => path(['arrivideo', 'contentItems'], state);
export const selectFolders = (state) => path(['arrivideo', 'folders'], state);

// Memoizing selectors
export const getBroadcastByIdentifier = createSelector(
  [selectIdentifier, selectBroadcasts], (broadcastId, broadcasts) => {
    if (!broadcasts || broadcasts.length === 0) return false;
    if (!broadcastId) return undefined;

    return find(propEq('id', broadcastId))(broadcasts);
  });

export const getContentItemByIdentifier = createSelector(
  [selectIdentifier, selectContentItems], (contentItemId, contentItems) => {
    if (!contentItems || contentItems.length === 0) return false;
    if (!contentItemId) return undefined;

    return find(propEq('id', contentItemId))(contentItems);
  },
);

export const getContentsByBroadcastId = createSelector(
  [selectContentItems, selectBroadcasts, selectPropAsValue],
  (contentItems, broadcasts, broadcastId) => {
    if (!contentItems || !broadcasts || !broadcastId) return undefined;

    const broadcast = find(propEq('id', broadcastId))(broadcasts);
    if (!broadcast) return undefined;

    // Map the broadcast content array so we keep the order
    return filter((b) => b)(sortBy(prop('order'))(broadcast.content).map((contentItem) =>
      find(propEq('id', contentItem.id))(contentItems)));
  });

export const getBroadcastsSortedByUpdatedAt = createSelector(
  [selectBroadcasts], (broadcasts) => {
    if (!broadcasts) return undefined;

    return reverse(sortBy(prop('updatedAt'))(broadcasts));
  },
);

export const getContentItemsSortedByUpdatedAt = createSelector(
  [selectContentItems], (contentItems) => {
    if (!contentItems) return undefined;

    return reverse(sortBy(prop('updatedAt'))(contentItems));
  },
);

export const getFolders = createSelector(
  [selectFolders], (folders) => {
    if (!folders) return undefined;

    return folders;
  },
);

export const getFolderByIdentifier = createSelector(
  [selectIdentifier, selectFolders], (folderId, folders) => {
    if (!folderId || folders.length === 0) return false;

    return find(propEq('id', folderId))(folders);
  },
);
