import axios from 'axios';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { groupBy, keys, path, range, sort, unnest } from 'ramda';
import React, { Component } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { readEndpoint } from 'redux-json-api';

import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Form from 'react-bootstrap/lib/Form';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import Tab from 'react-bootstrap/lib/Tab';
import Tabs from 'react-bootstrap/lib/Tabs';

import IntlShape from '../../lib/PropTypes/IntlShape';
import genericMessages from '../../lib/genericMessages';
import AxiosConfigPropTypeScheme from '../../lib/PropTypes/AxiosConfig';

import FileGrid from './FileGrid';
import Select from './Forms/Select';
import mediaLibraryMessages from './messages';

import './SelectFileModal.less';
import getPropTypeScheme from '../../lib/PropTypes';
import { selectUser } from '../../modules/selectors/session';

const { mediaLibrary: commonMediaLibrarySettings } = require(`../../../projects/${process.env.RAZZLE_PROJECT}/common`); //eslint-disable-line

const messages = defineMessages({
  descriptionRequired: {
    id: 'app.components.MediaLibrary.SelectFileModal.descriptionRequired',
    description: 'Description required message.',
    defaultMessage: 'A description is required.',
  },
  folder: {
    id: 'app.components.MediaLibrary.SelectFileModal.folder',
    description: 'Folder selection label.',
    defaultMessage: 'Folder',
  },
  fileSelectRequired: {
    id: 'app.components.MediaLibrary.SelectFileModal.fileSelectRequired',
    description: 'File selection required message.',
    defaultMessage: 'You must pick a file.',
  },
  unsorted: {
    id: 'app.components.MediaLibrary.SelectFileModal.unsorted',
    description: 'Unsorted files folder name.',
    defaultMessage: 'Unsorted',
  },
  tabTitleMediaLibrary: {
    id: 'app.components.MediaLibrary.SelectFileModal.tabTitleMediaLibrary',
    description: 'Media Library tab title.',
    defaultMessage: 'Media Library',
  },
  tabTitleUpload: {
    id: 'app.components.MediaLibrary.SelectFileModal.tabTitleUpload',
    description: 'Upload new file tab title.',
    defaultMessage: 'Upload a new file',
  },
});

class SelectFileModal extends Component {
  static propTypes = {
    // react-intl props
    intl: IntlShape.isRequired,

    // modal props
    closeModal: PropTypes.func,

    // own props
    id: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    doAdminCheck: PropTypes.bool,

    // state props
    axiosConfig: AxiosConfigPropTypeScheme.isRequired,
    user: getPropTypeScheme('User'),

    // dispatch props
    readEndpoint: PropTypes.func.isRequired,
  }

  static defaultProps = {
    doAdminCheck: path(['SelectFileModal', 'doAdminCheckForMediaLibrary'], commonMediaLibrarySettings) || false,
  }

  state = {
    activeFolderId: undefined,
    folders: [],
    selectedFile: undefined,
    tree: [],
  }

  componentDidMount() {
    const { activeFolderId } = this.state;

    this.getFolders(activeFolderId);
  }

  onSelectFile(file) {
    this.setState({ selectedFile: file });
  }

  onSelectFolder(folderId) {
    this.setState({ activeFolderId: folderId });

    this.getFolders(folderId);
  }

  onSubmitSelectedFile(selectedFile) {
    const { closeModal, onSuccess } = this.props;

    if (!selectedFile) return;

    const {
      id,
      type,
      attributes: {
        url: relative,
        file: absolute,
        name,
      },
    } = selectedFile;

    onSuccess({
      id,
      name,
      type,
      url: {
        absolute,
        relative,
      },
    });

    if (closeModal) closeModal();
  }

  onSubmitUploadForm(values, { setSubmitting }) {
    const {
      axiosConfig: { baseURL, headers: { Authorization } },
      closeModal, onSuccess,
    } = this.props;
    const {
      description, file, fileContents, folder,
    } = values;

    const isImage = /^data:image\/(jpeg|jpg|png|gif)/.test(fileContents);
    const resource = {
      type: isImage ? 'image' : 'file',
      attributes: {
        description,
        file: fileContents,
        name: file,
        originalFilename: file,
      },
    };

    if (folder !== '')
      resource.attributes.folder = `${baseURL}/folder/${folder}`;

    const options = {
      baseURL,
      data: JSON.stringify({ data: resource }),
      headers: {
        Authorization,
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      },
      method: 'POST',
      url: isImage ? 'image' : 'file',
    };

    axios(options)
      .then((response) => {
        setSubmitting(false);

        if (response.status === 201) {
          const {
            data: {
              data: {
                id,
                type,
                attributes: {
                  name,
                  url: relative,
                  file: absolute,
                },
              },
            },
          } = response;

          onSuccess({
            id,
            name,
            type,
            url: {
              absolute,
              relative,
            },
          });

          if (closeModal) closeModal();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);

        setSubmitting(false);
      });
  }

  onValidateUploadForm(values) {
    const { intl: { formatMessage } } = this.props;
    const errors = {};

    if (values.fileContents.length < 1 || values.file.length < 1)
      errors.file = formatMessage(messages.fileSelectRequired);

    if (values.description.length < 1)
      errors.description = formatMessage(messages.descriptionRequired);

    return errors;
  }

  getFolders(activeFolderId) {
    const { readEndpoint } = this.props;

    readEndpoint('folder')
      .then((response) => {
        const pages = path(['body', 'meta', 'pagination', 'pages'], response) || 1;
        const folders = path(['body', 'data'], response) || [];

        if (pages <= 1)
          return this.getFolderTree(activeFolderId, folders);

        const otherPages = range(2, pages + 1).map(page => readEndpoint(`folder?page=${page}`));

        Promise.all(otherPages)
          .then((responses) => {
            const allFolders = [
              folders,
              ...responses.map(response => (path(['body', 'data'], response) || [])),
            ];

            return this.getFolderTree(activeFolderId, unnest(allFolders));
          })
          .catch((error) => {
            Sentry.captureException(error);

            return this.getFolderTree(activeFolderId, []);
          });
      })
      .catch((error) => {
        Sentry.captureException(error);

        return this.getFolderTree(activeFolderId, []);
      });
  }

  getFolderTree(activeFolderId, folders) {
    const grouped = groupBy(folder => path(['relationships', 'parent', 'data', 'id'], folder) || 'root')(folders);
    const groupedByLevel = groupBy(folder => path(['attributes', 'level'], folder))(folders);

    const levels = keys(groupedByLevel);
    const sorted = sort((a, b) => Number(a) - Number(b))(levels);

    const results = [];
    const tree = [];
    const byId = {};

    const iterate = (item, level) => {
      results.push({ value: item.id, label: `${(new Array(level + 1)).join('-')} ${item.attributes.name}` });

      if (grouped[item.id] && grouped[item.id].length > 0)
        grouped[item.id].forEach(citem => iterate(citem, level + 1));
    };

    if (grouped.root && grouped.root.length > 0)
      grouped.root.forEach(item => iterate(item, 0));

    sorted.forEach(key => groupedByLevel[key].forEach((folder) => {
      const item = {
        data: folder,
        children: [],
      };

      byId[folder.id] = item;

      const parentId = path(['relationships', 'parent', 'data', 'id'], folder);

      if (folder.id === activeFolderId) {
        item.isActive = true;

        const activateParents = (id) => {
          byId[id].isActive = true;

          const _parentId = path(['relationships', 'parent', 'data', 'id'], byId[id].data);

          if (_parentId)
            return activateParents(_parentId);
        };

        if (parentId) {
          activateParents(parentId);
        }
      }

      if (parentId)
        byId[parentId].children.push(item);
      else
        tree.push(item);
    }));

    this.setState({ folders: results, tree });
  }

  renderMediaLibrary() {
    const { activeFolderId, selectedFile, tree } = this.state;

    const isRootActive = activeFolderId === undefined;

    return (
      <Form horizontal>
        <FormGroup>
          <Col
            sm={3}
            className="medialibrary-selectfilemodal-foldertree"
          >
            <ul>
              <li
                className="root"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();

                  this.onSelectFolder(undefined);
                }}
              >
                <Glyphicon
                  bsClass="fa"
                  glyph={isRootActive ? 'folder-open' : 'folder'}
                />

                <span>Media</span>

                <ul>
                  {tree.map(item => this.renderMediaLibraryTreeItem(item))}
                </ul>
              </li>
            </ul>
          </Col>

          <Col sm={9}>
            <FileGrid
              folderId={activeFolderId}
              onSelectFile={file => this.onSelectFile(file)}
              selectedFileId={selectedFile ? selectedFile.id : undefined}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col smOffset={3} sm={9}>
            <Button
              bsStyle="primary"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();

                this.onSubmitSelectedFile(selectedFile);
              }}
            >
              <FormattedMessage {...mediaLibraryMessages.fileSelect} />
            </Button>
          </Col>
        </FormGroup>
      </Form>
    );
  }

  renderMediaLibraryTreeItem(item) {
    const hasChildren = item.children.length > 0;

    return (
      <li
        key={`folder-${item.data.id}`}
        onClick={(event) => {
          this.onSelectFolder(item.data.id);

          event.stopPropagation();
          event.preventDefault();
        }}
      >
        <Glyphicon bsClass="fa" glyph={item.isActive ? 'folder-open' : 'folder'} />
        {item.data.attributes.name}

        {hasChildren && item.isActive && (
          <ul>
            {item.children.map(citem => this.renderMediaLibraryTreeItem(citem))}
          </ul>
        )}
      </li>
    );
  }

  renderUploadForm({
    values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, setFieldValue,
  }) {
    const { intl: { formatMessage } } = this.props;
    const { folders } = this.state;

    return (
      <Form horizontal onSubmit={handleSubmit}>
        <FormGroup
          controlId="file"
          validationState={touched.file && errors.file ? 'error' : 'success'}
        >
          <Col componentClass={ControlLabel} sm={3}>
            <FormattedMessage {...genericMessages.file} />
          </Col>

          <Col sm={9}>
            <InputGroup>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-attachment-add">
                    <FormattedMessage {...mediaLibraryMessages.fileSelect} />
                  </Tooltip>
                }
              >
                <Button
                  block
                  onClick={() => {
                    if (this.fileInputRef) this.fileInputRef.click();
                    else console.log(`[OI] SelectFileModal no file input: fileInputRef=${this.fileInputRef}`);
                  }}
                >
                  <Glyphicon glyph="file" />
                </Button>
              </OverlayTrigger>

              <FormControl
                type="file"
                inputRef={(ref) => { this.fileInputRef = ref; }}
                style={{ display: 'none' }}
                onChange={({ target: { files } }) => {
                  if (files.length > 0) {
                    const file = files[0];
                    const reader = new FileReader();

                    reader.onload = () => {
                      const { result } = reader;

                      setFieldValue('fileContents', result);
                      setFieldValue('file', file.name);
                      setFieldTouched('fileContents');
                      setFieldTouched('file');
                    };

                    reader.readAsDataURL(file);
                  } else {
                    setFieldValue('fileContents', '');
                    setFieldValue('file', '');
                    setFieldTouched('fileContents');
                    setFieldTouched('file');
                  }
                }}
              />

              {errors.file && <HelpBlock>{errors.file}</HelpBlock>}
            </InputGroup>
          </Col>
        </FormGroup>

        <FormGroup
          controlId="description"
          validationState={touched.description && errors.description ? 'error' : 'success'}
        >
          <Col componentClass={ControlLabel} sm={3}>
            <FormattedMessage {...genericMessages.description} />
          </Col>

          <Col sm={9}>
            <InputGroup>
              <FormControl
                type="text"
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
              />

              <FormControl.Feedback />

              {errors.description && <HelpBlock>{errors.description}</HelpBlock>}
            </InputGroup>
          </Col>
        </FormGroup>

        <Select
          controlId="folder"
          name={formatMessage(messages.folder)}
          error={errors.folder}
          touched={touched.folder}
          value={values.folder}
          choices={[
            { value: '', label: formatMessage(messages.unsorted) },
            ...folders,
          ]}
          handleBlur={() => setFieldTouched('folder')}
          handleChange={({ target: { value } }) => {
            setFieldValue('folder', value);
            setFieldTouched('folder');
          }}
        />

        <FormGroup>
          <Col smOffset={3} sm={9}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-attachment-submit">
                  <FormattedMessage {...genericMessages.add} />
                </Tooltip>
              }
            >
              <Button
                type="submit"
                bsStyle="primary"
              >
                <Glyphicon glyph="ok" />
              </Button>
            </OverlayTrigger>
          </Col>
        </FormGroup>
      </Form>
    );
  }

  render() {
    const {
      id,
      user,
      doAdminCheck,
      intl: {
        formatMessage,
      },
    } = this.props;
    return (
      <div>
        <Tabs defaultActiveKey="upload" id={`medialibrary-selectfilemodal-tabs-${id}`}>
          <Tab eventKey="upload" title={formatMessage(messages.tabTitleUpload)}>
            <Formik
              initialValues={{
                file: '',
                fileContents: '',
                description: '',
                folder: '',
              }}
              validate={values => this.onValidateUploadForm(values)}
              onSubmit={(values, formikBag) => this.onSubmitUploadForm(values, formikBag)}
              render={formikBag => this.renderUploadForm(formikBag)}
            />
          </Tab>
          {
            !doAdminCheck
            || (
              !!user
              && !!user.attributes
              && (user.attributes.isStaff || user.attributes.isSuperuser)
            ) ? (
              <Tab eventKey="medialibrary" title={formatMessage(messages.tabTitleMediaLibrary)}>
                {this.renderMediaLibrary()}
              </Tab>
              ) : undefined
          }
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  axiosConfig: path(['api', 'endpoint', 'axiosConfig'], state),
  user: selectUser(state, ownProps),
});

const mapDispatchToProps = {
  readEndpoint,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SelectFileModal));
