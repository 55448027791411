export default {
  // Article constants
  // @TODO: Technical debt, should be optional.
  ARTICLE_TYPE_HOSPITALITY: -1,
  DEFAULT_PAGES: [{
    path: '/',
    title: 'Home',
  }],

  // @TODO: Technical debt, should be optional.
  GROUP_TYPE_COMMISSION: '',
};
