import { Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { map, omit, path } from 'ramda';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import constants from '../../../lib/drive-constants';
import {
  get, patch, post, remove,
} from '../../../lib/driveApi';
import {
  loadingFolders, loadingFoldersDone, setFolders,
  addContentItems, loadingContentItems,
  loadingContentItemsDone, setBroadcasts,
} from '../actions';
import {
  isLoadingFolders, getBroadcastByIdentifier, selectIdentifier,
} from '../selectors';

import { broadcast as broadcastShape } from '../types';

import BroadcastForm from './BroadcastForm';
import { getLocale } from '../../../../../../app/modules/selectors/locale';

import genericMessages from '../../../../../../app/lib/genericMessages';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';

const messages = defineMessages({
  couldNotFindBroadcast: {
    id: 'projects.arriva.app.modules.Arrivideo.components.BroadcastsDetail.couldNotFindBroadcast',
    description: 'Could not find broadcast label.',
    defaultMessage: 'Could not find broadcast.',
  },
  broadcast: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastsDetail.broadcast',
    description: 'broadcast label',
    defaultMessage: 'Broadcast',
  },
  connectContentItem: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastsDetail.connectContentItem',
    description: '1 content item connected label',
    defaultMessage: 'At least 1 content item should be paired',
  },
  connectAudience: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastsDetail.connectAudience',
    description: '1 audience connected label',
    defaultMessage: 'At least 1 audience should be paired',
  },
  deleteBroadcast: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastsDetail.deleteBroadcast',
    description: 'Delete broadcast label',
    defaultMessage: 'Delete broadcast',
  },
  emailNotVallid: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastsDetail.emailNotValid',
    description: 'Not valid email address label',
    defaultMessage: 'This email address is not valid',
  },
  actionNotValid: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastsDetail.actionNotValid',
    description: 'Require content in field label',
    defaultMessage: 'When an action is selected, this input-field is required.'
  },
  urlNotValid: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastsDetail.urlNotValid',
    description: 'Not valid URL label',
    defaultMessage: 'When a url is selected, http:// or https:// is required.',
  },
  phoneNotValid: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastsDetail.phoneNotValid',
    description: 'Not valid phone number label',
    defaultMessage: 'When a phone number is selected, only the following characters are allowed: 0-9 +',
  },
  notificationWarning: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastsDetail.notificationWarning',
    description: 'Notification warning label',
    defaultMessage: 'A notification text is inserted,  are you sure you don\'t want to send this notification?',
  },
  dateError: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastsDetail.dateError',
    description: 'Date Error label',
    defaultMessage: 'The date cannot be further in the future than "to" ',
  },
  delete: {
    id: 'projects.arriva.app.modules.ArriVideo.components.BroadcastsDetail.delete',
    description: 'Delete Broadcast message',
    defaultMessage: 'Are you sure you want to delete broadcast {broadcast}?',
  },
});

class BroadcastDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      loading: true,
      showDeleteModal: false,
    };
  }

  componentDidMount() {
    const {
      addContentItems, broadcastId, get,
      loadingContentItems, loadingContentItemsDone, setBroadcasts,
    } = this.props;

    // Get the available folders
    this.fetchFolders();

    // Always load the individual broadcast to ensure we have the broadcast
    // with all its included data (content, audiences, etc.).
    // XXX calling `setBroadcasts([data])` will replace the existing list of
    // broadcasts in the redux store in its entirety.
    if (broadcastId) {
      get({ path: `arrivideo/broadcast/${broadcastId}` })
        .then(({ data }) => {
          setBroadcasts([data]);

          // Get all content item ids
          const contentItemIds = map((c) => c.id)(data.content || []);
          if (contentItemIds.length === 0) return;

          loadingContentItems();

          // Get the content items
          // We use /me/content, so we can specify only the content items we need
          get({ path: `arrivideo/me/content?ids=${contentItemIds.join(',')}` })
            .then(({ content }) => addContentItems(content))
            .finally(() => loadingContentItemsDone());
        })
        .catch((err) => this.setState({ error: err }))
        .finally(() => {
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false });
    }
  }

  async fetchFolders() {
    const {
      get, loadingFolders, loadingFoldersDone, setFolders,
    } = this.props;
    const { page, pageSize } = this.state;

    await loadingFolders();

    const url = `arrivideo/folders?page=${page}&page_size=${pageSize}`;

    get({ path: url, parallel: false })
      .then(async ({ folders }) => {
        await setFolders(folders);
      })
      .catch((err) => {
        this.setState({ error: err });
      })
      .finally(async () => {
        await loadingFoldersDone();
      });
  }

  deleteBroadcast() {
    const {
      broadcast, history, remove, intl: { formatMessage },
    } = this.props;

    /* eslint-disable-next-line no-alert */
    const confirm = window.confirm(formatMessage(messages.delete, {
      broadcast: broadcast.title,
    }));

    if (confirm) {
      remove({ path: `arrivideo/broadcast/${broadcast.id}` })
        .catch((err) => {
          this.setState({ error: err });
          Sentry.captureException(new Error(`Something went wrong: /arrivideo/broadcast/${broadcast.id} DELETE -> ${err}`));
        })
        .then(() => history.push('/arrivideo'));
    }
  }

  renderForm() {
    const {
      broadcast, history, locale, patch, post, intl: { formatMessage },
    } = this.props;

    const initialValues = {
      ...broadcast,
      audiences: (broadcast.audiences || []).map((audience) => ({
        label: audience.displayName,
        value: {
          id: audience.principalId,
          resourceType: audience.principalType, // specific for react-select
        },
      })),
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          const body = {
            ...omit(['audiences', 'content', 'createdAt', 'id', 'updatedAt'], values),
            audiences: (values.audiences || []).map(((audience) => ({
              id: path(['value', 'id'], audience),
              resourceType: path(['value', 'resourceType'], audience),
            }))),
            arriVideoFolderId: values.folder.id === '-1' ? null : values.folder.id,
          };

          (broadcast.id ? patch : post)({ path: `arrivideo/broadcast/${broadcast.id ? broadcast.id : ''}`, body })
            .then((result) => {
              if (result.message === constants.MSG_SUCCESS) {
                setSubmitting(false);

                history.goBack();
                return;
              }

              Sentry.captureException(new Error(`Something went wrong: broadcastForm post/patch-> ${result}`));
              this.setState({ error: `${formatMessage(genericMessages.errorMessage)}: ${result}` });
            })
            .catch((error) => {
              console.error(error);
              Sentry.captureException(error);

              setSubmitting(false);
            });
        }}
        validate={(values) => {
          const errors = {};

          const required = ['title', 'from', 'to', 'folder'];
          // Make sure the required fields are there
          required.forEach((k) => {
            if (!values[k]) errors[k] = formatMessage(genericMessages.required);
          });

          if (moment(values.from).diff(moment(values.to)) > 0) {
            errors.from = formatMessage(messages.dateError);
          }

          if (values.actionType && !values.actionContent) {
            errors.action = formatMessage(messages.actionNotValid);
          }

          if (values.actionType && values.actionContent) {
            if (values.actionType === 'site' && !/^https?:\/\//.test(values.actionContent)) {
              errors.action = formatMessage(messages.urlNotValid);
            }

            if (values.actionType === 'call' && !/[\s\d+]+/.test(values.actionContent)) {
              errors.action = formatMessage(messages.phoneNotValid);
            }

            if (values.actionType === 'mail' && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.actionContent)) {
              errors.action = formatMessage(messages.emailNotVallid);
            }
          }

          if (values.notificationText && !values.notification) {
            errors.notification = formatMessage(messages.notificationWarning);
          }

          if (!values.contentItems || values.contentItems.length === 0) {
            errors.contentItems = formatMessage(messages.connectContentItem);
          }

          if (!values.audiences || values.audiences.length === 0) {
            errors.audiences = formatMessage(messages.connectAudience);
          }

          return errors;
        }}
      >
        {(props) => (
          /* eslint-disable react/jsx-props-no-spreading */
          <BroadcastForm
            {...props}
            locale={locale}
            onClickDelete={() => this.setState({ showDeleteModal: true })}
          />
          /* eslint-enable react/jsx-props-no-spreading */
        )}
      </Formik>
    );
  }

  renderDeleteModal() {
    const {
      broadcast, history, remove, intl: { formatMessage },
    } = this.props;
    const { showDeleteModal } = this.state;

    const close = () => this.setState({ showDeleteModal: false });

    const removeBroadcast = () => {
      remove({ path: `arrivideo/broadcast/${broadcast.id}` })
        .catch((err) => {
          this.setState({ error: err });

          Sentry.captureException(
            new Error(`Something went wrong: /arrivideo/broadcast/${broadcast.id} DELETE -> ${err}`),
          );
        })
        .then(() => history.push('/arrivideo'));
    };

    return (
      <Modal show={showDeleteModal} onHide={close}>
        <Modal.Header>
          <Modal.Title>{formatMessage(messages.deleteBroadcast)}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p style={{ padding: 15 }}>
            {formatMessage(messages.delete, {
              broadcast: broadcast.title,
            })}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={close}>{formatMessage(genericMessages.close)}</Button>
          <Button bsStyle="danger" onClick={() => removeBroadcast()}>{formatMessage(genericMessages.delete)}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const {
      broadcast, broadcastId, isLoadingFolders, intl: { formatMessage },
    } = this.props;
    const { error, loading } = this.state;

    if (loading) {
      return <strong>{formatMessage(genericMessages.loadingInProgress)}</strong>;
    }

    if (error) {
      // eslint-disable-next-line react/jsx-one-expression-per-line
      return <strong>{formatMessage(genericMessages.errorMessage)}({error})</strong>;
    }

    if (!broadcast && broadcastId) {
      return <strong>{formatMessage(messages.couldNotFindBroadcast)}</strong>;
    }

    return (
      <div className="well">
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        <h2>
          {formatMessage(messages.broadcast)}
          {' '}
          {broadcast ? formatMessage(genericMessages.edit) : formatMessage(genericMessages.create)}
        </h2>

        {isLoadingFolders && <strong>{formatMessage(genericMessages.loadingInProgress)}</strong>}

        {!isLoadingFolders && (
          <>
            {this.renderForm()}
            {this.renderDeleteModal()}
          </>
        )}
      </div>
    );
  }
}

BroadcastDetail.propTypes = {
  // Own props
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),

  // Dispatch props
  addContentItems: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  loadingContentItems: PropTypes.func.isRequired,
  loadingContentItemsDone: PropTypes.func.isRequired,
  patch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  setBroadcasts: PropTypes.func.isRequired,
  setFolders: PropTypes.func.isRequired,
  loadingFolders: PropTypes.func.isRequired,
  loadingFoldersDone: PropTypes.func.isRequired,

  // State props
  broadcast: PropTypes.oneOfType([
    broadcastShape,
    PropTypes.bool,
  ]),
  broadcastId: PropTypes.string,
  locale: PropTypes.string.isRequired,
  isLoadingFolders: PropTypes.bool.isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
};

const mapStateToProps = (state, props) => ({
  broadcast: getBroadcastByIdentifier(state, props),
  broadcastId: selectIdentifier(state, props),
  locale: getLocale(state),
  isLoadingFolders: isLoadingFolders(state),
});

const mapDispatchToProps = {
  addContentItems,
  get,
  loadingContentItems,
  loadingContentItemsDone,
  patch,
  post,
  remove,
  setBroadcasts,
  loadingFolders,
  loadingFoldersDone,
  setFolders,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(BroadcastDetail));
