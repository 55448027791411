import { Alert, Button } from 'react-bootstrap';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import Well from 'react-bootstrap/lib/Well';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IntlShape from '../../../../../app/lib/PropTypes/IntlShape';
import { get } from '../../lib/driveApi';

import './Vehicle.less';
import genericMessages from '../../../../../app/lib/genericMessages';

const VehicleStatusesDetail = ({
  intl,
  get,
}) => {
  const { identifier } = useParams();
  const [vehicleStatuses, setVehicleStatuses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);

  function fetchVehicleStatuses() {
    get({ path: `vehicle-status/public/byRegion/${identifier}` })
      .then((vehicleStatus) => {
        setVehicleStatuses(vehicleStatus);
      })
      .catch((error) => {
        console.error(`Vehicle statuses: fetching data failed: ${error}`);
        setError(error);
      })
      .finally(() => setIsLoading(false));
    setTimeout(fetchVehicleStatuses, 30 * 1000);
  }

  useEffect(() => {
    fetchVehicleStatuses();
  }, []);

  function renderError() {
    if (!error) return null;

    const dismissError = () => setError(undefined);

    return (
      <Alert bsStyle="danger" onDismiss={dismissError}>
        <h4>{intl.formatMessage(genericMessages.errorMessage)}</h4>
        <p>{error.toString()}</p>
        <p>
          <Button onClick={dismissError}>
            {intl.formatMessage(genericMessages.closeNotification)}
          </Button>
        </p>
      </Alert>
    );
  }

  return (
    <Well>
      {renderError()}

      {!isLoading && vehicleStatuses.length === 0
        && <strong>{intl.formatMessage(genericMessages.nothingFound)}</strong>}

      {isLoading && <strong>{intl.formatMessage(genericMessages.loadingInProgress)}</strong>}

      {(!isLoading && vehicleStatuses.length > 0) && (
        <div className="vehicle-status">
          {vehicleStatuses.map((vehicle) => (
            <div className="vehicle-status-grid-cell" key={vehicle.vehicleNumber}>
              <div className={`vehicle-status-container vehicle-status-${vehicle.status !== false ? vehicle.status.status.toLowerCase() : 'default'}`}>
                <Glyphicon bsClass="fa" glyph="train" className="vehicle-status-train" />
                <h4 className="vehicle-status-number">{vehicle.vehicleNumber}</h4>
              </div>
            </div>
          ))}
        </div>
      )}
    </Well>
  );
};

VehicleStatusesDetail.propTypes = {
  // Dispatch props
  get: PropTypes.func.isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
};

const mapDispatchToProps = {
  get,
};
export default connect(null, mapDispatchToProps)(injectIntl(VehicleStatusesDetail));
