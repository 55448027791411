import React, { Component } from 'react';
import { find, propEq } from 'ramda';
import PropTypes from 'prop-types';

import HeaderBar from '../header-bar';
import { myxss } from '../form-elements';

class Dropdown extends Component {
  static propTypes = {
    _onDestroy: PropTypes.func,
    data: PropTypes.shape({
      field_name: PropTypes.string.isRequired,
      label: PropTypes.string,
      pageBreakBefore: PropTypes.bool,
      inline: PropTypes.bool,
      static: PropTypes.bool,
      required: PropTypes.bool,
      options: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    defaultValue: PropTypes.shape({}),
    editModeOn: PropTypes.func,
    error: PropTypes.string,
    parent: PropTypes.shape({}),
    mutable: PropTypes.bool,
    onEdit: PropTypes.func,
    readOnly: PropTypes.bool,
    handleChange: PropTypes.func,
  };

  render() {
    const {
      _onDestroy,
      data,
      defaultValue = '',
      editModeOn,
      error,
      handleChange,
      parent,
      mutable,
      onEdit,
      readOnly,
    } = this.props;

    const props = {};
    props.className = 'form-control';
    props.name = data.field_name;

    if (mutable) {
      props.defaultValue = defaultValue;
      props.ref = `child_ref_${data.field_name}`;
    }

    // eslint-disable-next-line camelcase
    if (readOnly) {
      props.disabled = 'disabled';
    }

    let baseClasses = 'rfb-item';
    if (data.pageBreakBefore) baseClasses += ' alwaysbreak';
    if (error) baseClasses += ' error';

    props.onChange = e => handleChange(data.field_name, e.target.value);

    return (
      <div className={baseClasses}>
        {!mutable &&
          <div>
            {data.pageBreakBefore && <div className="preview-page-break">Page Break</div>}

            <HeaderBar
              parent={parent}
              editModeOn={editModeOn}
              data={data}
              onDestroy={_onDestroy}
              onEdit={onEdit}
              static={data.static}
              required={data.required}
            />
          </div>
        }
        <div className="form-group">
          <label htmlFor={data.field_name}>
            <span dangerouslySetInnerHTML={{ __html: myxss.process(data.label) }} />{
            // eslint-disable-next-line camelcase
            }{ (data.hasOwnProperty('required') && data.required && !readOnly) &&
              <span className="label-required label label-danger">Verplicht</span>
            }
          </label>
          <select {...props}>
            {data.options.map(option => (
              <option value={option.value} key={`preview_${option.key}`}>{option.text}</option>
            ))}
          </select>

          {error && <div className="form-group-error">{error}</div>}
        </div>
      </div>
    );
  }
}

const getSubmissionValue = field => find(propEq('value', field.value))(field.options).text;

export {
  Dropdown as default,
  getSubmissionValue,
};
