import {
  INTERFACE_MODAL_CREATE_PAGE_OPEN,
  INTERFACE_MODAL_CREATE_PAGE_CLOSE,
} from '../actions/interface';

const ACTION_HANDLERS = {
  [INTERFACE_MODAL_CREATE_PAGE_OPEN]: (state, action) => ({
    ...state,
    modalCreatePageIsOpen: action.payload,
  }),
  [INTERFACE_MODAL_CREATE_PAGE_CLOSE]: (state, action) => ({
    ...state,
    modalCreatePageIsOpen: action.payload,
  }),
};

const initialState = {
  modalCreatePageIsOpen: false,
};

const interfaceReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default interfaceReducer;
