import { Alert, Button, Table } from 'react-bootstrap';
import React, { useState } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { path } from 'ramda';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IntlShape from '../../../../../../../app/lib/PropTypes/IntlShape';
import { getMalfunctions, getVehicleNumber, isLoadingMalfunctions } from '../../selectors';
import genericMessages from '../../../../../../../app/lib/genericMessages';

const styles = {
  main: {
    backgroundColor: '#FAFAFA',
  },
  fold: {
    display: 'block',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  description: {
    maxWidth: '360px',
  },
  long: {
    padding: '12px',
    maxWidth: '360px',
    backgroundColor: '#F3F9FE',
  },
  footerWrapper: {
    display: 'flex',
  },
  footerInnerWrapperRight: {
    textAlign: 'right',
  },
  footerAddButton: {
    margin: '20px 0',
  },
};

const messages = defineMessages({
  errorMessage: {
    id: 'projects.arriva.app.modules.VehicleStatus.components.VehicleStatuses.errorMessage',
    description: 'Error message fetching vehicle statuses',
    defaultMessage: 'Something went wrong!',
  },
  closeNotification: {
    id: 'projects.arriva.app.modules.VehicleStatus.components.VehicleStatuses.closeNotification',
    description: 'Button close notification',
    defaultMessage: 'Close notification',
  },
  loading: {
    id: 'projects.arriva.app.modules.VehicleStatus.components.VehicleStatuses.loading',
    description: 'Loading message',
    defaultMessage: 'Loading, please wait!',
  },
  vehicle: {
    id: 'projects.arriva.app.modules.Malfunctions.components.MalfunctionsList.vehicle',
    description: 'Vehicle Label',
    defaultMessage: 'Vehicle-number',
  },
  workOrderCode: {
    id: 'projects.arriva.app.modules.Malfunctions.components.MalfunctionsList.workOrderCode',
    description: 'Workorder-code Label',
    defaultMessage: 'Workorder-code',
  },
  description: {
    id: 'projects.arriva.app.modules.Malfunctions.components.CategoriesList.description',
    description: 'Malfunctions description',
    defaultMessage: 'Description',
  },
  workOrderDate: {
    id: 'projects.arriva.app.modules.Malfunctions.components.MalfunctionsList.workOrderDate',
    description: 'Workorder-date Label',
    defaultMessage: 'Workorder-date',
  },
  vehicleNumber: {
    id: 'projects.arriva.app.modules.Malfunctions.components.MalfunctionsList.vehicleNumber',
    description: 'Vehicle number does not exist Label',
    defaultMessage: 'This vehicle-number doesn\'t exists or doesn\'t have pending workorders',
  },
});

const MalfunctionsList = ({
  vehicleNumber,
  malfunctions,
  isLoadingMalfunctions,
  intl,
}) => {
  const [error, setError] = useState(undefined);
  const [showExtraInfo, setShowExtraInfo] = useState(false);
  const [workCode, setWorkCode] = useState();
  const rows = path(['rows'], malfunctions);

  function onClickHandler(row) {
    setShowExtraInfo(!showExtraInfo);
    setWorkCode(row.workOrderCode);
  }

  function renderError() {
    if (!error) return null;

    const dismissError = () => setError(undefined);

    return (
      <Alert bsStyle="danger" onDismiss={dismissError}>
        <h4>{intl.formatMessage(messages.errorMessage)}</h4>
        <p>{error.toString()}</p>
        <p>
          <Button onClick={dismissError}>{intl.formatMessage(messages.closeNotification)}</Button>
        </p>
      </Alert>
    );
  }

  function renderMalfunctions(row) {
    moment.locale('nl');

    return (
      <tr key={`malfunction-${row.workOrderCode}`} style={styles.row}>
        <td>{row.workOrderCode}</td>
        <td onClick={() => onClickHandler(row)} style={styles.fold}>
          <p style={styles.description}>
            {row.failureDescription}
          </p>
          {showExtraInfo && workCode === row.workOrderCode
            && (<p style={styles.long}>{row.workOrderDescription}</p>)}
        </td>
        <td>{moment(row.createdAt).format('DD MMM YYYY')}</td>
        <td>{row.statusDescription}</td>
      </tr>
    );
  }

  return (
    <div>
      {renderError()}

      {!isLoadingMalfunctions && malfunctions.rows && malfunctions.rows.length === 0
        && (
        <strong>
          <FormattedMessage {...messages.vehicleNumber} />
        </strong>
        )}

      {isLoadingMalfunctions && <strong>{intl.formatMessage(messages.loading)}</strong>}

      {(!isLoadingMalfunctions && rows && rows.length > 0) && (
        <div style={styles.main}>
          <h2>
            {intl.formatMessage(messages.vehicle)}
            {' '}
            {vehicleNumber}
          </h2>
          <br />
          <Table responsive>
            <thead>
              <tr style={styles.row}>
                <td>
                  <FormattedMessage {...messages.workOrderCode} />
                </td>
                <td>
                  <FormattedMessage {...messages.description} />
                </td>
                <td>
                  <FormattedMessage {...messages.workOrderDate} />
                </td>
                <td>Status</td>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => renderMalfunctions(row))}
            </tbody>
          </Table>
        </div>
      )}
      {Object.entries(malfunctions).length !== 0
        && (
        <div style={{ ...styles.footerInnerWrapper, ...styles.footerInnerWrapperRight }}>
          <Button
            bsStyle="primary"
            href={`/malfunctions/pending-malfunctions/add/${vehicleNumber}`}
            style={styles.footerAddButton}
          >
            <FormattedMessage {...genericMessages.add} />
          </Button>
        </div>
        )}
    </div>
  );
};

MalfunctionsList.propTypes = {
  // Own props
  malfunctions: PropTypes.shape([]),
  vehicleNumber: PropTypes.string,

  // State props
  isLoadingMalfunctions: PropTypes.bool.isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
};

const mapStateToProps = (state) => ({
  malfunctions: getMalfunctions(state),
  vehicleNumber: getVehicleNumber(state),
  isLoadingMalfunctions: isLoadingMalfunctions(state),
});

export default connect(mapStateToProps, null)(injectIntl(MalfunctionsList));
