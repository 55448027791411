/* eslint-disable max-classes-per-file */
import ExtendableError from 'es6-error';

export const AUTHENTICATION_NOTOK = 1;
export const AUTHENTICATION_MISSING_TOKEN = 2;

export class AuthenticationError extends ExtendableError {
  constructor(message = 'Authentication failed.', type = AUTHENTICATION_NOTOK, nonFieldErrors = []) {
    super(message);
    this.type = type;
    this.non_field_errors = nonFieldErrors;
  }
}

export class CurrentUserError extends ExtendableError {
  constructor(message = 'Missing user object.') {
    super(message);
  }
}
