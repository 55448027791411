import PropTypes from 'prop-types';
import { path } from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createResource } from 'redux-json-api';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Formik } from 'formik';
import Alert from 'react-bootstrap/lib/Alert';
import Button from 'react-bootstrap/lib/Button';
import Form from 'react-bootstrap/lib/Form';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';

import IntlShape from '../../lib/PropTypes/IntlShape';
import genericMessages from '../../lib/genericMessages';
import { selectModalCreatePageIsOpen } from '../../modules/selectors/interface';
import { uiModalCreatePageClose } from '../../modules/actions/interface';

import Modal from '../Modal';

const messages = defineMessages({
  chooseTitleNewPage: {
    id: 'app.components.Management.CreatePageModal.chooseTitleNewPage',
    description: 'CreatePageModal \'Choose title for new page\' header.',
    defaultMessage: 'Choose a title for the new page.',
  },
  errorCreatingNewPage: {
    id: 'app.components.Management.CreatePageModal.errorCreatingNewPage',
    description: 'CreatePageModal \'Error creating new page\' error message.',
    defaultMessage: 'Something went wrong while creating the new page.',
  },
});

class CreatePageModal extends Component {
  static propTypes = {
    // react-intl propTypes
    intl: IntlShape.isRequired,

    // react-router props.
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,

    // own props.
    baseURL: PropTypes.string.isRequired,
    modalCreatePageIsOpen: PropTypes.bool.isRequired,
    uiModalCreatePageClose: PropTypes.func.isRequired,

    // dispatch props.
    createResource: PropTypes.func.isRequired,
  };

  onSubmitForm(values, formikBag) {
    const {
      intl: { formatMessage },
      baseURL,
      createResource,
      uiModalCreatePageClose,
      history,
    } = this.props;
    const { title } = values;
    const { setStatus, setSubmitting, setFieldError } = formikBag;

    setSubmitting(true);

    const resource = {
      type: 'article',
      attributes: {
        title,
        content: '&nbsp;',
        tags: [],
        article_type: `${baseURL}/article-type/3`,
        group: `${baseURL}/group/2`,
      },
    };

    createResource(resource)
      .then(({ data: { attributes: { slug } } }) => {
        setSubmitting(false);
        uiModalCreatePageClose();
        history.push(`/page/${slug}/edit`);
      })
      .catch((error) => {
        const fieldregex = /^\/data\/attributes\/([A-Za-z0-9-_]+)$/;
        const { response } = error;
        const genericErrors = [];

        if (response && response.data && response.data.errors) {
          const { data: { errors } } = response;

          errors.forEach((fieldError) => {
            if (fieldError.source && fieldError.source.pointer) {
              const { source: { pointer } } = fieldError;
              const matches = fieldregex.exec(pointer);

              if (matches && matches.length === 2) {
                const field = matches[1];

                // This is a specific field error.
                // XXX i18n
                return setFieldError(field, fieldError.detail);
              }
            }

            // Return a generic error.
            // XXX i18n
            return genericErrors.push(fieldError.detail);
          });
        } else {
          Sentry.captureException(error);
          genericErrors.push(formatMessage(messages.errorCreatingNewPage));
        }

        if (genericErrors.length > 0)
          setStatus({ genericErrors });
        else
          setStatus({ genericErrors: undefined });

        setSubmitting(false);
      });
  }

  render() {
    const { intl: { formatMessage }, modalCreatePageIsOpen, uiModalCreatePageClose } = this.props;

    if (!modalCreatePageIsOpen)
      return null;

    return (
      <Modal
        title={formatMessage(messages.chooseTitleNewPage)}
        onHide={() => uiModalCreatePageClose()}
      >
        <Formik
          initialValues={{ title: '' }}
          validate={(values) => {
            const errors = {};

            ['title'].forEach((field) => {
              if (values[field] === '')
                errors[field] = formatMessage(genericMessages.fieldRequired);
            });

            return errors;
          }}
          onSubmit={(values, formikBag) => this.onSubmitForm(values, formikBag)}
          render={({
            errors,
            touched,
            status,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form horizontal onSubmit={handleSubmit}>
              {status && status.genericErrors && status.genericErrors.map((error, i) => (
                <Alert bsStyle="danger" key={`error-${i}`}>
                  {error}
                </Alert>
              ))}

              <FormGroup
                controlId="title"
                validationState={errors && errors.title ? 'error' : 'success'}
              >
                <ControlLabel><FormattedMessage {...genericMessages.title} /></ControlLabel>
                <InputGroup style={{ width: '100%' }}>
                  <FormControl
                    type="text"
                    onChange={handleChange}
                  />
                  {errors.title && touched.title &&
                  <div className="field-error">{errors.title}</div>}
                </InputGroup>
              </FormGroup>

              <div style={{ textAlign: 'right' }}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-page-create">
                      <FormattedMessage {...genericMessages.save} />
                    </Tooltip>
                  }
                >
                  <Button
                    bsStyle="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <Glyphicon glyph="ok" />
                  </Button>
                </OverlayTrigger>
              </div>
            </Form>
          )}
        />
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  baseURL: path(['api', 'endpoint', 'axiosConfig', 'baseURL'], state),
  modalCreatePageIsOpen: selectModalCreatePageIsOpen(state),
});

const mapDispatchToProps = {
  createResource,
  uiModalCreatePageClose,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CreatePageModal)));
