import PropTypes from 'prop-types';
import { path } from 'ramda';
import React, { PureComponent } from 'react';
import Button from 'react-bootstrap/lib/Button';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import Form from 'react-bootstrap/lib/Form';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { fetchSecureFileAsBase64 } from '../../modules/helpers';
import { selectAccessToken, selectUser } from '../../modules/selectors/session';
import getPropTypeScheme from '../../lib/PropTypes';

import Avatar from '../Avatar';

import './CommentForm.less';

const defaultAvatar = require(`../../../projects/${process.env.RAZZLE_PROJECT}/img/avatar-default.svg`); // eslint-disable-line

const messages = defineMessages({
  submit: {
    id: 'app.components.Comment.CommentForm.submit',
    description: 'Comment form submit button label.',
    defaultMessage: 'Post',
  },
});

class CommentForm extends PureComponent {
  static propTypes = {
    // own props
    errors: PropTypes.shape({
      comment: PropTypes.string,
    }),
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    values: PropTypes.shape({
      comment: PropTypes.string.isRequired,
    }).isRequired,

    // state props
    accessToken: PropTypes.string,
    user: getPropTypeScheme('User'),
  };

  static defaultProps = {
    accessToken: undefined,
    errors: undefined,
  };

  state = { avatar: undefined };

  componentDidMount() {
    const { accessToken, user } = this.props;

    const avatarUrl = path(['attributes', 'avatar'], user);

    if (accessToken && avatarUrl)
      this.onFetchAvatar(avatarUrl, accessToken);
  }

  componentWillReceiveProps(nextProps) {
    const currentAvatar = path(['user', 'attributes', 'avatar'], this.props);
    const nextAvatar = path(['user', 'attributes', 'avatar'], nextProps);
    const { accessToken } = nextProps;

    if (accessToken && nextAvatar && (currentAvatar !== nextAvatar))
      this.onFetchAvatar(nextAvatar, accessToken);
  }

  onFetchAvatar(avatarUrl, accessToken) {
    if (accessToken && avatarUrl) {
      fetchSecureFileAsBase64(avatarUrl, accessToken)
        .then(avatar => this.setState({ avatar }))
        .catch((error) => {
          console.error(`[OI] CommentForm onFetchAvatar: error=${error}`);

          this.setState({ avatar: undefined });
        });
    } else {
      console.error(`[OI] CommentForm onFetchAvatar: parameters missing: avatarUrl=${avatarUrl} accessToken=${accessToken}`);
    }
  }

  renderAvatar() {
    const { avatar } = this.state;

    if (avatar)
      return (<Avatar image={{ url: avatar }} />);
    else
      return (<Avatar image={{ src: defaultAvatar }} />);
  }

  render() {
    const {
      handleChange, handleSubmit,
      isSubmitting,
      errors, values,
    } = this.props;
    return (
      <Form className="form-profile" horizontal onSubmit={handleSubmit}>
        <div className="comment-form-container">
          {this.renderAvatar()}
          <FormGroup controlId="comment" validationState={errors && errors.comment ? 'error' : 'success'}>
            <FormControl
              componentClass="textarea"
              onChange={handleChange}
              value={values.comment}
            />
            {(errors && errors.comment) && <HelpBlock>{errors.comment}</HelpBlock>}
          </FormGroup>
        </div>

        <FormGroup>
          <Button type="submit" disabled={isSubmitting} >
            <FormattedMessage {...messages.submit} />
          </Button>
        </FormGroup>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  accessToken: selectAccessToken(state),
  user: selectUser(state),
});

export default connect(mapStateToProps, null)(CommentForm);
