import PropTypes from 'prop-types';
import { propEq, reject } from 'ramda';
import React, { Component } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import MicrosoftLogin from 'react-microsoft-login';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Alert from 'react-bootstrap/lib/Alert';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Row from 'react-bootstrap/lib/Row';

import Image from 'react-bootstrap/lib/Image';
import getPropTypeScheme from '../../../../app/lib/PropTypes';
import IntlShape from '../../../../app/lib/PropTypes/IntlShape';
import genericMessages from '../../../../app/lib/genericMessages';
import { authenticate, authenticateSSO, setCurrentUser } from '../../../../app/modules/actions/session';
import {
  AuthenticationError,
  AUTHENTICATION_NOTOK,
  AUTHENTICATION_MISSING_TOKEN,
  CurrentUserError,
} from '../../../../app/modules/errors/session';
import { selectUser } from '../../../../app/modules/selectors/session';

import logoMicrosoft from '../../img/logo_microsoft.png';

const messages = defineMessages({
  authenticationMissingTokenMessage: {
    id: 'app.layouts.Login.authenticationMissingTokenMessage',
    description: 'AUTHENTICATION_MISSING_TOKEN alert message.',
    defaultMessage: 'The server response did not contain an authentication token.',
  },
  authenticationNotOkMessage: {
    id: 'app.layouts.Login.authenticationNotOkMessage',
    description: 'AUTHENTICATION_NOT_OK alert message.',
    defaultMessage: 'Couldn\'t login using the supplied credentials.',
  },
  authenticationUnknownMessage: {
    id: 'app.layouts.Login.authenticationUnknownMessage',
    description: 'Unknown error while authenticating alert message.',
    defaultMessage: 'An unknown error occurred. Try again later.',
  },
  currentUserMissingMessage: {
    id: 'app.layouts.Login.currentUserMissingMessage',
    description: 'Current user missing alert message.',
    defaultMessage: 'Authentication was succesful, but the user could not be found. Try again later.',
  },
  loginInstructions: {
    id: 'app.layouts.Login.loginInstructions',
    description: 'Login instructions.',
    defaultMessage: 'You can log in using your e-mail address as username.',
  },
  loginMicrosoftInstructions: {
    id: 'app.layouts.Login.loginMicrosoftInstructions',
    description: 'Login Microsoft instructions.',
    defaultMessage: 'Log in with Single Sign-On',
  },
  passwordLabel: {
    id: 'app.layouts.Login.passwordLabel',
    description: 'Password field label.',
    defaultMessage: 'Password',
  },
  passwordPlaceholder: {
    id: 'app.layouts.Login.passwordPlaceholder',
    description: 'Password placeholder text.',
    defaultMessage: 'Enter your password here',
  },
  usernameLabel: {
    id: 'app.layouts.Login.usernameLabel',
    description: 'Username field label.',
    defaultMessage: 'Username',
  },
  usernamePlaceholder: {
    id: 'app.layouts.Login.usernamePlaceholder',
    description: 'Username placeholder text.',
    defaultMessage: 'Enter your username here',
  },
  welcome: {
    id: 'app.layouts.Login.welcome',
    description: 'Login welcome message.',
    defaultMessage: 'Welcome to the openintranet',
  },
});

class Login extends Component {
  static propTypes = {
    // react-intl props
    intl: IntlShape.isRequired,

    // State props.
    user: getPropTypeScheme('User'),
    location: PropTypes.shape({
      search: PropTypes.string,
      state: PropTypes.shape({
        from: PropTypes.shape({
          pathname: PropTypes.string,
        }),
      }),
    }),

    // Dispatch props.
    authenticate: PropTypes.func.isRequired,
    authenticateSSO: PropTypes.func.isRequired,
    setCurrentUser: PropTypes.func.isRequired,
  };

  state = {
    alerts: [],
    password: '',
    redirectToReferrer: false,
    username: '',
  };

  componentWillReceiveProps(nextProps) {
    // Redirect if we have a user.
    if (nextProps.user) this.setState({ redirectToReferrer: true });
  }

  login = async () => {
    const { authenticate, setCurrentUser, intl: { formatMessage } } = this.props;
    const { password, username } = this.state;

    try {
      await authenticate(username, password);
      await setCurrentUser();

      // setCurrentUser() should set redirectToReferrer to true from our
      // componentWillReceiveProps() method.
      // this.setState({ redirectToReferrer: true });
    } catch (error) {
      if (error instanceof AuthenticationError) {
        switch (error.type) {
          case AUTHENTICATION_NOTOK: {
            return this.setState({
              alerts: [{
                id: `authentication-notok-${Date.now()}`,
                style: 'danger',
                message: formatMessage(messages.authenticationNotOkMessage),
              }],
            });
          }
          case AUTHENTICATION_MISSING_TOKEN: {
            return this.setState({
              alerts: [{
                id: `authentication-missing-token-${Date.now()}`,
                style: 'danger',
                message: formatMessage(messages.authenticationMissingTokenMessage),
              }],
            });
          }
          default: {
            // ...
          }
        }
      } else if (error instanceof CurrentUserError) {
        return this.setState({
          alerts: [{
            id: `currentuser-missing-${Date.now()}`,
            style: 'danger',
            message: formatMessage(messages.currentUserMissingMessage),
          }],
        });
      }

      return this.setState({
        alerts: [{
          id: `authentication-unknown-${Date.now()}`,
          style: 'danger',
          message: formatMessage(messages.authenticationUnknownMessage),
        }],
      });
    }

    return false;
  };

  handleMicrosoftLogin = async (error, authData, msal) => {
    const { authenticateSSO, setCurrentUser, intl: { formatMessage } } = this.props;
    const { RAZZLE_OIDC_API_KEY } = process.env;
    const { mail } = authData;

    try {
      await authenticateSSO(mail, RAZZLE_OIDC_API_KEY, msal);
      await setCurrentUser();
    } catch (error) {
      if (error instanceof AuthenticationError) {
        return this.setState({
          alerts: [{
            id: `authentication-microsoft-unknown-${Date.now()}`,
            style: 'danger',
            message: formatMessage(messages.authenticationUnknownMessage),
          }],
        });
      }
    }
  }

  renderAlerts() {
    const { alerts } = this.state;

    return alerts.map((alert) => (
      <Alert
        key={alert.id}
        bsStyle={alert.style}
        onDismiss={() => {
          const { alerts } = this.state;
          const alertsWithout = reject(propEq('id', alert.id))(alerts);

          this.setState({ alerts: alertsWithout });
        }}
      >
        {alert.message}
      </Alert>
    ));
  }

  render() {
    const { intl: { formatMessage } } = this.props;
    const { RAZZLE_OIDC_CLIENT_ID } = process.env;

    /**
     * For some reason, react-router v4's <Redirect /> doesn't pass state
     * inside props.location.state, so we need to provide an alternative way to
     * pass our referrer path.
     */
    const matches = /(\?|&)from=([^&]+)/.exec(this.props.location.search);
    const queryPathname = matches && matches.length === 3 && matches[2];

    const { from } = this.props.location.state || { from: { pathname: (queryPathname || '/') } };
    const { password, redirectToReferrer, username } = this.state;

    if (redirectToReferrer) {
      return (
        <Redirect to={from} />
      );
    }

    return (
      <Row>
        <Col sm={6} smOffset={3}>
          <div>
            <div className="well well-primary">
              <h2><FormattedMessage {...messages.welcome} /></h2>

              <hr />

              <div className="row">
                <div className="col-lg-8">
                  <p><FormattedMessage {...messages.loginInstructions} /></p>

                  <div>
                    {this.renderAlerts()}
                  </div>

                  <form
                    onSubmit={(event) => {
                      this.login();
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                  >
                    <FormGroup controlId="username">
                      <ControlLabel><FormattedMessage {...messages.usernameLabel} /></ControlLabel>
                      <InputGroup>
                        <InputGroup.Addon>
                          <Glyphicon glyph="user" />
                        </InputGroup.Addon>
                        <FormControl
                          type="text"
                          value={username}
                          placeholder={formatMessage(messages.usernamePlaceholder)}
                          onChange={(event) => this.setState({ username: event.target.value })}
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup controlId="password">
                      <ControlLabel><FormattedMessage {...messages.passwordLabel} /></ControlLabel>
                      <InputGroup>
                        <InputGroup.Addon>
                          <Glyphicon glyph="lock" />
                        </InputGroup.Addon>
                        <FormControl
                          type="password"
                          value={password}
                          placeholder={formatMessage(messages.passwordPlaceholder)}
                          onChange={(event) => this.setState({ password: event.target.value })}
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup>
                      <Button
                        type="submit"
                        className="text-center"
                        componentClass="input"
                        bsStyle="primary"
                        value={formatMessage(genericMessages.login)}
                      />
                    </FormGroup>
                  </form>
                </div>
                <div className="col-lg-4 login">
                  <div>
                    <MicrosoftLogin
                      debug
                      buttonTheme="light"
                      clientId={RAZZLE_OIDC_CLIENT_ID}
                      tenantUrl={process.env.RAZZLE_OIDC_TENANT_URL}
                      authCallback={(error, authData, msal) => {
                        if (!error && authData) {
                          this.handleMicrosoftLogin(error, authData, msal);
                        }
                      }}
                      prompt={'select_account'}
                      withUserData
                      graphScopes={['user.read']}
                      forceRedirectStrategy
                      useLocalStorageCache
                    >
                      <Button bsStyle="primary" className="login-button">
                        <Image src={logoMicrosoft} style={{ marginRight: 10 }} />
                        SignIn with Microsoft
                      </Button>
                    </MicrosoftLogin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  user: selectUser(state),
});

const mapDispatchToProps = {
  authenticate,
  authenticateSSO,
  setCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(Login),
);
