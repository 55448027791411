import * as Sentry from '@sentry/browser';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { resume } from './app/modules/actions/session';

// eslint-disable-next-line import/no-dynamic-require
const { Routes } = require(`./projects/${process.env.RAZZLE_PROJECT}`).default;

Sentry.init({
  dsn: process.env.RAZZLE_SENTRY_FRONTEND_DSN,
  release: openintranet.version,
  environment: process.env.NODE_ENV || 'development',
});

window.Sentry = Sentry;

const configureStore = require('./app/modules/store').default;

const store = configureStore(window.__PRELOADED_STATE__);

const init = async () => {
  await store.dispatch(resume());
};

init();

hydrate(
  <BrowserRouter>
    <Provider store={store}>
      <Routes />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);

if (module.hot) {
  module.hot.accept();
}
