import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ID from './UUID';

export default class DynamicOptionList extends Component {
  static propTypes = {
    element: PropTypes.shape({
      options: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    preview: PropTypes.shape({}),
    updateElement: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      element: props.element,
      dirty: false,
    };
  }

  editOption = (optionIndex, e) => {
    const { element } = this.state;

    const setValue = t => t.replace(/[^A-Z0-9]+/ig, '_').toLowerCase();

    const thisElement = element;
    // eslint-disable-next-line max-len
    const val = (thisElement.options[optionIndex].value !== setValue(thisElement.options[optionIndex].text))
      ? thisElement.options[optionIndex].value : setValue(e.target.value);

    thisElement.options[optionIndex].text = e.target.value;
    thisElement.options[optionIndex].value = val;

    this.setState({ element: thisElement, dirty: true });
  };

  editOptionCorrect = (optionIndex) => {
    const { preview, updateElement } = this.props;
    const { element } = this.state;

    const thisElement = element;
    if (thisElement.options[optionIndex].hasOwnProperty('correct')) {
      delete (thisElement.options[optionIndex].correct);
    } else {
      thisElement.options[optionIndex].correct = true;
    }

    this.setState({ element: thisElement });
    updateElement.call(preview, thisElement);
  };

  addOption = (optionIndex) => {
    const { preview, updateElement } = this.props;
    const { element } = this.state;

    const thisElement = element;
    const newElement = {
      value: '',
      text: '',
      key: ID.uuid(),
    };
    thisElement.options.splice(optionIndex + 1, 0, newElement);
    updateElement.call(preview, thisElement);
  };

  updateOption = () => {
    const { preview, updateElement } = this.props;
    const { dirty, element } = this.state;

    // to prevent ajax calls with no change
    if (dirty) {
      updateElement.call(preview, element);
      this.setState({ dirty: false });
    }
  };

  removeOption = (optionIndex) => {
    const { preview, updateElement } = this.props;
    const { element } = this.state;

    const thisElement = element;
    thisElement.options.splice(optionIndex, 1);
    updateElement.call(preview, thisElement);
  };

  render() {
    const { element } = this.props;

    return (
      <div className="dynamic-option-list">
        <ul>
          <li>
            <div className="row">
              <div className="col-sm-8"><b>Opties</b></div>
              <div className="col-sm-4"><b>Correct</b></div>
            </div>
          </li>
          {element.options.map((option, index) => (
            <li className="clearfix" key={`edit_${option.key}`}>
              <div className="row">
                <div className="col-sm-8">
                  <input
                    tabIndex={index + 1}
                    className="form-control"
                    style={{ width: '100%' }}
                    type="text"
                    name={`text_${index}`}
                    placeholder="Optie tekst"
                    value={option.text}
                    onBlur={() => this.updateOption()}
                    onChange={e => this.editOption(index, e)}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-control"
                    type="checkbox"
                    value="1"
                    onChange={() => this.editOptionCorrect(index)}
                    checked={option.hasOwnProperty('correct')}
                  />
                </div>
                <div className="col-sm-3">
                  <div className="dynamic-options-actions-buttons">
                    <button
                      onClick={() => this.addOption(index)}
                      className="btn btn-success"
                    >
                      <i className="fa fa-plus-circle" />
                    </button>
                    {index > 0 &&
                    <button
                      onClick={() => this.removeOption(index)}
                      className="btn btn-danger"
                    >
                      <i className="fa fa-minus-circle" />
                    </button>
                    }
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
