import React from 'react';
import Well from 'react-bootstrap/lib/Well';
import { Tab, Tabs } from 'react-bootstrap';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { connect } from 'react-redux';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import Malfunctions from './Malfunctions/Malfunctions';
import Categories from './Categories/Categories';

const messages = defineMessages({
  malfunctions: {
    id: 'projects.arriva.app.modules.Malfunctions.components.Dashboard.malfunctions',
    description: 'Malfunctions label.',
    defaultMessage: 'Malfunctions',
  },
  pendingMalfunctions: {
    id: 'projects.arriva.app.modules.Malfunctions.components.Dashboard.pendingMalfunctions',
    description: 'Pending Malfunctions label.',
    defaultMessage: 'Pending malfunctions',
  },
  categories: {
    id: 'projects.arriva.app.modules.Malfunctions.components.Dashboard.categories',
    description: 'Categories label.',
    defaultMessage: 'Categories',
  },
});

const Dashboard = ({ history, location, intl: { formatMessage } }) => (
  <Well>
    <h2>{formatMessage(messages.malfunctions)}</h2>
    <Tabs
      activeKey={location.hash || '#malfunctions'}
      onSelect={(key) => history.push(key)}
      id="malfunctions-dashboard-tabs"
    >
      <Tab eventKey="#malfunctions" title={formatMessage(messages.pendingMalfunctions)}>
        <Malfunctions />
      </Tab>
      <Tab eventKey="#categories" title={formatMessage(messages.categories)}>
        <Categories />
      </Tab>
    </Tabs>
  </Well>
);

Dashboard.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    hash: PropTypes.string,
  }),

  // react-intl props
  intl: IntlShape.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  activeKey: path(['location', 'hash'], ownProps),
});

export default connect(mapStateToProps, null)(injectIntl(Dashboard));
