import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Alert,
  Button,
  Table,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { defineMessages, injectIntl } from 'react-intl';
import Pagination from '../../../../../../app/components/Pagination';
import { get } from '../../../lib/driveApi';
import { notification as notificationShape } from '../types';
import { isLoadingNotifications, selectNotifications } from '../selectors';
import { loadingNotifications, loadingNotificationsDone, setNotifications } from '../actions';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import genericMessages from '../../../../../../app/lib/genericMessages';

const styles = {
  footerWrapper: {
    display: 'flex',
  },
  footerInnerWrapper: {
    width: '50%',
  },
  footerInnerWrapperRight: {
    textAlign: 'right',
  },
  footerAddButton: {
    margin: '20px 0',
  },
};

const messages = defineMessages({
  title: {
    id: 'projects.arriva.app.modules.SoftwarePackage.components.Notifications.title',
    description: 'Notification message titel.',
    defaultMessage: 'Title',
  },
  sentAt: {
    id: 'projects.arriva.app.modules.SoftwarePackage.components.Notifications.sentAt',
    description: 'Notification sent date',
    defaultMessage: 'Sent at',
  },
  updatedAt: {
    id: 'projects.arriva.app.modules.SoftwarePackage.components.Notifications.updatedAt',
    description: 'Notification updated date',
    defaultMessage: 'Updated at',
  },
  pushNotification: {
    id: 'projects.arriva.app.modules.SoftwarePackage.components.Notifications.pushNotification',
    description: 'Push notification status',
    defaultMessage: 'Push notification',
  },
});

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      pageSize: 10,
      page: 1,
      pages: 1,
    };
  }

  componentDidMount() {
    this.fetchNotifications();
  }

  onSelectPage(page) {
    this.setState({ page }, () => this.fetchNotifications());
  }

  async fetchNotifications() {
    const {
      get, loadingNotifications, loadingNotificationsDone, setNotifications,
    } = this.props;
    const { page, pageSize } = this.state;

    await loadingNotifications();

    const url = `software-package/messages?page=${page}&page_size=${pageSize}`;

    get({ path: url, parallel: false })
      .then(({ pages, softwareNotifications }) => {
        this.setState({ pages }, async () => {
          await setNotifications(softwareNotifications);
          await loadingNotificationsDone();
        });
      })
      .catch((err) => this.setState({ error: err, page: 1, pages: 0 }));
  }

  renderError() {
    const { intl: { formatMessage } } = this.props;
    const { error } = this.state;

    if (!error) return null;

    const dismissError = () => this.setState({ error: undefined });

    return (
      <Alert bsStyle="danger" onDismiss={dismissError}>
        <h4>{formatMessage(genericMessages.error)}</h4>
        <p>{error.toString()}</p>
        <p>
          <Button onClick={dismissError}>{formatMessage(genericMessages.close)}</Button>
        </p>
      </Alert>
    );
  }

  renderNotifications(message) {
    const { intl: { formatMessage } } = this.props;

    return (
      <tr key={`message-${message.id}`}>
        <td>
          {!message.sendAt
            ? <Link to={`/message/package-notification/edit/${message.id}`}>{message.title}</Link>
            : message.title}
        </td>
        <td>
          {message.tag}
        </td>
        <td>
          {message.sendAt}
        </td>
        <td>
          {message.sendAt !== null ? message.pushNotification
            ? formatMessage(genericMessages.yes) : formatMessage(genericMessages.no) : ''}
        </td>
        <td>
          {message.updatedAt}
        </td>
      </tr>
    );
  }

  render() {
    const { notifications, isLoadingNotifications, intl: { formatMessage } } = this.props;
    const { page, pages } = this.state;

    return (
      <div>
        {this.renderError()}

        {notifications.length === 0 && <strong>{formatMessage(genericMessages.nothingFound)}</strong>}

        {isLoadingNotifications && <strong>{formatMessage(genericMessages.loadingInProgress)}</strong>}

        {(notifications.length > 0 && !isLoadingNotifications) && (
          <Table responsive>
            <thead>
              <tr>
                <td>{formatMessage(messages.title)}</td>
                <td>Tag</td>
                <td>{formatMessage(messages.sentAt)}</td>
                <td>{formatMessage(messages.pushNotification)}</td>
                <td>{formatMessage(messages.updatedAt)}</td>
              </tr>
            </thead>
            <tbody>
              {notifications.map((notification) => this.renderNotifications(notification))}
            </tbody>
          </Table>
        )}

        <div style={styles.footerWrapper}>
          <div style={styles.footerInnerWrapper}>
            <Pagination
              first
              prev
              last
              next
              ellipsis
              boundaryLinks
              maxButtons={5}
              bsSize="small"
              onSelect={(page) => this.onSelectPage(page)}
              page={page}
              pages={pages}
            />
          </div>

          <div style={{ ...styles.footerInnerWrapper, ...styles.footerInnerWrapperRight }}>
            <Button
              bsStyle="primary"
              href="/message/package-notification/add"
              style={styles.footerAddButton}
            >
              {formatMessage(genericMessages.add)}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

Notifications.propTypes = {
  // Dispatch props
  get: PropTypes.func.isRequired,
  loadingNotifications: PropTypes.func.isRequired,
  loadingNotificationsDone: PropTypes.func.isRequired,
  setNotifications: PropTypes.func.isRequired,

  // State props
  notifications: PropTypes.arrayOf(notificationShape).isRequired,
  isLoadingNotifications: PropTypes.bool.isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
};

const mapStateToProps = (state) => ({
  notifications: selectNotifications(state),
  isLoadingNotifications: isLoadingNotifications(state),
});

const mapDispatchToProps = {
  get,
  loadingNotifications,
  loadingNotificationsDone,
  setNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Notifications));
