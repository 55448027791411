export default {
  // Article constants
  ARTICLE_TYPE_HOSPITALITY: 4,
  DEFAULT_PAGES: [{
    path: '/contact',
    title: 'Contact',
  }, {
    path: '/',
    title: 'Home',
  }, {
    path: '/gallery/albums/2',
    title: 'Fotogalerijen',
  }],

  GROUP_TYPE_COMMISSION: 'comission',

  // Facebook constants
  FB_APP_ID: `${process.env.RAZZLE_FB_APP_ID}`,
  FB_DEV_REDIRECT_URI: `${process.env.RAZZLE_FB_REDIRECT_URI}`,
  FB_PAGE_URL: 'https://www.facebook.com/RKSVAlbertus/',
  FB_USERNAME: 'Albertus Magnus',
};
