import React from 'react';
import Pagination from '../Pagination';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import './FooterLayout.less';
import genericMessages from '../../lib/genericMessages';

export const FooterLayout = (props) => (
    <div className="footer-wrapper">
        <div className="footer-innerWrapper">
            <Pagination
                first
                prev
                last
                next
                ellipsis
                boundaryLinks
                maxButtons={5}
                bsSize="small"
                onSelect={(page) => props.onSelectPage(page)}
                page={props.page}
                pages={props.pages}
            />
        </div>

        <div className="footer-innerWrapper footer-innerWrapper-right">
            <Button
                bsStyle="primary"
                href={props.actionPath}
                className="footer-addButton"
            >
                <FormattedMessage {...genericMessages.add}/>
            </Button>
        </div>
    </div>
)
FooterLayout.propTypes = {
    actionPath: PropTypes.string.isRequired,
    onSelectPage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
}
