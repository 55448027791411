import PropTypes from 'prop-types';

export default PropTypes.shape({
  // interface IntlConfig
  locale: PropTypes.string,
  timeZone: PropTypes.string,
  formats: PropTypes.shape({}),
  textComponent: PropTypes.any,
  messages: PropTypes.shape({}),
  defaultLocale: PropTypes.string,
  defaultFormats: PropTypes.shape({}),
  onError: PropTypes.func,

  // interface IntlFormatters
  formatDate: PropTypes.func.isRequired,
  formatTime: PropTypes.func.isRequired,
  formatRelativeTime: PropTypes.func.isRequired,
  formatNumber: PropTypes.func.isRequired,
  formatPlural: PropTypes.func.isRequired,
  formatMessage: PropTypes.func.isRequired,
});
