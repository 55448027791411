import PropTypes from 'prop-types';
import { path } from 'ramda';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ConfirmRegistrationModal = (props) => {
  const {
    close,
    identifier,
    loading,
    register,
    showRegistrationModal,
    user,
  } = props;
  const displayName = path(['displayName'], user);
  const personnelNumber = path(['personnelNumber'], user);
  return (
    <Modal show={showRegistrationModal} onHide={close}>
      <Modal.Header>
        <Modal.Title>Registratie</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p style={{ padding: 15 }}>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line,max-len */}
          CI-nummer <code>{identifier}</code> koppelen met medewerker {displayName} ({personnelNumber})?
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button
          bsStyle="primary"
          disabled={loading}
          onClick={register}
        >
          Koppeling registreren
        </Button>
        <Button disabled={loading} onClick={close}>
          Annuleren
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmRegistrationModal.propTypes = {
  close: PropTypes.func.isRequired,
  identifier: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  register: PropTypes.func.isRequired,
  showRegistrationModal: PropTypes.bool,
  user: PropTypes.shape({
    USER_PERS_NR: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    resourceType: PropTypes.string.isRequired,
  }),
};

export default ConfirmRegistrationModal;
