import jQuery from 'jquery';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  tooltip: {
    id: 'app.lib.clearElement.tooltip',
    description: 'Tooltip for the \'Clear element\' Summernote button.',
    defaultMessage: 'Clearing element',
  },
});

export default function (formatMessage) {
  return (context) => {
    const { summernote: { ui } } = jQuery;

    const button = ui.button({
      contents: '<i class="fa fa-paragraph" />',
      tooltip: formatMessage(messages.tooltip),
      click: () => {
        context.invoke('editor.pasteHTML', '<div class="clearfix"></div><p><br></p>');
      },
    });

    return button.render();
  };
};
