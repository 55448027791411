import PropTypes from 'prop-types';
import { equals } from 'ramda';
import React, { Component } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import Nav from 'react-bootstrap/lib/Nav';
import Navbar from 'react-bootstrap/lib/Navbar';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import NavItem from 'react-bootstrap/lib/NavItem';
import Sidebar from 'react-sidebar';
import { readEndpoint } from 'redux-json-api';

import ManagementTopBar from '../../../../../app/components/Management/TopBar';
import genericMessages from '../../../../../app/lib/genericMessages';
import getPropTypeScheme from '../../../../../app/lib/PropTypes';
import IntlShape from '../../../../../app/lib/PropTypes/IntlShape';
import { selectMenuByIdentifier } from '../../../../../app/modules/selectors/api';
import { selectUser } from '../../../../../app/modules/selectors/session';

import '../../../../../app/components/Menu/TopBar.less';
import Favicon from '../../../../../app/components/Favicon';
import faviconFile from '../../../img/favicon.ico';

const messages = defineMessages({
});

class TopBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false,
    };

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;

    if (user) this.onUserChange();
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState, nextContent) {
    return !equals(nextProps, this.props) || !equals(nextState, this.state);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { user } = this.props;
    if (!equals(prevProps.user, user)) this.onUserChange();
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  onUserChange() {
    const { readEndpoint } = this.props;

    readEndpoint('menu?filter[identifier__exact]=_header')
      .catch((error) => {
        Sentry.captureException(error);
      });
  }

  renderNavParent({ children, title }, index) {
    const { intl: { formatMessage } } = this.props;

    return (
      <NavDropdown
        key={index}
        eventKey={index}
        title={title in messages ? formatMessage(messages[title]) : title}
        id={index}
      >
        {children.map((child, childIndex) => (
          child.type === 'parent' ? (
            // eslint-disable-next-line no-bitwise
            this.renderNavParent(child, (index << 4) | childIndex)
          ) : (
            // eslint-disable-next-line no-bitwise
            this.renderNavItem(child, (index << 4) | childIndex)
          )
        ))}
      </NavDropdown>
    );
    /* eslint-enable no-bitwise */
  }

  renderNavItem({ icon, path, title }, index) {
    return (
      <LinkContainer key={index} to={path} exact>
        <NavItem eventKey={index}>
          <span className={`glyphicon ${icon}`} />
          {title}
        </NavItem>
      </LinkContainer>
    );
  }

  renderNav() {
    const { user } = this.props;

    return (
      <div>
        <div>
          {(user !== undefined) ? this.renderNavLoggedIn() : this.renderNavLoggedOut()}
        </div>
      </div>
    );
  }

  renderNavLoggedIn() {
    const { headerMenu: { attributes: { configuration } } } = this.props;

    if (configuration.length === 0) {
      return null;
    }

    return (
      <Nav>
        {configuration.map((item, index) => (
          item.type === 'parent' ? (
            this.renderNavParent(item, index)
          ) : (
            this.renderNavItem(item, index)
          )
        ))}

        <ManagementTopBar />
      </Nav>
    );
  }

  renderNavLoggedOut() {
    return (
      <Nav>
        <LinkContainer to="/login">
          <NavItem eventKey="login">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <FormattedMessage {...genericMessages.login} />
          </NavItem>
        </LinkContainer>
      </Nav>
    );
  }

  render() {
    const { sidebarOpen } = this.state;

    return (
      <div>
        <Favicon icon={faviconFile} />
        <Navbar className="mainmenu" role="banner" fluid inverse staticTop>
          <Navbar.Collapse>
            {this.renderNav()}
          </Navbar.Collapse>
        </Navbar>
        <Sidebar
          sidebar={this.renderNav()}
          open={sidebarOpen}
          onSetOpen={this.onSetSidebarOpen}
          rootClassName="sidebar"
          sidebarClassName="sidebar-inner"
        >
          <div className="topbar-hamburger" onClick={() => this.onSetSidebarOpen(true)}>&#9776;</div>
        </Sidebar>
      </div>
    );
  }
}

TopBar.propTypes = {
  // react-intl props
  intl: IntlShape.isRequired,

  // state props
  headerMenu: PropTypes.shape({
    attributes: PropTypes.shape({
      configuration: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['link', 'parent']).isRequired,
      })).isRequired,
    }).isRequired,
  }),
  user: getPropTypeScheme('User'),

  // dispatch props
  readEndpoint: PropTypes.func.isRequired,
};

TopBar.defaultProps = { headerMenu: { attributes: { configuration: [] } } };

const mapStateToProps = (state) => ({
  headerMenu: selectMenuByIdentifier(state, '_header'),
  user: selectUser(state),
});

const mapDispatchToProps = {
  readEndpoint,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
  injectIntl(TopBar),
);
