import PropTypes from 'prop-types';

export const FileAttributes = PropTypes.shape({
  url: PropTypes.string,
  file: PropTypes.string,
  fileSize: PropTypes.number,
  sha1: PropTypes.string,
  originalFilename: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  uploadedAt: PropTypes.string,
  modifiedAt: PropTypes.string,
  isPublic: PropTypes.bool,
});

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  attributes: FileAttributes,
});
