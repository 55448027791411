const genericConstants = {
  // Contenttype constants
  CONTENT_TYPE_ARTICLE: 37,

  // Article constants
  ARTICLE_BY_FACEBOOK: '__FACEBOOK__',
  ARTICLE_GROUP_DEFAULT: '2',
  ARTICLE_PAGE_SIZE: 10,
  ARTICLE_POST_AS_GROUP: 'group',
  ARTICLE_POST_AS_USER: 'user',
  ARTICLE_TYPE_DEFAULT: '1',
  ARTICLE_TYPE_FACEBOOK: '5',
  ARTICLE_TYPE_HOSPITALITY: '4',
  ARTICLE_TYPE_NEWS: '2',
  ARTICLE_TYPE_PAGE: '3',
  DEFAULT_PAGES: [{
    path: '/contact',
    title: 'Contact',
  }, {
    path: '/',
    title: 'Home',
  }],

  // Comment constants
  COMMENT_DETAIL_PAGE_SIZE: 100,
  COMMENT_PREVIEW_PAGE_SIZE: 3,

  // Event-type constants
  EVENT_TYPE_GENERIC: '1',

  // Group constants
  GROUP_ID_ADMINS: '1',
  GROUP_ID_USERS: '2',
  GROUP_PAGE_SIZE: 15,
  GROUP_TYPE_COMMISSION: 'commission',

  // Galleries
  DEFAULT_THUMBNAIL_HEIGHT: 90,
  EMPTY_FOLDER_IMAGE: 'empty-folder.png',
  GALLERY_PAGE_SIZE: 250,
};

const projectConstants = require(`../../../projects/${process.env.RAZZLE_PROJECT}/app/constants.js`); // eslint-disable-line

export default { ...genericConstants, ...projectConstants.default };
