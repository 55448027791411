import React, { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

export const ErrorMessage = (props) => {
  const messages = defineMessages({
    notificationError: {
      id: 'app.components.ErrorMessage.serverError',
      description: 'Failed operation',
      defaultMessage: 'Something went wrong',
    },
    closeAlertText: {
      id: 'app.components.ErrorMessage.closeAlertText',
      description: 'Close notification alert',
      defaultMessage: 'Close notification',
    },
  });

  const [error, setError] = useState(props.error);

  if (!error) return null;

  const dismissError = () => setError(undefined);

  return (
    <Alert bsStyle="danger" onDismiss={dismissError}>
      <h4>{messages.notificationError}</h4>
      <p>{error.toString()}</p>
      <p>
        <Button onClick={dismissError}>{messages.closeAlertText}</Button>
      </p>
    </Alert>
  );
}
ErrorMessage.propTypes = {
  error: PropTypes.string.isRequired
}