// Loaders
export const SOFTWARE_NOTIFICATION_LOADING_NOTIFICATIONS = 'SOFTWARE_NOTIFICATION_LOADING_NOTIFICATIONS';
export const SOFTWARE_NOTIFICATION_LOADING_NOTIFICATIONS_DONE = 'SOFTWARE_NOTIFICATION_LOADING_NOTIFICATIONS_DONE';

// Adders / Setters
export const SOFTWARE_NOTIFICATION_SET_NOTIFICATIONS = 'SOFTWARE_NOTIFICATION_SET_NOTIFICATIONS';

// Loading actions
export const loadingNotifications = () => (dispatch) =>
  dispatch({ type: SOFTWARE_NOTIFICATION_LOADING_NOTIFICATIONS });
export const loadingNotificationsDone = () => (dispatch) =>
  dispatch({ type: SOFTWARE_NOTIFICATION_LOADING_NOTIFICATIONS_DONE });

// Actions
export const setNotifications = (data) => (dispatch) =>
  dispatch({ type: SOFTWARE_NOTIFICATION_SET_NOTIFICATIONS, payload: data });
