import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFlashMessages, removeMessage } from 'redux-flash';

import Alert from 'react-bootstrap/lib/Alert';

import './Flashmessage.less';

/**
 * React Bootstrap Flashmessage
 *
 * Feel free to add functionality
 */
class Flashmessage extends Component {
  static propTypes = {
    // state props
    flashMessages: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      isError: PropTypes.bool,
      message: PropTypes.string.isRequired,
      props: PropTypes.shape({
        title: PropTypes.string,
        alertStyle: PropTypes.oneOf(['danger', 'success', 'warning', 'info']),
      }).isRequired,
    })).isRequired,

    // dispatch props
    removeMessage: PropTypes.func,
  };

  render() {
    const { flashMessages, removeMessage } = this.props;

    if (flashMessages.length === 0) return null;

    const messages = flashMessages.map((flashMessage) => {
      const {
        id,
        message,
        isError,
        props: {
          alertStyle,
          title,
        },
      } = flashMessage;

      let bsStyle = alertStyle || 'success';
      if (isError) bsStyle = 'danger';

      return (
        <Alert key={`flashMessage_${id}`} bsStyle={bsStyle} onDismiss={() => removeMessage(id)}>
          {title && <h4>{title}</h4>}
          <p>{message}</p>
        </Alert>
      );
    });

    return (
      <div className="flashmessage-container">
        {messages}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  flashMessages: getFlashMessages(state) || {},
});

const mapDispatchToProps = {
  removeMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Flashmessage);
