import PropTypes from 'prop-types';

export const CommentAttributes = PropTypes.shape({
  created: PropTypes.string,
  modified: PropTypes.string,
  comment: PropTypes.string.isRequired,
  objectPk: PropTypes.string.isRequired,
});

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  attributes: CommentAttributes,
});
