import React from 'react';
import { injectIntl } from 'react-intl';
import VehicleForm from './VehicleForm';
import MalfunctionsList from './MalfunctionsList';

const styles = {
  container: {
    marginTop: '30px',
  },
};

const Malfunctions = () => (
  <div style={styles.container}>
    <VehicleForm />
    <MalfunctionsList />
  </div>
);

export default injectIntl(Malfunctions);
