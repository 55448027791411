/* eslint-disable */
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { Component } from 'react';
import TextAreaAutosize from 'react-textarea-autosize';
import Button from 'react-bootstrap/lib/Button';

import DynamicOptionList from './dynamic-option-list';

let toolbar = {
  options: ['inline', 'list', 'textAlign', 'fontSize', 'history'],
  inline: {
    inDropdown: false,
    className: undefined,
    options: ['bold', 'italic', 'underline', 'superscript', 'subscript'],
  },
};

export default class FormElementsEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorLoaded: false,
      element: this.props.element,
      data: this.props.data,
      dirty: false,
    }
  }

  componentDidMount() {
    const self = this;

    import('react-draft-wysiwyg').then(module => {
      self.Editor = module.Editor;
      self.setState({ editorLoaded: true });
    });
  }

  editElementProp(elemProperty, targProperty, e) {
    // elemProperty could be content or label
    // targProperty could be value or checked
    let this_element = this.state.element;
    this_element[elemProperty] = e.target[targProperty];

    this.setState({
      element: this_element,
      dirty: true,
    }, () => {
      if (targProperty === 'checked') this.updateElement();
    });
  }

  onEditorStateChange(index, property, editorContent) {
    let html = draftToHtml(convertToRaw(editorContent.getCurrentContent()))
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '')
      .replace(/\r?\n|\r/, '');
    let this_element = this.state.element;
    this_element[property] = html;

    this.setState({
      element: this_element,
      dirty: true,
    });
  }

  updateElement() {
    let this_element = this.state.element;

    // to prevent ajax calls with no change
    if (this.state.dirty) {
      this.props.updateElement.call(this.props.preview, this_element);
      this.setState({ dirty: false });
    }
  }

  render() {
    const {
      element,
      files = [],
      manualEditModeOff,
      showCorrectColumn,
      preview,
      updateElement,
    } = this.props;
    const { editorLoaded } = this.state;
    const { Editor } = this;

    let editorState = '';

    const this_checked = element.hasOwnProperty('required') ? element.required : false;
    const this_read_only = element.hasOwnProperty('readOnly') ? element.readOnly : false;
    const this_default_today = element.hasOwnProperty('defaultToday') ? element.defaultToday : false;
    const this_checked_inline = element.hasOwnProperty('inline') ? element.inline : false;
    const this_checked_bold = element.hasOwnProperty('bold') ? element.bold : false;
    const this_checked_italic = element.hasOwnProperty('italic') ? element.italic : false;
    const this_checked_center = element.hasOwnProperty('center') ? element.center : false;
    const this_checked_page_break = element.hasOwnProperty('pageBreakBefore') ? element.pageBreakBefore : false;
    const this_checked_alternate_form = element.hasOwnProperty('alternateForm') ? element.alternateForm : false;


    if (files.length < 1 || (files.length > 0 && files[0].id) !== "") {
      files.unshift({id: '', file_name: ''});
    }

    if (element.hasOwnProperty('content')) {
      const contentState = ContentState.createFromBlockArray(convertFromHTML(element.content));
      editorState = EditorState.createWithContent(contentState);
    }

    if (element.hasOwnProperty('label')) {
      const contentState = ContentState.createFromBlockArray(convertFromHTML(element.label));
      editorState = EditorState.createWithContent(contentState);
    }

    return (
      <div>
        <div className="clearfix">
          <h4 className="pull-left">{element.text}</h4>
          <i className="pull-right fa fa-times dismiss-edit" onClick={manualEditModeOff} />
        </div>

        {element.hasOwnProperty('content') &&
          <div className="form-group">
            <label className="control-label">Tekst om te tonen:</label>

            {editorLoaded ? (
              <Editor
                toolbar={toolbar}
                defaultEditorState={editorState}
                onBlur={this.updateElement.bind(this)}
                onEditorStateChange={this.onEditorStateChange.bind(this, 0, 'content')}
              />
            ) : (
              <div>Loading editor...</div>
            )}
          </div>
        }

        {element.hasOwnProperty('file_path') &&
          <div className="form-group">
            <label className="control-label" htmlFor="fileSelect">Kies bestand:</label>

            <select
              id="fileSelect"
              className="form-control"
              defaultValue={element.file_path}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(this, 'file_path', 'value')}
            >
              {files.map(file => <option value={file.id} key={`file_${file.id}`}>{file.file_name}</option>)}
            </select>
          </div>
        }

        {element.hasOwnProperty('href') &&
          <div className="form-group">
            <TextAreaAutosize
              type="text"
              className="form-control"
              defaultValue={element.href}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(this, 'href', 'value')}
            />
          </div>
        }

        {element.hasOwnProperty('src') &&
          <div>
            <div className="form-group">
              <label className="control-label" htmlFor="srcInput">Link naar:</label>
              <input
                id="srcInput"
                type="text"
                className="form-control"
                defaultValue={element.src}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'src', 'value')}
              />
            </div>
            <div className="form-group">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={this_checked_center}
                    value={true}
                    onChange={this.editElementProp.bind(this, 'center', 'checked')}
                  />
                  Center?
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3">
                <label className="control-label" htmlFor="elementWidth">Breedte:</label>
                <input
                  id="elementWidth"
                  type="text"
                  className="form-control"
                  defaultValue={element.width}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(this, 'width', 'value')}
                />
              </div>
              <div className="col-sm-3">
                <label className="control-label" htmlFor="elementHeight">Hoogte:</label>
                <input
                  id="elementHeight"
                  type="text"
                  className="form-control"
                  defaultValue={element.height}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(this, 'height', 'value')}
                />
              </div>
            </div>
          </div>
        }

        {element.hasOwnProperty('label') &&
          <div className="form-group">
            <label>Label om te tonen:</label>
            {editorLoaded ? (
              <Editor
                toolbar={toolbar}
                defaultEditorState={editorState}
                onBlur={this.updateElement.bind(this)}
                onEditorStateChange={this.onEditorStateChange.bind(this, 0, 'label')}
              />
            ) : (
              <div>Loading editor...</div>
            )}
            <br />
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={this_checked}
                  value={true}
                  onChange={this.editElementProp.bind(this, 'required', 'checked')}
                />
                Verplicht
              </label>
            </div>

            {element.hasOwnProperty('readOnly') &&
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={this_read_only}
                    value={true}
                    onChange={this.editElementProp.bind(this, 'readOnly', 'checked')}
                  />
                  Alleen lezen ( hiermee is dit veld niet in te vullen door de gebruiker )
                </label>
              </div>
            }

            {element.hasOwnProperty('defaultToday') &&
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={this_default_today}
                    value={true}
                    onChange={this.editElementProp.bind(this, 'defaultToday', 'checked')}
                  />
                  Standaard de huidige dag invullen?
                </label>
              </div>
            }

            {(this.state.element.element === 'RadioButtons' || this.state.element.element === 'Checkboxes') &&
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={this_checked_inline}
                    value={true}
                    onChange={this.editElementProp.bind(this, 'inline', 'checked')}
                  />
                  Toon horizontaal
                </label>
              </div>
            }
          </div>
        }

        {/*{(this.state.element.element === 'Signature' && element.readOnly) &&*/}
          {/*<div className="form-group">*/}
            {/*<label className="control-label" htmlFor="variableKey">Variable Key:</label>*/}
            {/*<input*/}
              {/*id="variableKey"*/}
              {/*type="text"*/}
              {/*className="form-control"*/}
              {/*defaultValue={element.variableKey}*/}
              {/*onBlur={this.updateElement.bind(this)}*/}
              {/*onChange={this.editElementProp.bind(this, 'variableKey', 'value')}*/}
            {/*/>*/}
            {/*<p className="help-block">*/}
              {/*This will give the element a key that can be used to replace the content with a runtime value.*/}
            {/*</p>*/}
          {/*</div>*/}
        {/*}*/}

        {/*<div className="form-group">*/}
          {/*<label className="control-label">Print Options</label>*/}
          {/*<div className="checkbox">*/}
            {/*<label>*/}
              {/*<input*/}
                {/*type="checkbox"*/}
                {/*checked={this_checked_page_break}*/}
                {/*value={true}*/}
                {/*onChange={this.editElementProp.bind(this, 'pageBreakBefore', 'checked')}*/}
              {/*/>*/}
              {/*Page Break Before Element?*/}
            {/*</label>*/}
          {/*</div>*/}
        {/*</div>*/}

        {/*<div className="form-group">*/}
          {/*<label className="control-label">Alternate/Signature Page</label>*/}
          {/*<div className="checkbox">*/}
            {/*<label>*/}
              {/*<input*/}
                {/*type="checkbox"*/}
                {/*checked={this_checked_alternate_form}*/}
                {/*value={true}*/}
                {/*onChange={this.editElementProp.bind(this, 'alternateForm', 'checked')}*/}
              {/*/>*/}
              {/*Display on alternate/signature Page?*/}
            {/*</label>*/}
          {/*</div>*/}
        {/*</div>*/}

        {element.hasOwnProperty('step') &&
          <div className="form-group">
            <div className="form-group-range">
              <label className="control-label" htmlFor="rangeStep">Stap</label>
              <input
                id="rangeStep"
                type="number"
                className="form-control"
                defaultValue={element.step}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'step', 'value')}
              />
            </div>
          </div>
        }

        {element.hasOwnProperty('min_value') &&
          <div className="form-group">
            <div className="form-group-range">
              <label className="control-label" htmlFor="rangeMin">Min</label>
              <input
                id="rangeMin"
                type="number"
                className="form-control"
                defaultValue={element.min_value}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'min_value', 'value')}
              />
              <input
                type="text"
                className="form-control"
                defaultValue={element.min_label}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'min_label', 'value')}
              />
            </div>
          </div>
        }

        {element.hasOwnProperty('max_value') &&
          <div className="form-group">
            <div className="form-group-range">
              <label className="control-label" htmlFor="rangeMax">Max</label>
              <input
                id="rangeMax"
                type="number"
                className="form-control"
                defaultValue={element.max_value}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'max_value', 'value')}
              />
              <input
                type="text"
                className="form-control"
                defaultValue={element.max_label}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'max_label', 'value')}
              />
            </div>
          </div>
        }

        {element.hasOwnProperty('default_value') &&
          <div className="form-group">
            <div className="form-group-range">
              <label className="control-label" htmlFor="defaultSelected">Standaard geselecteerd</label>
              <input
                id="defaultSelected"
                type="number"
                className="form-control"
                defaultValue={element.default_value}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'default_value', 'value')}
              />
            </div>
          </div>
        }

        {element.hasOwnProperty('static') && element.static &&
          <div className="form-group">
            <label className="control-label">Text Style</label>
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={this_checked_bold}
                  value={true}
                  onChange={this.editElementProp.bind(this, 'bold', 'checked')}
                />
                Bold
              </label>
            </div>
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={this_checked_italic}
                  value={true}
                  onChange={this.editElementProp.bind(this, 'italic', 'checked')}
                />
                Italic
              </label>
            </div>
          </div>
        }

        {showCorrectColumn && element.can_have_answer && !element.hasOwnProperty('options') &&
          <div className="form-group">
            <label className="control-label" htmlFor="correctAnswer">Juist antwoord</label>
            <input
              id="correctAnswer"
              type="text"
              className="form-control"
              defaultValue={element.correct}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(this, 'correct', 'value')}
            />
          </div>
        }

        {element.hasOwnProperty('options') &&
          <DynamicOptionList
            showCorrectColumn={showCorrectColumn}
            data={preview.state.data}
            updateElement={updateElement}
            preview={preview}
            element={element}
            key={this.props.element.options.length}
          />
        }

        <hr />

        <Button
          type="button"
          className="text-center action-widget-button"
          componentClass="input"
          bsStyle="primary"
          value="Klaar"
          onClick={manualEditModeOff}
        />
      </div>
    );
  }
}

FormElementsEdit.defaultProps = {className: 'edit-element-fields'}
