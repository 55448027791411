import React from 'react';
import { filter, path, propEq } from 'ramda';
import stringReplace from 'react-string-replace';
import $ from 'jquery';
import { defineMessages, FormattedMessage } from 'react-intl';

import Label from 'react-bootstrap/lib/Label';

import genericMessages from '../../lib/genericMessages';
import { ReactFormGenerator } from './FormBuilder';
import * as FormElements from './FormBuilder/form-elements';
import { getForms } from './reducer';

const { forms: commonFormSettings } = require(`../../../projects/${process.env.RAZZLE_PROJECT}/common`); // eslint-disable-line
const userMetaFieldMessages = require(`../../../projects/${process.env.RAZZLE_PROJECT}/app/lib/userMetaFieldMessages`).default; // eslint-disable-line

const messages = defineMessages({
  addForm: {
    id: 'app.components.Forms.helpers.addForm',
    description: '\'Add form\' label.',
    defaultMessage: 'Add form',
  },
  form: {
    id: 'app.components.Forms.helpers.form',
    description: '\'Form\' label.',
    defaultMessage: 'Form',
  },
  formUserFieldSelect: {
    id: 'app.components.Forms.helpers.formUserFieldSelect',
    description: '\'Form user fields\' label.',
    defaultMessage: 'Form user fields',
  },
  formUserFieldDisplayName: {
    id: 'app.components.Forms.helpers.formUserFieldDisplayName',
    description: '\'Form user displayName\' label.',
    defaultMessage: 'Name',
  },
  formUserFieldEmail: {
    id: 'app.components.Forms.helpers.formUserFieldEmail',
    description: '\'Form user email\' label.',
    defaultMessage: 'User email',
  },
  forms: {
    id: 'app.components.Forms.helpers.forms',
    description: '\'Forms\' label.',
    defaultMessage: 'Forms',
  },
  image: {
    id: 'app.components.Forms.helpers.image',
    description: '\'Image\' label',
    defaultMessage: 'Image',
  },
  noName: {
    id: 'app.components.Forms.helpers.noName',
    description: '\'No name\' label.',
    defaultMessage: 'No name',
  },
  fileLibraryLabel: {
    id: 'app.components.Forms.helpers.fileLibraryLabel',
    description: 'Summernote \'file library\' label.',
    defaultMessage: 'File Library',
  },
  fileLibraryTooltip: {
    id: 'app.components.Forms.helpers.fileLibraryTooltip',
    description: 'Summernote \'file library\' tooltip.',
    defaultMessage: 'Insert file',
  },
});

const formUserFields = (formatMessage) => {
  const formUserFields = path(['fieldSelector', 'user'], commonFormSettings);

  // set the user fields list, extend it with project specific settings
  const formUserFieldsList = [
    { id: 'user.email', messageKey: 'formUserFieldEmail' },
    { id: 'user.displayName', messageKey: 'formUserFieldDisplayName' },
    ...formUserFields,
  ];

  return formUserFieldsList.map(({ id, messageKey }) => {
    // use the field id as default message
    let message = id;

    // Check if the message key exists in the userMetaFieldMessages
    // otherwise check if it is in the local messages
    if (messageKey in userMetaFieldMessages) {
      message = formatMessage(userMetaFieldMessages[messageKey]);
    } else if (messageKey in messages) {
      message = formatMessage(messages[messageKey]);
    }

    return { id, label: message };
  });
};

// This function creates the html for every field from the form submission
// it uses the getSubmissionValue function from each element class
// this function will parse the right value
const fieldValueMapper = (data) => {
  const output = [];
  const fields = filter(propEq('can_have_answer', true))(data);

  if (fields.length === 0) return <div>Geen data gevonden</div>;

  fields.forEach((field) => {
    const getter = FormElements[`get${field.element}Value`];

    if (typeof getter !== 'function') return;

    const value = getter(field) || 'Geen waarde gevonden';

    // define a single html output and re-use it
    const outputHtml = (label, value) => (
      <div>
        <div>
          <div className="col-sm">
            <strong>{label}</strong>
          </div>

          <div className="col-sm">{value}</div>
        </div>

        <br />
      </div>
    );

    // getSubmissionValue parses the answer object and returns the correct value
    output.push(outputHtml(field.label, value));
  });

  return <div>{output}</div>;
};

/**
 * This function returns the passed content with replaced form markers
 *
 * It will check if there are forms in the content.
 * if so, it will replace the markers with the actual form
 *
 * This function is a redux dispatch function so it should be used as such
 * through the mapDispatchToProps prop in the connect() HOC wrapper
 *
 * @param content
 * @returns {function(Function, Function): string}
 */
const renderContentWithForms = content => (dispatch, getState) => {
  const forms = getForms(getState()) || [];

  let output = content;
  let matches;
  let result = null;
  const formRegex = /\[form\s?(?:title="[^"]*")?\s?(?:id="?(\d+)"?)?\]/g;

  // eslint-disable-next-line no-cond-assign
  while ((matches = formRegex.exec(content)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (matches.index === formRegex.lastIndex) formRegex.lastIndex += 1;

    const formId = matches[1];
    const form = filter(propEq('id', formId))(forms)[0];

    // eslint-disable-next-line no-loop-func
    output = stringReplace(output, matches[0], () => {
      const data = path(['attributes', 'data', 'fields'], form);
      if (!data) return null;

      return (
        <ReactFormGenerator
          key={`form-form-${formId}`}
          formId={formId}
          mutable
          validateForCorrectness
          download_path=""
          // back_action="/"
          back_name="Back"
          answer_data={{}}
          action_name="Verstuur"
          form_action="/"
          form_method="POST"
          readOnly={false}
          // variables={variables}
          hide_actions={false}
          data={data}
        />
      );
    });
  }

  // replacements have been made, stringReplace uses split which results in an array
  if (Array.isArray(output)) {
    // Add div with dangerouslySetInnerHTML to all even elements ( the forms )
    result = output.map((r, i) => {
      if (i % 2) return r;

      return <div dangerouslySetInnerHTML={{ __html: r }} key={`form-div-${i}`} />;
    });
  } else {
    result = <div dangerouslySetInnerHTML={{ __html: output }} />;
  }

  // Return a single div instead of multiple,
  // because of the flexing behaviour in .article-detail-body
  return result;
};

/**
 * This function is used to replace all the plain text urls
 * in the given content with <a>url</a>
 *
 * @param abstract
 * @returns {*}
 */
const renderContentWithLinks = (abstract) => {
  const regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;

  const result = stringReplace(abstract, regex, (match, i) => (
    `<a key="link-${i}" href="${match}">${match}</a>`
  ));

  return <div dangerouslySetInnerHTML={{ __html: result.join('') }} />;
};

/**
 * This function returns the passed content with replaced file markers
 *
 * It will check if there are files in the content.
 * if so, it will replace the markers with the actual file
 *
 * This function is a redux dispatch function so it should be used as such
 * through the mapDispatchToProps prop in the connect() HOC wrapper
 *
 * @param content
 * @returns {function(Function, Function): string}
 */
const replaceFileLinks = content => () => {
  let output = content;
  let matches;
  let result = null;
  const formRegex = /\[file\s?(?:title="([^"]*)")?\s?(?:id=(\d+))?\]/g;

  // eslint-disable-next-line no-cond-assign
  while ((matches = formRegex.exec(content)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (matches.index === formRegex.lastIndex) formRegex.lastIndex += 1;

    const fileTitle = matches[1];
    const fileId = matches[2];

    // eslint-disable-next-line no-loop-func
    output = stringReplace(output, matches[0], () => { // eslint-disable-line
      return `<a href="/download-file/${fileId}" target="_blank">${fileTitle}</a>`;
    });
  }

  result = output;

  // replacements have been made, stringReplace uses split which results in an array
  if (Array.isArray(output))
    result = output.join(' ');

  // Return a single div instead of multiple,
  // because of the flexing behaviour in .article-detail-body
  return result;
};

/**
 * Function that will return the File Library button and file insert callback
 *
 * This function is a redux dispatch function so it should be used as such
 * through the mapDispatchToProps prop in the connect() HOC wrapper
 *
 * @returns {function(Function, Function): Function}
 */
const getSummernoteFileLibrary = ({ formatMessage, showSelectFileModal }) => () => (context) => {
  const { summernote: { ui } } = $;

  // create button
  const buttonGroup = ui.buttonGroup([
    ui.button({
      className: 'note-btn-bold',
      contents: `<span class="fa fa-file"></span> ${formatMessage(messages.fileLibraryLabel)}`,
      tooltip: formatMessage(messages.fileLibraryTooltip),
      click: () => {
        // Cursor position must be saved because otherwise it is lost when dropdown is opened.
        context.invoke('editor.saveRange');

        showSelectFileModal(({ fetchedFile }) => {
          // Restore the range and focus the editor
          context.invoke('editor.restoreRange');
          context.invoke('editor.focus');

          const fileName = path(['attributes', 'name'], fetchedFile);
          const fileOriginalName = path(['attributes', 'originalFilename'], fetchedFile);
          const title = fileName.length > 0 ? fileName : fileOriginalName;

          // insert the text now
          context.invoke('editor.insertText', `[file title="${title}" id=${fetchedFile.id}]`);
        });
      },
    }),
  ]);

  return buttonGroup.render();
};

/**
 * Function that will return a list in a dropdown of forms for the Summernote ui
 *
 * This function is a redux dispatch function so it should be used as such
 * through the mapDispatchToProps prop in the connect() HOC wrapper
 *
 * @returns {function(Function, Function): Function}
 */
const getSummernoteFormList = formatMessage => (dispatch, getState) => (context) => {
  const forms = getForms(getState());
  const { summernote: { ui } } = $;

  if (!forms || forms.length === 0) return null;

  const list = forms.map((f) => {
    const name = path(['attributes', 'name'], f) || formatMessage(messages.noName);
    return `<li class="summernote-dropdown-item" data-id="${f.id}">${name}</li>`;
  }).join('');

  // create button
  const buttonGroup = ui.buttonGroup([
    ui.button({
      className: 'dropdown-toggle',
      contents: `<span class="fa fa-bars" /> ${formatMessage(messages.forms)} <span class="caret" />`,
      tooltip: formatMessage(messages.addForm),
      data: {
        toggle: 'dropdown',
      },
      click: () => {
        // Cursor position must be saved because otherwise it is lost when dropdown is opened.
        context.invoke('editor.saveRange');
      },
    }),
    ui.dropdown({
      className: 'drop-default summernote-list',
      contents: `<ul class="summernote-formfield-dropdown">${list}</ul>`,
      callback: (items) => {
        items.find('li').on('click', (e) => {
          const formId = $(e.target).data('id');
          const title = $(e.target).html();

          // Restore the range and focus the editor
          context.invoke('editor.restoreRange');
          context.invoke('editor.focus');
          // insert the text now
          context.invoke('editor.insertText', `[form title="${title}" id="${formId}"]`);
        });
      },
    }),
  ]);

  return buttonGroup.render();
};

/**
 * Function that will return a list in a dropdown of formfields for the Summernote ui
 *
 * This function is a redux dispatch function so it should be used as such
 * through the mapDispatchToProps prop in the connect() HOC wrapper
 *
 * @returns {function(Function, Function): Function}
 */
const getSummernoteFormFieldsList = (formFields, formatMessage) => () => (context) => {
  const { summernote: { ui } } = $;

  // Set the header for form fields
  let list = `<h6 class="summernote-dropdown-header">${formatMessage(genericMessages.formFieldSelect)}</h6>`;

  // Add the form fields
  list += formFields.map(ff =>
    `<li class="summernote-dropdown-item" data-id="${ff.id}">${ff.label.replace(/<(?:.|\n)*?>/gm, '')}</li>`,
  ).join('');

  // Add the user fields header
  list += `<h6 class="summernote-dropdown-header">${formatMessage(messages.formUserFieldSelect)}</h6>`;

  list += formUserFields(formatMessage).map(({ id, label }) => (
    `<li class="summernote-dropdown-item" data-id="${id}">${label}</li>`
  )).join('');

  // create button
  const buttonGroup = ui.buttonGroup([
    ui.button({
      className: 'dropdown-toggle',
      contents: `<span class="fa fa-bars" /> ${formatMessage(genericMessages.formFieldSelect)} <span class="caret" />`,
      tooltip: formatMessage(genericMessages.formFieldsSelectTooltip),
      data: {
        toggle: 'dropdown',
      },
      click: () => {
        // Cursor position must be saved because otherwise it is lost when dropdown is opened.
        context.invoke('editor.saveRange');
      },
    }),
    ui.dropdown({
      className: 'drop-default summernote-list',
      contents: `<ul class="summernote-formfield-dropdown">${list}</ul>`,
      callback: (items) => {
        items.find('li').on('click', (e) => {
          const fieldId = $(e.target).data('id');
          const label = $(e.target).html();

          // Restore the range and focus the editor
          context.invoke('editor.restoreRange');
          context.invoke('editor.focus');
          // insert the text now
          context.invoke('editor.insertText', `[field label="${label}" id="${fieldId}"]`);
        });
      },
    }),
  ]);

  return buttonGroup.render();
};

/**
 * This function is used to replace all the form markers
 * in the given content with Bootstrap Labels
 *
 * @param abstract
 * @returns {*}
 */
const renderAbstractWithFormBadges = (abstract) => {
  const formRegex = /\[form\s?(?:title="([^"]*)")?\s?(?:id="?(?:\d+)"?)?\]/g;

  const result = stringReplace(abstract, formRegex, (match, i) => (
    <Label key={`abstract-label-${i}`} bsStyle="success">
      <FormattedMessage {...messages.form} />{`: ${match}` || ''}
    </Label>
  ));

  return result;
};

/**
 * This function is used to replace all the images
 * in the given content with Bootstrap Labels
 *
 * @param abstract
 * @returns {*}
 */
const renderAbstractWithImgBadges = (abstract) => {
  const regex = /<img\s?src=".*?"\s.?data-filename="(.*?)".*?>/;

  const result = stringReplace(abstract, regex, (match, i) => (
    <Label key={`abstract-label-img-${i}`} bsStyle="success">
      <FormattedMessage {...messages.image} />{`: ${match}`}
    </Label>
  ));

  return result;
};

const renderAbstractWithBadges = (abstract, options = {}) => {
  let result = abstract;

  if (!('image' in options) || options.image === true) {
    result = renderAbstractWithImgBadges(result);
  }

  if (!('form' in options) || options.form === true) {
    result = renderAbstractWithFormBadges(result);
  }

  if (result.constructor !== Array) {
    result = [abstract];
  }

  // Add div with dangerouslySetInnerHTML to all even elements
  result = result.map((r, i) => {
    if (i % 2) return r;

    return <div key={`abstract-${i}`} dangerouslySetInnerHTML={{ __html: r }} />;
  });

  return result;
};

export {
  fieldValueMapper,
  formUserFields,
  getSummernoteFileLibrary,
  getSummernoteFormFieldsList,
  getSummernoteFormList,
  renderAbstractWithBadges,
  renderContentWithForms,
  renderContentWithLinks,
  replaceFileLinks,
};
