import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import IntlShape from '../../../../../../../app/lib/PropTypes/IntlShape';

const styles = {
  container: {
    margin: '24px',
  },
  button: {
    marginTop: '40px',
    height: '50px',
    backgroundColor: '#00becd',
    color: '#ffffff',
    fontSize: '18px',
  },
  message: {
    marginTop: '30px',
  },
};

const messages = defineMessages({
  sent: {
    id: 'projects.arrive.app.modules.Malfunctions.components.Confirmation.sent',
    description: 'Sent label',
    defaultMessage: 'Your workorder is sent successfully.',
  },
  return: {
    id: 'projects.arrive.app.modules.Malfunctions.components.Confirmation.return',
    description: 'Return label',
    defaultMessage: 'Return to pending workorders.',
  },
});

const Confirmation = ({
  location,
  intl: { formatMessage },
}) => {
  const history = useHistory();
  const vehicleNumber = pathOr('', ['state', 'vehicleNumber'], location);

  return (
    <div className="well" style={styles.container}>
      <div style={styles.message}>
        {formatMessage(messages.sent)}
      </div>
      {location && location.pathname === '/malfunctions/open-route/confirmation' && (
      <Button
        style={styles.button}
        onClick={() => history.replace(`/malfunctions/open/${vehicleNumber}`)}
      >
        {formatMessage(messages.return)}
      </Button>
      )}
    </div>
  );
};

Confirmation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }),

  // react-intl props
  intl: IntlShape.isRequired,
};

export default (injectIntl(Confirmation));
