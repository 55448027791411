import { useEffect } from 'react';

const Favicon = ({ icon }) => {
  useEffect(() => {
    const favicon = document.getElementById('favicon');
    if (favicon) {
      favicon.href = icon;
    }
  }, []);
  return null;
};

export default Favicon;
