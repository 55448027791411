import React from 'react';
import Well from 'react-bootstrap/lib/Well';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import NotificationsList from './NotificationsList';

const messages = defineMessages({
  notifications: {
    id: 'app.projects,arriva.app.modules.UserNotification.components.Dashboard.notifications',
    description: 'List of notifications',
    defaultMessage: 'Notifications',
  },
});

const Dashboard = () => (
  <Well>
    <h2>
      {' '}
      <FormattedMessage {...messages.notifications} />
    </h2>
    <NotificationsList />
  </Well>
);

export default (injectIntl(Dashboard));
