/* eslint-disable */
import React, { Component } from 'react';

import Preview from './preview'
import ReactFormGenerator from './form';

import './scss/react-date-picker.css.scss';
import './scss/react-draft.css.scss';
import './scss/react-native-slider.css.scss';
import './scss/react-select.css.scss';
import './scss/react-star-rating.css.scss';

import './scss/form-builder.css.scss';
import './scss/form-builder-form.css.scss';

class ReactFormBuilder extends Component {

  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editElement: null,
    };
  }


  editModeOn(data, e) {
    const { editMode } = this.state;

    e.stopPropagation();

    if (editMode) {
      this.setState({ editMode: false, editElement: null });
    } else {
      this.setState({ editMode: true, editElement: data });
    }
  }


  manualEditModeOff() {
    if (this.state.editMode) {
      this.setState({
        editMode: false,
        editElement: null,
      });
    }
  }


  editModeOff(e) {
    if (this.state.editMode) {
      this.setState({
        editMode: !this.state.editMode,
        editElement: null,
      });
    }
  }


  render() {
    const { editMode, editElement } = this.state;
    const { files, saveUrl, variables, url } = this.props;

    return (
      <div className="react-form-builder clearfix">
        <Preview
          files={files}
          manualEditModeOff={this.manualEditModeOff.bind(this)}
          parent={this}
          url={url}
          saveUrl={saveUrl}
          editModeOn={this.editModeOn}
          editMode={editMode}
          variables={variables}
          editElement={editElement}
        />
      </div>
    );
  }

}

export {
  ReactFormBuilder,
  ReactFormGenerator,
}
