import React from 'react';
import { injectIntl } from 'react-intl';
import CategoriesList from './CategoriesList';

const styles = {
  container: {
    marginTop: '30px',
  },
};

const Categories = () => (
  <div style={styles.container}>
    <CategoriesList />
  </div>
);

export default injectIntl(Categories);
