import PropTypes from 'prop-types';
import React from 'react';
import {
  FormControl,
  FormGroup,
  HelpBlock,
} from 'react-bootstrap';

const styles = {
  userListItem: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  userSelector: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

const userSelection = (props) => {
  const {
    changeInput,
    loading,
    selectUser,
    users,
  } = props;

  // noinspection JSUnresolvedVariable
  const renderedUsers = (users || []).map((user) => (
    <li key={user.id} style={styles.userListItem}>
      <a onClick={() => selectUser(user)} style={styles.userSelector}>
        {/* eslint-disable-next-line */}
        <span style={{ fontStyle: 'italic', color: '#aaa' }}>{user.personnelNumber}</span>&nbsp;&nbsp;{user.displayName}
      </a>
    </li>
  ));

  return (
    <div>
      <form>
        <FormGroup controlId="userSearchFormTitle">
          <FormControl
            style={{ width: 450 }}
            type="text"
            defaultValue=""
            placeholder="Naam medewerker"
            onChange={(e) => changeInput(e.target.value)}
          />
          <HelpBlock>Zoeken is niet hoofdlettergevoelig</HelpBlock>
        </FormGroup>
      </form>

      {(loading || renderedUsers) && (
        <div>
          {loading && (
            <p>Zoeken naar medewerkers...</p>
          )}

          {renderedUsers.length > 0 && (
            <>
              <p>Gevonden medewerkers:</p>
              <ul style={{ listStyleType: 'none' }}>
                {renderedUsers}
              </ul>
            </>
          )}
        </div>
      )}
    </div>
  );
};

userSelection.propTypes = {
  changeInput: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  selectUser: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      USER_PERS_NR: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      resourceType: PropTypes.string.isRequired,
    }),
  ),
};

export default userSelection;
