// Loaders
export const ARRIVIDEO_LOADING_BROADCASTS = 'ARRIVIDEO_LOADING_BROADCASTS';
export const ARRIVIDEO_LOADING_BROADCASTS_DONE = 'ARRIVIDEO_LOADING_BROADCASTS_DONE';
export const ARRIVIDEO_LOADING_CONTENT_ITEMS = 'ARRIVIDEO_LOADING_CONTENT_ITEMS';
export const ARRIVIDEO_LOADING_CONTENT_ITEMS_DONE = 'ARRIVIDEO_LOADING_CONTENT_ITEMS_DONE';
export const ARRIVIDEO_LOADING_FOLDERS = 'ARRIVIDEO_LOADING_FOLDERS';
export const ARRIVIDEO_LOADING_FOLDERS_DONE = 'ARRIVIDEO_LOADING_FOLDERS_DONE';

// Adders / Setters
export const ARRIVIDEO_ADD_CONTENT_ITEMS = 'ARRIVIDEO_ADD_CONTENT_ITEMS';
export const ARRIVIDEO_SET_BROADCASTS = 'ARRIVIDEO_SET_BROADCASTS';
export const ARRIVIDEO_SET_CONTENT_ITEMS = 'ARRIVIDEO_SET_CONTENT_ITEMS';
export const ARRIVIDEO_SET_FOLDERS = 'ARRIVIDEO_SET_FOLDERS';

// Loading actions
export const loadingBroadcasts = () => (dispatch) =>
  dispatch({ type: ARRIVIDEO_LOADING_BROADCASTS });
export const loadingBroadcastsDone = () => (dispatch) =>
  dispatch({ type: ARRIVIDEO_LOADING_BROADCASTS_DONE });
export const loadingContentItems = () => (dispatch) =>
  dispatch({ type: ARRIVIDEO_LOADING_CONTENT_ITEMS });
export const loadingContentItemsDone = () => (dispatch) =>
  dispatch({ type: ARRIVIDEO_LOADING_CONTENT_ITEMS_DONE });
export const loadingFolders = () => (dispatch) =>
  dispatch({ type: ARRIVIDEO_LOADING_FOLDERS });
export const loadingFoldersDone = () => (dispatch) =>
  dispatch({ type: ARRIVIDEO_LOADING_FOLDERS_DONE });

// Actions
export const addContentItems = (data) => (dispatch) =>
  dispatch({ type: ARRIVIDEO_ADD_CONTENT_ITEMS, payload: data });
export const setBroadcasts = (data) => (dispatch) =>
  dispatch({ type: ARRIVIDEO_SET_BROADCASTS, payload: data });
export const setContentItems = (data) => (dispatch) =>
  dispatch({ type: ARRIVIDEO_SET_CONTENT_ITEMS, payload: data });
export const setFolders = (data) => (dispatch) =>
  dispatch({ type: ARRIVIDEO_SET_FOLDERS, payload: data });
