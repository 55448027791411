import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import NavItem from 'react-bootstrap/lib/NavItem';
import { FormattedMessage, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';

import { uiModalCreatePageOpen } from '../../modules/actions/interface';
import { isAdmin } from '../../modules/selectors/session';

const messages = defineMessages({
  facebookPermission: {
    id: 'app.components.Management.TopBar.facebookPermission',
    description: 'Management TopBar \'Facebook permission\' menu item label.',
    defaultMessage: 'Facebook permission',
  },
  manageTopBar: {
    id: 'app.components.Management.TopBar.manageTopBar',
    description: 'Management TopBar \'Manage topbar\' menu item label.',
    defaultMessage: 'Manage menu',
  },
  medialibrary: {
    id: 'app.components.Management.TopBar.medialibrary',
    description: 'Management TopBar \'Media library\' menu item label.',
    defaultMessage: 'Media Library',
  },
  newPage: {
    id: 'app.components.Management.TopBar.newPage',
    description: 'Management TopBar \'New page\' menu item label.',
    defaultMessage: 'New page',
  },
  pages: {
    id: 'app.components.Management.TopBar.pages',
    description: 'Management TopBar \'Pages\' menu item label.',
    defaultMessage: 'Pages',
  },
});

class ManagementTopBar extends Component {
  static propTypes = {
    // state props
    isAdmin: PropTypes.bool.isRequired,

    // dispatch props
    uiModalCreatePageOpen: PropTypes.func.isRequired,
  };

  render() {
    const { isAdmin, uiModalCreatePageOpen } = this.props;

    if (!isAdmin) return null;

    return (
      <NavDropdown
        eventKey="management"
        title={<Glyphicon bsClass="fa" glyph="cogs" />}
        id="management"
      >
        <LinkContainer to="/medialibrary" style={{ cursor: 'pointer' }}>
          <NavItem eventKey="management-medialibrary">
            <Glyphicon glyph="picture" />

            {' '}

            <FormattedMessage {...messages.medialibrary} />
          </NavItem>
        </LinkContainer>

        <LinkContainer to="/manageTopBar" style={{ cursor: 'pointer' }}>
          <NavItem eventKey="management-manageTopBar">
            <Glyphicon bsClass="fa" glyph="bars" style={{ padding: '0px 6px' }} />

            {' '}

            <FormattedMessage {...messages.manageTopBar} />
          </NavItem>
        </LinkContainer>

        <LinkContainer to="/facebook-permission" target="new" style={{ cursor: 'pointer' }}>
          <NavItem eventKey="management-facebook-permission">
            <Glyphicon bsClass="fa" glyph="facebook" style={{ padding: '0px 7px' }} />

            {' '}

            <FormattedMessage {...messages.facebookPermission} />
          </NavItem>
        </LinkContainer>

        <NavDropdown
          eventKey="management-pages"
          title={<FormattedMessage {...messages.pages} />}
          id="management-pages"
        >
          <span onClick={() => uiModalCreatePageOpen()}>
            <NavItem eventKey="management-newpage">
              <Glyphicon glyph="user" />

              {' '}

              <FormattedMessage {...messages.newPage} />
            </NavItem>
          </span>
        </NavDropdown>
      </NavDropdown>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: isAdmin(state),
});

const mapDispatchToProps = {
  uiModalCreatePageOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagementTopBar);
