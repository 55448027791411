import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Collapse from 'react-bootstrap/lib/Collapse';
import ListGroupItem from 'react-bootstrap/lib/ListGroupItem';
import Well from 'react-bootstrap/lib/Well';

const CommonIssue = ({ children, title }) => {
  const [open, setOpen] = useState(false);

  return (
    <ListGroupItem onClick={() => setOpen(!open)}>
      {title}

      <Collapse in={open}>
        <div>
          <Well>
            {children}
          </Well>
        </div>
      </Collapse>
    </ListGroupItem>
  );
};

CommonIssue.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired,
};

export default CommonIssue;
