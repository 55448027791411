export const NOTIFICATIONS_LOADING_MESSAGES = 'NOTIFICATIONS_LOADING_MESSAGES';
export const NOTIFICATIONS_LOADING_MESSAGES_DONE = 'NOTIFICATIONS_LOADING_MESSAGES_DONE';
export const NOTIFICATIONS_SET_MESSAGES = 'NOTIFICATIONS_SET_MESSAGES';

export const loadingMessages = () => (dispatch) =>
  dispatch({ type: NOTIFICATIONS_LOADING_MESSAGES });
export const loadingMessagesDone = () => (dispatch) =>
  dispatch({ type: NOTIFICATIONS_LOADING_MESSAGES_DONE });
export const setMessages = (data) => (dispatch) =>
  dispatch({ type: NOTIFICATIONS_SET_MESSAGES, payload: data });
