import axios from 'axios';
import JWTDecode from 'jwt-decode';
import { path } from 'ramda';
import { readEndpoint, setAxiosConfig } from 'redux-json-api';

import {
  AuthenticationError,
  AUTHENTICATION_NOTOK,
  AUTHENTICATION_MISSING_TOKEN,
  CurrentUserError,
} from '../errors/session';
import { selectUserId } from '../selectors/session';
import { callHook } from '../../lib/hooks';

/* eslint-disable */
export const SESSION_ACCESS_TOKEN_SET = 'SESSION_ACCESS_TOKEN_SET';
export const SESSION_ACCESS_TOKEN_REFRESH = 'SESSION_ACCESS_TOKEN_REFRESH';
export const SESSION_ACCESS_TOKEN_REFRESH_DONE = 'SESSION_ACCESS_TOKEN_REFRESH_DONE';
export const SESSION_ACCESS_TOKEN_REFRESH_DEADLINE_SECONDS = 60;
export const SESSION_AUTHENTICATE = 'SESSION_AUTHENTICATE';
export const SESSION_CURRENT_USER_SET = 'SESSION_CURRENT_USER_SET';
export const SESSION_CURRENT_USER_UNSET = 'SESSION_CURRENT_USER_UNSET';
export const SESSION_DESTROY = 'SESSION_DESTROY';
export const SESSION_RESUME = 'SESSION_RESUME';


/**
 * Sets the access token.
 */
export const setAccessToken = accessToken => {
  return async dispatch => {
    if (!accessToken) return;

    try {
      const exp = JWTDecode(accessToken).exp;
      const cur = Date.now() / 1000;

      if (exp && exp < cur) {
        console.error('[OI] setAccessToken token expired');
        return;
      }
    } catch (error) {
      console.error(`[OI] setAccessToken decode error=${error}`);
      return;
    }

    await dispatch(setAxiosConfig({
      baseURL: process.env.RAZZLE_API_HOST + '/api',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }));

    window.localStorage.accessToken = accessToken;

    /**
     * Request an HTTP-only cookie from Express to aid in authenticated
     * server-side rendering. This call is done asynchronously from the rest of
     * this action.
     */
    axios.post('/token', { token: accessToken })
      .then((response) => {
        switch (response.status) {
          case 200: {
            console.log(`[OI] token-cookie OK: "${response.data.message}"`);
            break;
          }
          default: {
            console.error(`[OI] token-cookie ERROR: ${response.data.error} "${response.data.message}"`);
          }
        }
      })
      .catch((error) => {
        console.error('[OI] token-cookie EXCEPTION:', error);
      });

    return dispatch({ type: SESSION_ACCESS_TOKEN_SET, payload: accessToken });
  };
};

/**
 * Refreshes the access token.
 */
export const refreshAccessToken = () => {
  return async (dispatch, getState) => {
    const {
      api: { endpoint: { axiosConfig: { baseURL, headers } } },
      session: { accessToken },
    } = getState();

    if (!accessToken) return;

    const refreshEndpoint = `${baseURL}/refresh-json-web-token`;

    const refreshAccessTokenPromise = fetch(refreshEndpoint, {
      headers: {
        ...headers,
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      },
      method: 'POST',
      body: JSON.stringify({
        data: {
          type: 'refresh-json-web-token',
          attributes: {
            token: accessToken,
          },
        },
      }),
    }).then(async response => {
      const json = await response.json();

      dispatch({ type: SESSION_ACCESS_TOKEN_REFRESH_DONE });

      if (response.ok && json.data && json.data.token) {
        dispatch(setAccessToken(json.data.token));

        return Promise.resolve(json.data.token);
      }

      dispatch(destroy());

      if (json.errors && json.errors.non_field_errors)
        return Promise.reject(json.errors.non_field_errors.join(' '));

      return Promise.reject(`refreshAccessToken failed: ${response.statusText}`);
    }).catch(error => {
      Sentry.captureException(error);

      return Promise.reject(error);
    });

    await dispatch({ type: SESSION_ACCESS_TOKEN_REFRESH, payload: refreshAccessTokenPromise });

    return refreshAccessTokenPromise;
  };
};

/**
 * Redux middleware that refreshes the access token if necessary on every API
 * request.
 */
export const refreshAccessTokenMiddleware = ({ dispatch, getState }) => next => action => {
  if (typeof action === 'function') {
    const { session: { accessToken, refreshAccessTokenPromise } } = getState();

    if (accessToken) {
      const exp = JWTDecode(accessToken).exp;
      const cur = Date.now() / 1000;

      if (exp) {
        if (exp < cur) return destroy()(dispatch);

        if (exp < (cur + SESSION_ACCESS_TOKEN_REFRESH_DEADLINE_SECONDS)) {
          if (refreshAccessTokenPromise)
            return refreshAccessTokenPromise.then(() => next(action));

          return refreshAccessToken()(dispatch, getState).then(() => next(action));
        }
      }
    }
  }

  return next(action);
};

/**
 * Verifies the access token. Dispatches a session destroy action if it's
 * invalid.
 */
export const verifyAccessToken = () => {
  return async (dispatch, getState) => {
    const {
      api: { endpoint: { axiosConfig: { baseURL, headers } } },
      session: { accessToken },
    } = getState();

    if (!accessToken) {
      console.error('[OI] verifyAccessToken no accessToken in state');
      return;
    }

    const verifyEndpoint = `${baseURL}/verify-json-web-token`;

    try {
      await axios({
        url: verifyEndpoint,
        headers: {
          ...headers,
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
        },
        method: 'post',
        data: {
          data: {
            type: 'verify-json-web-token',
            attributes: {
              token: accessToken,
            },
          },
        },
      });
    } catch (error) {
      const errors = path(['response', 'data', 'errors'], error);

      console.error(`[OI] verify-json-web-token ERROR: ${errors}`);

      await dispatch(destroy());
    }
  };
};

/**
 * Authenticates against the API.
 */
export const authenticate = (username, password) => {
  return async (dispatch, getState) => {
    const {
      api: { endpoint: { axiosConfig: { baseURL } } },
    } = getState();

    const obtainEndpoint = `${baseURL}/obtain-json-web-token`;

    // When authenthicating with SSO, the password is a Drive! API token,
    // as the Django JSONWebTokenSerializer requires 'username' and 'password' as parameters.
    const response = await fetch(obtainEndpoint, {
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      },
      method: 'POST',
      body: JSON.stringify({
        data: {
          type: 'obtain-json-web-token',
          attributes: {
            username: username,
            password: password,
          },
        },
      }),
    });

    const result = await response.json();

    if (!response.ok) {
      if (result && result.errors && result.errors.non_field_errors) {
        throw new AuthenticationError(
          'Authentication failed.',
          AUTHENTICATION_NOTOK,
          result.errors.non_field_errors,
        );
      } else {
        const error = new AuthenticationError(
          'Authentication failed.',
          AUTHENTICATION_NOTOK,
          [],
        );

        Sentry.captureException(error, { extra: response });

        throw error;
      }
    }

    if (response.ok && !result.data.token) {
      throw new AuthenticationError(
        'Missing token.',
        AUTHENTICATION_MISSING_TOKEN,
        [],
      );
    }

    callHook('authenticated', result.data, dispatch);

    return dispatch(setAccessToken(result.data.token));
  };
};

/**
 * Authenticates using Single Sign-On.
 */
export const authenticateSSO = (email, authKey, msal) => {
  return async (dispatch) => {
    const baseURl = process.env.RAZZLE_DRIVE_API_HOST
    const apiEndpoint = `${baseURl}auth/token-by-oidc`

    const response = await fetch(apiEndpoint, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, authKey }),
      method: 'POST',
    })

    const result = await response.json();

    if (!response.ok) {
      throw new AuthenticationError(
        'Authentication failed.',
        AUTHENTICATION_NOTOK,
        result.errors.non_field_errors,
      );
    }

    callHook('msalInstance', msal, dispatch);

    return dispatch(authenticate(result.userData.email, result.token))
  }
};

/**
 * Retrieve and set the current user object.
 */
export const setCurrentUser = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const userId = selectUserId(state);

    if (!userId) return;

    const response = await dispatch(readEndpoint(`user/${userId}`));

    if (!response || !response.body || !response.body.data)
      throw new CurrentUserError();

    const user = response.body.data;

    callHook('currentUser', user, dispatch);

    return dispatch({ type: SESSION_CURRENT_USER_SET, payload: user });
  };
};

/**
 * Unset the current user object.
 */
export const unsetCurrentUser = () => ({ type: SESSION_CURRENT_USER_UNSET });

export const destroy = () => {
  return async (dispatch) => {
    try {
      if (window && window.localStorage) {
        console.log('[OI] destroy removing accessToken from localStorage...');
        delete window.localStorage.accessToken;
      }
    } catch (error) {
      console.error('[OI] destroy ERROR: could not delete from localStorage');
    }

    try {
      const response = await axios.get('/token/delete');

      switch (response.status) {
        case 200: {
          console.log(`[OI] destroy token-cookie OK: "${response.data.message}"`);
          break;
        }
        default: {
          console.error(`[OI] destroy token-cookie ERROR: ${response.data.error} "${response.data.message}"`);
        }
      }
    } catch (error) {
      const { message } = path(['response', 'data'], error) || {};

      if (!message || message !== 'Cookie does not exist.') {
        console.error(`[OI] destroy token-cookie EXCEPTION: ${message || error}`);
      }
    }

    await callHook('destroy', {}, dispatch);

    await dispatch({ type: SESSION_DESTROY });
  };
};

export const resume = () => {
  return async (dispatch, getState) => {
    const accessToken = window.localStorage.accessToken;

    if (!accessToken) {
      console.log('[OI] resume no accessToken...');
      await dispatch(destroy());
      return;
    }

    try {
      const { exp } = JWTDecode(accessToken);
      const cur = Date.now() / 1000;

      if (exp && exp < cur) {
        console.error('[OI] resume accessToken expired ');
        await dispatch(destroy());
        return;
      }
    } catch (error) {
      console.error(`[OI] resume accessToken decode error=${error}`);
      await dispatch(destroy());
      return;
    }

    callHook('resume', {}, dispatch);

    await dispatch(setAccessToken(accessToken));
    await dispatch(verifyAccessToken());
    await dispatch(setCurrentUser());
  };
};
