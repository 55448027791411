// Loaders
export const URLENTRY_LOADING_URLENTRIES = 'URLENTRY_LOADING_URLENTRIES';
export const URLENTRY_LOADING_URLENTRIES_DONE = 'URLENTRY_LOADING_URLENTRIES_DONE';

// Adders / Setters
export const URLENTRY_SET_URLENTRIES = 'URLENTRY_SET_URLENTRIES';

// Loading actions
export const loadingUrlEntries = () => (dispatch) =>
  dispatch({ type: URLENTRY_LOADING_URLENTRIES });
export const loadingUrlEntriesDone = () => (dispatch) =>
  dispatch({ type: URLENTRY_LOADING_URLENTRIES_DONE });

// Actions
export const setUrlEntries = (data) => (dispatch) =>
  dispatch({ type: URLENTRY_SET_URLENTRIES, payload: data });
