import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import { getLocale } from '../../../app/modules/selectors/locale';

import { defaultLocale } from '../common';

// We don't need to import English messages as they are the default.
import nlMessages from '../translations/nl.json';

/**
 * Polyfills for the global `Intl` API.
 */
if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/en');
  require('@formatjs/intl-pluralrules/dist/locale-data/nl');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/nl');
}

const messages = {
  nl: nlMessages,
};

const mapStateToProps = (state) => ({
  defaultLocale,
  key: getLocale(state),
  locale: getLocale(state),
  messages: messages[getLocale(state)],
});

export default connect(mapStateToProps)(IntlProvider);
