import PropTypes from 'prop-types';

export const softwarePackage = PropTypes.shape({
  // Default props
  id: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  information: PropTypes.string.isRequired,

  // Last update date
  updatedAt: PropTypes.string.isRequired,
});
