import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';

import { get } from '../lib/driveApi';
import IntlShape from '../../../../app/lib/PropTypes/IntlShape';

const messages = defineMessages({
  placeholder: {
    id: 'app.components.DriveSuggestFormElement',
    description: 'Drive Suggest placeholder label',
    defaultMessage: 'Search here for Aks Users, Guest users, Departments and Intranet groups...',
  },
});

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

// The translate labels object
// This object is also used to determine the order
// of which to show the items in the dropdown
// NOTE: These keys represent the keys returned by the api from /account/suggest/:query
export const translateLabels = {
  AksAccount: 'Aks accounts',
  User: 'Aks accounts',
  Department: 'Afdelingen',
  Usergroup: 'Afdelingen',
  Group: 'Intranet groepen',
  GuestAccount: 'Gast accounts',
  SpecialDepartment: 'Speciale groepen/afdelingen', // For now only 600001 "Arriva Iedereen"
};

/**
 * Method that will retrieve user accounts, user groups/departments and guest accounts
 * from the Drive! API
 *
 * This will be displayed by type
 *
 * @param get
 * @param intl
 * @param onSelect
 * @param placeholder
 * @returns {*}
 * @constructor
 */
const DriveSuggest = ({
  get, intl, onSelect, placeholder,
}) => (
  <AsyncSelect
    cacheOptions
    controlShouldRenderValue={false}
    placeholder={placeholder || intl.formatMessage(messages.placeholder)}
    name="drive-suggest"
    loadOptions={(q) => get({ path: `account/suggest/${q}`, parallel: false })
      .then((accounts) => Object.keys(translateLabels).map((account) => ({
        label: translateLabels[account],
        options: (accounts[account] || []).map((a) => ({ label: a.displayName, value: a })),
      })))}
    onChange={onSelect}
    formatGroupLabel={(data) => (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    )}
  />
);

DriveSuggest.propTypes = {
  // Own props
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,

  // Dispatch props
  get: PropTypes.func.isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
};

const mapDispatchToProps = {
  get,
};

export default connect(null, mapDispatchToProps)(injectIntl(DriveSuggest));
