import { path } from 'ramda';
import { readEndpoint } from 'redux-json-api';

const NODE_ENV = path(['env', 'NODE_ENV'], process) || 'development';

/**
 * `readEndpoint` variant which fetches all pages.
 * @param {String} endpoint The endpoint to request.
 * @returns {Promise} A Promise that resolves with the responses
 */
export const readEndpointAllPages = endpoint => async (dispatch) => {
  const responses = [];
  let next = endpoint;

  while (next) {
    try {
      const response = await dispatch(readEndpoint(next)); // eslint-disable-line no-await-in-loop

      responses.push(response);

      next = path(['body', 'links', 'next'], response);
    } catch (error) {
      console.error(`[OI] readEndpointAllPages error=${error}`);
      Sentry.captureException(error, {
        extra: {
          source: 'readEndpointAllPages',
          endpoint: next,
        },
      });

      error.endpoint = next;

      // Throw the error again so it propagates up the Promise chain.
      throw error;
    }
  }

  return responses;
};

/**
 * Wrapper for a Promise that can be resolved by another caller.
 */
class DedupPromise {
  /**
   * Return the wrapper Promise.
   *
   * @returns {Promise} A Promise that can be resolved by another caller.
   */
  async promise() {
    const self = this;

    return new Promise((resolve, reject) => {
      self.resolve = resolve;
      self.reject = reject;
    });
  }

  /**
   * Resolve the wrapper Promise with the given response.
   *
   * @param {Object} response The response to resolve the Promise with.
   */
  onResolve(response) {
    this.resolve(response);
  }

  /**
   * Reject the wrapper Promise with the given error.
   *
   * @param {Error} error The error to reject the Promise with.
   */
  onReject(error) {
    this.reject(error);
  }
}

class DedupTask {
  /**
   * Create a new task to execute a `readEndpoint` on a unique endpoint.
   *
   * @param {Function} dispatch The Redux dispatch function.
   */
  constructor(dispatch) {
    if (typeof dispatch !== 'function') {
      throw new Error('DedupTask: dispatch is not a function');
    }

    this.dispatch = dispatch;
    this.callbacks = [];
  }

  /**
   * Start the `readEndpoint`, and add ourself to the list of callbacks. If the
   * caller is not the first to call for the given endpoint, it will just add
   * itself to the list of callbacks.
   *
   * @param {String} endpoint The API endpoint to call.
   * @returns {Promise} A Promise that will resolve with the API response
   */
  async run(endpoint) {
    if (this.callbacks.length === 0) {
      setTimeout(() => {
        this.dispatch(readEndpoint(endpoint))
          .then(response => this.onResolve(response))
          .catch(error => this.onReject(error));
      }, 1);
    }

    const dp = new DedupPromise();
    this.callbacks.push(dp);

    return dp.promise();
  }

  /**
   * Resolves all currently registered callback Promises with the API response.
   *
   * @param {Object} response The API response to resolve the callbacks with.
   */
  onResolve(response) {
    if (NODE_ENV === 'development') {
      console.log(`[OI] DedupTask.onResolve() called for ${this.callbacks.length} callers...`);
    }

    this.callbacks.forEach(dp => dp.onResolve(response));
    this.callbacks = [];
  }

  /**
   * Rejects all currently registered callback Promises with the API error.
   *
   * @param {Error} error The API error to reject the callbacks with.
   */
  onReject(error) {
    if (NODE_ENV === 'development') {
      console.log(`[OI] DedupTask.onReject() called for ${this.callbacks.length} callers...`);
    }

    this.callbacks.forEach(dp => dp.onReject(error));
    this.callbacks = [];
  }
}

/**
 * This object contains all instantiated DedupTasks for unique endpoints. These
 * tasks will clear their own callback when they resolve or reject, and can be
 * reused.
 */
const _dedups = {};

/**
 * This action creator will trigger a GET request to the specified endpoint. If
 * multiple requests for the same endpoint are in flight, it will only be
 * issued once, and all requests will resolve with the same response.
 *
 * @param {String} endpoint The endpoint to request.
 * @returns {Promise} A Promise that resolves with the API response
 */
export const readEndpointDedup = endpoint => async (dispatch) => {
  if (!(endpoint in _dedups)) {
    _dedups[endpoint] = new DedupTask(dispatch);
  }

  return _dedups[endpoint].run(endpoint);
};
