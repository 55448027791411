import { find, path, propEq } from 'ramda';
import { createSelector } from 'reselect';

// Loading selectors
export const isLoadingNotifications = (state) => path(['softwareNotification', 'loadingNotifications'], state);

// Data selectors
export const selectNotifications = (state) => path(['softwareNotification', 'notifications'], state);
export const selectIdentifier = (state, props) => path(['match', 'params', 'identifier'], props);

// Memoizing selectors
export const getNotificationByIdentifier = createSelector(
  [selectIdentifier, selectNotifications], (notificationId, softwareNotifications) => {
    if (!softwareNotifications || softwareNotifications.length === 0) return false;
    if (!notificationId) return undefined;

    return find(propEq('id', notificationId))(softwareNotifications);
  });
