import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Nav from 'react-bootstrap/lib/Nav';
import Navbar from 'react-bootstrap/lib/Navbar';
import NavItem from 'react-bootstrap/lib/NavItem';
import { defineMessages, injectIntl } from 'react-intl';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';

import IntlShape from '../../lib/PropTypes/IntlShape';
import { slugify } from '../../lib/utils';
import { isAdmin } from '../../modules/selectors/session';

import './QuickMenu.less';

const messages = defineMessages({
  header: {
    id: 'app.components.Menu.QuickMenu.header',
    description: 'Quick navigation header.',
    defaultMessage: 'Jump to',
  },
  arriVideo: {
    id: 'app.components.Menu.QuickMenu.arriVideo',
    description: 'ArriVideo label',
    defaultMessage: 'ArriVideo',
  },
  urlEntry: {
    id: 'app.components.Menu.QuickMenu.urlEntry',
    description: 'urlEntry label',
    defaultMessage: 'URL Module',
  },
  establishments: {
    id: 'app.components.Menu.QuickMenu.establishments',
    description: 'Establishment label',
    defaultMessage: 'Establishments',
  },
  tabletRegistration: {
    id: 'app.components.Menu.QuickMenu.tabletRegistration',
    description: 'Tablet registration label',
    defaultMessage: 'Tablet registration',
  },
  softwarePackage: {
    id: 'app.components.Menu.QuickMenu.softwarePackage',
    description: 'Software package label',
    defaultMessage: 'Software package',
  },
  notifications: {
    id: 'app.components.Menu.QuickMenu.notifications',
    description: 'Software notifications label',
    defaultMessage: 'Software Notifications',
  },
  malfunctions: {
    id: 'app.components.Menu.QuickMenu.malfunctions',
    description: 'Malfunctions label',
    defaultMessage: 'Malfunctions',
  },
  usersNotifications: {
    id: 'app.components.Menu.QuickMenu.usersNotifications',
    description: 'Users notification label',
    defaultMessage: 'Users Notifications',
  },
});

class QuickMenu extends PureComponent {
  static propTypes = {
    // react-intl props
    intl: IntlShape.isRequired,

    // Own props
    menuItems: PropTypes.array.isRequired, // eslint-disable-line

    // State props
    isAdmin: PropTypes.bool.isRequired,
  };

  render() {
    const { isAdmin, menuItems, intl: { formatMessage } } = this.props;

    return (
      <Navbar className="quicklinks hidden-print">
        <Navbar.Header>
          <h3>
            <span>{formatMessage(messages.header)}</span>
          </h3>

          <Navbar.Toggle>
            <Glyphicon glyph="triangle-bottom" />
          </Navbar.Toggle>
        </Navbar.Header>

        <Navbar.Collapse>
          <Nav stacked>

            {menuItems && menuItems.map((item) => {
              const key = slugify(item.name);

              const menuLink = item.link.match(/^(http|https):/) ? (
                <NavItem
                  className="nav-item"
                  eventKey={key}
                  key={key}
                  href={item.link}
                  target={item.target || '_self'}
                >
                  <Glyphicon glyph={item.icon} />
                  {formatMessage(messages[item.name])}
                </NavItem>
              ) : (
                <LinkContainer to={item.link} key={key}>
                  <NavItem className="nav-item" eventKey={key}>
                    <Glyphicon glyph={item.icon} />
                    {formatMessage(messages[item.name])}
                  </NavItem>
                </LinkContainer>
              );

              return item.hasOwnProperty('isAdmin') // eslint-disable-line
                ? ((item.isAdmin && isAdmin) ? menuLink : null) : menuLink; // eslint-disable-line
            })}

          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: isAdmin(state),
});

export default connect(mapStateToProps)(injectIntl(QuickMenu));
