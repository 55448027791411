import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import constants from '../../../../lib/drive-constants';
import {
  get,
  patch,
  post,
  remove,
} from '../../../../lib/driveApi';
import { getCategoryByIdentifier, selectIdentifier } from '../../selectors';
import { setMalfunctionsCategories } from '../../actions';
import { getLocale } from '../../../../../../../app/modules/selectors/locale';
import IntlShape from '../../../../../../../app/lib/PropTypes/IntlShape';
import genericMessages from '../../../../../../../app/lib/genericMessages';
import CategoryForm from './CategoryForm';

const messages = defineMessages({
  category: {
    id: 'projects.arriva.app.modules.Malfunctions.components.CategoryDetail.category',
    description: 'Category label.',
    defaultMessage: 'Category',
  },
});

const CategoryDetail = ({
  category,
  categoryId,
  history,
  get,
  patch,
  post,
  remove,
  intl: { formatMessage },
  setMalfunctionsCategories,
  locale,
}) => {
  const [error, setError] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Check if the category is false, if so, there are no categories at all
  // and the user probably got here by using the direct url
  // So, load the category
  if (category === false && categoryId) {
    get({ path: `malfunctions/categories/${categoryId}` })
      .then((cat) => {
        setMalfunctionsCategories([cat]);
      })
      .catch((err) => setError(err));
  }

  function renderForm() {
    const initialValues = {
      ...category,
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          const body = {
            values,
          };

          (category.id ? patch : post)({ path: `malfunctions/categories/${category.id ? category.id : ''}`, body })
            .then((result) => {
              if (result.message === constants.MSG_SUCCESS) {
                setSubmitting(false);

                history.replace('/malfunction-form#categories');
                return;
              }

              Sentry.captureException(new Error(`Something went wrong: CategoryForm post/patch-> ${result}`));
              setError(`Something went wrong: ${result}`);
            })
            .catch((error) => {
              console.error(error);
              Sentry.captureException(error);

              setSubmitting(false);
            });
        }}
        validate={(values) => {
          const errors = {};

          const required = ['label', 'value', 'vehicleType'];

          // Make sure the required fields are there
          required.forEach((k) => {
            if (!values[k]) errors[k] = 'Verplicht';
          });

          return errors;
        }}
      >
        {(props) => (
          <CategoryForm
            {...props}
            locale={locale}
            onClickDelete={() => setShowDeleteModal(true)}
          />
        )}
      </Formik>
    );
  }

  function renderDeleteModal() {
    const close = () => setShowDeleteModal(false);

    const removeMalfunctionsCategory = () => {
      remove({ path: `malfunctions/categories/${category.id}` })
        .catch((err) => {
          setError(err);

          Sentry.captureException(
            new Error(`Something went wrong: /malfunctions/categories/${category.id} DELETE -> ${err}`),
          );
        })
        .then(() => history.push('/malfunction-form#categories'));
    };

    return (
      <Modal show={showDeleteModal} onHide={close}>
        <Modal.Header>
          <Modal.Title>Verwijderen categorie</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p style={{ padding: 15 }}>
            {`Weet u zeker dat u categorie: '${category.label}' wilt verwijderen?`}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={close}>Sluiten</Button>
          <Button bsStyle="danger" onClick={() => removeMalfunctionsCategory()}>Verwijderen</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <div className="well">
      <h2>
        {formatMessage(messages.category)}
        {' '}
        {category
          ? formatMessage(genericMessages.edit).toLowerCase()
          : formatMessage(genericMessages.add).toLowerCase()}
      </h2>

      {renderForm()}
      {renderDeleteModal()}
    </div>
  );
};

CategoryDetail.propTypes = {
  // Own props
  category: PropTypes.shape({}),
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),

  // react-intl props
  intl: IntlShape.isRequired,

  // State props
  categoryId: PropTypes.string,
  locale: PropTypes.string.isRequired,

  // Dispatch props
  get: PropTypes.func.isRequired,
  patch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  setMalfunctionsCategories: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  category: getCategoryByIdentifier(state, props),
  categoryId: selectIdentifier(state, props),
  locale: getLocale(state),
});

const mapDispatchToProps = {
  get,
  patch,
  post,
  remove,
  setMalfunctionsCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CategoryDetail));
