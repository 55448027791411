import PropTypes from 'prop-types';
import { path } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import { defineMessages, injectIntl } from 'react-intl';

import Broadcasts from './Broadcasts';
import ContentItems from './ContentItems';
import Folders from './Folders';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';

const messages = defineMessages({
  folders: {
    id: 'projects.arriva.app.modules.ArriVideo.components.Dashboard.folders',
    description: 'Folders label',
    defaultMessage: 'Folders',
  },
  broadcasts: {
    id: 'projects.arriva.app.modules.ArriVideo.components.Dashboard.broadcasts',
    description: 'Broadcasts label',
    defaultMessage: 'Broadcasts',
  },
});

const Dashboard = ({
  history,
  location,
  intl: { formatMessage },
}) => (
  <div className="well">
    <h2>ArriVideo</h2>

    <Tabs
      activeKey={location.hash || '#broadcasts'}
      onSelect={(key) => history.push(key)}
      id="arrivideo-dashboard-tabs"
    >
      <Tab eventKey="#folders" title={formatMessage(messages.folders)}>
        <Folders />
      </Tab>
      <Tab eventKey="#broadcasts" title={formatMessage(messages.broadcasts)}>
        <Broadcasts />
      </Tab>
      <Tab eventKey="#content" title="Content">
        <ContentItems />
      </Tab>
    </Tabs>
  </div>
);

Dashboard.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    hash: PropTypes.string,
  }),

  // react-intl props
  intl: IntlShape.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  activeKey: path(['location', 'hash'], ownProps),
});

export default connect(mapStateToProps)(injectIntl(Dashboard));
