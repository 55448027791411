import {
  SOFTWARE_PACKAGE_LOADING_PACKAGES,
  SOFTWARE_PACKAGE_LOADING_PACKAGES_DONE,
  SOFTWARE_PACKAGE_SET_PACKAGES,
} from './actions';

import { registerWithRootReducer } from '../../../../../app/modules/reducers/projects';

const ACTION_HANDLERS = {
  // Loaders
  [SOFTWARE_PACKAGE_LOADING_PACKAGES]: (state) => ({ ...state, loadingSoftwarePackages: true }),
  [SOFTWARE_PACKAGE_LOADING_PACKAGES_DONE]: (state) => ({
    ...state,
    loadingSoftwarePackages: false,
  }),

  // Adders / Setters
  [SOFTWARE_PACKAGE_SET_PACKAGES]: (state, action) => ({
    ...state,
    softwarePackages: action.payload,
  }),
};

const initialState = {
  softwarePackages: [],
  loadingSoftwarePackages: false,
};

registerWithRootReducer('softwarePackage', (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
});
