import React from 'react';
import Well from 'react-bootstrap/lib/Well';
import { defineMessages, injectIntl } from 'react-intl';
import Establishments from './Establishments';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';

const messages = defineMessages({
  establishments: {
    id: 'projects.arriva.app.modules.EmailAddressEstablishment.components.Dashboard.establishments',
    description: 'Email Address label.',
    defaultMessage: 'Establishments',
  },
});

const Dashboard = ({ intl: { formatMessage } }) => (
  <Well>
    <h2>{formatMessage(messages.establishments)}</h2>
    <Establishments />
  </Well>
);

Dashboard.propTypes = {
  // react-intl props
  intl: IntlShape.isRequired,
};

export default (injectIntl(Dashboard));
