import { createResource, deleteResource, updateResource } from 'redux-json-api';

export const ACTION_STORE = 'ACTION_STORE';
export const FORMS_SAVE = 'FORMS_SAVE';
export const FORMS_STORE_DATA = 'FORMS_STORE_DATA';
export const FORMS_STORE_NAME = 'FORMS_STORE_NAME';
export const FORMS_UPDATE_ORDER = 'FORMS_UPDATE_ORDER';
export const FORMS_ADD_ELEMENT = 'FORMS_ADD_ELEMENT';
export const FORMS_EDIT_ELEMENT = 'FORMS_EDIT_ELEMENT';
export const FORMS_DELETE_ELEMENT = 'FORMS_DELETE_ELEMENT';

/**
 * Add an element to the store
 */
export const addElement = element => async (dispatch, getState) => {
  if (!element) return;

  const { forms: { formData: { fields } } } = getState();
  fields.push(element);

  return dispatch({ type: FORMS_STORE_DATA, payload: fields });
};

/**
 * Delete an element from the store
 *
 * @param element
 * @returns {function(*, *)}
 */
export const deleteElement = element => async (dispatch, getState) => {
  if (!element) return;

  const { forms: { formData: { fields } } } = getState();
  fields.splice(fields.indexOf(element), 1);

  return dispatch({ type: FORMS_STORE_DATA, payload: fields });
};

/**
 * Update the order of the elements
 *
 * @param elements
 * @returns {function(*)}
 */
export const updateOrder = elements => async (dispatch) => {
  if (!elements) return;

  return dispatch({ type: FORMS_STORE_DATA, payload: elements });
};

/**
 * Load a form
 *
 * @param data
 * @returns {function(*)}
 */
export const loadForm = data => async (dispatch) => {
  if (data.name !== undefined) {
    dispatch({ type: FORMS_STORE_NAME, payload: data.name });
  }

  if (data.fields !== undefined) {
    dispatch({ type: FORMS_STORE_DATA, payload: data.fields });
  }
};

export const deleteForm = id => async (dispatch) => {
  if (id) {
    return dispatch(deleteResource({ type: 'form', id }));
  } else {
    return Promise.reject();
  }
};

export const saveForm = id => async (dispatch, getState) => {
  const {
    api: { endpoint: { axiosConfig: { baseURL } } },
    forms: { formData: { fields, name } },
    session: { user },
  } = getState();

  let resource = {};

  if (id) {
    resource = updateResource({
      type: 'form',
      id,
      attributes: {
        name,
        user: `${baseURL}/user/${user.id}`,
        data: {
          fields,
        },
      },
    });
  } else {
    resource = createResource({
      type: 'form',
      attributes: {
        name,
        user: `${baseURL}/user/${user.id}`,
        data: {
          fields,
        },
        actionemailSet: [],
        actionredirectSet: [],
        actionmessageSet: [],
        formsubmissionSet: [],
      },
    });
  }

  return dispatch(resource);
};

export const addFormSubmission = (data, formId) => async (dispatch, getState) => {
  const {
    api: { endpoint: { axiosConfig: { baseURL } } },
    session: { user },
  } = getState();

  return dispatch(createResource({
    type: 'form-submission',
    attributes: {
      data,
      user: `${baseURL}/user/${user.id}`,
      form: `${baseURL}/form/${formId}`,
    },
  }));
};

export const deleteFormSubmission = id => async (dispatch) => {
  if (id) {
    return dispatch(deleteResource({ type: 'form-submission', id }));
  } else {
    return Promise.reject();
  }
};

export const deleteBulkFormSubmission = ids => async (dispatch) => {
  const waitFor = [];

  ids.forEach(id => waitFor.push(dispatch(deleteFormSubmission(id))));

  return Promise.all(waitFor);
};

export const loadAction = data => async (dispatch) => {
  dispatch({ type: ACTION_STORE, payload: data });
};

export const resetAction = () => async (dispatch) => {
  dispatch({
    type: ACTION_STORE,
    payload: {
      id: null,
      type: '',
      data: {},
    },
  });
};

export const deleteAction = (id, type) => async (dispatch) => {
  if (id) {
    return dispatch(deleteResource({ type, id }));
  } else {
    return Promise.reject();
  }
};

export const saveAction = formId => async (dispatch, getState) => {
  const {
    api: { endpoint: { axiosConfig: { baseURL } } },
    forms: { actionData: { data, id, type } },
  } = getState();

  const typeEndpointMapper = {
    email: 'action-email',
    message: 'action-message',
    redirect: 'action-redirect',
  };

  let resource = {};

  if (id) {
    resource = updateResource({
      type: typeEndpointMapper[type],
      id,
      attributes: {
        ...data,
      },
    });
  } else {
    resource = createResource({
      type: typeEndpointMapper[type],
      attributes: {
        ...data,
        form: `${baseURL}/form/${formId}`,
      },
    });
  }

  return dispatch(resource);
};
