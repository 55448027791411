import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  ButtonToolbar,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Row,
} from 'react-bootstrap';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import genericMessages from '../../../../../../app/lib/genericMessages';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import { vState } from '../../../../../../app/lib/utils';

const messages = defineMessages({
  title: {
    id: 'projects.arriva.app.modules.SoftwarePackage.components.SoftwarePackageForm.title',
    description: 'Software Package title label',
    defaultMessage: 'Title',
  },
  information: {
    id: 'projects.arriva.app.modules.SoftwarePackage.components.SoftwarePackageForm.information',
    description: 'Software Package information label.',
    defaultMessage: 'Information',
  },
  softwarePackage: {
    id: 'projects.arriva.app.modules.SoftwarePackage.components.SoftwarePackageForm.softwarePackage',
    description: 'Software Package label.',
    defaultMessage: 'Software package',
  },
});

const SoftwarePackageForm = ({
  handleChange,
  handleSubmit,
  errors,
  isSubmitting,
  onClickDelete,
  values,
}) => (
  <Form horizontal onSubmit={handleSubmit}>
    <Row>
      <Col xs={12} md={8} lg={8}>
        <FormGroup controlId="code" validationState={vState(errors, 'code')}>
          <ControlLabel>Id</ControlLabel>

          <FormControl type="text" autoComplete="off" value={values.code} onChange={handleChange} />

          {errors.code && <HelpBlock>{errors.code}</HelpBlock>}
        </FormGroup>
        <FormGroup controlId="title" validationState={vState(errors, 'title')}>
          <ControlLabel>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <FormattedMessage {...messages.title} />
          </ControlLabel>

          <FormControl type="text" autoComplete="off" value={values.title} onChange={handleChange} />

          {errors.title && <HelpBlock>{errors.title}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId="tag" validationState={vState(errors, 'tag')}>
          <ControlLabel>Tag</ControlLabel>

          <FormControl type="text" autoComplete="off" value={values.tag} onChange={handleChange} />

          <HelpBlock style={{ fontStyle: 'italic', fontSize: 12 }}>
            E.g. format #tag1
          </HelpBlock>

          {errors.tag && <HelpBlock>{errors.tag}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId="information">
          <ControlLabel>
            <FormattedMessage {...messages.information} />
          </ControlLabel>

          <FormControl type="text" autoComplete="off" value={values.information} onChange={handleChange} />
        </FormGroup>

        <hr />

        <FormGroup controlId="formActions">
          <ButtonToolbar>
            {values.id && (
              <Button bsStyle="danger" onClick={() => onClickDelete()}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <FormattedMessage {...genericMessages.delete} />
              </Button>
            )}

            <Button type="submit" disabled={isSubmitting || Object.keys(errors).length > 0}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...genericMessages.save} />
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Col>
    </Row>
  </Form>
);

SoftwarePackageForm.propTypes = {
  // Own props
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClickDelete: PropTypes.func.isRequired,

  errors: PropTypes.shape({
    code: PropTypes.string,
    title: PropTypes.string,
    tag: PropTypes.string,
  }),

  values: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    title: PropTypes.string,
    tag: PropTypes.string,
    information: PropTypes.string,
  }).isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
  locale: PropTypes.string.isRequired,
};

// noinspection JSCheckFunctionSignatures
export default injectIntl(SoftwarePackageForm);
