import PropTypes from 'prop-types';
import { none } from 'ramda';
import React, { PureComponent } from 'react';

import Grid from 'react-bootstrap/lib/Grid';
import Panel from 'react-bootstrap/lib/Panel';
import { withRouter } from 'react-router-dom';

import FlashmessagesContainer from '../../../../app/components/Flashmessage';
import Management from '../../../../app/components/Management';

/* eslint-disable react/prefer-stateless-function */

class AppLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    Header: PropTypes.func,
    TopBar: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ error });
    // noinspection JSCheckFunctionSignatures
    Sentry.captureException(error, { extra: info });
  }

  render() {
    const {
      children, Header, TopBar, location,
    } = this.props;
    const { error } = this.state;

    // @TODO Quite hacky, find better solution for disabling the header on specific pages.
    const showHeader = none((it) => location.pathname.startsWith(it), [
      '/vehicle/status/',
      '/vehicle-wash-status/',
      '/malfunctions/open/',
      '/malfunctions/open-internal/',
      '/malfunctions/open-route/',
    ]);

    if (error !== null) {
      return (
        <div>
          <Panel
            header={<h3>Fout</h3>}
            bsStyle="danger"
          >
            Er is een fout opgetreden in de applicatie.
          </Panel>
        </div>
      );
    }

    return (
      <div>
        <FlashmessagesContainer />

        {showHeader && (
          <div>
            <Header />
            <TopBar />
          </div>
        )}
        <Grid fluid>
          {children}
        </Grid>
        <Management />
      </div>
    );
  }
}

export default withRouter(AppLayout);
