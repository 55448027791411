import { selectUnit } from '@formatjs/intl-utils';
import PropTypes from 'prop-types';
import { find, path, propEq } from 'ramda';
import React, { Component } from 'react';
import Button from 'react-bootstrap/lib/Button';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import { connect } from 'react-redux';
import { deleteResource, readEndpoint } from 'redux-json-api';
import { createSelector } from 'reselect';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import {
  FormattedMessage,
  FormattedRelativeTime,
  defineMessages,
  injectIntl,
} from 'react-intl';
import { LinkContainer } from 'react-router-bootstrap';

import getPropTypeScheme from '../../lib/PropTypes';
import IntlShape from '../../lib/PropTypes/IntlShape';
import { trunc, userToRepresentation } from '../../lib/utils';
import { fetchSecureFileAsBase64 } from '../../modules/helpers';
import { isAdmin, selectAccessToken, selectUserId as selectCurrentUserId } from '../../modules/selectors/session';
import Avatar from '../Avatar';

import './Comment.less';

const defaultAvatar = require(`../../../projects/${process.env.RAZZLE_PROJECT}/img/avatar-default.svg`); // eslint-disable-line

const messages = defineMessages({
  deleteComment: {
    id: 'app.components.Comment.deleteComment',
    description: 'Comment \'delete comment\' button label.',
    defaultMessage: 'Delete comment',
  },
});
class Comment extends Component {
  static propTypes = {
    // react-intl props
    intl: IntlShape.isRequired,

    // own props
    attributes: getPropTypeScheme('CommentAttributes'),
    id: PropTypes.string.isRequired,
    truncate: PropTypes.bool,

    // state props
    accessToken: PropTypes.string.isRequired,
    currentUserId: PropTypes.number.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    user: getPropTypeScheme('User'),
    userId: PropTypes.string.isRequired,

    // dispatch props
    deleteResource: PropTypes.func.isRequired,
    readEndpoint: PropTypes.func.isRequired,
  };

  static defaultProps = {
    truncate: false,
  };

  state = {
    loading: true,
    avatar: null,
  };

  componentWillMount() {
    const {
      accessToken,
      user, userId, readEndpoint,
    } = this.props;

    if (user === undefined) readEndpoint(`user/${userId}`)
      .then(() => this.setState({ loading: false }));
    if (user) this.setState({ loading: false });

    if (path(['attributes', 'avatar'], user)) {
      fetchSecureFileAsBase64(path(['attributes', 'avatar'], user), accessToken)
        .then(avatar => this.setState({ avatar }))
        .catch(error => console.error(`[OI] Comment: failed to fetch avatar: ${error}`));
    }
  }

  onClickDelete(event) {
    const { deleteResource, id } = this.props;

    event.stopPropagation();
    event.preventDefault();

    const resource = { type: 'comment', id };

    deleteResource(resource)
      .then(() => {
        console.log('[OI] Comment: deleteResource success!');
      })
      .catch((error) => {
        console.error(`[OI] Comment: deleteResource failed: ${error}`);
      });
  }

  render() {
    const {
      currentUserId,
      isAdmin,
      truncate,
      userId,
    } = this.props;
    const { avatar, loading } = this.state;

    if (loading) return null;

    const { attributes: { comment, created }, user } = this.props;
    const { username } = path(['attributes'], user);
    const canEdit = isAdmin || (currentUserId === Number(userId));

    let avatarSrc = defaultAvatar;

    if (avatar) avatarSrc = avatar;

    return (
      <div className="comment">
        <div className="comment-header">
          <LinkContainer to={`/profile/${username}`} style={{ cursor: 'pointer' }}>
            <div className="avatar">
              <div className="avatar-image">
                <Avatar image={{ src: avatarSrc }} />
              </div>
            </div>
          </LinkContainer>

          <LinkContainer to={`/profile/${username}`} style={{ cursor: 'pointer' }}>
            <div className="meta">
              <div className="author">
                {userToRepresentation(user)}
              </div>

              <div className="date">
                <Glyphicon bsClass="fa" glyph="clock-o" />

                &nbsp;

                <FormattedRelativeTime {...selectUnit(new Date(created))} />
              </div>
            </div>
          </LinkContainer>

          {canEdit && (
            <div className="options">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-file-add">
                    <FormattedMessage {...messages.deleteComment} />
                  </Tooltip>
                }
              >
                <Button bsSize="xsmall" onClick={event => this.onClickDelete(event)}>
                  <Glyphicon glyph="trash" />
                </Button>
              </OverlayTrigger>
            </div>
            )}
        </div>

        <div className="text">
          {truncate ? trunc(comment, 30) : comment}
        </div>
      </div>
    );
  }
}

const selectUsers = state => path(['api', 'user', 'data'], state) || [];
const selectUserId = (state, ownProps) => path(['relationships', 'user', 'data', 'id'], ownProps);
const selectUser = createSelector([selectUsers, selectUserId], (users, userId) => {
  if (userId === undefined) return false;
  return find(propEq('id', String(userId)))(users);
});

const mapStateToProps = (state, ownProps) => ({
  accessToken: selectAccessToken(state),
  currentUserId: selectCurrentUserId(state),
  isAdmin: isAdmin(state),
  user: selectUser(state, ownProps),
  userId: selectUserId(state, ownProps),
});

const mapDispatchToProps = {
  deleteResource,
  readEndpoint,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Comment));
