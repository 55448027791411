import { defineMessages } from 'react-intl';

const genericMessages = defineMessages({
  add: {
    id: 'app.lib.genericMessages.add',
    description: '\'Add\' label.',
    defaultMessage: 'Add',
  },
  addAttachment: {
    id: 'app.lib.genericMessages.addAttachment',
    description: 'Generic \'add attachment\' title.',
    defaultMessage: 'Add attachment',
  },
  article: {
    id: 'app.lib.genericMessages.article',
    description: '\'Article\' Label',
    defaultMessage: 'Article',
  },
  attachments: {
    id: 'app.lib.genericMessages.attachments',
    description: 'Generic \'attachments\' title.',
    defaultMessage: 'Attachments',
  },
  category: {
    id: 'app.lib.genericMessages.category',
    description: 'Category label.',
    defaultMessage: 'Category',
  },
  cancel: {
    id: 'app.lib.genericMessages.cancel',
    description: '\'Cancel\' label.',
    defaultMessage: 'Cancel',
  },
  close: {
    id: 'app.lib.genericMessages.close',
    description: '\'Close\' label.',
    defaultMessage: 'Close',
  },
  closeNotification: {
    id: 'app.lib.genericMessages.closeNotification',
    description: '\'Close Notification\' label.',
    defaultMessage: 'Close notification',
  },
  create: {
    id: 'app.lib.genericMessages.create',
    description: 'Create label',
    defaultMessage: 'create',
  },
  delete: {
    id: 'app.lib.genericMessages.delete',
    description: '\'Delete\' label.',
    defaultMessage: 'Delete',
  },
  deleteArticle: {
    id: 'app.lib.genericMessages.deleteArticle',
    description: '\'Delete article\' label.',
    defaultMessage: 'Delete article',
  },
  description: {
    id: 'app.lib.genericMessages.description',
    description: '\'Description\' label.',
    defaultMessage: 'Description',
  },
  edit: {
    id: 'app.lib.genericMessages.edit',
    description: '\'Edit\' label.',
    defaultMessage: 'Edit',
  },
  errorMessage: {
    id: 'app.lib.genericMessages.errorMessage',
    description: 'Error message',
    defaultMessage: 'Something went wrong',
  },
  errorFetchingAttachments: {
    id: 'app.lib.genericMessages.errorFetchingAttachments',
    description: 'Generic \'fetch attachments failed\' error message.',
    defaultMessage: 'Something went wrong while fetching the attachments',
  },
  field: {
    id: 'app.lib.genericMessages.field',
    description: 'Generic \'field\' title.',
    defaultMessage: 'Field',
  },
  fieldRequired: {
    id: 'app.lib.genericMessages.fieldRequired',
    description: 'Generic \'field required\' form validation message.',
    defaultMessage: 'This field is required.',
  },
  file: {
    id: 'app.lib.genericMessages.file',
    description: '\'File\' label.',
    defaultMessage: 'File',
  },
  forgotPassword: {
    id: 'app.lib.genericMessages.forgotPassword',
    description: '\'Forgot password\' label.',
    defaultMessage: 'Forgot password',
  },
  formSubmitLabel: {
    id: 'app.lib.genericMessages.formSubmitLabel',
    description: 'Generic \'form submit\' label.',
    defaultMessage: 'Send',
  },
  formSubmitFailed: {
    id: 'app.lib.genericMessages.formSubmitFailed',
    description: 'Generic \'form submit failed\' message.',
    defaultMessage: 'Something went wrong submitting the form. This incident has been reported.',
  },
  formNoName: {
    id: 'app.lib.genericMessages.formNoName',
    description: 'Generic \'form with no name\' title.',
    defaultMessage: 'Unnamed form',
  },
  formFieldSelect: {
    id: 'app.lib.genericMessages.formFieldSelect',
    description: 'Generic \'form field selectbox\' title.',
    defaultMessage: 'Form fields',
  },
  formSelect: {
    id: 'app.lib.genericMessages.formSelect',
    description: 'Generic \'forms selectbox\' title.',
    defaultMessage: 'Forms',
  },
  formSelectTooltip: {
    id: 'app.lib.genericMessages.formSelectTooltip',
    description: 'Generic \'forms selectbox \' tooltip.',
    defaultMessage: 'Insert form',
  },
  formFieldsSelectTooltip: {
    id: 'app.lib.genericMessages.formFieldsSelectTooltip',
    description: 'Generic \'form fields selectbox \' tooltip.',
    defaultMessage: 'Insert form field',
  },
  formRequiredField: {
    id: 'app.lib.genericMessages.formRequiredField',
    description: 'Generic \'required field\' label.',
    defaultMessage: 'This field is required',
  },
  group: {
    id: 'app.lib.genericMessages.group',
    description: '\'Group\' label.',
    defaultMessage: 'Group',
  },
  loadingInProgress: {
    id: 'app.lib.genericMessages.loadingInProgress',
    description: '\'Loading in progress\' label.',
    defaultMessage: 'Loading...',
  },
  login: {
    id: 'app.lib.genericMessages.login',
    description: '\'Login\' label.',
    defaultMessage: 'Login',
  },
  name: {
    id: 'app.lib.genericMessages.name',
    description: '\'Name\' label.',
    defaultMessage: 'Name',
  },
  no: {
    id: 'app.lib.genericMessages.no',
    description: 'No label.',
    defaultMessage: 'No',
  },
  post: {
    id: 'app.lib.genericMessages.post',
    description: '\'Post\' label.',
    defaultMessage: 'Post',
  },
  read: {
    id: 'app.lib.genericMessages.read',
    description: '\'Read\' label.',
    defaultMessage: 'Read',
  },
  required: {
    id: 'app.lib.genericMessages.required',
    description: 'Generic \'Required\' form validation message.',
    defaultMessage: 'Required',
  },
  selectFileButton: {
    id: 'app.lib.genericMessages.selectFileButton',
    description: 'Generic \'select file\' title.',
    defaultMessage: 'Select a file',
  },
  return: {
    id: 'app.lib.genericMessages.return',
    description: 'Generic \'return to\' title.',
    defaultMessage: 'Return',
  },
  save: {
    id: 'app.lib.genericMessages.save',
    description: '\'Save\' label.',
    defaultMessage: 'Save',
  },
  saveAndClose: {
    id: 'app.lib.genericMessages.saveAndClose',
    description: 'Generic \'save and close\' button title.',
    defaultMessage: 'Save and Close',
  },
  saveInProgress: {
    id: 'app.lib.genericMessages.saveInProgress',
    description: '\'saveInProgress\' label.',
    defaultMessage: 'Saving...',
  },
  title: {
    id: 'app.lib.genericMessages.title',
    description: '\'Title\' label.',
    defaultMessage: 'Title',
  },
  unknown: {
    id: 'app.lib.genericMessages.unknown',
    description: 'Generic \'unknown\' label.',
    defaultMessage: 'unknown',
  },
  user: {
    id: 'app.lib.genericMessages.user',
    description: 'Generic \'user\' label',
    defaultMessage: 'User',
  },
  yes: {
    id: 'app.lib.genericMessages.yes',
    description: 'Yes label.',
    defaultMessage: 'Yes',
  },
  upload: {
    id: 'app.lib.genericMessages.upload',
    description: 'Upload label.',
    defaultMessage: 'Upload',
  },
  back: {
    id: 'app.lib.genericMessages.back',
    description: 'Back label.',
    defaultMessage: 'Back',
  },
  saveAndSend: {
    id: 'app.lib.genericMessages.saveAndSend',
    descriptions: 'Save and send label',
    defaultMessage: 'Save and send',
  },
  from: {
    id: 'app.lib.genericMessages.from',
    description: 'From label',
    defaultMessage: 'From',
  },
  updatedAt: {
    id: 'app.lib.genericMessages.updatedAt',
    description: 'Updated at label',
    defaultMessage: 'Updated at',
  },
  nothingFound: {
    id: 'app.lib.genericMessages.nothingFound',
    description: 'nothing found message',
    defaultMessage: 'Nothing found!',
  },
  error: {
    id: 'app.lib.genericMessages.errorMessage',
    description: 'Error message',
    defaultMessage: 'Something went wrong',
  },
  information: {
    id: 'app.lib.genericMessages.information',
    description: 'Information label',
    defaultMessage: 'Information',
  },
});

export default genericMessages;
