import { intersection, filter, path, propEq, find, contains, without, sortBy, reverse } from 'ramda';
import { createSelector } from 'reselect';

import { selectGroups, selectGroupId } from './api';
import { isAdmin, selectUserId, selectGroupIdsFromCurrentUser } from './session';

export const selectArticles = state => path(['api', 'article', 'data'], state) || [];

export const selectArticlesByGroupIds = createSelector(
  [selectArticles, selectGroups],
  (articles, groups) => {
    const groupIds = groups.map(group => String(group.id));
    const isArticleInGroup = (article) => {
      // @TODO: A single article can only be in one group, but somehow
      // this function was written as it could belong to multiple.
      // Find out why, and if it needs to be.
      const articleGroups = ([path(['relationships', 'group', 'data'], article)] || []);
      if (!articleGroups.length)
        return false;
      const articleGroupIds = articleGroups.map(item => String(item.id));
      return intersection(groupIds, articleGroupIds).length > 0;
    };

    return reverse(sortBy(path(['attributes', 'created']), filter(isArticleInGroup)(articles)));
  },
);

export const selectArticlesByGroupIdsFromCurrentUser = createSelector(
  [selectGroupIdsFromCurrentUser, selectArticles],
  (groupIds, articles) => {
    const isArticleInGroups = (article) => {
      const articleGroupId = path(['relationships', 'group', 'data', 'id'], article);

      return contains(articleGroupId, groupIds);
    };

    return reverse(sortBy(path(['attributes', 'created']), filter(isArticleInGroups)(articles)));
  },
);

export const selectArticlesBySingleGroupId = createSelector(
  [selectArticles, selectGroupId],
  (articles, groupId) => {
    const isArticleInGroup = (article) => {
      const articleGroup = [path(['relationships', 'group', 'data'], article)] || [];
      return !!(find(propEq('id', String(groupId)))(articleGroup));
    };
    return filter(isArticleInGroup)(articles);
  },
);

export const selectArticleId = (state, article) => path(['id'], article) || null;

export const selectArticleById = createSelector(
  [selectArticles, selectArticleId],
  (articles, articleId) => {
    if (!articleId) return;
    if (articles.length === 0) return;

    return find(propEq('id', String(articleId)))(articles);
  },
);

export const canLoggedInUserEditArticle = createSelector(
  [isAdmin, selectUserId, selectGroupIdsFromCurrentUser, selectArticleById],
  (isAdmin, userId, userGroups, article) => {
    const isGroupMember = contains(
      path(['relationships', 'group', 'data', 'id'], article),
      without(String(2), userGroups),
    ) || false;
    const isArticleOwner = String(userId) === path(['relationships', 'user', 'data', 'id'], article);
    return isAdmin || isGroupMember || isArticleOwner;
  },
);
