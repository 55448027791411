import PropTypes from 'prop-types';

export const ArticleAttributes = PropTypes.shape({
  title: PropTypes.string,
  slug: PropTypes.string,
  content: PropTypes.string,
  // abstract: null,
  status: PropTypes.number,
  activateDate: PropTypes.string,
  deactivateDate: PropTypes.string,
  allowComments: PropTypes.bool,
  sticky: PropTypes.bool,
  hidden: PropTypes.bool,
  deleted: PropTypes.bool,
  created: PropTypes.string,
  modified: PropTypes.string,
  viewCount: PropTypes.number,
  numberOfComments: PropTypes.number,
  postAs: PropTypes.string,
});

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  attributes: ArticleAttributes,
});
