import {
  pipe, prop, sortBy, uniqBy,
} from 'ramda';

import {
  ARRIVIDEO_ADD_CONTENT_ITEMS,
  ARRIVIDEO_LOADING_BROADCASTS,
  ARRIVIDEO_LOADING_BROADCASTS_DONE,
  ARRIVIDEO_LOADING_CONTENT_ITEMS,
  ARRIVIDEO_LOADING_CONTENT_ITEMS_DONE,
  ARRIVIDEO_SET_BROADCASTS,
  ARRIVIDEO_SET_CONTENT_ITEMS,
  ARRIVIDEO_SET_FOLDERS,
  ARRIVIDEO_LOADING_FOLDERS,
  ARRIVIDEO_LOADING_FOLDERS_DONE,
} from './actions';

import { registerWithRootReducer } from '../../../../../app/modules/reducers/projects';

const ACTION_HANDLERS = {
  // Adders / Setters
  [ARRIVIDEO_ADD_CONTENT_ITEMS]: (state, action) => ({
    ...state,
    contentItems: pipe(
      uniqBy(prop('id')),
      sortBy(prop('order')),
    )([...state.contentItems || [], ...action.payload]),
  }),
  [ARRIVIDEO_SET_BROADCASTS]: (state, action) => ({
    ...state,
    broadcasts: action.payload,
  }),
  [ARRIVIDEO_SET_CONTENT_ITEMS]: (state, action) => ({
    ...state,
    contentItems: action.payload,
  }),
  [ARRIVIDEO_SET_FOLDERS]: (state, actions) => ({
    ...state,
    folders: actions.payload,
  }),

  // Loaders
  [ARRIVIDEO_LOADING_BROADCASTS]: (state) => ({ ...state, loadingBroadcasts: true }),
  [ARRIVIDEO_LOADING_BROADCASTS_DONE]: (state) => ({ ...state, loadingBroadcasts: false }),
  [ARRIVIDEO_LOADING_CONTENT_ITEMS]: (state) => ({ ...state, loadingContentItems: true }),
  [ARRIVIDEO_LOADING_CONTENT_ITEMS_DONE]: (state) => ({ ...state, loadingContentItems: false }),
  [ARRIVIDEO_LOADING_FOLDERS]: (state) => ({ ...state, loadingFolders: true }),
  [ARRIVIDEO_LOADING_FOLDERS_DONE]: (state) => ({ ...state, loadingFolders: false }),
};

const initialState = {
  broadcasts: [],
  contentItems: [],
  loadingBroadcasts: false,
  loadingContentItems: false,
  folders: [],
  loadingFolders: false,
};

registerWithRootReducer('arrivideo', (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
});
