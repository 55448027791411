import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { filter, path, uniqBy } from 'ramda';
import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { defineMessages, injectIntl } from 'react-intl';
import Masonry from 'react-masonry-component';
import { connect } from 'react-redux';
import { readEndpoint } from 'redux-json-api';

import getPropTypeScheme from '../../lib/PropTypes';
import IntlShape from '../../lib/PropTypes/IntlShape';
import Constants from '../../modules/constants';
import {
  selectArticlesByGroupIdsFromCurrentUser,
  selectArticlesBySingleGroupId,
} from '../../modules/selectors/article';
import { selectGroupIdsFromCurrentUser } from '../../modules/selectors/session';

import Loading from '../Loading';
import Modal from '../Modal';

import Article from './';
import ArticleDetail from './ArticleDetail';
import ArticleWrite from './ArticleWrite';

const messages = defineMessages({
  editArticleModalTitle: {
    id: 'app.components.Article.ArticleContainer.editArticleModalTitle',
    description: 'Article container \'edit article\' modal title.',
    defaultMessage: 'Edit article',
  },
});

class ArticleContainer extends Component {
  static propTypes = {
    // react-intl props
    intl: IntlShape.isRequired,

    // own props
    id: PropTypes.string,
    type: PropTypes.string,
    groupId: PropTypes.string,
    groupIdNext: PropTypes.string,

    // state props
    articles: PropTypes.arrayOf(getPropTypeScheme('Article')).isRequired,
    groupIds: PropTypes.arrayOf(PropTypes.string).isRequired,

    // dispatch props
    readEndpoint: PropTypes.func.isRequired,
  };

  static renderSpacer() {
    return (
      <Col
        xs={12}
        sm={12}
        md={6}
        lg={3}
        key="grid-sizer"
        className="grid-sizer"
        style={{ position: 'absolute' }}
      />
    );
  }

  constructor() {
    super();

    this.state = {
      loading: true,
      page: 1,
      articlePages: 0,
      articles: [],
      modalArticle: null,
      modalEditArticle: null,
    };

    this.onArticlesRendered = debounce(this.onArticlesRendered, 500);
    this.setModalData = this.setModalData.bind(this);
  }

  componentWillMount() {
    this.getArticles(this.props.groupId || false);
  }

  componentWillReceiveProps(nextProps) {
    const { groupId } = this.props;
    const { groupIdNext } = nextProps;

    if (groupIdNext && groupId !== groupIdNext) {
      this.setState({ page: 1, loading: true });
      this.getArticles(groupIdNext);
    }
  }

  onArticlesRendered() {
    this.masonry.layout();
  }

  onLoadMore() {
    this.setState({ loading: true });
    this.getArticles(this.props.groupId, this.state.page + 1);
  }

  setModalData(type, props) {
    this.setState({ [type]: props }); // eslint-disable-line
  }

  getArticles = debounce((inGroupId, page) => {
    const { articlePages, articles } = this.state;
    const {
      groupId, type,
      groupIds,
      readEndpoint,
    } = this.props;

    const articleGroupId = inGroupId || groupId;

    if (!articleGroupId)
      return null;

    this.forceMasonryUpdate();

    let pageQuery = '';

    const pageNum = !page ? 1 : page;

    if (pageNum > 1 && pageNum <= articlePages)
      pageQuery = `&page=${pageNum}`;

    let filterString = `page_size=${Constants.ARTICLE_PAGE_SIZE}&order[created]=DESC&include=articleType`;

    if (type)
      filterString += `&filter[article_type_id__exact]=${type}`;

    if (articleGroupId && articleGroupId !== 'all' && !type)
      filterString += `&filter[group__exact]=${articleGroupId}`;
    else if (articleGroupId === 'all' &&
      groupIds.length > 0 &&
      type !== `${Constants.ARTICLE_TYPE_HOSPITALITY}`) {
      filterString += `&filter[group__in]=${groupIds.join(',')}`;
    }

    // by default exclude ARTICLE_TYPE_PAGE from article list
    filterString += `&exclude[article_type_id__exact]=${Constants.ARTICLE_TYPE_PAGE}`;

    readEndpoint(`article?${filterString}${pageQuery}`)
      .then((response) => {
        const pages = path(['body', 'meta', 'pagination', 'pages'], response) || 0;

        let articleList = path(['body', 'data'], response) || [];

        if (articles.length > 0)
          articleList = uniqBy(item => item.id, [...articles, ...articleList]);

        this.setState({
          articlePages: pages,
          articles: articleList,
          modalArticle: null,
          loading: false,
          page: pageNum,
        });
      })
      .catch((error) => {
        Sentry.captureException(error);

        this.setState({
          articlePages: 0,
          articles: [],
          loading: false,
        });
      });
  }, 300);

  forceMasonryUpdate() {
    const setMasonryLayout = setInterval(() => {
      if (this.masonry)
        this.masonry.layout();
    }, 500);
    setTimeout(() => clearInterval(setMasonryLayout), 5000);
  }

  renderModalEditArticle() {
    const { modalEditArticle } = this.state;
    const { intl: { formatMessage } } = this.props;

    if (!modalEditArticle) return false;

    return (
      <Modal
        title={formatMessage(messages.editArticleModalTitle)}
        onHide={() => this.setModalData('modalEditArticle', false)}
      >
        <ArticleWrite initialValues={modalEditArticle} setModalData={this.setModalData} />
      </Modal>
    );
  }

  renderModalArticle() {
    const { modalArticle } = this.state;

    if (!modalArticle) return false;

    const title = path(['attributes', 'title'], modalArticle);
    const articleTypeId = path(['relationships', 'articleType', 'data', 'id'], modalArticle);
    const isFacebook = articleTypeId === Constants.ARTICLE_TYPE_FACEBOOK;

    return (
      <Modal
        isFacebook={isFacebook}
        title={title}
        onHide={() => this.setModalData('modalArticle', false)}
      >
        <ArticleDetail
          setModalData={this.setModalData}
          {...modalArticle}
        />
      </Modal>
    );
  }

  renderArticle(props) {
    return (
      <Col xs={12} sm={12} md={6} lg={6} key={props.id} className="item">
        <Article
          {...props}
          setModalData={this.setModalData}
          onRendered={() => this.onArticlesRendered()}
        />
      </Col>
    );
  }

  render() {
    const { id } = this.props;
    const {
      articles,
      loading,
      page,
    } = this.state;

    const masonryOptions = {
      horizontalOrder: true,
      transitionDuration: 0,
      columnWidth: '.grid-sizer',
      itemSelector: '.item',
    };

    const hasMore = this.state.articlePages > page;

    const isNotAPage = a => path(['relationships', 'articleType', 'data', 'id'], a) !== String(Constants.ARTICLE_TYPE_PAGE);
    const filteredArticles = filter(isNotAPage)(articles);

    return (
      <div id={id}>
        {filteredArticles.length > 0 ?
          <InfiniteScroll
            loadMore={() => this.onLoadMore()}
            hasMore={hasMore}
            initialLoad={false}
            loader={<span key="loader-span" />}
          >
            <Masonry
              ref={(c) => {
                this.masonry = this.masonry || c.masonry;
              }}
              className="article-container"
              options={masonryOptions}
            >
              {this.constructor.renderSpacer()}
              {filteredArticles.map(props => this.renderArticle(props))}
            </Masonry>
          </InfiniteScroll> : null}
        {loading ?
          <div className="infinite-loading">
            <Loading />
          </div> : null}
        {this.renderModalArticle()}
        {this.renderModalEditArticle()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  articles: (ownProps.groupId && ownProps.groupId !== 'all') ?
    selectArticlesBySingleGroupId(state, ownProps.groupId) :
    selectArticlesByGroupIdsFromCurrentUser(state),
  groupIds: selectGroupIdsFromCurrentUser(state),
});

const mapDispatchToProps = {
  readEndpoint,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ArticleContainer));
