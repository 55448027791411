/**
 * This file does not use import/export syntax since it is required by code
 * running on bare-bones Node v6.
 */
module.exports = {
  // Supported locales, ordered by priority from high to low.
  defaultLocale: 'en',
  supportedLocales: ['nl', 'en'],

  localeMap: {
    en: 'en-US',
    nl: 'nl-NL',
  },

  // Locale names to Summernote language names.
  localeMapSummernote: {
    en: undefined,
    nl: 'nl-NL',
  },

  localeNames: {
    en: 'English',
    nl: 'Nederlands',
  },

  // Openintranet settings
  oi: {
    favicon: require('./img/favicon.ico'),
    title: 'Arriva Portal',
  },
};
