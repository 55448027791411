import Cropper from 'cropperjs';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { Button, Modal } from 'react-bootstrap';

import 'cropperjs/src/css/cropper.scss';

class Base64ImageCropper extends Component {
  constructor(props) {
    super(props);

    this.imageCropAreaRef = React.createRef();
  }

  componentDidMount() {
    const { aspectRatio } = this.props;

    if (!this.imageCropAreaRef) {
      console.error('Base64ImageCropper.componentDidMount() no `imageCropArea` ref');
      return;
    }

    // eslint-disable-next-line react/no-find-dom-node
    const image = ReactDOM.findDOMNode(this.imageCropAreaRef.current);

    if (!image) {
      console.error('Base64ImageCropper.componentDidMount() no `imageCropArea` DOM node');
      return;
    }

    this.cropper = new Cropper(image, {
      aspectRatio,
    });
  }

  save() {
    const {
      data, imageSmoothingEnabled, imageSmoothingQuality, onSave, scale = [],
    } = this.props;

    const {
      x, y, width, height,
    } = this.cropper.getData(true);

    const image = new Image();
    const canvas = document.getElementById('drawingBoard');

    image.src = data;

    image.onload = () => {
      canvas.width = scale && scale[0] ? scale[0] : width;
      canvas.height = scale && scale[1] ? scale[1] : height;

      const ctx = canvas.getContext('2d');
      ctx.imageSmoothingEnabled = imageSmoothingEnabled;
      if (imageSmoothingEnabled) {
        ctx.imageSmoothingQuality = imageSmoothingQuality;
      }

      ctx.drawImage(image,
        x, y, // Start at x/y pixels from the left and the top of the image (crop),
        width, height, // "Get" a `height * width` (h * w) area from the source image (crop),
        0, 0, // Place the result at 0, 0 in the canvas,
        canvas.width, canvas.height, // With as width / height: 100 * 100 (scale)
      );

      onSave(canvas.toDataURL('image/png'));
    };
  }

  render() {
    const { data, onHide, onSave } = this.props;

    return (
      <div>
        <canvas id="drawingBoard" style={{ display: 'none' }} />

        <Modal show onHide={onHide} bsSize="large">
          <Modal.Header>
            <Modal.Title>Bijsnijden</Modal.Title>
          </Modal.Header>

          <div>
            <img
              ref={this.imageCropAreaRef}
              height={350}
              src={data}
              alt="Crop area"
            />
          </div>

          <Modal.Footer>
            <Button onClick={() => onHide()}>Sluiten</Button>
            <Button onClick={() => onSave(data)}>Ga verder zonder bijsnijden</Button>
            <Button bsStyle="primary" onClick={() => this.save()}>Opslaan</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

Base64ImageCropper.defaultProps = {
  aspectRatio: 1,
  imageSmoothingEnabled: true,
  imageSmoothingQuality: 'high',
};

Base64ImageCropper.propTypes = {
  // Own props
  aspectRatio: PropTypes.number,
  data: PropTypes.string,
  imageSmoothingEnabled: PropTypes.bool,
  imageSmoothingQuality: PropTypes.oneOf(['low', 'medium', 'high']),
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  scale: PropTypes.arrayOf(PropTypes.number),
};

export default Base64ImageCropper;
