/* eslint-disable */
import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import '../../../../../../node_modules/react-day-picker/lib/style.css';

import HeaderBar from '../header-bar';
import { myxss } from '../form-elements';


class DatePicker extends Component {
  static propTypes = {
    _onDestroy: PropTypes.func,
    data: PropTypes.shape({
      field_name: PropTypes.string.isRequired,
      label: PropTypes.string,
      pageBreakBefore: PropTypes.bool,
      inline: PropTypes.bool,
    }),
    defaultValue: PropTypes.shape({}),
    editModeOn: PropTypes.func,
    error: PropTypes.string,
    parent: PropTypes.shape({}),
    mutable: PropTypes.bool,
    onEdit: PropTypes.func,
    readOnly: PropTypes.bool,
    handleChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: null,
      internalValue: null,
      placeholder: 'DD-MM-YYYY',
      defaultToday: path(['data', 'defaultToday'], props) || true
    };
  }


  componentWillMount() {
    const { data, defaultValue, handleChange } = this.props;

    let value = null;
    let internalValue = null;

    if (data.defaultToday && (defaultValue === '' || defaultValue === undefined) ) {
      value = moment().format('DD-MM-YYYY');
      internalValue = moment();

      if (typeof handleChange === 'function') handleChange(data.field_name, value);
    } else {
      value = defaultValue;

      if (defaultValue !== '' && defaultValue !== undefined) {
        internalValue = moment(value, 'DD-MM-YYYY');
      }
    }

    this.setState({
      value: value,
      internalValue: internalValue,
    });
  }

  handleChange(dt) {
    const { data, handleChange } = this.props;

    let value, internalValue;
    const placeholder = (dt && dt.target && dt.target.value === '') ? 'DD-MM-YYYY': '';
    const formattedDate = (dt && dt.target && dt.target.value) ? moment(dt.target.value).format('YYYY-MM-DD') : '';

    if (dt && dt.target) {
      value = formattedDate;
      internalValue = formattedDate;
    } else {
      value = (dt) ? dt.format('DD-MM-YYYY') : '';
      internalValue = dt;
    }

    this.setState({ value, internalValue, placeholder });

    handleChange(data.field_name, value);
  }

  componentWillReceiveProps() {
    const { data } = this.props;
    const { defaultToday, value } = this.state;

    if (data.defaultToday && !defaultToday) {
      this.setState({
        value: moment().format('DD-MM-YYYY'),
        intervalValue: moment(value),
      });
    } else if (!data.defaultToday && defaultToday) {
      this.setState({
        value: '',
        internalValue: undefined,
      });
    }

    this.setState({ defaultToday: data.defaultToday });
  }

  render() {
    const {
      defaultValue,
      data,
      mutable,
      readOnly,
      editModeOn,
      _onDestroy,
      onEdit,
      parent,
      error,
    } = this.props;

    let props = {};
    props.type = 'date';
    props.className = 'form-control';
    props.name = data.field_name;

    if (mutable) {
      props.defaultValue = defaultValue;
      props.ref = `child_ref_${data.field_name}`;
    }

    if (readOnly) {
      props.disabled = 'disabled';
    }

    let baseClasses = 'rfb-item';
    if (data.pageBreakBefore) baseClasses += ' alwaysbreak';

    if (error) baseClasses += ' error';

    return (
      <div className={baseClasses}>
        {!mutable &&
          <div>
            {data.pageBreakBefore && <div className="preview-page-break">Page Break</div>}

            <HeaderBar
              parent={parent}
              editModeOn={editModeOn}
              data={data}
              onDestroy={_onDestroy}
              onEdit={onEdit}
              static={data.static}
              required={data.required}
            />
          </div>
        }

        <div className="form-group">
          <label>
            <span dangerouslySetInnerHTML={{__html: myxss.process(data.label) }} />

            {(data.hasOwnProperty("required") && data.required === true && !readOnly) &&
              <span className="label-required label label-danger">Verplicht</span>
            }
          </label>
          <div>
            {data.readOnly &&
              <input
                type="text"
                name={props.name}
                ref={props.ref}
                readOnly="true"
                dateFormat="DD-MM-YYYY"
                placeholder={this.state.placeholder}
                value={this.state.value}
                className="form-control"
              />
            }

            {!data.readOnly &&
              <DayPickerInput
                name={props.name}
                ref={props.ref}
                placeholder="DD-MM-YYYY"
                format="DD-MM-YYYY"
                value={this.state.value}
                onDayChange={this.handleChange.bind(this)}
              />
            }
          </div>

          {error && <div className="form-group-error">{error}</div>}
        </div>
      </div>
    );
  }
}

const getSubmissionValue = field => field.value;

export {
  DatePicker as default,
  getSubmissionValue,
};
