import { path } from 'ramda';

export const trunc = (string, length) =>
  (string.length > length ? `${string.substr(0, length - 1)}...` : string);

export const slugify = text =>
  text.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

export const userToRepresentation = (user) => {
  if (!user || !user.attributes)
    return '';

  if (user.attributes.displayName)
    return user.attributes.displayName;

  const _names = [user.attributes.firstName];

  if (user.attributes.infix)
    _names.push(user.attributes.infix);

  _names.push(user.attributes.lastName);

  return _names.join(' ');
};

/**
 * Helper for returning the validation state for a field 'key'. This way, we
 * can use Formik errors to determine the `validationState` property of
 * react-bootstrap `FormGroup`s.
 *
 * @param {Object|undefined} errors The Formik errors object.
 * @param {String} key The field to look up an error for.
 * @returns {String} The FormGroup validationState.
 */
export const vState = (errors, key) => {
  if (errors && errors[key]) return 'error';

  return 'success';
};

/**
 * Helper for parsing API responses to per-field error messages.
 *
 * @param {Object|undefined} response The JSON API error response.
 * @returns {Object} Object with fields as keys and messages as values.
 */
const fieldRegex = /^\/data\/attributes\/([A-Za-z0-9-_]+)$/;
export const parseFieldErrors = (response) => {
  const errors = path(['data', 'errors'], response);
  const ret = { fieldErrors: {}, genericErrors: [] };

  if (!errors || errors.length === 0) return;

  errors.forEach(({ detail, source }) => {
    if (source && source.pointer) {
      const matches = fieldRegex.exec(source.pointer);

      if (matches && matches.length === 2) {
        ret.fieldErrors[matches[1]] = detail;
        return;
      }
    }

    ret.genericErrors.push(detail);
  });

  if ((Object.keys(ret.fieldErrors).length + ret.genericErrors.length) > 0) {
    return ret;
  }
};

/**
 * Mutex that will help by locking resources
 * This Mutex will hold until the Lock has been unlocked
 *
 * @constructor
 */
export function Mutex() {
  let current = Promise.resolve();

  this.lock = () => {
    let _resolve;
    const p = new Promise((resolve) => {
      _resolve = () => resolve();
    });
    // Caller gets a promise that resolves when the current outstanding
    // lock resolves
    const rv = current.then(() => _resolve);
    // Don't allow the next request until the new promise is done
    current = p;
    // Return the new promise
    return rv;
  };
}
