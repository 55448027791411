/* eslint-disable */
import React, { Component } from 'react';
import SignaturePad from 'react-signature-pad';

import HeaderBar from '../header-bar';
import { myxss } from '../form-elements';


export default class Signature extends Component {
  componentDidMount() {
    const { data, defaultValue, readOnly } = this.props;

    if (defaultValue !== undefined && defaultValue.length > 0 && !readOnly) {
      let canvas = this.refs[`canvas_${data.field_name}`];
      canvas.fromDataURL('data:image/png;base64,' + defaultValue);
    }
  }


  render() {
    let props = {};
    props.type = "hidden";
    props.name = this.props.data.field_name;

    if (this.props.mutable) {
      props.defaultValue = this.props.defaultValue;
      props.ref = "child_ref_" + this.props.data.field_name;
    }
    let pad_props = {};
    pad_props.clearButton = true;
    if (this.props.mutable) {
      pad_props.defaultValue = this.props.defaultValue;
      pad_props.ref = 'canvas_'+this.props.data.field_name;
    }

    let baseClasses = 'rfb-item';
    if (this.props.data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }

    let sourceDataURL;
    if (this.props.readOnly === true && this.props.defaultValue && this.props.defaultValue.length > 0) {
      sourceDataURL = `data:image/png;base64,${this.props.defaultValue}`
    }

    return (
      <div className={baseClasses}>
        { !this.props.mutable &&
        <div>
          { this.props.data.pageBreakBefore &&
          <div className="preview-page-break">Page Break</div>
          }
          <HeaderBar parent={this.props.parent} editModeOn={this.props.editModeOn} data={this.props.data} onDestroy={this.props._onDestroy} onEdit={this.props.onEdit} static={this.props.data.static} required={this.props.data.required} />
        </div>
        }
        <div className="form-group">
          <label>
            <span dangerouslySetInnerHTML={{__html: myxss.process(this.props.data.label) }} />
            { (this.props.data.hasOwnProperty('required') && this.props.data.required === true  && !this.props.readOnly) &&
            <span className="label-required label label-danger">Verplicht</span>
            }
          </label>
          {this.props.readOnly === true && this.props.defaultValue && this.props.defaultValue.length > 0
            ? (<div><img alt="signature" src={sourceDataURL} /></div>)
            : (<SignaturePad {...pad_props} />)
          }
          <input {...props} />
        </div>
      </div>
    );
  }
}
