(function($) {
  $.extend(true, $.summernote.lang['nl-NL'], {
    videoAttributes: {
      dialogTitle: 'Videoeigenschappen',
      tooltip: 'Videoeigenschappen',
      pluginTitle: 'Videoeigenschappen',
      href: 'URL van de video',
      videoSize: 'Videoafmetingen',
      videoOption0: 'Responsief',
      videoOption1: '1280x720',
      videoOption2: '853x480',
      videoOption3: '640x360',
      videoOption4: '560x315',
      alignment: 'Uitlijning',
      alignmentOption0: 'Geen',
      alignmentOption1: 'Links',
      alignmentOption2: 'Rechts',
      alignmentOption3: 'Initieël',
      alignmentOption4: 'Overerven',
      suggested: 'Suggesties tonen na tonen video',
      controls: 'Afspeelbediening weergeven',
      autoplay: 'Automatisch afspelen',
      loop: 'Herhalen',
      note: 'Opmerking: niet alle opties zijn mogelijk bij alle diensten.',
      ok: 'OK'
    }
  });
})(jQuery);
