import { selectUnit } from '@formatjs/intl-utils';
import PropTypes from 'prop-types';
import { find, path, propEq } from 'ramda';
import React, { Component } from 'react';
import { FormattedRelativeTime, defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { readEndpoint } from 'redux-json-api';
import { createSelector } from 'reselect';

import getPropTypeScheme from '../../lib/PropTypes';
import IntlShape from '../../lib/PropTypes/IntlShape';
import { userToRepresentation } from '../../lib/utils';
import Constants from '../../modules/constants';
import AlbertusConstants from '../../../projects/albertus/app/constants';
import { selectAccessToken } from '../../modules/selectors/session';

import Avatar from '../Avatar';

import './Author.less';

const defaultAvatar = require(`../../../projects/${process.env.RAZZLE_PROJECT}/img/avatar-default.svg`); // eslint-disable-line
const defaultGroupIcon = require(`../../../projects/${process.env.RAZZLE_PROJECT}/img/group-icon-default.png`); // eslint-disable-line

const messages = defineMessages({
  authorNameUnknown: {
    id: 'app.components.Author.authorNameUnknown',
    description: 'Author name unknown.',
    defaultMessage: 'Unknown',
  },
});

class Author extends Component {
  static propTypes = {
    // react-intl props
    intl: IntlShape.isRequired,

    // own props
    attributes: getPropTypeScheme('ArticleAttributes'),

    // state props
    group: getPropTypeScheme('Group'),
    groupId: PropTypes.string,
    user: getPropTypeScheme('User'),
    userId: PropTypes.string,

    // dispatch props
    readEndpoint: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
    icon: null,
  };

  componentWillMount() {
    const {
      group, groupId, user, userId, readEndpoint,
    } = this.props;

    const waitFor = [];

    if (group === undefined) waitFor.push(readEndpoint(`group/${groupId}`));
    if (user === undefined) waitFor.push(readEndpoint(`user/${userId}`));

    Promise.all(waitFor).then(() => {
      this.setState({ loading: false });
      this.fetchIcon();
    });
  }

  fetchIcon() {
    const {
      attributes: { postAs }, group, user,
    } = this.props;

    switch (postAs) {
      case Constants.ARTICLE_POST_AS_USER: {
        const { avatar } = path(['attributes'], user);

        this.setState({ icon: { src: avatar || defaultAvatar } });

        break;
      }
      case Constants.ARTICLE_POST_AS_GROUP:
      default: {
        const { icon } = path(['attributes'], group) || {};

        this.setState({ icon: { url: icon || defaultGroupIcon } });
      }
    }
  }

  renderUser() {
    const {
      attributes: { created },
      user: { attributes: { username } },
    } = this.props;
    const { icon } = this.state;

    const isFacebook = username === Constants.ARTICLE_BY_FACEBOOK;

    const authorContent = (
      <div className="author-block">
        <div className="avatar">
          {icon ? <Avatar image={icon} /> : null}
        </div>

        <div className="group">
          {isFacebook ? AlbertusConstants.FB_USERNAME : userToRepresentation(this.props.user)}

          <br />

          <FormattedRelativeTime {...selectUnit(new Date(created))} />
        </div>
      </div>
    );

    if (isFacebook) {
      return (
        <a className="a-no-hover-underline" style={{ cursor: 'pointer' }}>
          {authorContent}
        </a>
      );
    } else {
      return (
        <LinkContainer to={`/profile/${username}`} style={{ cursor: 'pointer' }}>
          {authorContent}
        </LinkContainer>
      );
    }
  }

  render() {
    const {
      intl: { formatMessage },
      attributes: { created, postAs }, group, user,
    } = this.props;
    const { loading, icon } = this.state;

    if (loading) return null;

    let authorName = formatMessage(messages.authorNameUnknown);

    if (postAs === Constants.ARTICLE_POST_AS_USER && path(['attributes', 'username'], user))
      return this.renderUser();
    else if (postAs === Constants.ARTICLE_POST_AS_GROUP)
      authorName = path(['attributes', 'name'], group) || authorName;

    return (
      <div className="author-block" style={{ cursor: 'pointer' }}>
        <div className="avatar">
          {icon ? <Avatar image={icon} /> : null}
        </div>

        <div className="group">
          {authorName}

          <br />

          <FormattedRelativeTime {...selectUnit(new Date(created))} />
        </div>
      </div>
    );
  }
}

const selectGroups = state => path(['api', 'group', 'data'], state) || [];
const selectGroupId = (state, ownProps) => path(['relationships', 'group', 'data', 'id'], ownProps);
const selectGroup = createSelector([selectGroups, selectGroupId], (groups, groupId) => {
  if (groupId === undefined) return null;
  return find(propEq('id', String(groupId)))(groups);
});

const selectUsers = state => path(['api', 'user', 'data'], state) || [];
const selectUserId = (state, ownProps) => path(['relationships', 'user', 'data', 'id'], ownProps);
const selectUser = createSelector([selectUsers, selectUserId], (users, userId) => {
  if (userId === undefined) return null;
  return find(propEq('id', String(userId)))(users);
});

const mapStateToProps = (state, ownProps) => ({
  group: selectGroup(state, ownProps),
  groupId: selectGroupId(state, ownProps),
  user: selectUser(state, ownProps),
  userId: selectUserId(state, ownProps),
  accessToken: selectAccessToken(state),
});

const mapDispatchToProps = {
  readEndpoint,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Author));
