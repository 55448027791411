import PropTypes from 'prop-types';
import { head, propEq, reject } from 'ramda';
import React, { Component } from 'react';
import Button from 'react-bootstrap/lib/Button';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { readEndpoint } from 'redux-json-api';

import IntlShape from '../../../../lib/PropTypes/IntlShape';
import genericMessages from '../../../../lib/genericMessages';
import FileCard from '../../../MediaLibrary/FileCard';
import SelectFileModal from '../../../MediaLibrary/SelectFileModal';
import Modal from '../../../Modal';
import HeaderBar from '../header-bar';

const messages = defineMessages({
  pageBreakLabel: {
    id: 'app.components.Forms.FormBuilder.elements.Upload.pageBreakLabel',
    description: 'Form Upload \'page break\' label.',
    defaultMessage: 'Page break',
  },
  noFilesUploaded: {
    id: 'app.components.Forms.FormBuilder.elements.Upload.noFilesUploaded',
    description: 'Form Upload \'no files uploaded\' text.',
    defaultMessage: 'No file(s) uploaded',
  },
});

class Upload extends Component {
  static propTypes = {
    // react-intl props
    intl: IntlShape.isRequired,

    _onDestroy: PropTypes.func,
    data: PropTypes.shape({
      field_name: PropTypes.string.isRequired,
      label: PropTypes.string,
      pageBreakBefore: PropTypes.bool,
      inline: PropTypes.bool,
      static: PropTypes.bool,
      required: PropTypes.bool,
    }),
    editModeOn: PropTypes.func,
    parent: PropTypes.shape({}),
    mutable: PropTypes.bool,
    onEdit: PropTypes.func,
    readOnly: PropTypes.bool,
    handleChange: PropTypes.func,
    error: PropTypes.string,

    // dispatch props.
    readEndpoint: PropTypes.func.isRequired,
  };

  state = {
    files: [],
  };

  fetchFile(fileId) {
    const { readEndpoint } = this.props;

    return readEndpoint(`file?filter[pk__exact]=${fileId}`)
      .then(({ body: { data: file } }) => {
        if (file && file.length > 0)
          return head(file);
      });
  }

  renderSelectFileModal() {
    const {
      intl: { formatMessage },
      data,
      handleChange,
    } = this.props;
    const { showSelectFileModal } = this.state;

    if (!showSelectFileModal) return null;

    return (
      <Modal
        title={formatMessage(genericMessages.selectFileButton)}
        onHide={() => this.setState({ showSelectFileModal: false })}
      >
        <SelectFileModal
          id="folder-actions"
          onSuccess={async (file) => {
            const { files } = this.state;
            const fetchedFile = await this.fetchFile(file.id);
            const newFiles = [...files, fetchedFile];

            this.setState({ files: newFiles });

            handleChange(data.field_name, newFiles);
          }}
        />
      </Modal>
    );
  }

  render() {
    const {
      data,
      error,
      mutable,
      parent,
      editModeOn,
      _onDestroy,
      onEdit,
      readOnly,
    } = this.props;

    const { files } = this.state;

    let baseClasses = 'rfb-item';
    if (data.pageBreakBefore) baseClasses += ' alwaysbreak';

    return (
      <div className={baseClasses}>
        {this.renderSelectFileModal()}

        {!mutable &&
          <div>
            {data.pageBreakBefore &&
              <div className="preview-page-break">
                <FormattedMessage {...messages.pageBreakLabel} />
              </div>
            }
            <HeaderBar
              parent={parent}
              editModeOn={editModeOn}
              data={data}
              onDestroy={_onDestroy}
              onEdit={onEdit}
              static={data.static}
              required={data.required}
            />
          </div>
        }

        <div className="form-group">
          <label htmlFor="image-upload-container">
            {data.label}
            {(data.hasOwnProperty('required') && data.required && !readOnly) &&
              <span className="label-required label label-danger">
                <FormattedMessage {...genericMessages.required} />
              </span>
            }
          </label>

          <div className="image-upload-container" id="image-upload-container">
            {files && files.length > 0 && files.map(file => (
              <FileCard
                key={`file-${file.id}`}
                file={file}
                onFileDeleted={(file) => {
                  this.setState({ files: reject(propEq('id', file.id), files) });
                }}
                canDelete
                isCompact
              />
            ))}

            <div>
              <div className="image-upload-control">
                <Button bsSize="small" bsStyle="info" onClick={() => this.setState({ showSelectFileModal: true })}>
                  <Glyphicon glyph="upload" bsClass="fa" /> <FormattedMessage {...genericMessages.upload} />
                </Button>
              </div>
            </div>
          </div>

          {error && <div className="form-group-error">{error}</div>}
        </div>
      </div>
    );
  }
}

const getSubmissionValue = (field) => {
  const files = field.value;

  if (!files)
    return <FormattedMessage {...messages.noFilesUploaded} />;

  return files.map(file => (
    <FileCard
      key={`file-${file.id}`}
      file={file}
      isCompact
    />
  ));
};

const mapDispatchToProps = {
  readEndpoint,
};

const ConnectedUpload = connect(null, mapDispatchToProps)(injectIntl(Upload));

export {
  ConnectedUpload as default,
  getSubmissionValue,
};
