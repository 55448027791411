import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Pagination as P } from 'react-bootstrap';

class Pagination extends PureComponent {
  renderFirst() {
    const { first, onSelect, page } = this.props;

    if (!first) return null;

    return (
      <P.First
        key="first"
        disabled={page === 1}
        onClick={() => typeof onSelect === 'function' && onSelect(1)}
      />
    );
  }

  renderPrev() {
    const { prev, onSelect, page } = this.props;

    if (!prev) return null;

    return (
      <P.Prev
        key="prev"
        disabled={page === 1}
        onClick={() => typeof onSelect === 'function' && onSelect(page - 1)}
      />
    );
  }

  renderItems() {
    const {
      boundaryLinks, ellipsis,
      maxButtons, onSelect,
      page, pages,
    } = this.props;

    const pageButtons = [];
    let endPage;
    let startPage;
    let hasHiddenPagesAfter;

    if (maxButtons) {
      const hiddenPagesBefore = Math.floor(page - maxButtons / 2);
      startPage = hiddenPagesBefore > 1 ? hiddenPagesBefore : 1;
      hasHiddenPagesAfter = startPage + maxButtons <= pages;

      if (!hasHiddenPagesAfter) {
        endPage = pages;
        startPage = pages - maxButtons + 1;
        if (startPage < 1) {
          startPage = 1;
        }
      } else {
        endPage = startPage + maxButtons - 1;
      }
    } else {
      startPage = 1;
      endPage = pages;
    }

    for (let pageNumber = startPage; pageNumber <= endPage; pageNumber += 1) {
      pageButtons.push(
        <P.Item
          key={pageNumber}
          active={pageNumber === page}
          onClick={() => typeof onSelect === 'function' && onSelect(pageNumber)}
        >
          {pageNumber}
        </P.Item>,
      );
    }

    if (boundaryLinks && ellipsis && startPage !== 1) {
      pageButtons.unshift(<P.Item disabled>…</P.Item>);

      pageButtons.unshift(
        <P.Item
          key={1}
          active={false}
          onClick={() => typeof onSelect === 'function' && onSelect(1)}
        >
          1
        </P.Item>,
      );
    }

    if (maxButtons && hasHiddenPagesAfter && ellipsis) {
      pageButtons.push(<P.Item disabled key="ellipsis">…</P.Item>);

      if (boundaryLinks && endPage !== pages) {
        pageButtons.push(
          <P.Item
            key={pages}
            onClick={() => typeof onSelect === 'function' && onSelect(pages)}
          >
            {pages}
          </P.Item>,
        );
      }
    }

    return pageButtons;
  }

  renderNext() {
    const {
      next, onSelect,
      page, pages,
    } = this.props;

    if (!next) return null;

    return (
      <P.Next
        key="next"
        disabled={page >= pages}
        onClick={() => typeof onSelect === 'function' && onSelect(page + 1)}
      />
    );
  }

  renderLast() {
    const {
      last, onSelect,
      page, pages,
    } = this.props;

    if (!last) return null;

    return (
      <P.Last
        key="last"
        disabled={page >= pages}
        onClick={() => typeof onSelect === 'function' && onSelect(pages)}
      />
    );
  }

  render() {
    const { bsSize } = this.props;

    return (
      <P bsSize={bsSize}>
        {this.renderFirst()}
        {this.renderPrev()}
        {this.renderItems()}
        {this.renderNext()}
        {this.renderLast()}
      </P>
    );
  }
}

Pagination.defaultProps = {
  boundaryLinks: false,
  bsSize: 'medium',
  ellipsis: true,
  first: false,
  last: false,
  maxButtons: 0,
  next: false,
  prev: false,
};

Pagination.propTypes = {
  // Required
  onSelect: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,

  // Optional
  boundaryLinks: PropTypes.bool,
  bsSize: PropTypes.string,
  ellipsis: PropTypes.bool,
  first: PropTypes.bool,
  last: PropTypes.bool,
  maxButtons: PropTypes.number,
  next: PropTypes.bool,
  prev: PropTypes.bool,
};

export default Pagination;
