import { Alert, Button, Table } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from '../../../../lib/driveApi';
import IntlShape from '../../../../../../../app/lib/PropTypes/IntlShape';
import genericMessages from '../../../../../../../app/lib/genericMessages';
import { getMalfunctionsCategories, isLoadingMalfunctionsCategories } from '../../selectors';
import { loadingMalfunctionsCategories, loadingMalfunctionsCategoriesDone, setMalfunctionsCategories } from '../../actions';

const styles = {
  main: {
    backgroundColor: '#FAFAFA',
  },
  footerWrapper: {
    display: 'flex',
  },
  footerInnerWrapperRight: {
    textAlign: 'right',
  },
  footerAddButton: {
    margin: '20px 0',
  },
};

const messages = defineMessages({
  errorMessage: {
    id: 'projects.arriva.app.modules.VehicleStatus.components.VehicleStatuses.errorMessage',
    description: 'Error message fetching vehicle statuses',
    defaultMessage: 'Something went wrong!',
  },
  closeNotification: {
    id: 'projects.arriva.app.modules.VehicleStatus.components.VehicleStatuses.closeNotification',
    description: 'Button close notification',
    defaultMessage: 'Close notification',
  },
  notFound: {
    id: 'projects.arriva.app.modules.VehicleStatus.components.VehicleStatuses.notFound',
    description: 'Message data not found',
    defaultMessage: 'Nothing found!',
  },
  loading: {
    id: 'projects.arriva.app.modules.VehicleStatus.components.VehicleStatuses.loading',
    description: 'Loading message',
    defaultMessage: 'Loading, please wait!',
  },
  code: {
    id: 'projects.arriva.app.modules.Malfunctions.components.CategoriesList.code',
    description: 'Malfunctions code',
    defaultMessage: 'Code',
  },
  description: {
    id: 'projects.arriva.app.modules.Malfunctions.components.CategoriesList.description',
    description: 'Malfunctions description',
    defaultMessage: 'Description',
  },
  vehicle: {
    id: 'projects.arriva.app.modules.Malfunctions.components.CategoriesList.vehicle',
    description: 'Vehicle Type',
    defaultMessage: 'Vehicle-type',
  },
  createdAt: {
    id: 'projects.arriva.app.modules.Malfunctions.components.CategoriesList.createdAt',
    description: 'Malfunctions created at',
    defaultMessage: 'Created at',
  },
  updatedAt: {
    id: 'projects.arriva.app.modules.Malfunctions.components.CategoriesList.updatedAt',
    description: 'Malfunctions updated at',
    defaultMessage: 'Updated at',
  },
});

const CategoriesList = ({
  categories,
  isLoadingMalfunctionsCategories,
  intl: { formatMessage },
  get,
  loadingMalfunctionsCategories,
  loadingMalfunctionsCategoriesDone,
  setMalfunctionsCategories,
}) => {
  const [error, setError] = useState(undefined);

  function fetchMalfunctionsCategories() {
    loadingMalfunctionsCategories();
    get({ path: 'malfunctions/categories' })
      .then((result) => {
        setMalfunctionsCategories(result);
      })
      .catch((error) => {
        console.error(`MalfunctionsCategories: fetching data failed: ${error}`);
        setError(error);
      })
      .finally(() => loadingMalfunctionsCategoriesDone());
  }

  useEffect(() => {
    fetchMalfunctionsCategories();
  }, []);

  function renderError() {
    if (!error) return null;

    const dismissError = () => setError(undefined);

    return (
      <Alert bsStyle="danger" onDismiss={dismissError}>
        <h4>{formatMessage(messages.errorMessage)}</h4>
        <p>{error.toString()}</p>
        <p>
          <Button onClick={dismissError}>{formatMessage(messages.closeNotification)}</Button>
        </p>
      </Alert>
    );
  }

  function renderCategories(cat) {
    moment.locale('nl');

    return (
      <tr key={`malfunction-${cat.id}`}>
        <td>
          <Link to={`/malfunctions/categories/edit/${cat.id}`}>{cat.value}</Link>
        </td>
        <td>{cat.label}</td>
        <td>{cat.vehicleType}</td>
        <td>{moment(cat.createdAt).format('DD MMM YYYY')}</td>
        <td>{moment(cat.updatedAt).format('DD MMM YYYY')}</td>
      </tr>
    );
  }

  return (
    <div>
      {renderError()}

      {!isLoadingMalfunctionsCategories && categories && categories.length === 0
        && <strong>{formatMessage(messages.notFound)}</strong>}

      {isLoadingMalfunctionsCategories && <strong>{formatMessage(messages.loading)}</strong>}

      {(!isLoadingMalfunctionsCategories && categories && categories.length > 0) && (
      <div style={styles.main}>
        <Table responsive>
          <thead>
            <tr style={styles.row}>
              <td>{formatMessage(messages.code)}</td>
              <td>{formatMessage(messages.description)}</td>
              <td>{formatMessage(messages.vehicle)}</td>
              <td>{formatMessage(messages.createdAt)}</td>
              <td>{formatMessage(messages.updatedAt)}</td>
            </tr>
          </thead>
          <tbody>
            {categories.map((cat) => renderCategories(cat))}
          </tbody>
        </Table>
      </div>
      )}
      <div style={{ ...styles.footerInnerWrapper, ...styles.footerInnerWrapperRight }}>
        <Button
          bsStyle="primary"
          href="/malfunctions/categories/add"
          style={styles.footerAddButton}
        >
          <FormattedMessage {...genericMessages.add} />
        </Button>
      </div>
    </div>
  );
};

CategoriesList.propTypes = {
  // Own props
  categories: PropTypes.shape([]),
  loadingMalfunctionsCategories: PropTypes.func.isRequired,
  loadingMalfunctionsCategoriesDone: PropTypes.func.isRequired,
  setMalfunctionsCategories: PropTypes.func.isRequired,

  // State props
  isLoadingMalfunctionsCategories: PropTypes.bool.isRequired,

  // Dispatch props
  get: PropTypes.func.isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
};

const mapStateToProps = (state) => ({
  categories: getMalfunctionsCategories(state),
  isLoadingMalfunctionsCategories: isLoadingMalfunctionsCategories(state),
});

const mapDispatchToProps = {
  get,
  loadingMalfunctionsCategories,
  loadingMalfunctionsCategoriesDone,
  setMalfunctionsCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CategoriesList));
