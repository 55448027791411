/* eslint-disable */
import React, { Component } from 'react';
import StarRating from '../star-rating';

import HeaderBar from '../header-bar';
import { myxss } from '../form-elements';


export default class Rating extends Component {
  render() {
    const {
      data,
      mutable,
      defaultValue,
      parent,
      editModeOn,
      _onDestroy,
      onEdit,
      readOnly,
    } = this.props;

    let baseClasses = 'rfb-item';

    if (data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }

    const rating = (defaultValue !== undefined && defaultValue.length) ? parseFloat(defaultValue, 10) : 0;

    return (
      <div className={baseClasses}>
        {!mutable &&
          <div>
            {data.pageBreakBefore &&
              <div className="preview-page-break">Page Break</div>
            }
          <HeaderBar
            parent={parent}
            editModeOn={editModeOn}
            data={data}
            onDestroy={_onDestroy}
            onEdit={onEdit}
            static={data.static}
            required={data.required}
          />
        </div>
        }

        <div className="form-group">
          <label>
            <span dangerouslySetInnerHTML={{__html: myxss.process(data.label) }} />
            {(data.hasOwnProperty('required') && data.required === true && !readOnly) &&
              <span className="label-required label label-danger">Verplicht</span>
            }
          </label>

          <StarRating
            ref={`child_ref_${data.field_name}`}
            name={data.field_name}
            ratingAmount={5}
            rating={rating}
            editing={true}
          />
        </div>
      </div>
    );
  }
}
