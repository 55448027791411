import React, { Component } from 'react';

import ArticleContainer from '../components/Article/ArticleContainer';

class Home extends Component {
  render() {
    return <ArticleContainer groupId="all" />;
  }
}

export default Home;
