/* eslint-disable */
import React, { Component } from 'react';
import Select from 'react-select';

import HeaderBar from '../header-bar';
import { myxss } from '../form-elements';


export default class Tags extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.defaultValue !== undefined ? this.props.defaultValue.split(",") : []
    };
  }

  handleChange(e) {
    this.setState({value: e});
  }

  render() {
    const { value } = this.state;
    const { mutable, parent, editModeOn, readOnly, data, onEdit, _onDestroy } = this.props;

    const options = data.options.map( option => {
      option.label = option.text;
      return option;
    });

    let baseClasses = 'rfb-item';
    if (data.pageBreakBefore) baseClasses += ' alwaysbreak';

    return (
      <div className={baseClasses}>
        {!this.props.mutable &&
          <div>
            {data.pageBreakBefore &&
              <div className="preview-page-break">Page Break</div>
            }
            <HeaderBar
              parent={parent}
              editModeOn={editModeOn}
              data={data}
              onDestroy={_onDestroy}
              onEdit={onEdit}
              static={data.static}
              required={data.required}
            />
          </div>
        }

        <div className="form-group">
          <label>
            <span dangerouslySetInnerHTML={{__html: myxss.process(data.label) }} />
            {(this.props.data.hasOwnProperty('required') && data.required === true && !readOnly) &&
              <span className="label-required label label-danger">Verplicht</span>
            }
          </label>
          <Select
            multi
            options={options}
            ref={"child_ref_" + data.field_name}
            name={data.field_name}
            value={mutable ? options[0].text : value}
            onChange={this.handleChange.bind(this)}
          />
        </div>
      </div>
    );
  }
}
