// A single point for initializing the project if needed
import Routes from './app/Routes';
import common from './common';

// Initialize the Arriva Project store reducer
import './app/modules/store';

// Initialize the ArriVideo store reducer
import './app/modules/ArriVideo/store';

// Initialize the UrlEntry store reducer
import './app/modules/UrlEntry/store';

// Initialize the SoftwarePackage store reducer
import './app/modules/SoftwarePackage/store';

// Initialize the SoftwareNotification store reducer
import './app/modules/SoftwareNotification/store';

// Initialize the Malfunctions store reducer
import './app/modules/Malfunctions/store';

// Initialize the Establishment store reducer
import './app/modules/EmailAddressEstablishment/store';

// Initialize the UserNotifications store reducer
import './app/modules/UserNotifications/store';

// Initialize the Arriva specific hooks
import './app/lib/hooks';

export default {
  ...common,

  // The routes to export from this project
  Routes,
};
