/* eslint-disable max-classes-per-file */
import ExtendableError from 'es6-error';

export const FETCH_ERROR = 1;
export const FETCH_FORBIDDEN = 403;
export const FETCH_NOT_FOUND = 404;

export class FetchError extends ExtendableError {
  constructor(message = 'Fetch failed', type = FETCH_ERROR) {
    super(message);
    this.type = type;
  }
}

export class ForbiddenError extends ExtendableError {
  constructor(message = 'Forbidden', type = FETCH_FORBIDDEN) {
    super(message);
    this.type = type;
  }
}

export class NotFoundError extends ExtendableError {
  constructor(message = 'Not Found', type = FETCH_NOT_FOUND) {
    super(message);
    this.type = type;
  }
}
