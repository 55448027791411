import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { defineMessages, injectIntl } from 'react-intl';

import { get } from '../../../lib/driveApi';
import { message as messageType } from '../types';
import { FooterLayout } from '../../../../../../app/components/Groups/FooterLayout';
import { loadingMessages, loadingMessagesDone, setMessages } from '../actions';
import { getMessagesSortByUpdatedAt, isLoadingMessages } from '../selector';
import { ErrorMessage } from '../../../../../../app/components/ErrorMessage';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';

const translations = defineMessages({
  from: {
    id: 'app.projects,arriva.app.modules.UserNotification.components.NotificationsList.from',
    description: 'Start date',
    defaultMessage: 'From',
  },
  to: {
    id: 'app.projects,arriva.app.modules.UserNotification.components.NotificationsList.to',
    description: 'End date',
    defaultMessage: 'To',
  },
  message: {
    id: 'app.projects,arriva.app.modules.UserNotification.components.NotificationsList.message',
    description: 'Notification content',
    defaultMessage: 'Message',
  },
  updatedAt: {
    id: 'app.projects,arriva.app.modules.UserNotification.components.NotificationsList.updatedAt',
    description: 'Last edit date',
    defaultMessage: 'Edit date',
  },
  noResults: {
    id: 'projects.arriva.app.modules.UserNotifications.components.NotificationsList.noResults',
    description: 'Warning message no notifications fetched',
    defaultMessage: 'Nothing found!',
  },
});

class NotificationsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      pages: 1,
      pageSize: 10,
      error: undefined,
    };
  }

  componentDidMount() {
    this.fetchMessages();
  }

  onSelectPage(page) {
    this.setState({ page }, () => this.fetchMessages());
  }

  async fetchMessages() {
    const {
      get,
      loadingMessages,
      loadingMessagesDone,
      setMessages,
    } = this.props;
    const { page, pageSize } = this.state;

    await loadingMessages();

    const url = `notifications/notifications?page=${page}&page_size=${pageSize}`;

    get({ path: url, parallel: false })
      .then(({ pages, messages }) => {
        this.setState({ pages }, async () => {
          await setMessages(messages);
          await loadingMessagesDone();
        });
      })
      .catch((err) => this.setState({ error: err, page: 1, pages: 0 }));
  }

  renderMessage(notificationMessage) {
    return (
      <tr key={`message-${notificationMessage.id}`}>
        <td>
          <Link to={`/notifications/notification/edit/${notificationMessage.id}`}>{notificationMessage.notificationText}</Link>
        </td>
        <td>
          {moment(notificationMessage.startDate).format('LLL')}
        </td>
        <td>
          {moment(notificationMessage.endDate).format('LLL')}
        </td>
        <td>
          {moment(notificationMessage.updatedAt).format('LLL')}
        </td>
      </tr>
    );
  }

  render() {
    const { messages, isLoadingMessages, intl: { formatMessage } } = this.props;
    const { error, page, pages } = this.state;

    return (
      <div>
        <ErrorMessage error={error} />

        {messages.length === 0 && <strong>{formatMessage(translations.noResults)}</strong>}
        {isLoadingMessages && <strong>Bezig met laden, een moment geduld alstublieft!</strong>}

        {(messages && messages.length > 0 && !isLoadingMessages) && (
        <Table responsive>
          <thead>
            <tr>
              <td>{formatMessage(translations.message)}</td>
              <td>{formatMessage(translations.from)}</td>
              <td>{formatMessage(translations.to)}</td>
              <td>{formatMessage(translations.updatedAt)}</td>
            </tr>
          </thead>
          <tbody>
            {messages.map((message) => this.renderMessage(message))}
          </tbody>
        </Table>
        )}
        <FooterLayout
          actionPath="/notifications/notification/add"
          onSelectPage={this.onSelectPage.bind(this)}
          page={page}
          pages={pages}
        />
      </div>
    );
  }
}
NotificationsList.propTypes = {
  get: PropTypes.PropTypes.func.isRequired,
  loadingMessages: PropTypes.func.isRequired,
  loadingMessagesDone: PropTypes.func.isRequired,
  setMessages: PropTypes.func.isRequired,

  messages: PropTypes.arrayOf(messageType),
  isLoadingMessages: PropTypes.bool.isRequired,
  intl: IntlShape.isRequired,
};

const mapStateToProps = (state) => ({
  messages: getMessagesSortByUpdatedAt(state),
  isLoadingMessages: isLoadingMessages(state),
});

const mapDispatchToProps = {
  get,
  loadingMessages,
  loadingMessagesDone,
  setMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(NotificationsList));
