import PropTypes from 'prop-types';
import {
  filter, find, groupBy, path, pathEq, uniq,
} from 'ramda';
import React, { useEffect, useState } from 'react';
import {
  Button, ButtonToolbar, Col, ControlLabel, Form, FormControl, FormGroup, HelpBlock, Jumbotron,
  ListGroup, ListGroupItem, Row,
} from 'react-bootstrap';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import Select from 'react-select';
import DriveSuggest, { translateLabels } from '../../../components/DriveSuggestFormElement';
import genericMessages from '../../../../../../app/lib/genericMessages';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import { vState } from '../../../../../../app/lib/utils';

const messages = defineMessages({
  audiences: {
    id: 'projects.arriva.app.modules.UrlEntry.components.UrlEntryForm.audiences',
    description: 'URL Entry audiences label.',
    defaultMessage: 'Audiences',
  },
  name: {
    id: 'projects.arriva.app.modules.UrlEntry.components.UrlEntryForm.name',
    description: 'URL entry name label.',
    defaultMessage: 'Name',
  },
  noAudiences: {
    id: 'projects.arriva.app.modules.UrlEntry.components.UrlEntryForm.noAudiences',
    description: 'URL entry no audiences label.',
    defaultMessage: 'No groups or users paired',
  },
  url: {
    id: 'projects.arriva.app.modules.UrlEntry.components.UrlEntryForm.url',
    description: 'URL entry URL label.',
    defaultMessage: 'URL',
  },
  browsingType: {
    id: 'projects.arriva.app.modules.UrlEntry.components.UrlEntryForm.browsingType',
    description: 'URL entry type of browsing.',
    defaultMessage: 'Browsing Type',
  },
});

const UrlEntryForm = ({
  handleChange,
  handleSubmit,
  errors,
  isSubmitting,
  onClickDelete,
  setFieldValue,
  values,
}) => {
  const [audiences, setAudiences] = useState(values.audiences || []);

  const browsingTypeOptions = [
    {
      key: 0, value: 'Internal', label: 'Internal',
    }, {
      key: 1, value: 'External', label: 'External',
    },
  ];

  useEffect(() => {
    setFieldValue('audiences', audiences);
  }, [audiences]);

  // Group the audiences
  const groupedAudiences = groupBy((a) => translateLabels[path(['value', 'resourceType'], a)] || 'Onbekend')(audiences);

  return (
    <Form horizontal onSubmit={handleSubmit}>
      <Row>
        <Col xs={12} md={8} lg={8}>
          <FormGroup controlId="name" validationState={vState(errors, 'name')}>
            <ControlLabel>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...messages.name} />
            </ControlLabel>

            <FormControl type="text" autoComplete="off" value={values.name} onChange={handleChange} />

            {errors.name && <HelpBlock>{errors.name}</HelpBlock>}
          </FormGroup>

          <FormGroup controlId="url" validationState={vState(errors, 'url')}>
            <ControlLabel>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...messages.url} />
            </ControlLabel>

            <FormControl type="text" autoComplete="off" value={values.url} onChange={handleChange} />

            {errors.url && <HelpBlock>{errors.url}</HelpBlock>}
          </FormGroup>

          <FormGroup controlId="browsingType" validationState={vState(errors, 'browsingType')}>
            <ControlLabel>
              <FormattedMessage {...messages.browsingType} />
            </ControlLabel>

            <Select
              name="browsingType"
              options={browsingTypeOptions}
              value={browsingTypeOptions.find((option) => option.value === values.browsingType)}
              onChange={({ value }) => setFieldValue('browsingType', value)}
            />

            {errors.browsingType && <HelpBlock>{errors.browsingType}</HelpBlock>}
          </FormGroup>

          <FormGroup controlId="audiences" validationState={vState(errors, 'audiences')}>
            <ControlLabel>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...messages.audiences} />
            </ControlLabel>

            <Jumbotron style={{ backgroundColor: '#E9EAE9', padding: 20, marginBottom: 0 }}>
              <DriveSuggest
                onSelect={(audience) => {
                  // Make sure we don't add duplicates
                  if (!find(pathEq(['value', 'id'], path(['value', 'id'], audience)))(audiences)) {
                    const audienceArray = [...audiences, audience];

                    setFieldValue('audiences', audienceArray);
                    setAudiences([...audiences, audience]);
                  }
                }}
              />

              <hr />

              {(!audiences || audiences.length === 0) && (
                <strong>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <FormattedMessage {...messages.noAudiences} />
                </strong>
              )}

              {(audiences && audiences.length > 0) && (
                <div>
                  {uniq(Object.values(translateLabels)).map((resourceType) => {
                    if (!groupedAudiences[resourceType]) return null;

                    return (
                      <div key={resourceType}>
                        <strong>{resourceType}</strong>
                        <ListGroup>
                          {groupedAudiences[resourceType].map((audience) => (
                            <ListGroupItem
                              key={`audience-${audience.id}`}
                              style={{ display: 'flex', justifyContent: 'space-between' }}
                            >
                              {audience.label}
                              <Button
                                bsSize="small"
                                bsStyle="danger"
                                onClick={() => setAudiences(
                                  filter((a) => path(['value', 'id'], a) !== path(['value', 'id'], audience))(audiences),
                                )}
                              >
                                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                <FormattedMessage {...genericMessages.delete} />
                              </Button>
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      </div>
                    );
                  })}
                </div>
              )}
            </Jumbotron>

            {errors.audiences && <HelpBlock>{errors.audiences}</HelpBlock>}
          </FormGroup>

          <hr />

          <FormGroup controlId="formActions">
            <ButtonToolbar>
              {values.id && (
                <Button bsStyle="danger" onClick={() => onClickDelete()}>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <FormattedMessage {...genericMessages.delete} />
                </Button>
              )}

              <Button type="submit" disabled={isSubmitting || Object.keys(errors).length > 0}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <FormattedMessage {...genericMessages.formSubmitLabel} />
              </Button>
            </ButtonToolbar>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

UrlEntryForm.propTypes = {
  // Own props
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,

  errors: PropTypes.shape({
    audiences: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    browsingType: PropTypes.string,
  }),

  values: PropTypes.shape({
    audiences: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.shape({
        id: PropTypes.string.isRequired,
        resourceType: PropTypes.string.isRequired,
      }),
    })),
    id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    browsingType: PropTypes.string.isRequired,
  }).isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
  locale: PropTypes.string.isRequired,
};

// noinspection JSCheckFunctionSignatures
export default injectIntl(UrlEntryForm);
