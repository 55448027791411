import { Alert, Button, Table } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { path } from 'ramda';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IntlShape from '../../../../../app/lib/PropTypes/IntlShape';
import { post } from '../../lib/driveApi';
import genericMessages from '../../../../../app/lib/genericMessages';

const { RAZZLE_ARRIVA_MALFUNCTIONS_API_KEY } = process.env;

const styles = {
  container: {
    padding: '40px',
  },
  listContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    height: '60px',
  },
  main: {
    backgroundColor: '#FAFAFA',
    padding: '30px',
  },
  fold: {
    display: 'block',
    flexDirection: 'column',
  },
  description: {
    fontSize: 18,
  },
  long: {
    padding: '12px',
    fontSize: 18,
  },
  row: {
    height: '60px',
  },
  date: {
    fontSize: 18,
    width: '180px',
  },
  keyWord: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  footerInnerWrapperRight: {
    textAlign: 'right',
  },
  footerAddButton: {
    fontSize: 20,
    margin: '20px 0',
    width: '200px',
    height: '50px',
  },
};

const messages = defineMessages({
  errorMessage: {
    id: 'projects.arriva.app.modules.VehicleStatus.components.VehicleStatuses.errorMessage',
    description: 'Error message fetching vehicle statuses',
    defaultMessage: 'Something went wrong!',
  },
  closeNotification: {
    id: 'projects.arriva.app.modules.VehicleStatus.components.VehicleStatuses.closeNotification',
    description: 'Button close notification',
    defaultMessage: 'Close notification',
  },
  notFound: {
    id: 'projects.arriva.app.modules.VehicleStatus.components.VehicleStatuses.notFound',
    description: 'Message data not found',
    defaultMessage: 'Nothing found!',
  },
  loading: {
    id: 'projects.arriva.app.modules.VehicleStatus.components.VehicleStatuses.loading',
    description: 'Loading message',
    defaultMessage: 'Loading, please wait!',
  },
  vehicle: {
    id: 'projects.arriva.app.modules.Malfunctions.components.MalfunctionsList.vehicleWithNumber',
    description: 'Vehicle',
    defaultMessage: 'Vehicle-number: {message}',
  },
  noVehicle: {
    id: 'projects.arriva.app.modules.Malfunctions.components.MalfunctionsList.noVehicle',
    description: 'No Vehicle',
    defaultMessage: 'Vehicle-number {message} doesn\'t exists or has no pending workorders',
  },
  workOrderCode: {
    id: 'projects.arriva.app.modules.Malfunctions.components.MalfunctionsList.workOrderCode',
    description: 'Workorder-code Label',
    defaultMessage: 'Workorder-code:',
  },
  description: {
    id: 'projects.arriva.app.modules.Malfunctions.components.CategoriesList.description',
    description: 'Malfunctions description',
    defaultMessage: 'Description',
  },
  extendedDescription: {
    id: 'projects.arriva.app.modules.Malfunctions.components.MalfunctionsList.extendedDescription',
    description: 'Malfunctions extended description',
    defaultMessage: 'Extended description:',
  },
  workOrderDate: {
    id: 'projects.arriva.app.modules.Malfunctions.components.MalfunctionsList.workOrderDate',
    description: 'Workorder-date Label',
    defaultMessage: 'Workorder-date',
  },
  workOrderStatus: {
    id: 'projects.arriva.app.modules.Malfunctions.components.MalfunctionsList.workOrderStatus',
    description: 'Workorder-status Label',
    defaultMessage: 'Status:',
  },
});

const Malfunctions = ({
  intl,
  post,
}) => {
  const { pathname } = useLocation();
  const { identifier } = useParams();
  const [malfunctions, setMalfunctions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [showExtraInfo, setShowExtraInfo] = useState(false);
  const [workCode, setWorkCode] = useState();
  const rows = path(['rows'], malfunctions);

  const internal = pathname.startsWith('/malfunctions/open-internal/');

  function fetchMalfunctions() {
    post({
      path: `malfunctions/public/${identifier}/open`,
      body: {
        key: RAZZLE_ARRIVA_MALFUNCTIONS_API_KEY,
      },
      internal,
    })
      .then((result) => {
        setMalfunctions(result);
      })
      .catch((error) => {
        console.error(`Malfunctions: fetching data failed: ${error}`);
        setError(error);
      })
      .finally(() => setIsLoading(false));
    setTimeout(fetchMalfunctions, 30 * 1000);
  }

  useEffect(() => {
    fetchMalfunctions();
  }, []);

  function onClickHandler(row) {
    setShowExtraInfo(!showExtraInfo);
    setWorkCode(row.workOrderCode);
  }

  function renderError() {
    if (!error) return null;

    const dismissError = () => setError(undefined);

    return (
      <Alert bsStyle="danger" onDismiss={dismissError}>
        <h4>{intl.formatMessage(messages.errorMessage)}</h4>
        <p>{error.toString()}</p>
        <p>
          <Button onClick={dismissError}>{intl.formatMessage(messages.closeNotification)}</Button>
        </p>
      </Alert>
    );
  }

  function renderMalfunctions(row) {
    moment.locale('nl');

    return (
      <tr style={styles.row} key={`malfunction-${row.workOrderCode}`} onClick={() => onClickHandler(row)}>
        <td style={styles.fold}>
          <p style={styles.description}>
            {row.failureDescription}
          </p>
          {showExtraInfo && workCode === row.workOrderCode
            ? (
              <div style={styles.fold}>
                <div style={styles.long}>
                  <p style={styles.keyWord}>
                    <FormattedMessage {...messages.extendedDescription} />
                  </p>
                  <p>{row.workOrderDescription}</p>
                </div>
                <div style={styles.long}>
                  <p style={styles.keyWord}>
                    <FormattedMessage {...messages.workOrderCode} />
                  </p>
                  <p>{row.workOrderCode}</p>
                </div>
                <div style={styles.long}>
                  <p style={styles.keyWord}>
                    <FormattedMessage {...messages.workOrderStatus} />
                  </p>
                  <p>{row.statusDescription}</p>
                </div>
              </div>
            )
            : ''}
        </td>
        <td style={styles.date}>{moment(row.createdAt).format('DD MMM YYYY')}</td>
      </tr>

    );
  }

  return (
    <div style={styles.container}>
      {renderError()}

      {!isLoading && malfunctions && malfunctions.length === 0
        && <strong>{intl.formatMessage(messages.notFound)}</strong>}

      {!isLoading && malfunctions.rows && malfunctions.rows.length === 0
        && (
        <strong>
          <FormattedMessage
            {...messages.noVehicle}
            values={{ message: `${identifier}` }}
          />
        </strong>
        )}

      {isLoading && <strong>{intl.formatMessage(messages.loading)}</strong>}

      {(!isLoading && rows && rows.length > 0) && (
      <div style={styles.main}>
        <h2>
          <FormattedMessage
            {...messages.vehicle}
            values={{ message: `${identifier}` }}
          />
        </h2>
        <br />
        <Table responsive>
          <thead>
            <tr style={styles.row}>
              <td style={styles.keyWord}>
                <FormattedMessage {...messages.description} />
              </td>
              <td style={styles.keyWord}>
                <FormattedMessage {...messages.workOrderDate} />
              </td>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => renderMalfunctions(row))}
          </tbody>
        </Table>
      </div>
      )}

      {/*
      {Object.entries(malfunctions).length !== 0 && (
        <div style={{ ...styles.footerInnerWrapper, ...styles.footerInnerWrapperRight }}>
          <Button
            bsStyle="primary"
            href={`/malfunctions/open/pending-malfunctions/add/${identifier}`}
            style={styles.footerAddButton}
          >
            <FormattedMessage {...genericMessages.add} />
          </Button>
        </div>
      )}
       */}
    </div>
  );
};

Malfunctions.propTypes = {
  // Dispatch props
  post: PropTypes.func.isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
};

const mapDispatchToProps = {
  post,
};

export default connect(null, mapDispatchToProps)(injectIntl(Malfunctions));
