import { Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { filter, find, head, path, pathEq, propEq, range } from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { createResource, deleteResource, readEndpoint, updateResource } from 'redux-json-api';

import getPropTypeScheme from '../../lib/PropTypes';
import IntlShape from '../../lib/PropTypes/IntlShape';
import genericMessages from '../../lib/genericMessages';
import Constants from '../../modules/constants';
import { isAdmin, selectUser } from '../../modules/selectors/session';

import { getSummernoteFileLibrary, getSummernoteFormList } from '../Forms/helpers';
import SelectFileModal from '../MediaLibrary/SelectFileModal';
import Modal from '../Modal';

import WriteForm from './WriteForm';

import '../../../styles/react-datepicker/style.scss';
import './ArticleWrite.less';

const messages = defineMessages({
  articleSuccessMessage: {
    id: 'app.components.Article.ArticleWrite.articleSuccessMessage',
    description: 'ArticleWrite \'success\' message.',
    defaultMessage: 'The article has been modified.',
  },
  selectHouse: {
    id: 'app.components.Article.ArticleWrite.selectHouse',
    description: 'ArticleWrite \'selectHouse\' message.',
    defaultMessage: 'Select a house',
  },
});

class ArticleWrite extends Component {
  static propTypes = {
    // react-intl props
    intl: IntlShape.isRequired,

    // own props.
    initialValues: PropTypes.shape({
      id: PropTypes.string,
      userId: PropTypes.string,
      articleType: PropTypes.string,
      postAs: PropTypes.string,
    }).isRequired,
    user: getPropTypeScheme('User'),
    attributes: getPropTypeScheme('ArticleAttributes'),
    setModalData: PropTypes.func.isRequired,

    // state props.
    baseURL: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,

    // dispatch props.
    readEndpoint: PropTypes.func.isRequired,
    createResource: PropTypes.func.isRequired,
    updateResource: PropTypes.func.isRequired,
    deleteResource: PropTypes.func.isRequired,
    getSummernoteFileLibrary: PropTypes.func.isRequired,
    getSummernoteFormList: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.onSubmit = this.onSubmit.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  state = {
    groups: [],
    loading: true,
    showSelectFileModal: false,
    onFileInsertCallback: undefined,
  };

  componentWillMount() {
    const {
      isAdmin, user,
      readEndpoint,
    } = this.props;

    readEndpoint('form?page_size=100');

    /**
     * All group id's of the currently logged in user.
     * Filter out GROUP_ID_USERS for non-admins.
     */
    const userGroupIds = (path(['relationships', 'groups', 'data'], user) || [])
      .map(g => g.id)
      .filter(id => !(!isAdmin && id === Constants.GROUP_ID_USERS))
      .join(',');

    /**
     * Administrators fetch all groups, other users only their own.
     */
    const endpoint = isAdmin ? 'group?page_size=100' : `group?page_size=100&filter[pk__in]=${userGroupIds}`;
    const pager = '&page';

    /**
     * Fetch groups. If the response has multiple pages, fetch those as well..
     */
    readEndpoint(endpoint).then((response) => {
      const pages = path(['body', 'meta', 'pagination', 'pages'], response) || 0;
      let groups = path(['body', 'data'], response) || [];

      if (pages <= 1) {
        this.setState({ groups, loading: false });
      } else {
        const otherPages = range(2, pages + 1).map(n => readEndpoint(`${endpoint}${pager}=${n}`));
        Promise.all(otherPages).then((responses) => {
          responses.forEach((response) => {
            const otherGroups = path(['body', 'data'], response) || [];
            groups = groups.concat(otherGroups);
          });

          this.setState({ groups, loading: false });
        });
      }
    });
  }

  onCloseModal() {
    const { setModalData, initialValues } = this.props;

    // @XXX Refactor this code please.

    let type = 'modalArticle';

    if (initialValues.id)
      type = 'modalEditArticle';

    if (initialValues && Object.keys(initialValues).length === 1 && initialValues.articleType)
      type = 'modalWriteArticle';

    setModalData(type, false);
  }

  /**
   * Clear the hidden cover photo file input using the ref stored in this
   * instance, because WriteForm is a stateless functional component. We do
   * this to clear the input's value when it is cleared, in a cross-browser
   * fashion.
   *
   * Works in all modern browsers, and IE from 9 and up.
   */
  onCoverPhotoClear() {
    if (this._coverPhotoRef) {
      try {
        this._coverPhotoRef.value = null;

        /**
         * If the previous reset didn't work, we are running IE 9 or 10.
         * Swizzle the input type attribute to reset it in those browsers.
         */
        if (this._coverPhotoRef.value) {
          this._coverPhotoRef.type = 'text';
          this._coverPhotoRef.type = 'file';
        }
      } catch (error) {
        console.error(`[OI] ArticleWrite.onCoverPhotoClear() error=${error}`);
      }
    } else {
      console.error('[OI] ArticleWrite.onCoverPhotoClear() _coverPhotoRef missing!');
    }
  }

  /**
   * Activate the hidden cover photo file input using the ref stored in this
   * instance, because WriteForm is a stateless functional component.
   */
  onCoverPhotoClick() {
    if (this._coverPhotoRef) this._coverPhotoRef.click();
    else console.error('[OI] ArticleWrite.onCoverPhotoClick() _coverPhotoRef missing!');
  }

  /**
   * Store the ref of the cover photo file input here, because WriteForm is a
   * stateless functional component.
   */
  onCoverPhotoRef(ref) {
    this._coverPhotoRef = ref;
  }

  onSubmit(values, { setFieldError, setSubmitting, setStatus }) {
    const {
      baseURL,
      createResource,
      updateResource,
      intl: { formatMessage },
    } = this.props;

    let resource = {
      type: 'article',
      attributes: {
        title: values.title,
        content: values.content,
        activateDate: values.activateDate ? moment(values.activateDate).toDate() : null,
        deactivateDate: values.deactivateDate ? moment(values.deactivateDate).toDate() : null,
        group: `${baseURL}/group/${values.groupId}`,
        articleType: `${baseURL}/article-type/${values.articleType}`,
        postAs: values.postAs,
        tags: [],
        status: values.articleType === Constants.ARTICLE_TYPE_HOSPITALITY ? 0 : 1,
      },
    };

    if (values.cover && values.cover.startsWith('data:image')) {
      resource.attributes.cover = values.cover;
    } else if (values.cover === false) {
      resource.attributes.cover = null;
    }

    let createOrUpdate = createResource;

    if (values.id) {
      resource = {
        id: values.id,
        ...resource,
      };
      createOrUpdate = updateResource;
    }

    createOrUpdate(resource)
      .then(() => {
        setStatus({
          genericErrors: undefined,
          successMessage: formatMessage(messages.articleSuccessMessage),
        });

        setSubmitting(false);
        this.onCloseModal();
      })
      .catch((error) => {
        const fieldregex = /^\/data\/attributes\/([A-Za-z0-9-_]+)$/;
        const { response } = error;
        const genericErrors = [];

        if (response && response.data && response.data.errors) {
          const { data: { errors } } = response;

          errors.forEach((fieldError) => {
            if (fieldError.source && fieldError.source.pointer) {
              const { source: { pointer } } = fieldError;
              const matches = fieldregex.exec(pointer);

              if (matches && matches.length === 2) {
                const field = matches[1];

                // This is a specific field error.
                return setFieldError(field, fieldError.detail);
              }
            }

            // Return a generic error.
            return genericErrors.push(fieldError.detail);
          });
        } else {
          Sentry.captureException(error);

          genericErrors.push(formatMessage(genericMessages.formSubmitFailed));
        }

        if (genericErrors.length > 0)
          setStatus({ genericErrors });
        else
          setStatus({ genericErrors: undefined });

        setSubmitting(false);

        this.onCloseModal();
      });
  }

  userCanManageArticle() {
    const { user: { id }, initialValues: { userId }, isAdmin } = this.props;

    return (isAdmin || id === userId);
  }

  fetchFile(fileId) {
    const { readEndpoint } = this.props;
    return readEndpoint(`file?filter[pk__exact]=${fileId}`)
      .then(({ body: { data: file } }) => {
        if (file && file.length > 0)
          return head(file);
      });
  }

  renderSelectFileModal() {
    const { intl: { formatMessage } } = this.props;
    const { showSelectFileModal, onFileInsertCallback } = this.state;

    if (!showSelectFileModal) return null;

    return (
      <Modal
        title={formatMessage(genericMessages.selectFileButton)}
        onHide={() => this.setState({ showSelectFileModal: false })}
      >
        <SelectFileModal
          id="folder-actions"
          onSuccess={async (file) => {
            const fetchedFile = await this.fetchFile(file.id);
            onFileInsertCallback({ file, fetchedFile });
          }}
        />
      </Modal>
    );
  }

  render() {
    const {
      isAdmin,
      attributes,
      initialValues,
      deleteResource,
      getSummernoteFileLibrary,
      getSummernoteFormList,
      intl: { formatMessage },
    } = this.props;
    const { groups, loading } = this.state;

    const articleType = attributes ? attributes.articleType : (
      initialValues && initialValues.articleType) || Constants.ARTICLE_TYPE_DEFAULT;
    const postAs = attributes ? attributes.postAs : (
      initialValues && initialValues.postAs
    ) || Constants.ARTICLE_POST_AS_GROUP;

    const summernoteProps = isAdmin ? {
      toolbar: [
        ['custom', ['forms', 'filelibrary']],
      ],
      options: {
        buttons: {
          forms: getSummernoteFormList(formatMessage),
          filelibrary: getSummernoteFileLibrary({
            formatMessage,
            showSelectFileModal: (onFileInsert) => {
              this.setState({
                showSelectFileModal: true,
                onFileInsertCallback: onFileInsert,
              });
            },
          }),
        },
      },
    } : null;

    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={{
          activateDate: attributes ? attributes.activateDate : moment(),
          articleType,
          content: attributes ? attributes.content : '',
          cover: attributes ? attributes.cover : null,
          deactivateDate: attributes ? attributes.deactivateDate : '',
          groupId: attributes ? attributes.groupId : undefined,
          postAs,
          title: attributes ? attributes.title : '',
          ...attributes,
          ...initialValues,
        }}
        validate={(values) => {
          const errors = {};

          const required = ['title', 'content', 'articleType', 'postAs', 'groupId'];

          // Make sure the required fields are filled in
          required.forEach((k) => {
            if (!values[k]) {
              errors[k] = true;
            }
          });

          // Make sure the group type is 'house' when creating a hospitality article
          // @XXX: make this code generic, or move it to the project specific file(s).
          if (values.articleType === Constants.ARTICLE_TYPE_HOSPITALITY) {
            const group = find(propEq('id', values.groupId || Constants.ARTICLE_GROUP_DEFAULT))(groups);
            if (path(['attributes', 'groupType'], group) !== 'house') {
              errors.groupId = true;
            }
          }

          return errors;
        }}
        render={({ ...renderProps }) => {
          let _groups = {};

          // @XXX: make this code generic, or move it to the project specific file(s).
          if (path(['values', 'articleType'], renderProps) === Constants.ARTICLE_TYPE_HOSPITALITY) {
            _groups = filter(pathEq(['attributes', 'groupType'], 'house'))(groups);
            _groups.unshift({
              id: null,
              attributes: {
                name: formatMessage(messages.selectHouse),
              },
            });
          } else {
            _groups = groups;
          }

          return (
            <div>
              {this.renderSelectFileModal()}

              {loading && <span>Laden...</span>}

              <WriteForm
                {...renderProps}
                closeModal={this.onCloseModal}
                groups={_groups}
                isAdmin={isAdmin}
                summernoteProps={summernoteProps}
                userCanManageArticle={this.userCanManageArticle()}
                onDelete={() => {
                  if (initialValues.id) {
                    const resource = {
                      type: 'article',
                      id: initialValues.id,
                    };

                    deleteResource(resource)
                      .then(() => this.onCloseModal())
                      .catch((error) => {
                        Sentry.captureException(error);
                      });
                  }
                }}
                onCoverPhotoClear={() => this.onCoverPhotoClear()}
                onCoverPhotoClick={() => this.onCoverPhotoClick()}
                onCoverPhotoRef={ref => this.onCoverPhotoRef(ref)}
              />
            </div>
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  baseURL: path(['api', 'endpoint', 'axiosConfig', 'baseURL'], state),
  isAdmin: isAdmin(state),
  user: selectUser(state),
});

const mapDispatchToProps = {
  createResource,
  readEndpoint,
  updateResource,
  deleteResource,
  getSummernoteFileLibrary,
  getSummernoteFormList,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ArticleWrite));
