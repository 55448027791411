import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Row from 'react-bootstrap/lib/Row';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import getPropTypeScheme from '../../lib/PropTypes';
import { readEndpointAllPages } from '../../modules/actions/api';
import { selectFilesByFolderId, selectFilesWithoutFolder } from '../../modules/selectors/file';

import FileCard from './FileCard';

import './FileGrid.less';

const messages = defineMessages({
  noItemsFound: {
    id: 'app.components.MediaLibrary.FileGrid.noItemsFound',
    description: 'No folders or files found.',
    defaultMessage: 'No folders or files found in this folder.',
  },
});

class FileGrid extends Component {
  static propTypes = {
    // own props
    folderId: PropTypes.string,
    onSelectFile: PropTypes.func,
    selectedFileId: PropTypes.string,

    // state props
    files: PropTypes.arrayOf(getPropTypeScheme('File')).isRequired,
    filesWithoutFolder: PropTypes.arrayOf(getPropTypeScheme('File')).isRequired,

    // dispatch props
    readEndpointAllPages: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { folderId } = this.props;

    this.getFiles(folderId);
  }

  componentWillReceiveProps(nextProps) {
    const { folderId: oldFolderId } = this.props;
    const { folderId } = nextProps;

    if (folderId !== oldFolderId) {
      this.getFiles(folderId);
    }
  }

  getFiles(folderId) {
    const { readEndpointAllPages } = this.props;

    const endpoint = folderId === undefined ?
      'file?filter[folder__isnull]=True&page_size=100' :
      `file?filter[folder__exact]=${folderId}&page_size=100`;

    readEndpointAllPages(endpoint)
      .catch((error) => {
        console.error(`[OI] FileGrid getFiles error=${error}`);
        Sentry.captureException(error);
      });
  }

  render() {
    const {
      folderId, onSelectFile, selectedFileId,
      files, filesWithoutFolder,
    } = this.props;

    const _files = folderId ? files : filesWithoutFolder;

    return (
      <div className="medialibrary-filegrid">
        <Row>
          {_files.length > 0 ? _files.map(file => (
            <FileCard
              key={`file-${file.id}`}
              file={file}
              onSelectFile={onSelectFile}
              selectedFileId={selectedFileId}
            />
          )) : <FormattedMessage {...messages.noItemsFound} />}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  files: selectFilesByFolderId(state, ownProps.folderId),
  filesWithoutFolder: selectFilesWithoutFolder(state),
});

const mapDispatchToProps = { readEndpointAllPages };

export default connect(mapStateToProps, mapDispatchToProps)(FileGrid);
