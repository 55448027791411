// Loaders
export const SOFTWARE_PACKAGE_LOADING_PACKAGES = 'SOFTWARE_PACKAGE_LOADING_PACKAGES';
export const SOFTWARE_PACKAGE_LOADING_PACKAGES_DONE = 'SOFTWARE_PACKAGE_LOADING_PACKAGES_DONE';

// Adders / Setters
export const SOFTWARE_PACKAGE_SET_PACKAGES = 'SOFTWARE_PACKAGE_SET_PACKAGES';

// Loading actions
export const loadingSoftwarePackages = () => (dispatch) =>
  dispatch({ type: SOFTWARE_PACKAGE_LOADING_PACKAGES });
export const loadingSoftwarePackagesDone = () => (dispatch) =>
  dispatch({ type: SOFTWARE_PACKAGE_LOADING_PACKAGES_DONE });

// Actions
export const setSoftwarePackages = (data) => (dispatch) =>
  dispatch({ type: SOFTWARE_PACKAGE_SET_PACKAGES, payload: data });
