import { LOCALE_SET } from '../actions/locale';

const { supportedLocales } = require(`../../../projects/${process.env.RAZZLE_PROJECT}/common`); //eslint-disable-line

const localeReducer = (state = supportedLocales[0], action) => {
  switch (action.type) {
    case LOCALE_SET: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export default localeReducer;
