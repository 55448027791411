import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Alert, Button, Table,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';

import Pagination from '../../../../../../app/components/Pagination';
import { loadingUrlEntries, loadingUrlEntriesDone, setUrlEntries } from '../actions';
import { get } from '../../../lib/driveApi';
import { getUrlEntriesSortedByUpdatedAt, isLoadingUrlEntries } from '../selectors';
import { urlEntry as urlEntryShape } from '../types';
import IntlShape from "../../../../../../app/lib/PropTypes/IntlShape";
import genericMessages from "../../../../../../app/lib/genericMessages";

const styles = {
  footerWrapper: {
    display: 'flex',
  },
  footerInnerWrapper: {
    width: '50%',
  },
  footerInnerWrapperRight: {
    textAlign: 'right',
  },
  footerAddButton: {
    margin: '20px 0',
  },
};

class UrlEntries extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      pageSize: 10,
      page: 1,
      pages: 1,
    };
  }

  componentDidMount() {
    // noinspection JSIgnoredPromiseFromCall
    this.fetchUrlEntries();
  }

  onSelectPage(page) {
    this.setState({ page }, () => this.fetchUrlEntries());
  }

  async fetchUrlEntries() {
    const {
      get, loadingUrlEntries, loadingUrlEntriesDone, setUrlEntries,
    } = this.props;
    const { page, pageSize } = this.state;

    await loadingUrlEntries();

    const url = `url-entry/url-entries?page=${page}&page_size=${pageSize}`;

    get({ path: url, parallel: false })
      .then(({ pages, urlEntries }) => {
        this.setState({ pages }, async () => {
          await setUrlEntries(urlEntries);
          await loadingUrlEntriesDone();
        });
      })
      .catch((err) => this.setState({ error: err, page: 1, pages: 0 }));
  }

  renderError() {
    const { intl: { formatMessage } } = this.props;
    const { error } = this.state;

    if (!error) return null;

    const dismissError = () => this.setState({ error: undefined });

    return (
      <Alert bsStyle="danger" onDismiss={dismissError}>
        <h4>{formatMessage(genericMessages.error)}</h4>
        <p>{error.toString()}</p>
        <p>
          <Button onClick={dismissError}>{formatMessage(genericMessages.close)}</Button>
        </p>
      </Alert>
    );
  }

  renderUrlEntry(urlEntry) {
    return (
      <tr key={`urlentry-${urlEntry.id}`}>
        <td>
          <Link to={`/url-entry/url-entry/edit/${urlEntry.id}`}>{urlEntry.name}</Link>
        </td>
        <td>
          <Link to={urlEntry.url}>{urlEntry.url}</Link>
        </td>
        <td>
          {urlEntry.browsingType}
        </td>
      </tr>
    );
  }

  render() {
    const { urlEntries, isLoadingUrlEntries, intl: { formatMessage } } = this.props;
    const { page, pages } = this.state;

    return (
      <div>
        {this.renderError()}

        {urlEntries.length === 0 && <strong>{formatMessage(genericMessages.nothingFound)}</strong>}

        {isLoadingUrlEntries && <strong>{formatMessage(genericMessages.loadingInProgress)}</strong>}

        {(urlEntries.length > 0 && !isLoadingUrlEntries) && (
          <Table responsive>
            <thead>
              <tr>
                <td>{formatMessage(genericMessages.name)}</td>
                <td>URL</td>
                <td>Browsetype</td>
              </tr>
            </thead>
            <tbody>
              {urlEntries.map((urlEntry) => this.renderUrlEntry(urlEntry))}
            </tbody>
          </Table>
        )}

        <div style={styles.footerWrapper}>
          <div style={styles.footerInnerWrapper}>
            <Pagination
              first
              prev
              last
              next
              ellipsis
              boundaryLinks
              maxButtons={5}
              bsSize="small"
              onSelect={(page) => this.onSelectPage(page)}
              page={page}
              pages={pages}
            />
          </div>

          <div style={{ ...styles.footerInnerWrapper, ...styles.footerInnerWrapperRight }}>
            <Button
              bsStyle="primary"
              href="/url-entry/url-entry/add"
              style={styles.footerAddButton}
            >
              {formatMessage(genericMessages.add)}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

UrlEntries.propTypes = {
  // Dispatch props
  get: PropTypes.func.isRequired,
  loadingUrlEntries: PropTypes.func.isRequired,
  loadingUrlEntriesDone: PropTypes.func.isRequired,
  setUrlEntries: PropTypes.func.isRequired,

  // react-intl props
  intl: IntlShape.isRequired,

  // State props
  urlEntries: PropTypes.arrayOf(urlEntryShape).isRequired,
  isLoadingUrlEntries: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  urlEntries: getUrlEntriesSortedByUpdatedAt(state),
  isLoadingUrlEntries: isLoadingUrlEntries(state),
});

const mapDispatchToProps = {
  get,
  loadingUrlEntries,
  loadingUrlEntriesDone,
  setUrlEntries,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UrlEntries));
