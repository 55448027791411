import {
  SOFTWARE_NOTIFICATION_LOADING_NOTIFICATIONS,
  SOFTWARE_NOTIFICATION_LOADING_NOTIFICATIONS_DONE,
  SOFTWARE_NOTIFICATION_SET_NOTIFICATIONS,
} from './actions';

import { registerWithRootReducer } from '../../../../../app/modules/reducers/projects';

const ACTION_HANDLERS = {
  // Loaders
  [SOFTWARE_NOTIFICATION_LOADING_NOTIFICATIONS]: (state) => ({
    ...state,
    loadingNotifications: true,
  }),
  [SOFTWARE_NOTIFICATION_LOADING_NOTIFICATIONS_DONE]: (state) => ({
    ...state,
    loadingNotifications: false,
  }),

  // Adders / Setters
  [SOFTWARE_NOTIFICATION_SET_NOTIFICATIONS]: (state, action) => ({
    ...state,
    notifications: action.payload,
  }),
};

const initialState = {
  notifications: [],
  loadingNotifications: false,
};

registerWithRootReducer('softwareNotification', (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
});
