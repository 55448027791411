import { path } from 'ramda';
import { createSelector } from 'reselect';

import {
  ACTION_STORE,
  FORMS_STORE_DATA,
  FORMS_STORE_NAME,
} from './reducerActions';

// Handlers
const ACTION_HANDLERS = {
  [FORMS_STORE_DATA]: (state, action) =>
    ({ ...state, formData: { ...state.formData, fields: action.payload } }),
  [FORMS_STORE_NAME]: (state, action) =>
    ({ ...state, formData: { ...state.formData, name: action.payload } }),
  [ACTION_STORE]: (state, action) =>
    ({ ...state, actionData: { ...state.actionData, ...action.payload } }),
};

// Initial state
const initialState = {
  formData: {
    name: '',
    fields: [],
  },
  actionData: {
    id: null,
    type: '',
    data: {},
  },
};

// Selectors
const selectForms = state => path(['api', 'form', 'data'], state);
export const getForms = createSelector(selectForms, forms => forms);

// Reducer
const formsReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default formsReducer;
