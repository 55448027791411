import React from 'react';
import Well from 'react-bootstrap/lib/Well';

import { defineMessages, injectIntl } from 'react-intl';
import Notifications from './Notifications';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';

const messages = defineMessages({
  notifications: {
    id: 'projects.arriva.app.modules.SoftwareNotification.components.Dashboard.notifications',
    description: 'Notification label.',
    defaultMessage: 'Notifications',
  },
});

const Dashboard = ({ intl: { formatMessage } }) => (
  <Well>
    <h2>{formatMessage(messages.notifications)}</h2>
    <Notifications />
  </Well>
);

Dashboard.propTypes = {
  // react-intl props
  intl: IntlShape.isRequired,
};

export default (injectIntl(Dashboard));
