import PropTypes from 'prop-types';

export const MetaAttributes = PropTypes.shape({
  identifier: PropTypes.string.isRequired,
  value: PropTypes.shape({}),
});

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  attributes: MetaAttributes,
});
