import React from 'react';
import VehicleStatusesDetail from '../components/Vehicle';

const VehicleStatuses = () => (
  <div>
    <VehicleStatusesDetail />
  </div>
);

export default VehicleStatuses;
