import PropTypes from 'prop-types';
import { path } from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Col from 'react-bootstrap/lib/Col';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';

import { NotFoundError } from '../../modules/errors/fetch';
import { fetchSecureFileAsBase64, fetchSecureFileAsBlob, humanFileSize } from '../../modules/helpers';
import { selectAccessToken } from '../../modules/selectors/session';

import './FileCard.less';

class FileCard extends Component {
  static propTypes = {
    // own props
    file: PropTypes.shape({
      type: PropTypes.oneOf(['file']).isRequired,
      id: PropTypes.string.isRequired,
      attributes: PropTypes.shape({
        url: PropTypes.string.isRequired,
        file: PropTypes.string.isRequired,
        fileSize: PropTypes.number.isRequired,
        originalFilename: PropTypes.string.isRequired,
        name: PropTypes.string,
      }).isRequired,
    }).isRequired,
    onSelectFile: PropTypes.func,
    selectedFileId: PropTypes.string,
    onFileDeleted: PropTypes.func,
    canDelete: PropTypes.bool,
    isCompact: PropTypes.bool,

    // state props
    accessToken: PropTypes.string.isRequired,
  }

  state = {
    preview: undefined,
  }

  componentDidMount() {
    const { accessToken, file: { attributes: { file } } } = this.props;

    if (!file) return null;

    if (file && /\.(gif|jpg|jpeg|png)$/i.test(file)) {
      const thumbFile = `${file.replace(
        /smedia\/files\/filer_public/,
        'smedia/thumbs/filer_public',
      )}__medialibrary-thumbnail.jpg`;

      fetchSecureFileAsBase64(thumbFile, accessToken)
        .then(base64 => this.setState({ preview: base64 }))
        .catch((error) => {
          console.error(`[OI] FileCard failed to fetch thumbnail error=${error}`);

          if (!(error instanceof NotFoundError))
            Sentry.captureException(error);
        });
    }
  }

  onDownloadFile() {
    const { accessToken, file: { attributes: { file, name } } } = this.props;

    if (file) {
      fetchSecureFileAsBlob(file, accessToken)
        .then((blob) => {
          // @XXX: Method used from https://stackoverflow.com/a/19328891
          // probably need to get a more cross browser method for this.
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';

          const contentType = blob.data.type || 'application/octet-stream';
          let blobWithType;

          if (blob.data.webkitSlice)
            blobWithType = blob.data.webkitSlice(0, blob.data.size, contentType);
          else if (blob.data.mozSlice)
            blobWithType = blob.data.mozSlice(0, blob.data.size, contentType);
          else
            blobWithType = blob.data.slice(0, blob.data.size, contentType);

          const url = (URL || window.URL || window.webkitURL).createObjectURL(blobWithType);

          a.href = url;
          a.download = name;
          a.click();

          (URL || window.URL || window.webkitURL).revokeObjectURL(url);
        })
        .catch((error) => {
          if (!(error instanceof NotFoundError))
            Sentry.captureException(error);
        });
    }
  }

  onDeleteFile() {
    const { onFileDeleted, file } = this.props;

    // @TODO: figure out what to do with this,
    // delete the file here or leave it up to the Media Library?

    if (onFileDeleted)
      onFileDeleted(file);
  }

  render() {
    const {
      file,
      onSelectFile,
      selectedFileId,
      canDelete,
      isCompact,
    } = this.props;
    const { preview } = this.state;

    const filename = path(['attributes', 'originalFilename'], file);
    const filesize = path(['attributes', 'fileSize'], file);

    let glyph;

    switch (true) {
      case /\.(gif|jpg|jpeg|png)$/i.test(filename): {
        glyph = 'file-image-o';
        break;
      }
      case /\.pdf$/i.test(filename): {
        glyph = 'file-pdf-o';
        break;
      }
      case /\.(ppt|pptx|pptm|pps|ppsx|ppsm)$/i.test(filename): {
        glyph = 'file-powerpoint-o';
        break;
      }
      case /\.(xls|xlsx|xlsm|xlsb)$/i.test(filename): {
        glyph = 'file-excel-o';
        break;
      }
      case /\.(doc|docx|docm)$/i.test(filename): {
        glyph = 'file-word-o';
        break;
      }
      default: {
        glyph = 'file-o';
      }
    }

    const icon = preview ? <img src={preview} alt="Preview" /> :
    <Glyphicon bsClass="fa" glyph={glyph} />;

    const isSelected = selectedFileId === file.id ? 'medialibrary-filegrid-file-selected' : '';
    const classNames = ['medialibrary-filegrid-file', isSelected, isCompact ? 'medialibrary-filegrid-card-compact' : '']
      .join(' ');

    if (isCompact) {
      return (
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classNames}
        >
          <div className="medialibrary-filegrid-file-icon">
            {icon}
          </div>

          <div
            className="medialibrary-filegrid-file-name"
            onClick={(event) => {
              this.onDownloadFile();
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            {file.attributes.originalFilename}
          </div>

          {canDelete &&
            <div
              className="medialibrary-filegrid-file-delete"
              onClick={(event) => {
                this.onDeleteFile();
                event.stopPropagation();
                event.preventDefault();
              }}
            >
              <Glyphicon bsClass="fa" glyph="trash" />
            </div>
          }

          <div
            className="medialibrary-filegrid-file-download"
            onClick={(event) => {
              this.onDownloadFile();
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            <span className="filesize">{humanFileSize(filesize)}</span>
            <Glyphicon bsClass="fa" glyph="download" />
          </div>
        </Col>
      );
    } else {
      return (
        <Col
          xs={6}
          sm={6}
          md={4}
          lg={3}
          className={classNames}
          onClick={(event) => {
            if (onSelectFile) {
              onSelectFile(file);

              event.stopPropagation();
              event.preventDefault();
            }
          }}
        >
          <div className="medialibrary-filegrid-file-icon">
            {icon}
          </div>

          <span className="medialibrary-filegrid-file-name">
            {file.attributes.originalFilename}
          </span>
        </Col>
      );
    }
  }
}

const mapStateToProps = state => ({
  accessToken: selectAccessToken(state),
});

export default connect(mapStateToProps)(FileCard);
