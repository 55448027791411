import PropTypes from 'prop-types';

export const GalleryAttributes = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.number,
  tags: PropTypes.array,
  activateDate: PropTypes.string,
  deactivateDate: PropTypes.string,
  created: PropTypes.string,
  modified: PropTypes.string,
});

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  attributes: GalleryAttributes,
});
