import React from 'react';
import VehicleWashStatusesDetail from '../components/VehicleWash';

const VehicleWashStatuses = () => (
  <div>
    <VehicleWashStatusesDetail />
  </div>
);

export default VehicleWashStatuses;
