import { find, path, propEq } from 'ramda';
import { createSelector } from 'reselect';

// Loading selectors
export const isLoadingMessages = (state) => path(['notifications', 'loadingMessages'], state);

// Prop selectors
export const selectPropAsValue = (state, props) => props;
export const selectIdentifier = (state, props) => path(['match', 'params', 'identifier'], props);

// Data selectors
export const selectMessages = (state) => path(['notifications', 'messages'], state);

// // Memoizing selectors
export const getMessageByIdentifier = createSelector(
  [selectIdentifier, selectMessages], (messageId, messages) => {
    if (!messages || messages.length === 0) return false;
    if (!messageId) return undefined;

    return find(propEq('id', messageId))(messages);
  });

export const getMessagesSortByUpdatedAt = createSelector(
  [selectMessages], (messages) => {
    if (!messages) return [];

    return (messages);
  },
);
