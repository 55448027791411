import { combineReducers } from 'redux';
import { reducer as apiReducer } from 'redux-json-api';
import { reducer as FlashReducer } from 'redux-flash';

import counterReducer from './counter';
import formsReducer from '../../components/Forms/reducer';
import interfaceReducer from './interface';
import localeReducer from './locale';
import sessionReducer from './session';
import userAgentReducer from './userAgent';

// This is imported so this file is not called before all modules are registered
import { reducers } from './projects';

const allReducers = {
  api: apiReducer,
  counter: counterReducer,
  flash: FlashReducer,
  forms: formsReducer,
  interface: interfaceReducer,
  locale: localeReducer,
  session: sessionReducer,
  userAgent: userAgentReducer,
};

// Add the registered reducers
if (Object.keys(reducers).length > 0) {
  Object.keys(reducers).forEach((name) => {
    // Check if there is not already a reducer with the same name from the default stack
    if (allReducers[name]) {
      throw new Error(`This is not right.. reducer with ${name} already exists within the default stack`);
    }

    allReducers[name] = reducers[name];
  });
}

const rootReducer = combineReducers(allReducers);

export default rootReducer;
