import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isAdmin } from '../../modules/selectors/session';

import CreatePageModal from './CreatePageModal';

class Management extends Component {
  static propTypes = {
    // state props
    isAdmin: PropTypes.bool.isRequired,
  };

  render() {
    const { isAdmin } = this.props;

    if (!isAdmin) return null;

    return (
      <div>
        <CreatePageModal />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: isAdmin(state),
});

export default connect(mapStateToProps)(Management);
