import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  ButtonToolbar,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Row,
} from 'react-bootstrap';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import genericMessages from '../../../../../../app/lib/genericMessages';
import { vState } from '../../../../../../app/lib/utils';

const messages = defineMessages({
  name: {
    id: 'projects.arriva.app.modules.EmailEstablishment.components.EstablishmentForm.name',
    description: 'Email Establishment name label',
    defaultMessage: 'Establishment name',
  },
  code: {
    id: 'projects.arriva.app.modules.EmailEstablishment.components.EstablishmentForm.code',
    description: 'Email Establishment code label.',
    defaultMessage: 'Establishment code',
  },
  email: {
    id: 'projects.arriva.app.modules.EmailEstablishment.components.EstablishmentForm.email',
    description: 'Email Establishment label.',
    defaultMessage: 'Establishment email-address',
  },
});

const EstablishmentForm = ({
  handleChange,
  handleSubmit,
  errors,
  isSubmitting,
  values,
}) => (
  <Form horizontal onSubmit={handleSubmit}>
    <Row>
      <Col xs={12} md={8} lg={8}>
        <FormGroup
          controlId="principalId"
          validationState={vState(errors, 'principalId')}
        >
          <ControlLabel>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <FormattedMessage {...messages.code} />
          </ControlLabel>

          <FormControl
            type="text"
            value={values.principalId}
            disabled
          />

          {errors.principalId && <HelpBlock>{errors.principalId}</HelpBlock>}
        </FormGroup>
        <FormGroup controlId="name" validationState={vState(errors, 'name')}>
          <ControlLabel>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <FormattedMessage {...messages.name} />
          </ControlLabel>

          <FormControl
            type="text"
            value={values.name}
            disabled
          />

          {errors.name && <HelpBlock>{errors.name}</HelpBlock>}
        </FormGroup>

        <FormGroup
          controlId="emailAddress"
          validationState={vState(errors, 'emailAddress')}
        >
          <ControlLabel>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <FormattedMessage {...messages.email} />
          </ControlLabel>

          <FormControl
            type="text"
            autoComplete="off"
            value={values.emailAddress}
            onChange={handleChange}
          />

          {errors.emailAddress && (
          <HelpBlock>{errors.emailAddress}</HelpBlock>
          )}
        </FormGroup>
        <hr />

        <FormGroup controlId="formActions">
          <ButtonToolbar>
            <Button href="/email-establishment/">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...genericMessages.cancel} />
            </Button>

            <Button
              type="submit"
              disabled={isSubmitting || Object.keys(errors).length > 0}
            >
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormattedMessage {...genericMessages.save} />
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Col>
    </Row>
  </Form>
);

EstablishmentForm.propTypes = {
  // Own props
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,

  errors: PropTypes.shape({
    id: PropTypes.string,
    principalId: PropTypes.string,
    name: PropTypes.string,
    emailAddress: PropTypes.string,
  }),

  values: PropTypes.shape({
    id: PropTypes.string,
    principalId: PropTypes.string,
    name: PropTypes.string,
    emailAddress: PropTypes.string,
  }).isRequired,
};

// noinspection JSCheckFunctionSignatures
export default injectIntl(EstablishmentForm);
