import jQuery from 'jquery';
import PropTypes from 'prop-types';
import { mergeDeepRight, path } from 'ramda';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import 'codemirror/lib/codemirror.css';
import 'summernote/dist/summernote.css';

import IntlShape from '../lib/PropTypes/IntlShape';
import clearElement from '../lib/clearElement';
import { createSecureFileFromBase64 } from '../modules/helpers';
import { getLocale } from '../modules/selectors/locale';

import './Summernote.less';

const { defaultLocale, localeMapSummernote, supportedLocales } = require(`../../projects/${process.env.RAZZLE_PROJECT}/common`); // eslint-disable-line

/**
 * Forbidden tags in Office-pasted contents.
 */
const badHtmlTags = ['applet', 'embed', 'noframes', 'noscript', 'script', 'style'];
const ToolbarPropType = (props, propName, componentName) => {
  // toolbar is optional
  if (!props[propName]) return;

  const value = props[propName];

  if (!value || value.constructor !== Array) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`,` +
      ' expected `array`.',
    );
  }

  for (let i = 0; i < value.length; i += 1) {
    const option = value[i];

    if (!option[0] || option[0].constructor !== String) {
      return new Error(
        `Invalid prop \`${propName}\`.\`${i}\`.\`0\` supplied to \`${componentName}\`,` +
        ' expected `string`.',
      );
    }

    if (!option[1] || option[1].constructor !== Array) {
      return new Error(
        `Invalid prop \`${propName}\`.\`${i}\`.\`1\` supplied to \`${componentName}\`,` +
        ' expected `array`.',
      );
    }

    for (let j = 0; j < option[1].length; j += 1) {
      if (!option[1][j] || option[1][j].constructor !== String) {
        return new Error(
          `Invalid prop \`${propName}\`.\`${i}\`.\`1\`.\`${j}\` supplied to` +
          ` \`${componentName}\`, expected \`string\`.`,
        );
      }
    }
  }
};

class Summernote extends Component {
  static propTypes = {
    // react-intl props
    intl: IntlShape.isRequired,

    // own props
    fileUploadHandler: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.shape({}),
    toolbar: ToolbarPropType,
    value: PropTypes.string,

    // state props
    axiosConfig: PropTypes.shape({
      baseURL: PropTypes.string.isRequired,
      headers: PropTypes.shape({
        Authorization: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    locale: PropTypes.string.isRequired,
  };

  static defaultProps = {
    options: {},
    toolbar: undefined,
    value: undefined,
  };

  constructor(props) {
    super(props);

    this.editorElement = React.createRef();
  }

  componentDidMount() {
    const {
      intl: { formatMessage },
      axiosConfig: { baseURL, headers },
      fileUploadHandler,
      locale, onChange,
      options, toolbar: optionalToolbar,
    } = this.props;
    const { editorElement } = this;

    /* eslint-disable import/no-dynamic-require */
    // Required for summernote-video-attributes to work.
    window.jQuery = jQuery;

    require('summernote/dist/summernote');
    require('../lib/external/summernote-video-attributes');
    supportedLocales.filter((locale) => locale !== defaultLocale).forEach((locale) => {
      require(`summernote/lang/summernote-${localeMapSummernote[locale]}`);
      require(`../lib/external/summernote-video-attributes-${localeMapSummernote[locale]}`);
    });

    require('bootstrap/js/modal');
    require('bootstrap/js/dropdown');
    require('bootstrap/js/tooltip');
    /* eslint-enable import/no-dynamic-require */

    const toolbar = [
      ['style', ['style']],
      ['font', ['bold', 'italic', 'clear']],
      ['para', ['ul', 'ol', 'paragraph', 'clearElement']],
      ['table', ['table']],
      ['insert', ['link', 'picture', 'videoAttributes']],
      ['view', ['fullscreen', 'codeview']],
    ];

    if (optionalToolbar) {
      optionalToolbar.forEach(option => toolbar.push(option));
    }

    const mergedOptions = mergeDeepRight({
      buttons: { clearElement: clearElement(formatMessage) },
    }, options);

    // jQuery(editorElement).summernote('destroy');
    jQuery(editorElement.current).summernote({
      lang: localeMapSummernote[locale],
      height: 350,
      toolbar,
      buttons: { clearElement: clearElement(formatMessage) },
      ...mergedOptions,
      callbacks: {
        onChange,
        onImageUpload: (files) => {
          const readFileAsDataURL = (file) => {
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = () => {
              let handler;

              if (typeof fileUploadHandler === 'function') {
                handler = () => fileUploadHandler(file, reader.result);
              } else {
                handler = () => createSecureFileFromBase64(
                  reader.result,
                  file.name,
                  baseURL,
                  path(['Authorization'], headers),
                )
                  .then(({ object: { attributes: { file } }, name }) => ({ file, name }));
              }

              handler().then(({ file, name }) => {
                jQuery(editorElement.current).summernote('insertImage', file, name);
              })
                .catch((error) => {
                  console.error(`[OI] Summernote onImageUpload
                    ${typeof fileUploadHandler === 'function' ? 'fileUploadHandler' : 'createSecureFileFromBase64'}
                    error=${error}`);
                });
            };
            reader.onerror = (error) => {
              console.error(`[OI] Summernote onImageUpload readAsDataURL error=${error}`);
            };
          };

          for (let i = 0; i < files.length; i += 1) {
            readFileAsDataURL(files[i]);
          }
        },
        onPaste: () => {
          /**
           * Process the pasted editor contents in a future event loop
           * iteration, because this event is called before the pasted contents
           * are available in the editor.
           */
          setTimeout(() => {
            /**
             * Get the current editor contents (after the paste event).
             */
            const original = jQuery(editorElement.current).summernote('code');

            /**
             * Replace Windows-style line endings.
             */
            let output = original.replace(/\r\n/g, '\n');

            /**
             * Clear comments inserted throughout the HTML by Office.
             */
            const commentRe = new RegExp('<\\!--[\\s\\S]*?-->', 'g');
            output = output.replace(commentRe, '');

            /**
             * Clear Office-specific tags that have no semantic meaning.
             */
            const msoGarbageTagsRe = new RegExp('</?(meta|link|\\?xml:|st1:|o:|font)(.*?)>', 'gi');
            output = output.replace(msoGarbageTagsRe, '');

            /**
             * Remove tags that are forbidden with their entire contents.
             */
            badHtmlTags.forEach((badTag) => {
              const badTagRe = new RegExp(`<${badTag}\\b.*>[\\s\\S]*?</${badTag}>`, 'gi');
              output = output.replace(badTagRe, '');
            });

            /**
             * Condense multiple subsequent newlines to a single one.
             */
            output = output.replace(/\n+/g, '\n');

            /* eslint-disable prefer-arrow-callback */
            /* eslint-disable func-names */
            const $output = jQuery(`<div>${output}</div>`);

            /**
             * Sniff out lists created by Office. These are special paragraphs
             * with classes that describe what kind of list element they are (a
             * first list element, middle and last).
             */
            const $firstItems = $output.find('p').filter(function () {
              return /MsoList.*?First/g.test(this.className);
            });
            const $lists = [];
            $firstItems.each(function () {
              const $item = jQuery(this)
                .nextUntil('.MsoListParagraphCxSpLast')
                .addBack()
                .next()
                .addBack();

              $lists.push($item);
            });
            $lists.push($output.find('.MsoListParagraph'));
            if ($lists.length !== 0) {
              $lists.forEach(function ($list) {
                if ($list.length > 0) {
                  /**
                   * Sniff out if this is an ordered or unordered list by
                   * checking the contents of the HTML children. If Symbol or
                   * Wingdings is used as font for some child element, it is an
                   * unordered list; if the text contents of the HTML nodes
                   * starts with a number, it is ordered.
                   */
                  const hasUnorderedFont = /[\s\S]*?(Symbol|Wingdings)[\s\S]*?/.test($list.html());
                  const hasOrderedNumber = /[0-9]/.test($list.text()[0]);
                  const unordered = hasUnorderedFont || !hasOrderedNumber;

                  $list.each(function () {
                    /**
                     * Detect whether this is a nested list by examining the
                     * special Office 'level[2-9]' style property.
                     */
                    const nested = !!(/[\s\S]*?level[2-9][\s\S]*/.test(this.outerHTML));

                    const $this = jQuery(this);
                    let newText;

                    if (unordered) {
                      /**
                       * Strip the Symbol/Wingdings character and non-breaking
                       * spaces (&nbsp; as HTML entity, but 0xa0 in ASCII) that
                       * separate the bullet from the list item.
                       */
                      newText = $this.text().replace(
                        /[^0-9][\xa0\n]*([^\xa0\n][\s\S]*)/,
                        '$1',
                      );
                    } else {
                      /**
                       * Strip the list item number and non-breaking spaces
                       * (&nbsp; as HTML entity, but 0xa0 in ASCII) that
                       * separate the number from the list item.
                       */
                      newText = $this.text().replace(
                        /[0-9](?:\.|\))[\xa0\n]*([^\xa0\n][\s\S]*)/,
                        '$1',
                      );
                    }

                    $this.html(newText);

                    /**
                     * Handle nested lists by inserting new lists as children
                     * of the current list item.
                     */
                    if (nested) {
                      if (unordered) {
                        $this.wrapInner('<ul>\n<li>');
                      } else {
                        $this.wrapInner('<ol>\n</li>');
                      }
                    }
                  });

                  /**
                   * Wrap all list items in <li> tags.
                   */
                  $list.wrapInner('<li>');

                  /**
                   * Wrap all list items in a <ul> or <ol> tag.
                   */
                  if (unordered) {
                    $list.wrapAll('<ul>');
                  } else {
                    $list.wrapAll('<ol>');
                  }

                  /**
                   * Remove the containing paragraphs Office had created.
                   */
                  $list.find('li')
                    .filter(function () { return this.parentNode.tagName === 'P'; })
                    .unwrap();
                }
              });
            }

            /**
             * Delete empty paragraphs.
             */
            $output.find('p').filter(function () {
              /**
               * 0xa0 is the ASCII representation of a non-breaking space
               * (&nbsp; as HTML entity). Paragraphs that only have this as
               * contents can be removed.
               */
              return /^\xa0$/g.test(jQuery(this).text());
            }).remove();

            output = $output.html();
            /* eslint-enable func-names */
            /* eslint-enable prefer-arrow-fallback */

            /**
             * Separate lists with newlines.
             */
            output = output.replace(/([^\n])<ul>/g, '$1\n<ul>');
            output = output.replace(/<\/ul>([^\n])/g, '</ul>\n$1');
            output = output.replace(/([^\n])<ol>/g, '$1\n<ol>');
            output = output.replace(/<\/ol>([^\n])/g, '</ol>\n$1');

            /**
             * Convert special paragraphs to semantic HTML tags.
             */
            output = output.replace(
              /<div style="[^"]*">([\s\S]*?)<\/div>/g,
              '<div>$1</div>',
            );
            output = output.replace(
              /<p class="MsoNormal"(?: style="[^"]*")>([\s\S]*?)?<\/p>/g,
              '<p>$1</p>',
            );
            output = output.replace(/<p class="MsoTitle">([\s\S]*?)<\/p>/g, '<h1>$1</h1>');
            output = output.replace(/<p class="MsoSubTitle">([\s\S]*?)<\/p>/g, '<h2>$1</h2>');
            output = output.replace(
              /<span class="Mso(?:Subtle|Intense)?Emphasis">([\s\S]*?)<\/span>/g,
              '<em>$1</em>',
            );
            output = output.replace(
              /<p class="Mso(?:Subtle|Intense)?Quote"(?: style="[^"]*")?>([\s\S]*?)<\/p>/g,
              '<blockquote>$1</blockquote>',
            );

            /**
             * Remove Office classes.
             */
            output = output.replace(/ class="Mso[A-Za-z]+"/g, '');

            /**
             * Remove Office text alignment that is duplicated by style
             * properties.
             */
            output = output.replace(/ align="[^"]*"/g, '');

            /**
             * Remove Office span elements with styling.
             */
            output = output.replace(
              /<span(?: lang="[^"]")? style="[^"]*mso[^"]*">([\s\S]*?)<\/span>/g,
              '$1',
            );

            /**
             * Remove LibreOffice styling of headers.
             */
            output = output.replace(/<h([1-6]) class="[^"]">/g, '<h$1>');

            /**
             * Finally, replace the editor contents with our filtered ones.
             */
            jQuery(editorElement.current).summernote('code', output);
          }, 10);
        },
      },
    });
  }

  componentWillReceiveProps(nextProps) {
    const { locale, value } = this.props;
    const { locale: nextLocale, value: nextValue } = nextProps;
    const { editorElement } = this;

    if (typeof nextValue === 'string' && value !== nextValue) {
      const noteEditable = jQuery(editorElement.current).find('.note-editable');
      const notePlaceholder = jQuery(editorElement.current).find('.note-placeholder');

      if (jQuery(editorElement.current).summernote('isEmpty') && nextValue.length > 0) {
        notePlaceholder.hide();
      } else if (nextValue.length === 0) {
        notePlaceholder.show();
      }

      noteEditable.html(nextValue);
    }

    if (locale !== nextLocale) {
      jQuery(editorElement.current).summernote({ lang: localeMapSummernote[nextLocale] });
    }
  }

  shouldComponentUpdate() {
    /**
     * Always return false, because we only need to render once in order to
     * create the <div>-element with the DOM reference. All further interaction
     * with the component happen using jQuery and the ref.
     */
    return false;
  }

  componentWillUnmount() {
    const { editorElement } = this;

    if (editorElement) {
      const jQueryElement = jQuery(editorElement.current);

      if (jQueryElement.summernote) {
        jQueryElement.summernote('destroy');
      }
    }
  }

  render() {
    const { value } = this.props;

    return (
      <div
        ref={this.editorElement}
        dangerouslySetInnerHTML={{ __html: value || '' }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  axiosConfig: path(['api', 'endpoint', 'axiosConfig'], state),
  locale: getLocale(state),
});

export default connect(mapStateToProps, null)(injectIntl(Summernote));
