import PropTypes from 'prop-types';
import { path } from 'ramda';
import React, { Component } from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { flashErrorMessage, flashSuccessMessage } from 'redux-flash';
import { deleteResource, readEndpoint } from 'redux-json-api';
import { createSelector } from 'reselect';
import Image from 'react-bootstrap/lib/Image';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Tooltip from 'react-bootstrap/lib/Tooltip';

import 'animate.css';

import getPropTypeScheme from '../../lib/PropTypes';
import IntlShape from '../../lib/PropTypes/IntlShape';
import Constants from '../../modules/constants';
import { canLoggedInUserEditArticle } from '../../modules/selectors/article';
import Author from '../Author';
import Comment from '../Comment';
import CommentWrite from '../Comment/Write';
import { renderContentWithLinks, replaceFileLinks, renderContentWithForms } from '../Forms/helpers';

import './ArticleDetail.less';

const messages = defineMessages({
  articleDeleteFailed: {
    id: 'app.components.Article.ArticleDetail.articleDeleteFailed',
    description: 'Article \'delete failed\' message.',
    defaultMessage: 'There was an error while attempting to delete the article.',
  },
  articleDeleteSuccess: {
    id: 'app.components.Article.ArticleDetail.articleDeleteSuccess',
    description: 'Article \'delete success\' message.',
    defaultMessage: 'The article has been deleted.',
  },
  commentsHeader: {
    id: 'app.components.Article.ArticleDetail.commentsHeader',
    description: 'Article detail commentlist header.',
    defaultMessage: 'Comments',
  },
  deleteArticle: {
    id: 'app.components.Article.ArticleDetail.deleteArticle',
    description: 'Article detail \'delete article\' button label.',
    defaultMessage: 'Delete article',
  },
  editArticle: {
    id: 'app.components.Article.ArticleDetail.editArticle',
    description: 'Article detail \'edit article\' button label.',
    defaultMessage: 'Edit article',
  },
  noComments: {
    id: 'app.components.Article.ArticleDetail.noComments',
    description: 'Article detail \'no comments\' message.',
    defaultMessage: 'No comments yet',
  },
  writeCommentHeader: {
    id: 'app.components.Article.ArticleDetail.writeCommentHeader',
    description: 'Article detail \'write comment\' header.',
    defaultMessage: 'Write comment',
  },
  attachmentHeader: {
    id: 'app.components.Article.ArticleDetail.attachmentHeader',
    description: 'Article detail \'attachment\' header.',
    defaultMessage: '{count, plural, one {Attachment} other {Attachments}}',
  },
});

class ArticleDetail extends Component {
  static propTypes = {
    // react-intl props
    intl: IntlShape.isRequired,

    // own props
    setModalData: PropTypes.func.isRequired,
    editDisabled: PropTypes.bool,

    // state props
    articleTypeId: PropTypes.string,
    id: PropTypes.string,
    forms: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.oneOf(['form']).isRequired,
      id: PropTypes.string.isRequired,
    })),
    userId: PropTypes.string,
    attributes: getPropTypeScheme('ArticleAttributes'),
    relationships: PropTypes.shape({
      attachments: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string.isRequired,
        })),
      }),
    }),
    comments: PropTypes.arrayOf(getPropTypeScheme('Comment')),
    group: PropTypes.oneOfType([
      PropTypes.bool,
      getPropTypeScheme('Group'),
    ]),
    user: getPropTypeScheme('User'),
    canLoggedInUserEditArticle: PropTypes.bool.isRequired,

    // dispatch props
    deleteResource: PropTypes.func.isRequired,
    flashErrorMessage: PropTypes.func.isRequired,
    flashSuccessMessage: PropTypes.func.isRequired,
    readEndpoint: PropTypes.func.isRequired,
    replaceFileLinks: PropTypes.func.isRequired,
    renderContentWithForms: PropTypes.func.isRequired,
  };

  static defaultProps = {
    forms: [],
  };

  constructor() {
    super();

    this.loadNextComment = this.loadNextComment.bind(this);
  }

  state = {
    comments: [],
    loading: true,
  };

  componentWillMount() {
    const {
      readEndpoint,
      attributes: { numberOfComments },
      id,
    } = this.props;

    readEndpoint(`comment?filter[content-type__model__exact]=article&filter[object-pk__exact]=${id}&page_size=${Constants.COMMENT_DETAIL_PAGE_SIZE}`)
      .then(({ body: { data: comments } }) => {
        this.setState({ numberOfComments, comments, loading: false });
      });
  }

  onClickDeleteButton() {
    const {
      deleteResource, flashErrorMessage, flashSuccessMessage,
      id,
      setModalData,
      intl: { formatMessage },
    } = this.props;

    const resource = { type: 'article', id };

    deleteResource(resource)
      .then(() => {
        setModalData('modalArticle', false);
        flashSuccessMessage(formatMessage(messages.articleDeleteSuccess));
      })
      .catch((error) => {
        console.error(`[OI] ArticleDetail: deleteResource failed: ${error}`);
        flashErrorMessage(formatMessage(messages.articleDeleteFailed));
      });
  }

  onClickEditButton() {
    const {
      id,
      setModalData,
      attributes,
      relationships,
      group,
      userId,
    } = this.props;

    // closes the article modal
    setModalData('modalArticle', false);

    // set article data for modal
    setModalData('modalEditArticle', {
      articleType: path(['articleType', 'data', 'id'], relationships),
      groupId: path(['id'], group),
      userId,
      id,
      ...attributes,
      attachments: relationships.attachments,
    });
  }

  loadNextComment(comment) {
    let { numberOfComments, comments } = this.state;
    numberOfComments += 1;
    comments = comments.concat(comment);
    this.setState({ numberOfComments, comments });
  }

  renderMeta() {
    const {
      articleTypeId,
      editDisabled,
      canLoggedInUserEditArticle,
    } = this.props;

    const isFacebook = articleTypeId === Constants.ARTICLE_TYPE_FACEBOOK;

    return [
      <Author {...this.props} key="meta-author" />,
      (!editDisabled && canLoggedInUserEditArticle && !isFacebook) &&
        <ButtonToolbar className="options" key="meta-button-toolbar" >
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="tooltip-file-add">
                <FormattedMessage {...messages.editArticle} />
              </Tooltip>
            }
          >
            <Button onClick={() => this.onClickEditButton()}>
              <Glyphicon glyph="pencil" />
            </Button>
          </OverlayTrigger>

          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="tooltip-file-add">
                <FormattedMessage {...messages.deleteArticle} />
              </Tooltip>
            }
          >
            <Button onClick={() => this.onClickDeleteButton()}>
              <Glyphicon glyph="trash" />
            </Button>
          </OverlayTrigger>
        </ButtonToolbar>,
    ];
  }

  renderComments() {
    const { id } = this.props;
    const { numberOfComments, comments } = this.state;

    return (
      <div className="article-comments">
        <h3>
          <FormattedMessage {...messages.commentsHeader} />
        </h3>

        {numberOfComments > 0 ? (
          <div className="has-comments">
            {comments.slice(0, Constants.COMMENT_DETAIL_PAGE_SIZE).map(props => (
              <Comment key={props.id} {...props} />
            ))}
          </div>
        ) : (
          <div className="no-comments">
            <FormattedMessage {...messages.noComments} />
          </div>
        )}

        <h3>
          <FormattedMessage {...messages.writeCommentHeader} />
        </h3>

        <CommentWrite articleId={id} loadNextComment={this.loadNextComment} />
      </div>
    );
  }

  render() {
    const {
      attributes: { allowComments, content, cover },
      articleTypeId,
      forms,
      id,
      replaceFileLinks,
      renderContentWithForms,
    } = this.props;
    const { loading } = this.state;

    if (!id || loading || forms === false) return null;

    const isFacebook = articleTypeId === Constants.ARTICLE_TYPE_FACEBOOK;

    return (
      <div className="article-detail">
        <div className="article-meta">{this.renderMeta()}</div>

        <hr />

        {cover && <Image responsive src={cover} style={{ width: '100%' }} />}
        {cover && <hr />}

        <div className="article-detail-body">
          {isFacebook ?
            <div className="content">{renderContentWithLinks(content)}</div> :
            <div className="content">{renderContentWithForms(replaceFileLinks(content))}</div>
          }
        </div>

        {allowComments &&
          <div className="article-detail-comments">
            {this.renderComments()}
          </div>
        }
      </div>
    );
  }
}

const mapDispatchToProps = {
  deleteResource,
  flashErrorMessage,
  flashSuccessMessage,
  readEndpoint,
  replaceFileLinks,
  renderContentWithForms,
};

const selectArticleTypeId = (state, ownProps) => path(['relationships', 'articleType', 'data', 'id'], ownProps);
const selectForms = state => path(['api', 'form', 'data'], state);
const getForms = createSelector(selectForms, forms => forms);

const mapStateToProps = (state, ownProps) => ({
  articleTypeId: selectArticleTypeId(state, ownProps),
  canLoggedInUserEditArticle: canLoggedInUserEditArticle(state, ownProps),
  forms: getForms(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ArticleDetail));
