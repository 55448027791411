import PropTypes from 'prop-types';

export const EventAttributes = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  startdate: PropTypes.string,
  enddate: PropTypes.string,
  starttime: PropTypes.string,
  endtime: PropTypes.string,
  location: PropTypes.string,
});

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  attributes: EventAttributes,
});
