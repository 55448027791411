const { supportedLocales } = require(`../../../projects/${process.env.RAZZLE_PROJECT}/common`); //eslint-disable-line

export const LOCALE_SET = 'LOCALE_SET';

export const setLocale = locale => async (dispatch) => {
  if (supportedLocales.indexOf(locale) === -1) return;

  /**
   * Protect against accessing `document` outside browser environments.
   */
  try {
    if (document) {
      const exp = new Date();
      exp.setTime(exp + (365 * 24 * 60 * 60 * 1000));

      document.cookie = `openintranet_locale_cookie=${locale}; expires=${exp.toUTCString()}`;
    }
  } catch (error) {
    console.error(`[OI] setLocale('${locale}'): failed to set cookie: ${error}`);
  }

  return dispatch({ type: LOCALE_SET, payload: locale });
};
