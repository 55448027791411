import { defineMessages } from 'react-intl';

const messages = defineMessages({
  all: {
    id: 'app.components.MediaLibrary.messages.all',
    description: 'All field label.',
    defaultMessage: 'All',
  },
  fileSelect: {
    id: 'app.components.MediaLibrary.messages.fileSelect',
    description: 'File selection button label.',
    defaultMessage: 'Select a file',
  },
  group: {
    id: 'app.components.MediaLibrary.messages.group',
    description: 'Group field label.',
    defaultMessage: 'Group',
  },
  permissionTypeAll: {
    id: 'app.components.MediaLibrary.messages.permissionTypeAll',
    description: '\'All\' permission type label.',
    defaultMessage: 'all',
  },
  permissionTypeChildren: {
    id: 'app.components.MediaLibrary.messages.permissionTypeChildren',
    description: '\'Children\' permission type label.',
    defaultMessage: 'this folder and its children',
  },
  permissionTypeSelect: {
    id: 'app.components.MediaLibrary.messages.permissionTypeSelect',
    description: '\'Select a type\' permission type label.',
    defaultMessage: 'Select a type...',
  },
  permissionTypeThis: {
    id: 'app.components.MediaLibrary.messages.permissionTypeThis',
    description: '\'This\' permission type label.',
    defaultMessage: 'this folder',
  },
  tooltipAddFolder: {
    id: 'app.components.MediaLibrary.messages.tooltipAddFolder',
    description: 'Folder actions \'Add folder\' button tooltip.',
    defaultMessage: 'Add folder',
  },
  tooltipEditFolder: {
    id: 'app.components.MediaLibrary.messages.tooltipEditFolder',
    description: 'Folder actions \'Edit folder\' button tooltip.',
    defaultMessage: 'Edit folder',
  },
  type: {
    id: 'app.components.MediaLibrary.messages.type',
    description: 'Type field label.',
    defaultMessage: 'Type',
  },
  user: {
    id: 'app.components.MediaLibrary.messages.user',
    description: 'User field label.',
    defaultMessage: 'User',
  },
});

export default messages;
