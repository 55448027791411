import {
  ARRIVA_SESSION_DESTROY,
  ARRIVA_SET_DRIVE_TOKEN,
  ARRIVA_SET_PERMISSIONS,
  ARRIVA_SET_PERMISSIONS_FETCHED,
} from './actions';

import { registerWithRootReducer } from '../../../../app/modules/reducers/projects';

const initialState = {
  driveToken: '',
  permissions: [],
  permissionsFetched: false,
};

const ACTION_HANDLERS = {
  // Adders / Setters
  [ARRIVA_SESSION_DESTROY]: () => ({ ...initialState }),
  [ARRIVA_SET_DRIVE_TOKEN]: (state, action) => ({ ...state, driveToken: action.payload }),
  [ARRIVA_SET_PERMISSIONS]: (state, action) => ({ ...state, permissions: action.payload }),
  [ARRIVA_SET_PERMISSIONS_FETCHED]: (state) => ({ ...state, permissionsFetched: true }),
};

registerWithRootReducer('arriva', (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
});
