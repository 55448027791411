import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { defineMessages, injectIntl } from 'react-intl';
import constants from '../../../lib/drive-constants';
import {
  get,
  patch,
  post,
  remove,
} from '../../../lib/driveApi';

import { setSoftwarePackages } from '../actions';
import { selectIdentifier, getSoftwarePackageByIdentifier } from '../selectors';
import { softwarePackage as softwarePackageShape } from '../types';

import SoftwarePackageForm from './SoftwarePackageForm';
import { getLocale } from '../../../../../../app/modules/selectors/locale';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import genericMessages from '../../../../../../app/lib/genericMessages';

const messages = defineMessages({
  softwarePackage: {
    id: 'projects.arriva.app.modules.SoftwarePackage.components.SoftwarePackageDetail.softwarePackage',
    description: 'Software Package label.',
    defaultMessage: 'Software package',
  },
});

class SoftwarePackageDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      showDeleteModal: false,
    };
  }

  componentDidMount() {
    const {
      softwarePackage, softwarePackageId, get, setSoftwarePackages,
    } = this.props;

    // Check if the package is false, if so, there are no packages at all
    // and the user probably got here by using the direct url
    if (softwarePackage === false && softwarePackageId) {
      get({ path: `software-package/package/${softwarePackageId}` })
        .then((softwarePackage) => {
          setSoftwarePackages([softwarePackage]);
        })
        .catch((err) => this.setState({ error: err }));
    }
  }

  renderForm() {
    const {
      softwarePackage, history, locale, patch, post,
    } = this.props;

    const initialValues = {
      ...softwarePackage,
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          const body = {
            ...omit(['id', 'createdAt', 'updatedAt'], values),
          };

          (softwarePackage.id ? patch : post)({ path: `software-package/package/${softwarePackage.id ? softwarePackage.id : ''}`, body })
            .then((result) => {
              if (result.message === constants.MSG_SUCCESS) {
                setSubmitting(false);

                history.replace('/software-package');
                return;
              }

              Sentry.captureException(new Error(`Something went wrong: softwarePackageForm post/patch-> ${result}`));
              this.setState({ error: `Er ging iets fout bij het opslaan: ${result}` });
            })
            .catch((error) => {
              console.error(error);
              Sentry.captureException(error);

              setSubmitting(false);
            });
        }}
        validate={(values) => {
          const errors = {};

          const required = ['code', 'title', 'tag'];

          // Make sure the required fields are there
          required.forEach((k) => {
            if (!values[k]) errors[k] = 'Verplicht';
          });

          return errors;
        }}
      >
        {(props) => (
          /* eslint-disable react/jsx-props-no-spreading */
          <SoftwarePackageForm
            {...props}
            locale={locale}
            onClickDelete={() => this.setState({ showDeleteModal: true })}
          />
        )}
      </Formik>
    );
  }

  renderDeleteModal() {
    const { softwarePackage, history, remove } = this.props;
    const { showDeleteModal } = this.state;

    const close = () => this.setState({ showDeleteModal: false });

    const removeSoftwarePackage = () => {
      remove({ path: `software-package/package/${softwarePackage.id}` })
        .catch((err) => {
          this.setState({ error: err });

          Sentry.captureException(
            new Error(`Something went wrong: /software-package/package/${softwarePackage.id} DELETE -> ${err}`),
          );
        })
        .then(() => history.push('/software-package'));
    };

    return (
      <Modal show={showDeleteModal} onHide={close}>
        <Modal.Header>
          <Modal.Title>Verwijderen pakket</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p style={{ padding: 15 }}>
            {`Weet u zeker dat u het pakket: '${softwarePackage.title}' wilt verwijderen?`}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={close}>Sluiten</Button>
          <Button bsStyle="danger" onClick={() => removeSoftwarePackage()}>Verwijderen</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const { softwarePackage, softwarePackageId, intl: { formatMessage } } = this.props;
    const { error } = this.state;

    if (error) {
      // eslint-disable-next-line react/jsx-one-expression-per-line
      return <strong>Er ging iets fout! ({error})</strong>;
    }

    if (!softwarePackage && softwarePackageId) {
      return <strong>Kon het pakket niet vinden!!</strong>;
    }

    return (
      <div className="well">
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        <h2>
          {formatMessage(messages.softwarePackage)}
          {' '}
          {softwarePackage
            ? formatMessage(genericMessages.edit).toLowerCase()
            : formatMessage(genericMessages.add).toLowerCase()}
        </h2>

        {this.renderForm()}
        {this.renderDeleteModal()}
      </div>
    );
  }
}

SoftwarePackageDetail.propTypes = {
  // Own props
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),

  // react-intl props
  intl: IntlShape.isRequired,

  // Dispatch props
  get: PropTypes.func.isRequired,
  patch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  setSoftwarePackages: PropTypes.func.isRequired,

  // State props
  softwarePackage: PropTypes.oneOfType([
    softwarePackageShape,
    PropTypes.bool,
  ]),
  softwarePackageId: PropTypes.string,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => ({
  softwarePackage: getSoftwarePackageByIdentifier(state, props),
  softwarePackageId: selectIdentifier(state, props),
  locale: getLocale(state),
});

const mapDispatchToProps = {
  get,
  patch,
  post,
  remove,
  setSoftwarePackages,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SoftwarePackageDetail));
