import React from 'react';
import Well from 'react-bootstrap/lib/Well';

import UrlEntries from './UrlEntries';

const Dashboard = () => (
  <Well>
    <h2>URL module</h2>
    <UrlEntries />
  </Well>
);

export default Dashboard;
