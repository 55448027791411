import jwtDecode from 'jwt-decode';
import { find, path, propEq } from 'ramda';
import { createSelector } from 'reselect';

import Constants from '../constants';

export const selectUser = (state) => {
  const { session: { user } } = state;

  return user;
};

export const selectUserId = (state) => {
  const { session: { accessToken } } = state;

  if (!accessToken) return undefined;

  try {
    return jwtDecode(accessToken).user_id;
  } catch (exception) {
    Sentry.captureException(exception);

    return undefined;
  }
};

export const isLoggedIn = state => selectUserId(state);

const selectUsers = state => path(['api', 'user', 'data'], state) || [];

export const selectUserFromApi = createSelector([selectUsers, selectUserId], (users, userId) => {
  if (!userId) return;
  if (users.length === 0) return;

  return find(propEq('id', String(userId)))(users); // eslint-disable-line consistent-return
});

export const selectGroupIdsFromCurrentUser = createSelector(
  [selectUsers, selectUserId],
  (users, userId) => {
    if (!userId) return [];
    if (users.length === 0) return [];

    const user = find(propEq('id', String(userId)))(users);

    if (user) return (path(['relationships', 'groups', 'data'], user) || []).map(group => String(group.id));

    return [];
  },
);

export const selectAccessToken = state => path(['session', 'accessToken'], state);

/**
 * Returns whether the currently logged in user is an administrator.
 *
 * @param {Object} state The current Redux state object
 * @returns {Boolean} Whether the user is an administrator or not
 */
export const isAdmin = createSelector(
  [selectUsers, selectUserId],
  (users, userId) => {
    if (!userId || !users || users.length === 0) return false;

    const user = find(propEq('id', String(userId)))(users);

    if (!user) return false;

    const isSuperuser = path(['attributes', 'isSuperuser'], user) || false;
    const isAdminGroupMember = find(propEq('id', Constants.GROUP_ID_ADMINS))(
      path(['relationships', 'groups', 'data'], user) || [],
    ) !== undefined;

    return (isSuperuser || isAdminGroupMember);
  },
);
