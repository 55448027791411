import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HeaderBar from '../header-bar';
import { myxss } from '../form-elements';

class TextInput extends Component {
  static propTypes = {
    _onDestroy: PropTypes.func,
    data: PropTypes.shape({
      field_name: PropTypes.string.isRequired,
      label: PropTypes.string,
      pageBreakBefore: PropTypes.bool,
      inline: PropTypes.bool,
      static: PropTypes.bool,
      required: PropTypes.bool,
    }),
    defaultValue: PropTypes.shape({}),
    editModeOn: PropTypes.func,
    parent: PropTypes.shape({}),
    mutable: PropTypes.bool,
    onEdit: PropTypes.func,
    readOnly: PropTypes.bool,
    handleChange: PropTypes.func,
    error: PropTypes.string,
  };

  render() {
    const {
      _onDestroy,
      data,
      defaultValue,
      editModeOn,
      error,
      parent,
      mutable,
      onEdit,
      readOnly,
      handleChange,
    } = this.props;

    const props = {};

    props.type = 'text';
    props.className = 'form-control';
    props.name = data.field_name;
    props.id = data.field_name;

    if (mutable) {
      props.defaultValue = defaultValue;
      props.ref = (input) => { this[`child_ref_${data.field_name}`] = input; };
    }

    let baseClasses = 'rfb-item';
    if (error) baseClasses += ' error';
    if (data.pageBreakBefore) baseClasses += ' alwaysbreak';

    if (readOnly) {
      props.disabled = 'disabled';
    }

    props.onChange = e => handleChange(data.field_name, e.target.value);

    return (
      <div className={baseClasses}>
        {!mutable &&
          <div>
            {data.pageBreakBefore && <div className="preview-page-break">Page Break</div>}
            <HeaderBar
              parent={parent}
              editModeOn={editModeOn}
              data={data}
              onDestroy={_onDestroy}
              onEdit={onEdit}
              static={data.static}
              required={data.required}
            />
          </div>
        }
        <div className="form-group">
          <label htmlFor={props.id}>
            <span dangerouslySetInnerHTML={{ __html: myxss.process(data.label) }} />{
            }{ (data.hasOwnProperty('required') && data.required && !readOnly) &&
              <span className="label-required label label-danger">Verplicht</span>
            }
          </label>
          <input {...props} />

          {error && <div className="form-group-error">{error}</div>}
        </div>
      </div>
    );
  }
}

const getSubmissionValue = field => field.value;

export {
  TextInput as default,
  getSubmissionValue,
};
