import PropTypes from 'prop-types';

export const GroupAttributes = PropTypes.shape({
  name: PropTypes.string,
  isSystem: PropTypes.bool,
  groupType: PropTypes.string,
});

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  attributes: GroupAttributes,
});
