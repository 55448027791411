/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { filter, find, indexOf, path, propEq } from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { flashErrorMessage, flashSuccessMessage } from 'redux-flash';
import { readEndpoint } from 'redux-json-api';
import { createSelector } from 'reselect';

import Loading from '../../Loading';
import { renderAbstractWithBadges } from '../helpers';
import { addFormSubmission } from '../reducerActions';
import * as FormElements from './form-elements';

class ReactForm extends Component {
  static propTypes = {
    // Dispatch props.
    addFormSubmission: PropTypes.func.isRequired,
    flashErrorMessage: PropTypes.func.isRequired,
    flashSuccessMessage: PropTypes.func.isRequired,
    readEndpoint: PropTypes.func.isRequired,

    // other props
    actionMessage: PropTypes.arrayOf(PropTypes.shape({})),
    actionRedirect: PropTypes.arrayOf(PropTypes.shape({})),
    forms: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.oneOf(['form']).isRequired,
      id: PropTypes.string.isRequired,
    })),
    validateForCorrectness: PropTypes.bool,
    formId: PropTypes.string,

    // form props
    action_name: PropTypes.string,
    authenticity_token: PropTypes.string,
    answer_data: PropTypes.shape({}),
    back_action: PropTypes.string,
    back_name: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
    display_short: PropTypes.bool,
    hide_actions: PropTypes.bool,
    form_action: PropTypes.string,
    form_method: PropTypes.string,
    mutable: PropTypes.bool,
    variables: PropTypes.shape([]),
    task_id: PropTypes.number,
    read_only: PropTypes.bool,
  };

  static defaultProps = {
    forms: [],
    validateForCorrectness: false,
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitted = this.handleSubmitted.bind(this);
  }

  state = {
    actionRedirectCount: 5,
    actionRedirectCountDownInterval: null,
    values: [],
    errors: {},
    submitting: false,
    showActionMessage: null,
  };

  componentWillMount() {
    const { formId, forms, readEndpoint } = this.props;

    if (formId) {
      const form = find(propEq('id', formId))(forms);

      if (form) {
        // make sure the form actions are in redux state
        // the email actions get handles by the API on the server
        path(['relationships', 'actionmessageSet', 'data'], form)
          .forEach(a => readEndpoint(`action-message/${a.id}`));
        path(['relationships', 'actionredirectSet', 'data'], form)
          .forEach(a => readEndpoint(`action-redirect/${a.id}`));
      }
    }
  }

  // getSignatureImg(item) {
  //   const $canvas_sig = this.refs[item.field_name].refs[`canvas_${item.field_name}`];
  //   let base64 = $canvas_sig.toDataURL().replace('data:image/png;base64,', '');
  //   let isEmpty = $canvas_sig.isEmpty();
  //   let $input_sig = ReactDOM.findDOMNode(
  //     this.refs[item.field_name].refs[`child_ref_${item.field_name}`]
  //   );
  //
  //   if (isEmpty) {
  //     $input_sig.value = '';
  //   } else {
  //     $input_sig.value = base64;
  //   }
  //   return true;
  // }

  isInvalid(item) {
    const { values } = this.state;

    let invalid = false;

    if (item.required) {
      const value = values[item.field_name];

      if (!value) invalid = true;
    }

    return invalid;
  }

  isIncorrect(item) {
    const { values } = this.state;

    let incorrect = false;

    if (!item.can_have_answer) return incorrect;

    const value = values[item.field_name];

    switch (item.element) {
      case 'Checkboxes':
      case 'RadioButtons':
      case 'Dropdown': {
        const isCorrectValue = option => propEq('correct', true)(option);
        const correctValues = filter(isCorrectValue)(item.options).map(o => o.value);

        // No correct values assigned, so must be correct
        if (correctValues.length === 0) {
          incorrect = false;
        } else if (!value || value.length === 0) {
          incorrect = true;
        } else if (value.constructor === Array) {
          // eslint-disable-next-line no-restricted-syntax
          for (const k in value) {
            // one of the selected values is not correct
            if (indexOf(value[k], correctValues) === -1) {
              incorrect = true;
              break;
            }
          }
        } else {
          incorrect = indexOf(value.trim().toLowerCase(), correctValues) === -1;
        }
        break;
      }

      default:
        return;
    }

    return incorrect;
  }

  _checkboxesDefaultValue(item) {
    const { answer_data } = this.props;
    const defaultChecked = [];

    item.options.forEach(option => defaultChecked.push(answer_data[`option_${option.key}`]));

    return defaultChecked;
  }

  handleChange(field_name, value) {
    const { values } = this.state;
    values[field_name] = value;

    this.setState({ values });
  }

  handleSubmit(event) {
    const {
      addFormSubmission,
      data,
      flashErrorMessage,
      formId,
    } = this.props;

    /**
     * If a submit of a child form happens (e.g. a submission in an Upload
     * element), the `onSubmit` of our form is also triggered, even if the form
     * is not a child in the DOM, because of the way React's synthetic events
     * work. We can however check the `target` and `currentTarget` of the
     * event to check if it is a submission of our form. This works safely for
     * native form submits.
     */
    if (event.target !== event.currentTarget) {
      return;
    }

    event.preventDefault();

    this.setState({ submitting: true });

    const errors = this.validateForm();

    // Only submit if there are no errors.
    if (Object.keys(errors).length < 1) {
      addFormSubmission(data, formId)
        .then(() => this.handleSubmitted())
        .catch((e) => {
          console.log(e);
          flashErrorMessage('Er is iets misgegaan bij het versturen van de gegevens');
        });
    } else {
      this.setState({ submitting: false });
    }
  }

  handleSubmitted() {
    const { actionMessage, actionRedirect } = this.props;

    // Action messages
    if (actionMessage.length > 0) {
      // @TODO: determine out what to do with multiple messages
      this.setState({ showActionMessage: path(['attributes', 'message'], actionMessage[0]) });
    }

    // Action redirects
    if (actionRedirect.length > 0) {
      const countDownInterval = setInterval(() =>
        this.actionRedirectTimer(path(['attributes', 'redirect'], actionRedirect[0])),
      1000);

      this.setState({
        actionRedirectCountDownInterval: countDownInterval,
        showActionRedirectMessage: path(['attributes', 'redirect'], actionRedirect[0]),
      });
    }

    // If there is no action, just thank the user
    if (actionMessage.length === 0 && actionRedirect.length === 0) {
      this.setState({
        showActionMessage: 'Bedankt voor het invullen van het formulier',
      });
    }

    this.setState({ submitting: false });
  }

  actionRedirectTimer(url) {
    const { actionRedirectCount, actionRedirectCountDownInterval } = this.state;

    const newCount = actionRedirectCount - 1;

    if (newCount >= 0) {
      this.setState({ actionRedirectCount: newCount });
    } else {
      clearInterval(actionRedirectCountDownInterval);
      window.location.href = url;
    }
  }

  validateForm() {
    const { data, validateForCorrectness } = this.props;
    const { values } = this.state;

    const errors = Object.assign({}, this.state.errors);

    data.forEach((item, index, array) => {
      let errorMessage = '';

      // if (item.element === 'Signature') {
      //   this.getSignatureImg(item);
      // }

      if (item.required && this.isInvalid(item)) {
        errorMessage = 'Is verplicht!';
      } else if (validateForCorrectness && this.isIncorrect(item)) {
        errorMessage = 'Heeft geen geldige waarde!';
      }

      if (errorMessage !== '') {
        errors[item.id] = errorMessage;
      } else {
        if (errors[item.id]) delete errors[item.id];

        // add value to fields array
        // eslint-disable-next-line no-param-reassign
        array[index] = { ...item, value: values[item.field_name] };
      }
    });

    this.setState({ errors });

    return errors;
  }

  render() {
    const {
      form_action,
      form_method,
      action_name,
      back_name,
      back_action,
      hide_actions,
      answer_data,
      variables,
      display_short,
      authenticity_token,
      task_id,
      data,
      read_only,
      mutable,
    } = this.props;

    const {
      actionRedirectCount,
      errors,
      showActionMessage,
      showActionRedirectMessage,
      submitting,
    } = this.state;

    let data_items = data;

    if (display_short) {
      data_items = data.filter(i => i.alternateForm);
    }

    data_items.forEach((item) => {
      if (item.readOnly && item.variableKey && variables[item.variableKey]) {
        answer_data[item.field_name] = variables[item.variableKey];
      }
    });

    const items = data_items.map((item) => {
      const FormElement = FormElements[item.element];

      const props = {
        mutable,
        key: `form_${item.id}`,
        data: item,
        handleChange: this.handleChange,
        defaultValue: answer_data[item.field_name],
        read_only,
        error: errors ? errors[item.id] : '',
      };

      return <FormElement {...props} ref={(ref) => { this[item.field_name] = ref; }} />;
    });

    const actionName = action_name || 'Verstuur';
    const backName = back_name || 'Annuleren';

    return (
      <div className="react-form-builder-form">
        {submitting && <div className="form-loader"><Loading /></div>}

        {(!submitting && showActionMessage) &&
          <div className="actionMessage">
            {renderAbstractWithBadges(showActionMessage, { image: true })}
          </div>
        }

        {(!submitting && showActionRedirectMessage) &&
          <div className="actionRedirectMessage">
            Na {actionRedirectCount} seconden wordt u doorverwezen naar {showActionRedirectMessage}
          </div>
        }

        {(!submitting && !showActionMessage && !showActionRedirectMessage) &&
          <form
            encType="multipart/form-data"
            ref={(ref) => { this.form = ref; }}
            action={form_action}
            onSubmit={this.handleSubmit}
            method={form_method}
          >
            {authenticity_token &&
              <div style={{ display: 'none' }}>
                <input name="utf8" type="hidden" value="&#x2713;" />
                <input name="authenticity_token" type="hidden" value={authenticity_token} />
                <input name="task_id" type="hidden" value={task_id} />
              </div>
            }

            {items}

            <div className="btn-toolbar">
              {!hide_actions &&
                <input type="submit" className="btn btn-primary btn-big btn-agree" value={actionName} />
              }
              {!hide_actions && back_action &&
                <a href={back_action} className="btn btn-default btn-cancel btn-big">{backName}</a>
              }
            </div>
          </form>
        }
      </div>
    );
  }
}

const selectFormId = (state, props) => path(['formId'], props) || null;
const selectActionMessage = state => path(['api', 'action-message', 'data'], state) || [];
const selectActionRedirect = state => path(['api', 'action-redirect', 'data'], state) || [];
const selectForms = state => path(['api', 'form', 'data'], state);

const getForms = createSelector(selectForms, forms => forms);

const selectForm = createSelector([selectFormId, getForms], (id, forms) => {
  if (!id) return null;

  return find(propEq('id', id))(forms);
});

const getActionMessage =
  createSelector([selectForm, selectActionMessage], (form, actionMessage) => {
    if (!actionMessage || !form) return [];

    return filter(am => path(['relationships', 'form', 'data', 'id'], am) === form.id)(actionMessage);
  });

const getActionRedirect =
  createSelector([selectForm, selectActionRedirect], (form, actionRedirect) => {
    if (!getActionRedirect || !form) return [];

    return filter(ar => path(['relationships', 'form', 'data', 'id'], ar) === form.id)(actionRedirect);
  });

const mapStateToProps = (state, props) => ({
  actionMessage: getActionMessage(state, props),
  actionRedirect: getActionRedirect(state, props),
  forms: getForms(state),
  formId: selectFormId(state, props),
});

const mapDispatchToProps = {
  addFormSubmission,
  flashErrorMessage,
  flashSuccessMessage,
  readEndpoint,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReactForm);
