import { applyMiddleware, compose, createStore } from 'redux';
import { middleware as flashMiddleware } from 'redux-flash';
import createSentryMiddleware from 'redux-sentry-middleware';
import thunk from 'redux-thunk';

// eslint-disable-next-line import/no-cycle
import { refreshAccessTokenMiddleware } from './actions/session';

const composeEnhancer = ((typeof window !== 'undefined') ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : undefined) || compose;

const configureStore = (preloadedState) => {
  const rootReducer = require('./reducers').default;

  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancer(applyMiddleware(
      refreshAccessTokenMiddleware,
      thunk,
      createSentryMiddleware(Sentry, {}),
      flashMiddleware(),
    )),
  );

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers').default;

      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;
