import { Alert, Button } from 'react-bootstrap';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import Well from 'react-bootstrap/lib/Well';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IntlShape from '../../../../../app/lib/PropTypes/IntlShape';
import { get } from '../../lib/driveApi';

import './VehicleWash.less';
import genericMessages from '../../../../../app/lib/genericMessages';

const VehicleWashStatusesDetail = ({
  intl,
  get,
}) => {
  const { identifier } = useParams();
  const [vehicleStatuses, setVehicleStatuses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);

  function fetchVehicleWashStatuses() {
    get({ path: `check/public/byRegion/${identifier}` })
      .then((vehicleStatus) => {
        setVehicleStatuses(vehicleStatus);
      })
      .catch((error) => {
        console.error(`Vehicle statuses: fetching data failed: ${error}`);
        setError(error);
      })
      .finally(() => setIsLoading(false));
    setTimeout(fetchVehicleWashStatuses, 30 * 1000);
  }

  useEffect(() => {
    fetchVehicleWashStatuses();
  }, []);

  function renderError() {
    if (!error) return null;

    const dismissError = () => setError(undefined);

    return (
      <Alert bsStyle="danger" onDismiss={dismissError}>
        <h4>{intl.formatMessage(genericMessages.errorMessage)}</h4>
        <p>{error.toString()}</p>
        <p>
          <Button onClick={dismissError}>
            {intl.formatMessage(genericMessages.closeNotification)}
          </Button>
        </p>
      </Alert>
    );
  }

  return (
    <Well>
      {renderError()}

      {!isLoading && vehicleStatuses.length === 0
        && <strong>{intl.formatMessage(genericMessages.nothingFound)}</strong>}

      {isLoading && <strong>{intl.formatMessage(genericMessages.loadingInProgress)}</strong>}

      {(!isLoading && vehicleStatuses.length > 0) && (
        <div className="vehicle-wash-status">
          {vehicleStatuses.map((vehicle) => {
            let hoursAgo = null;
            let nextCheck = null;
            let vehicleClassName = 'success';
            const now = moment();
            const checkDate = vehicle.CheckType.lastCheck && vehicle.CheckType.lastCheck.date
              ? vehicle.CheckType.lastCheck.date : undefined;
            const checkType = vehicle.CheckType;

            if (checkDate) {
              hoursAgo = moment().diff(moment(checkDate, 'X'), 'hours');

              if (checkType.warning === checkType.success) {
                if (hoursAgo >= checkType.danger) {
                  vehicleClassName = 'danger';
                }

                if (hoursAgo <= checkType.success) {
                  vehicleClassName = 'success';
                }
              }

              if (checkType.warning !== checkType.success) {
                if (hoursAgo > checkType.warning) {
                  vehicleClassName = 'warning';
                }

                if (hoursAgo >= checkType.danger) {
                  vehicleClassName = 'danger';
                }

                if (hoursAgo <= checkType.success && hoursAgo < checkType.warning) {
                  vehicleClassName = 'success';
                }
              }

              nextCheck = moment(checkDate, 'X').add(checkType.danger, 'hours');
            } else {
              vehicleClassName = 'danger';
            }

            return (
              <div className="vehicle-wash-status-grid-cell" key={vehicle.vehicleNumber}>
                <div className={`vehicle-wash-status-container vehicle-wash-status-${vehicleClassName}`}>
                  <div className="vehicleContainer">
                    <Glyphicon bsClass="fa" glyph="train" className="vehicle-status-train" />
                    <h4 className="vehicle-wash-status-number">{vehicle.vehicleNumber}</h4>
                  </div>
                  <span className="hours">
                    {checkDate
                      ? `${Math.abs(nextCheck && nextCheck.diff(now, 'hours'))} uren`
                      : 'Nooit gewassen'}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Well>
  );
};

VehicleWashStatusesDetail.propTypes = {
  // Dispatch props
  get: PropTypes.func.isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
};

const mapDispatchToProps = {
  get,
};
export default connect(null, mapDispatchToProps)(injectIntl(VehicleWashStatusesDetail));
