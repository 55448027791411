import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { omit, path } from 'ramda';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';

import constants from '../../../lib/drive-constants';
import {
  get, patch, post, remove,
} from '../../../lib/driveApi';

import { setUrlEntries } from '../actions';
import { getUrlEntryByIdentifier, selectIdentifier } from '../selectors';
import { urlEntry as urlEntryShape } from '../types';

import UrlEntryForm from './UrlEntryForm';
import { getLocale } from '../../../../../../app/modules/selectors/locale';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import genericMessages from '../../../../../../app/lib/genericMessages';

const messages = defineMessages({
  noAudiences: {
    id: 'projects.arriva.app.modules.UrlEntry.components.UrlEntryDetail.noAudiences',
    description: 'No audiences message',
    defaultMessage: 'At least one group or user should be paired!',
  },
  type: {
    id: 'projects.arriva.app.modules.UrlEntry.components.UrlEntryDetail.type',
    description: 'Type message',
    defaultMessage: 'Type has to be  \'Internal\' or \'External\'',
  },
  urlNotFound: {
    id: 'projects.arriva.app.modules.UrlEntry.components.UrlEntryDetail.urlNotFound',
    description: 'Url not found message',
    defaultMessage: 'URL not found',
  },
  delete: {
    id: 'projects.arriva.app.modules.UrlEntry.components.UrlEntryDetail.delete',
    description: 'Delete URL message',
    defaultMessage: 'Are you sure you want to delete URL {url}?',
  },
});

class UrlEntryDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      showDeleteModal: false,
    };
  }

  componentDidMount() {
    const {
      urlEntry, urlEntryId, get, setUrlEntries,
    } = this.props;

    // Check if the url entry is false, if so, there are no url entries at all
    // and the user probably got here by using the direct url
    // So, load the url entry
    if (urlEntry === false && urlEntryId) {
      get({ path: `url-entry/url-entry/${urlEntryId}` })
        .then(({ data }) => {
          setUrlEntries([data]);
        })
        .catch((err) => this.setState({ error: err }));
    }
  }

  renderForm() {
    const {
      urlEntry, history, locale, patch, post, intl: { formatMessage },
    } = this.props;

    const initialValues = {
      ...urlEntry,
      audiences: (urlEntry.audiences || []).map((audience) => ({
        label: audience.displayName,
        value: {
          id: audience.principalId,
          resourceType: audience.principalType, // specific for react-select
        },
      })),
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          const body = {
            ...omit(['audiences', 'createdAt', 'id', 'updatedAt'], values),
            audiences: (values.audiences || []).map(((audience) => ({
              id: path(['value', 'id'], audience),
              resourceType: path(['value', 'resourceType'], audience),
            }))),
          };

          (urlEntry.id ? patch : post)({ path: `url-entry/url-entry/${urlEntry.id ? urlEntry.id : ''}`, body })
            .then((result) => {
              if (result.message === constants.MSG_SUCCESS) {
                setSubmitting(false);

                history.replace('/url-entry');
                return;
              }

              Sentry.captureException(new Error(`Something went wrong: urlEntryForm post/patch-> ${result}`));
              this.setState({ error: `Er ging iets fout bij het opslaan: ${result}` });
            })
            .catch((error) => {
              console.error(error);
              Sentry.captureException(error);

              setSubmitting(false);
            });
        }}
        validate={(values) => {
          const errors = {};

          const required = ['name', 'url', 'browsingType', 'audiences'];
          const browsingTypeFormat = ['Internal', 'External'];

          // Make sure the required fields are there
          required.forEach((k) => {
            if (!values[k]) errors[k] = formatMessage(genericMessages.required);
            if (k === 'browsingType' && !(values[k] === browsingTypeFormat[0] || values[k] === browsingTypeFormat[1])) {
              errors[k] = formatMessage(messages.type);
            }
          });
          if (!values.audiences || values.audiences.length === 0) {
            errors.audiences = formatMessage(messages.noAudiences);
          }

          return errors;
        }}
      >
        {(props) => (
          /* eslint-disable react/jsx-props-no-spreading */
          <UrlEntryForm
            {...props}
            locale={locale}
            onClickDelete={() => this.setState({ showDeleteModal: true })}
          />
        )}
      </Formik>
    );
  }

  renderDeleteModal() {
    const {
      urlEntry, history, remove, intl: { formatMessage },
    } = this.props;
    const { showDeleteModal } = this.state;

    const close = () => this.setState({ showDeleteModal: false });

    const removeUrlEntry = () => {
      remove({ path: `url-entry/url-entry/${urlEntry.id}` })
        .catch((err) => {
          this.setState({ error: err });

          Sentry.captureException(
            new Error(`Something went wrong: /url-entry/url-entry/${urlEntry.id} DELETE -> ${err}`),
          );
        })
        .then(() => history.push('/url-entry'));
    };

    return (
      <Modal show={showDeleteModal} onHide={close}>
        <Modal.Header>
          <Modal.Title>
            {formatMessage(genericMessages.delete)}
            {' '}
            URL
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p style={{ padding: 15 }}>
            {formatMessage(messages.delete, {
              url: urlEntry.name,
            })}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={close}>{formatMessage(genericMessages.close)}</Button>
          <Button bsStyle="danger" onClick={() => removeUrlEntry()}>{formatMessage(genericMessages.delete)}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const { urlEntry, urlEntryId, intl: { formatMessage } } = this.props;
    const { error } = this.state;

    if (error) {
      // eslint-disable-next-line react/jsx-one-expression-per-line
      return <strong>{formatMessage(genericMessages.errorMessage)} ({error})</strong>;
    }

    if (!urlEntry && urlEntryId) {
      return <strong>{formatMessage(messages.urlNotFound)}</strong>;
    }

    return (
      <div className="well">
        <h2>
          URL
          {' '}
          {urlEntry
            ? formatMessage(genericMessages.edit)
            : formatMessage(genericMessages.add)}
        </h2>

        {this.renderForm()}
        {this.renderDeleteModal()}
      </div>
    );
  }
}

UrlEntryDetail.propTypes = {
  // Own props
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),

  // react-intl props
  intl: IntlShape.isRequired,

  // Dispatch props
  get: PropTypes.func.isRequired,
  patch: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  setUrlEntries: PropTypes.func.isRequired,

  // State props
  urlEntry: PropTypes.oneOfType([
    urlEntryShape,
    PropTypes.bool,
  ]),
  urlEntryId: PropTypes.string,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => ({
  urlEntry: getUrlEntryByIdentifier(state, props),
  urlEntryId: selectIdentifier(state, props),
  locale: getLocale(state),
});

const mapDispatchToProps = {
  get,
  patch,
  post,
  remove,
  setUrlEntries,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UrlEntryDetail));
