import Button from 'react-bootstrap/lib/Button';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Base64ImageCropper from './Base64ImageCropper';

const UploadBase64 = ({
  buttonText,
  cropperProps,
  onFileReady,
  removeBase64DataUriPrefix,
  useCropper,
}) => {
  const fileInputRef = React.createRef();

  const [imageData, setImageData] = useState('');
  const [showImageCropper, setShowImageCropper] = useState(false);

  const _removeBase64DataUriPrefix = (r) => r.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');

  return (
    <div>
      <Button
        bsStyle="primary"
        block
        onClick={() => fileInputRef.current && fileInputRef.current.click()}
      >
        {buttonText}
      </Button>

      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={(e) => {
          const toBase64 = (file) => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              const result = removeBase64DataUriPrefix
                ? _removeBase64DataUriPrefix(reader.result) : reader.result;

              resolve(result);
            };
            reader.onerror = (error) => reject(error);
          });

          toBase64(e.target.files[0]).then(async (data) => {
            if (useCropper) {
              await setImageData(data);
              setShowImageCropper(true);
            } else {
              onFileReady(data);
            }
          });
        }}
      />

      {showImageCropper && (
        <Base64ImageCropper
          data={`data:image/png;base64,${_removeBase64DataUriPrefix(imageData)}`}
          onHide={() => setShowImageCropper(false)}
          onSave={(data) => {
            setShowImageCropper(false);
            const image = removeBase64DataUriPrefix ? _removeBase64DataUriPrefix(data) : data;
            onFileReady(image);
          }}
          {...cropperProps}
        />
      )}
    </div>
  );
};

UploadBase64.defaultProps = {
  buttonText: 'Upload image',
  cropperProps: {
    aspectRatio: 1,
  },
  removeBase64DataUriPrefix: false,
  useCropper: false,
};

UploadBase64.propTypes = {
  // Own props.
  buttonText: PropTypes.string,
  cropperProps: PropTypes.shape({
    aspectRatio: PropTypes.number,
    scale: PropTypes.arrayOf(PropTypes.number),
  }),
  onFileReady: PropTypes.func.isRequired,
  removeBase64DataUriPrefix: PropTypes.bool,
  useCropper: PropTypes.bool,
};

export default UploadBase64;

