import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { destroy } from '../modules/actions/session';
import { selectUser } from '../modules/selectors/session';

class Logout extends Component {
  static propTypes = {
    // react-router props
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,

    // dispatch props
    destroy: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { destroy, history } = this.props;
    destroy()
      .then(() => history.push('/login'));
  };

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  user: selectUser(state),
});

const mapDispatchToProps = {
  destroy,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
