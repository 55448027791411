import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  Button,
  ButtonToolbar,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import genericMessages from '../../../../../../../app/lib/genericMessages';
import IntlShape from '../../../../../../../app/lib/PropTypes/IntlShape';
import { vState } from '../../../../../../../app/lib/utils';
import { getMalfunctionsCategories } from '../../selectors';

const styles = {
  buttonContainer: {
    display: 'flex',
    paddingBottom: '10px',
    flexDirection: 'column',
    maxHeight: '440px',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  button: {
    paddingTop: '20px',
    marginTop: '14px',
    minWidth: '400px',
    height: '70px',
    borderRadius: '4px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 18,
  },
};

const messages = defineMessages({
  vehicle: {
    id: 'projects.arriva.app.modules.Malfunctions.components.MalfunctionsList.vehicle',
    description: 'Vehicle Label',
    defaultMessage: 'Vehicle-number',
  },
  category: {
    id: 'projects.arriva.app.modules.Malfunctions.components.MalfunctionsForm.category',
    description: 'Category label.',
    defaultMessage: 'Select a category',
  },
  comments: {
    id: 'projects.arriva.app.modules.Malfunctions.components.MalfunctionsForm.comments',
    description: 'Comments label.',
    defaultMessage: 'Enter a comment (optional)',
  },
});

const MalfunctionsForm = ({
  categories,
  handleChange,
  handleSubmit,
  errors,
  isSubmitting,
  values,
  setFieldValue,
}) => {
  const history = useHistory();
  const { identifier } = useParams();

  const handleChangeButton = (event) => (
    setFieldValue('category', event)
  );

  useEffect(() => {
    if (values.category) {
      setFieldValue('vehicleNumber', identifier);
    }
    categories.find((cat) => {
      if (cat.value === values.category) {
        setFieldValue('vehicleType', cat.vehicleType);
      }
    });
  }, [values.category]);

  return (
    <Form horizontal onSubmit={handleSubmit}>
      <Row>
        <Col xs={12} md={12} lg={8}>
          <FormGroup controlId="vehicleNumber" validationState={vState(errors, 'vehicleNumber')}>
            <ControlLabel>
              <FormattedMessage {...messages.vehicle} />
            </ControlLabel>
            <FormControl type="text" autoComplete="off" value={identifier} disabled />
            {errors.label && <HelpBlock>{errors.label}</HelpBlock>}
          </FormGroup>
          <FormGroup
            controlId="category"
            validationState={vState(errors, 'category')}
          >
            <ControlLabel>
              <FormattedMessage {...messages.category} />
            </ControlLabel>
            <ToggleButtonGroup
              type="radio"
              name="options"
              onChange={handleChangeButton}
              style={styles.buttonContainer}
            >
              {categories && categories.map((cat) => (
                <ToggleButton value={cat.value} bsStyle="primary" style={styles.button} key={cat.id}>
                  <span style={styles.text}>{cat.label}</span>
                </ToggleButton>
              ),
              )}
            </ToggleButtonGroup>
          </FormGroup>
          <FormGroup controlId="comment" validationState={vState(errors, 'comment')}>
            <ControlLabel>
              <FormattedMessage {...messages.comments} />
            </ControlLabel>
            <FormControl type="text" autoComplete="off" onChange={handleChange} />
            {errors.label && <HelpBlock>{errors.label}</HelpBlock>}
          </FormGroup>
          <hr />

          <FormGroup controlId="formActions">
            <ButtonToolbar>
              <Button bsStyle="danger" onClick={() => history.goBack()}>
                <FormattedMessage {...genericMessages.cancel} />
              </Button>
              <Button type="submit" disabled={isSubmitting || Object.keys(errors).length > 0}>
                <FormattedMessage {...genericMessages.save} />
              </Button>
            </ButtonToolbar>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

MalfunctionsForm.propTypes = {
  // Own props
  categories: PropTypes.shape([]),
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,

  // react-router props.
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,

  errors: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    vehicleType: PropTypes.string,
  }),

  values: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    vehicleType: PropTypes.string,
    category: PropTypes.string,
  }).isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  categories: getMalfunctionsCategories(state),
});

export default connect(mapStateToProps, null)(injectIntl(MalfunctionsForm));
