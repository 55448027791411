import {
  filter, find, prop, propEq, sortBy,
} from 'ramda';

// Global register for all the hooks
const hooks = [];

/**
 * This method will find all handlers by the hooks name
 *
 * @param name
 * @returns {*}
 */
const findHandlersByName = (name) => sortBy(prop('priority'))(filter(propEq('name', name))(hooks));

/**
 * This method will register a new hook
 *
 * @param name
 * @param handler
 * @param priority
 */
export const registerHook = (name, handler, priority = 10) => {
  const handlers = findHandlersByName(name);

  const newHandler = {
    name,
    handler,
    priority,
  };

  // Just register the new hook
  if (handlers.length === 0) return hooks.push(newHandler);

  // Make sure the new priority does not exist already
  if (find(propEq('priority', priority))(handlers)) {
    throw new Error(`Hook with name ${name} already registered with priority: ${priority}`);
  }

  return hooks.push(newHandler);
};

/**
 * This method will only call the hooks asynchronously
 *
 * @param name
 * @param args
 * @param dispatch
 * @returns {*}
 */
export const callHook = async (name, args, dispatch = undefined) => {
  console.log(`Calling hook: "${name}"`);
  const handlers = findHandlersByName(name);

  return Promise.all(handlers.map((h) => h.handler(args, dispatch)));
};

/**
 * This method will call all hooks but will pass the result of the previous to the next
 *
 * @param name
 * @param args
 * @param dispatch
 */
export const callHookChain = (name, args, dispatch = undefined) => {
  console.log(`Calling hook chain: "${name}"`);
  const handlers = findHandlersByName(name);

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let result;

    for (let i = 0; i < handlers.length; i += 1) {
      const _args = i === 0 ? args : result;

      try {
        // eslint-disable-next-line no-await-in-loop
        result = await handlers[i].handler(_args, dispatch);

        if (!handlers[i + 1]) {
          resolve('DONE');
          break;
        }
      } catch (error) {
        reject(error);
      }
    }
  });
};
