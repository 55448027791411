export default {
  ERROR_INVALID_CREDENTIALS: 'ERROR_INVALID_CREDENTIALS',
  ERROR_USER_DOES_NOT_EXIST: 'ERROR_USER_DOES_NOT_EXIST',
  ERROR_NAME_REQUIRED: 'ERROR_NAME_REQUIRED',
  ERROR_USERNAME_REQUIRED: 'ERROR_USERNAME_REQUIRED',
  ERROR_PASSWORD_REQUIRED: 'ERROR_PASSWORD_REQUIRED',
  ERROR_EMAIL_REQUIRED: 'ERROR_EMAIL_REQUIRED',
  ERROR_EMAIL_INVALID: 'ERROR_EMAIL_INVALID',
  ERROR_INVALID_PRIVATE_KEY: 'ERROR_INVALID_PRIVATE_KEY',
  ERROR_INVALID_AUTH_TOKEN: 'ERROR_INVALID_AUTH_TOKEN',
  ERROR_CREATING_DATA: 'ERROR_CREATING_DATA',
  ERROR_FETCHING_DATA: 'ERROR_FETCHING_DATA',
  ERROR_UPDATING_DATA: 'ERROR_UPDATING_DATA',
  ERROR_DELETING_DATA: 'ERROR_DELETING_DATA',
  ERROR_BAD_REQUEST: 'ERROR_BAD_REQUEST',
  ERROR_TOO_MANY_REQUESTS: 'ERROR_TOO_MANY_REQUESTS',
  ERROR_TOKEN_INVALID: 'ERROR_TOKEN_INVALID',
  ERROR_TOKEN_EXPIRED: 'ERROR_TOKEN_EXPIRED',
  ERROR_TOKEN_REQUIRED: 'ERROR_TOKEN_REQUIRED',
  ERROR_USER_ALREADY_ACTIVATED: 'ERROR_USER_ALREADY_ACTIVATED',
  ERROR_PASSWORD_LENGTH: 'ERROR_PASSWORD_LENGTH',
  ERROR_DOES_NOT_EXIST: 'ERROR_DOES_NOT_EXIST',
  ERROR_RECORD_DOES_NOT_EXIST: 'ERROR_RECORD_DOES_NOT_EXIST',
  ERROR_RECORD_ALREADY_EXIST: 'ERROR_RECORD_ALREADY_EXIST',
  ERROR_NOT_AUTHORIZED: 'ERROR_NOT_AUTHORIZED',
  ERROR_RSS_FEED: 'ERROR_RSS_FEED',
  ERROR_RSS_FEED_NO_ITEMS: 'ERROR_RSS_FEED_NO_ITEMS',
  ERROR_GENERIC: 'ERROR_GENERIC',
  ERROR_INVALID_PRINCIPAL_TYPE: 'ERROR_INVALID_PRINCIPAL_TYPE',
  ERROR_PAYLOAD_TOO_LARGE: 'ERROR_PAYLOAD_TOO_LARGE',
  ERROR_BAD_VEHICLE_ID: 'ERROR_BAD_VEHICLE_ID',
  ERROR_UNKNOWN: 'ERROR_UNKNOWN',
  MSG_SUCCESS: 'SUCCESS',
  ACCESS_GRANTED: 'ACCESS_GRANTED',
  TASK_SUCCESS: 'TASK_SUCCESS',
  TASK_ERROR: 'TASK_ERROR',
};
