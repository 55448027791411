import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Col from 'react-bootstrap/lib/Col';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import InputGroup from 'react-bootstrap/lib/InputGroup';

class Select extends Component {
  static propTypes = {
    // own props
    choices: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    controlId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,

    error: PropTypes.string,
    touched: PropTypes.bool,
    value: PropTypes.string.isRequired,

    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
  }

  render() {
    const {
      choices, controlId, name,
      error, touched, value,
      handleBlur, handleChange,
    } = this.props;

    return (
      <FormGroup
        controlId={controlId}
        validationState={error ? 'error' : 'success'}
      >
        <Col componentClass={ControlLabel} sm={3}>
          {name}
        </Col>

        <Col sm={9}>
          <InputGroup>
            <FormControl
              componentClass="select"
              onBlur={handleBlur}
              onChange={handleChange}
              value={value}
            >
              {choices.map(choice => (
                <option
                  key={`choice-${choice.value}`}
                  value={choice.value}
                >
                  {choice.label}
                </option>
              ))}
            </FormControl>

            <FormControl.Feedback />

            {touched && error && (
              <HelpBlock>{error}</HelpBlock>
            )}
          </InputGroup>
        </Col>
      </FormGroup>
    );
  }
}

export default Select;
