import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import {
  post,
} from '../../../../lib/driveApi';
import {
  loadingMalfunctionsCategories,
  loadingMalfunctionsCategoriesDone,
  setMalfunctionsCategories,
} from '../../actions';
import { getLocale } from '../../../../../../../app/modules/selectors/locale';
import IntlShape from '../../../../../../../app/lib/PropTypes/IntlShape';
import genericMessages from '../../../../../../../app/lib/genericMessages';
import MalfunctionsForm from './MalfunctionsForm';

const { RAZZLE_ARRIVA_MALFUNCTIONS_API_KEY } = process.env;

const messages = defineMessages({
  malfunction: {
    id: 'projects.arriva.app.modules.Malfunctions.components.MalfunctionDetail.malfunction',
    description: 'Malfunction label.',
    defaultMessage: 'Malfunction workorder',
  },
});

const MalfunctionsDetailOpen = ({
  post,
  intl: { formatMessage },
  loadingMalfunctionsCategories,
  loadingMalfunctionsCategoriesDone,
  setMalfunctionsCategories,
  locale,
  history,
}) => {
  const [error, setError] = useState(undefined);
  const { identifier } = useParams();

  function fetchMalfunctionsCategories() {
    loadingMalfunctionsCategories();
    post({
      path: 'malfunctions/categories/public/open',
      body: {
        key: RAZZLE_ARRIVA_MALFUNCTIONS_API_KEY,
      },
    })
      .then((result) => {
        setMalfunctionsCategories(result);
      })
      .catch((error) => {
        console.error(`MalfunctionsCategories: fetching data failed: ${error}`);
        setError(error);
      })
      .finally(() => loadingMalfunctionsCategoriesDone());
  }

  useEffect(() => {
    fetchMalfunctionsCategories();
  }, []);

  function renderForm() {
    const initialValues = {};

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          // VehicleType set to "TRAIN", because  "ALL" is not yet implemented.
          // when implemented, set vehicleType to values.vehicleType.
          const body = {
            key: RAZZLE_ARRIVA_MALFUNCTIONS_API_KEY,
            failureCode: values.category,
            vehicleNumber: identifier,
            vehicleType: 'TRAIN',
            description: values.comment,
          };

          post({ path: 'malfunctions/work-order', body })
            .then((result) => {
              if (result.ResultData) {
                setSubmitting(false);
                history.replace({ pathname: '/malfunctions/open-route/confirmation', state: { vehicleNumber: identifier } });

                return;
              }

              Sentry.captureException(new Error(`Something went wrong: workorderForm post/patch-> ${result}`));
              setError(`Something went wrong: ${result}`);
            })
            .catch((error) => {
              console.error(error);
              Sentry.captureException(error);

              setSubmitting(false);
            });
        }}

        validate={(values) => {
          const errors = {};

          const required = ['category'];

          // Make sure the required fields are there
          required.forEach((k) => {
            if (!values[k]) errors[k] = 'Verplicht';
          });

          return errors;
        }}
      >
        {(props) => (
          /* eslint-disable react/jsx-props-no-spreading */
          <MalfunctionsForm
            {...props}
            locale={locale}
          />
        )}
      </Formik>
    );
  }

  return (
    <div className="well">
      <h2>
        {formatMessage(messages.malfunction)}
        {' '}
        {formatMessage(genericMessages.add).toLowerCase()}
      </h2>
      {renderForm()}
    </div>
  );
};

MalfunctionsDetailOpen.propTypes = {
  // Own props
  loadingMalfunctionsCategories: PropTypes.func.isRequired,
  loadingMalfunctionsCategoriesDone: PropTypes.func.isRequired,
  setMalfunctionsCategories: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),

  // react-intl props
  intl: IntlShape.isRequired,

  // State props
  locale: PropTypes.string.isRequired,

  // Dispatch props
  post: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  locale: getLocale(state),
});

const mapDispatchToProps = {
  post,
  loadingMalfunctionsCategories,
  loadingMalfunctionsCategoriesDone,
  setMalfunctionsCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MalfunctionsDetailOpen));
