import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Image from 'react-bootstrap/lib/Image';

const defaultAvatar = require(`../../../projects/${process.env.RAZZLE_PROJECT}/img/avatar-default.svg`); // eslint-disable-line

class Avatar extends PureComponent {
  static propTypes = {
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      url: PropTypes.string,
    }),
  };

  render() {
    const { image } = this.props;

    if (!image.src && image.url)
      image.src = image.url;
    else if (!image.src)
      image.src = defaultAvatar;

    return (<Image src={image.src} alt={image.alt} circle responsive />);
  }
}

export default Avatar;
