import PropTypes from 'prop-types';
import React from 'react';
import {
  Button, ButtonToolbar, Col, ControlLabel, Form, FormControl, FormGroup, HelpBlock, Row,
} from 'react-bootstrap';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import genericMessages from '../../../../../../app/lib/genericMessages';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import { vState } from '../../../../../../app/lib/utils';

const messages = defineMessages({
  notificationText: {
    id: 'projects.arriva.app.modules.UserNotifications.components.NotificationMessageForm.notificationText',
    description: 'Message content',
    defaultMessage: 'Message',
  },
  startDate: {
    id: 'projects.arriva.app.modules.UserNotifications.components.NotificationMessageForm.startDate',
    description: 'Message start date',
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: 'projects.arriva.app.modules.UserNotifications.components.NotificationMessageForm.endDate',
    description: 'Message end date',
    defaultMessage: 'End Date',
  },
  timeCaption: {
    id: 'projects.arriva.app.modules.ArriVideo.components.NotificationMessageForm.timeCaption',
    description: 'Time caption label.',
    defaultMessage: 'Time',
  },
  todayButton: {
    id: 'projects.arriva.app.modules.ArriVideo.components.NotificationMessageForm.todayButton',
    description: 'Today button label.',
    defaultMessage: 'Today',
  },
});

const NotificationMessageForm = ({
  handleChange,
  handleSubmit,
  errors,
  intl,
  isSubmitting,
  locale,
  onClickDelete,
  setFieldValue,
  values,
}) => (
  <Form horizontal onSubmit={handleSubmit}>
    <Row>
      <Col xs={12} md={8} lg={8}>
        <FormGroup controlId="notificationText" validationState={vState(errors, 'notificationText')}>
          <ControlLabel>

            <FormattedMessage {...messages.notificationText} />
          </ControlLabel>

          <FormControl type="text" autoComplete="off" value={Object.keys(values).length > 0 ? values.notificationText : ''} onChange={handleChange} />

          {errors.notificationText && <HelpBlock>{errors.notificationText}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId="startDate" validationState={vState(errors, 'startDate')}>
          <ControlLabel>
            <FormattedMessage {...messages.startDate} />
          </ControlLabel>

          <br />

          <DatePicker
            className="form-control"
            selected={moment(values.startDate).toDate()}
            onChange={(dateTime) => setFieldValue('startDate', dateTime)}
            showTimeSelect
            dateFormat="MMMM d, yyyy HH:mm"
            timeFormat="HH:mm"
            timeCaption={intl.formatMessage(messages.timeCaption)}
            timeIntervals={15}
            todayButton={intl.formatMessage(messages.todayButton)}
            locale={locale}
            minDate={moment().toDate()}
          />

          {errors.startDate && <HelpBlock>{errors.startDate}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId="endDate" validationState={vState(errors, 'endDate')}>
          <ControlLabel>
            <FormattedMessage {...messages.endDate} />
          </ControlLabel>

          <br />

          <DatePicker
            className="form-control"
            selected={moment(values.endDate).toDate()}
            onChange={(dateTime) => setFieldValue('endDate', dateTime)}
            showTimeSelect
            dateFormat="MMMM d, yyyy HH:mm"
            timeFormat="HH:mm"
            timeCaption={intl.formatMessage(messages.timeCaption)}
            timeIntervals={15}
            todayButton={intl.formatMessage(messages.todayButton)}
            locale={locale}
            minDate={moment(values.startDate).toDate()}
          />

          {errors.startDate && <HelpBlock>{errors.startDate}</HelpBlock>}
        </FormGroup>

        <FormGroup controlId="formActions">
          <ButtonToolbar>
            {values.id ? (
              <Button bsStyle="danger" onClick={() => onClickDelete()}>
                <FormattedMessage {...genericMessages.delete} />
              </Button>
            ) : (
              <Button type="submit" disabled={isSubmitting || Object.keys(errors).length > 0}>
                <FormattedMessage {...genericMessages.formSubmitLabel} />
              </Button>
            )}
          </ButtonToolbar>
        </FormGroup>
      </Col>
    </Row>
  </Form>
);

NotificationMessageForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,

  errors: PropTypes.shape({
    notificationText: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),

  values: PropTypes.shape({
    id: PropTypes.string,
    notificationText: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,

  // react-intl props
  intl: IntlShape.isRequired,
  locale: PropTypes.string.isRequired,
};

export default injectIntl(NotificationMessageForm);
