import {
  URLENTRY_LOADING_URLENTRIES,
  URLENTRY_LOADING_URLENTRIES_DONE,
  URLENTRY_SET_URLENTRIES,
} from './actions';

import { registerWithRootReducer } from '../../../../../app/modules/reducers/projects';

const ACTION_HANDLERS = {
  // Adders / Setters
  [URLENTRY_SET_URLENTRIES]: (state, action) => ({
    ...state,
    urlEntries: action.payload,
  }),

  // Loaders
  [URLENTRY_LOADING_URLENTRIES]: (state) => ({ ...state, loadingUrlEntries: true }),
  [URLENTRY_LOADING_URLENTRIES_DONE]: (state) => ({ ...state, loadingUrlEntries: false }),
};

const initialState = {
  urlEntries: [],
  loadingUrlEntries: false,
};

registerWithRootReducer('urlEntry', (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
});
