import React from 'react';
import Well from 'react-bootstrap/lib/Well';

import { defineMessages, injectIntl } from 'react-intl';
import SoftwarePackages from './SoftwarePackages';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';

const messages = defineMessages({
  softwarePackages: {
    id: 'projects.arriva.app.modules.SoftwarePackage.components.Dashboard.softwarePackages',
    description: 'Software Packages label.',
    defaultMessage: 'Software packages',
  },
});

const Dashboard = ({ intl: { formatMessage } }) => (
  <Well>
    <h2>{formatMessage(messages.softwarePackages)}</h2>
    <SoftwarePackages />
  </Well>
);

Dashboard.propTypes = {
  // react-intl props
  intl: IntlShape.isRequired,
};

export default (injectIntl(Dashboard));
