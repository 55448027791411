import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createResource, readEndpoint } from 'redux-json-api';

import IntlShape from '../../lib/PropTypes/IntlShape';
import genericMessages from '../../lib/genericMessages';
import { selectContentTypeByModelName } from '../../modules/selectors/api';
import { selectUserFromApi, selectUserId } from '../../modules/selectors/session';
import CommentForm from './CommentForm';

class Write extends Component {
  static propTypes = {
    // react-intl props
    intl: IntlShape.isRequired,

    // own props
    articleId: PropTypes.string,
    loadNextComment: PropTypes.func.isRequired,

    // state props
    articleContentType: PropTypes.shape({ id: PropTypes.string.isRequired }),
    baseURL: PropTypes.string.isRequired,
    userId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,

    // dispatch props
    createResource: PropTypes.func.isRequired,
    readEndpoint: PropTypes.func.isRequired,
  };

  static defaultProps = { articleContentType: undefined };

  state = {
    loading: true,
  };

  componentDidMount() {
    const { articleContentType, readEndpoint, userId } = this.props;

    readEndpoint(`user/${userId}`)
      .then(() => {
        if (!articleContentType) {
          readEndpoint('content-type')
            .then(() => this.setState({ loading: false }));
        } else {
          this.setState({ loading: false });
        }
      });
  }

  render() {
    const {
      intl: { formatMessage },
      articleContentType,
      articleId, baseURL, createResource, loadNextComment, userId,
    } = this.props;

    const { loading } = this.state;

    if (loading) return <span>Loading...</span>;

    const articleContentTypeId = articleContentType && articleContentType.id;

    return (
      <Formik
        component={CommentForm}
        initialValues={{ comment: '' }}
        onSubmit={(values, { resetForm, setErrors, setSubmitting }) => {
          const resource = {
            type: 'comment',
            attributes: {
              user: `${baseURL}/user/${userId}`,
              comment: values.comment,
              object_pk: articleId,
              contentType: `${baseURL}/content-type/${articleContentTypeId}`,
            },
          };

          createResource(resource)
            .then((comment) => {
              resetForm();

              return loadNextComment(comment.data);
            })
            .catch(() => setErrors({ comment: formatMessage(genericMessages.formSubmitFailed) }))
            .then(() => {
              setSubmitting(false);

              this.setState({ loading: false });
            });
        }}
        validate={(values) => {
          const errors = {};

          if (!values.comment)
            errors.comment = formatMessage(genericMessages.required);

          return errors;
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  articleContentType: selectContentTypeByModelName(state, 'article'),
  baseURL: path(['api', 'endpoint', 'axiosConfig', 'baseURL'], state),
  user: selectUserFromApi(state),
  userId: selectUserId(state),
});

const mapDispatchToProps = {
  readEndpoint,
  createResource,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Write));
