import PropTypes from 'prop-types';
import {
  find, path, propEq, props,
} from 'ramda';
import React, { useEffect, useState } from 'react';
import { Alert, Button, ProgressBar } from 'react-bootstrap';
import { defineMessages, injectIntl } from 'react-intl';

import IntlShape from '../lib/PropTypes/IntlShape';

const messages = defineMessages({
  busyUploading: {
    id: 'app.components.VimeoUpload.busyUploading',
    description: 'Upload is busy',
    defaultMessage: 'Busy uploading...',
  },
  doneUploading: {
    id: 'app.components.VimeoUpload.doneUploading',
    description: 'Uploading is done',
    defaultMessage: 'Upload is done',
  },
  errorUploading: {
    id: 'app.components.VimeoUpload.errorUploading',
    description: 'An error occured while uploading the video',
    defaultMessage: 'An error occured while uploading the video',
  },
  moveVideoToProject: {
    id: 'app.components.VimeoUpload.moveVideoToProject',
    description: 'Moving the video to the specified project',
    defaultMessage: 'Moving video to the correct project...',
  },
  receiveUploadLinkFromVimeo: {
    id: 'app.components.VimeoUpload.receiveUploadLinkFromVimeo',
    description: 'Receiving an upload link from Vimeo',
    defaultMessage: 'Receiving upload link from Vimeo...',
  },
  startUpload: {
    id: 'app.components.VimeoUpload.startUpload',
    description: 'Uploading has started',
    defaultMessage: 'Start upload...',
  },
  waitForVideoDone: {
    id: 'app.components.VimeoUpload.waitForVideoDone',
    description: 'Waiting for the video to be done transcoding',
    defaultMessage: 'Waiting for video to be done at Vimeo, this could take a while...',
  },
});

const endpoint = process.env.RAZZLE_VIMEO_API_HOST;

// Some default headers used to interact with the Vimeo API
const headers = {
  Accept: 'application/vnd.vimeo.*+json;version=3.4',
  'Content-Type': 'application/json',
};

/**
 * This method will ask Vimeo for an upload location
 *
 * @param accessToken
 * @param size
 * @param name
 * @returns {Promise<any>}
 */
const getUploadObject = (accessToken, size, name) => fetch(`${endpoint}/me/videos`, {
  method: 'POST',
  headers: { ...headers, Authorization: `bearer ${accessToken}` },
  body: JSON.stringify({
    upload: {
      approach: 'tus',
      size,
    },
    name,
    /**
     * For more information on setting the privacy settings
     * @see: https://developer.vimeo.com/api/reference/videos#upload_video
     *
     * For the view setting:
     * @see: https://developer.vimeo.com/api/guides/videos/interact#set-vimeo-privacy
     */
    privacy: {
      comments: 'nobody',
      download: false,
      view: 'nobody',
    },
  }),
}).then((res) => res.json());

/**
 * This method will move a newly uploaded video the the correct project
 *
 * @param accessToken
 * @param projectId
 * @param videoId
 * @returns {Promise<Response>}
 */
const moveVideoToProject = (accessToken, projectId, videoId) =>
  fetch(`${endpoint}/me/projects/${projectId}/videos/${videoId}`, {
    method: 'PUT',
    headers: { ...headers, Authorization: `bearer ${accessToken}` },
  });

/**
 * Method that will retrieve the video object from Vimeo
 *
 * @param accessToken
 * @param videoId
 * @returns {Promise<any>}
 */
const getVideoObject = (accessToken, videoId) => fetch(`${endpoint}/videos/${videoId}`, {
  method: 'GET',
  headers: { ...headers, Authorization: `bearer ${accessToken}` },
})
  .then((res) => res.json());

/**
 * Method that will extract the video embed uri from the html embed code
 * This way we can create an iframe to show it
 *
 * @param vimeoObject
 * @param quality
 * @returns {*}
 */
const extractVideoPreview = ({ files, status }, quality = 'sd') =>
  status === 'available' && props(['link', 'width', 'height'], find(propEq('quality', quality))(files));

/**
 * The VimeoUpload component
 *
 * @param accessToken
 * @param buttonText
 * @param onUploadDone
 * @param projectId
 * @param videoId
 * @param title
 * @returns {*}
 * @constructor
 */
const VimeoUpload = ({
  accessToken,
  buttonText,
  onUploadDone,
  intl: { formatMessage },
  projectId,
  videoId,
  title,
}) => {
  const fileInputRef = React.createRef();

  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState('');
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState('');

  let tus;
  let step = 1;
  const steps = projectId ? 4 : 3;

  // Only load the TUS client in the browser
  useEffect(() => {
    tus = require('tus-js-client/dist/tus');

    if (videoId && !preview) {
      getVideoObject(accessToken, videoId)
        .then((vimeoObject) => setPreview(extractVideoPreview(vimeoObject)));
    }
  });

  return (
    <div>
      {(status && loading) && (
        <Alert bsStyle="info">
          {status}
        </Alert>
      )}

      {(loading && progress > 0 && progress !== 100) && (
        <ProgressBar now={progress} label={`${progress}%`} />
      )}

      {(!loading && preview) && (
        <iframe
          width={preview[1]}
          height={preview[2]}
          frameBorder="0"
          title="Vimeo Player"
          src={preview[0]}
        />
      )}

      <Button
        bsStyle="primary"
        block
        onClick={() => fileInputRef.current && fileInputRef.current.click()}
        style={{ width: 250 }}
      >
        {buttonText}
      </Button>

      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={async (e) => {
          const file = e.target.files[0];

          setLoading(true);
          setStatus(`[${step}/${steps}] ${formatMessage(messages.receiveUploadLinkFromVimeo)}`);
          step = 2;

          const uploadObject = await getUploadObject(accessToken, file.size, title)
            .catch((err) => console.log(err));

          // Create a new tus upload
          const upload = new tus.Upload(file, {
            // From the Vimeo docs:
            // NOTE: This high-end pro tip might not apply to general-purpose resumeable uploads.
            // But if you're using a tus library that breaks the video file into separate chunks
            // based on a maximum size value, we recommend setting the chunk size in the
            // range of 128–512 MB. Smaller chunk sizes might result in slower upload speeds,
            // especially when there are many chunks.
            chunkSize: 268435456, // 256MB
            retryDelays: [0, 1000, 3000, 5000],
            headers: {
              Accept: 'application/vnd.vimeo.*+json;version=3.4',
            },
            uploadUrl: path(['upload', 'upload_link'], uploadObject),
            uploadSize: file.size,
            onError: (error) => {
              setStatus(`${formatMessage(messages.errorUploading)}: ${error}`);
              setLoading(false);

              Sentry.withScope((scope) => {
                scope.setTag('component', 'VimeoUpload');

                Sentry.captureException(error);
              });
            },
            onProgress: (bytesUploaded, bytesTotal) => {
              // This should be done once, but there is no onStartUpload callback for instance
              setStatus(`[${step}/${steps}] ${formatMessage(messages.busyUploading)}`);

              setProgress(Math.round((100 / bytesTotal) * bytesUploaded));
            },
            onSuccess: () => {
              setStatus(formatMessage(messages.doneUploading));
              step = 3;

              const videoId = uploadObject.uri.substr(uploadObject.uri.lastIndexOf('/') + 1);

              if (projectId) {
                setStatus(`[${step}/${steps}] ${formatMessage(messages.moveVideoToProject)}`);
                step = 4;

                moveVideoToProject(accessToken, projectId, videoId);
              }

              setStatus(`[${step}/${steps}] ${formatMessage(messages.waitForVideoDone)}`);

              const _getVideoObject = () => {
                getVideoObject(accessToken, videoId)
                  .then((vimeoObject) => {
                    const preview = extractVideoPreview(vimeoObject);

                    // If not active, Vimeo gives a url that has the default Vimeo preview image
                    if (preview) {
                      setStatus(undefined);
                      setLoading(false);
                      setPreview(preview);

                      onUploadDone(videoId);
                    } else {
                      setTimeout(_getVideoObject, 1000);
                    }
                  });
              };

              setTimeout(_getVideoObject, 1000);
            },
          });

          setStatus(`[${step}/${steps}] ${formatMessage(messages.startUpload)}`);

          // Start the upload
          upload.start();
        }}
      />
    </div>
  );
};

VimeoUpload.defaultProps = {
  buttonText: 'Upload video',
};

VimeoUpload.propTypes = {
  // Own props
  accessToken: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  projectId: PropTypes.string,
  onUploadDone: PropTypes.func.isRequired,
  videoId: PropTypes.string,
  title: PropTypes.string,

  // react-intl props
  intl: IntlShape.isRequired,
};

export default injectIntl(VimeoUpload);
