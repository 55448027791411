import PropTypes from 'prop-types';

export const urlEntry = PropTypes.shape({
  // Default props
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,

  // Updated and created dates
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
});
