import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Alert,
  Button,
  Table,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import Pagination from '../../../../../../app/components/Pagination';
import { loadingSoftwarePackages, loadingSoftwarePackagesDone, setSoftwarePackages } from '../actions';
import { get } from '../../../lib/driveApi';
import { softwarePackage as softwarePackageShape } from '../types';
import { isLoadingSoftwarePackages, getSoftwarePackagesSortedByUpdatedAt } from '../selectors';
import IntlShape from '../../../../../../app/lib/PropTypes/IntlShape';
import genericMessages from '../../../../../../app/lib/genericMessages';

const styles = {
  footerWrapper: {
    display: 'flex',
  },
  footerInnerWrapper: {
    width: '50%',
  },
  footerInnerWrapperRight: {
    textAlign: 'right',
  },
  footerAddButton: {
    margin: '20px 0',
  },
};

class SoftwarePackages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      pageSize: 10,
      page: 1,
      pages: 1,
    };
  }

  componentDidMount() {
    this.fetchSoftwarePackages();
  }

  onSelectPage(page) {
    this.setState({ page }, () => this.fetchSoftwarePackages());
  }

  async fetchSoftwarePackages() {
    const {
      get, loadingSoftwarePackages, loadingSoftwarePackagesDone, setSoftwarePackages,
    } = this.props;
    const { page, pageSize } = this.state;

    await loadingSoftwarePackages();

    const url = `software-package/packages?page=${page}&page_size=${pageSize}`;

    get({ path: url, parallel: false })
      .then(({ pages, softwarePackages }) => {
        this.setState({ pages }, async () => {
          await setSoftwarePackages(softwarePackages);
          await loadingSoftwarePackagesDone();
        });
      })
      .catch((err) => this.setState({ error: err, page: 1, pages: 0 }));
  }

  renderError() {
    const { intl: { formatMessage } } = this.props;
    const { error } = this.state;

    if (!error) return null;

    const dismissError = () => this.setState({ error: undefined });

    return (
      <Alert bsStyle="danger" onDismiss={dismissError}>
        <h4>{formatMessage(genericMessages.error)}</h4>
        <p>{error.toString()}</p>
        <p>
          <Button onClick={dismissError}>{formatMessage(genericMessages.closeNotification)}</Button>
        </p>
      </Alert>
    );
  }

  renderPackages(softwarePackage) {
    return (
      <tr key={`package-${softwarePackage.id}`}>
        <td>
          <Link to={`/software-package/package/edit/${softwarePackage.id}`}>{softwarePackage.code}</Link>
        </td>
        <td>
          {softwarePackage.title}
        </td>
        <td>
          {softwarePackage.tag}
        </td>
        <td>
          {softwarePackage.information}
        </td>
        <td>
          {softwarePackage.updatedAt}
        </td>
      </tr>
    );
  }

  render() {
    const { softwarePackages, isLoadingSoftwarePackages, intl: { formatMessage } } = this.props;
    const { page, pages } = this.state;

    return (
      <div>
        {this.renderError()}

        {softwarePackages.length === 0 && (
          <strong>{formatMessage(genericMessages.nothingFound)}</strong>
        )}

        {isLoadingSoftwarePackages
          && <strong>{formatMessage(genericMessages.loadingInProgress)}</strong>}

        {(softwarePackages.length > 0 && !isLoadingSoftwarePackages) && (
        <Table responsive>
          <thead>
            <tr>
              <td>Id</td>
              <td>{formatMessage(genericMessages.title)}</td>
              <td>Tag</td>
              <td>{formatMessage(genericMessages.information)}</td>
              <td>{formatMessage(genericMessages.updatedAt)}</td>
            </tr>
          </thead>
          <tbody>
            {softwarePackages.map((softwarePackage) => this.renderPackages(softwarePackage))}
          </tbody>
        </Table>
        )}

        <div style={styles.footerWrapper}>
          <div style={styles.footerInnerWrapper}>
            <Pagination
              first
              prev
              last
              next
              ellipsis
              boundaryLinks
              maxButtons={5}
              bsSize="small"
              onSelect={(page) => this.onSelectPage(page)}
              page={page}
              pages={pages}
            />
          </div>

          <div style={{ ...styles.footerInnerWrapper, ...styles.footerInnerWrapperRight }}>
            <Button
              bsStyle="primary"
              href="/software-package/package/add"
              style={styles.footerAddButton}
            >
              {formatMessage(genericMessages.add)}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

SoftwarePackages.propTypes = {
  // Dispatch props
  get: PropTypes.func.isRequired,
  loadingSoftwarePackages: PropTypes.func.isRequired,
  loadingSoftwarePackagesDone: PropTypes.func.isRequired,
  setSoftwarePackages: PropTypes.func.isRequired,

  // react-intl props
  intl: IntlShape.isRequired,

  // State props
  softwarePackages: PropTypes.arrayOf(softwarePackageShape).isRequired,
  isLoadingSoftwarePackages: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  softwarePackages: getSoftwarePackagesSortedByUpdatedAt(state),
  isLoadingSoftwarePackages: isLoadingSoftwarePackages(state),
});

const mapDispatchToProps = {
  get,
  loadingSoftwarePackages,
  loadingSoftwarePackagesDone,
  setSoftwarePackages,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SoftwarePackages));
