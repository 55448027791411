import { path } from 'ramda';
import { readEndpoint } from 'redux-json-api';

import { PublicClientApplication } from '@azure/msal-browser';
import { registerHook } from '../../../../app/lib/hooks';
import { destroy, fetchDrivePermissions, setDriveAccessToken } from '../modules/actions';

// Initialize the Arriva specific authentication handler
registerHook('authenticated', (args, dispatch) => new Promise((resolve) => {
  const driveToken = path(['drive_jwt_token'], args);

  if (driveToken) {
    dispatch(setDriveAccessToken(driveToken));
    dispatch(fetchDrivePermissions());

    window.localStorage.driveToken = driveToken;
  }

  resolve();
}));

registerHook('msalInstance', (args) => new Promise((resolve) => {
  window.localStorage.msalInstance = JSON.stringify(args);

  resolve();
}));

// Get some specific user-meta fields
registerHook('currentUser', (user, dispatch) => new Promise((resolve) => {
  dispatch(readEndpoint('user-meta?filter[meta_key__in]=vimeoAccessToken,vimeoProjectIdArriVideo'));
  resolve();
}));

// Make sure all Arriva related session credentials are deleted when the user logs out
registerHook('destroy', async (args, dispatch) => {
  try {
    if (window && window.localStorage) {
      console.log('[OI :: Arriva] destroy removing accessToken from localStorage...');
      if (window.localStorage.msalInstance) {
        console.log('[OI :: Arriva] destroy removing SSO instance from localStorage...');
        const { config } = JSON.parse(window.localStorage.msalInstance);
        const { auth } = config;
        const { cache } = config;
        const msalConfig = { auth, cache };

        const msalInstance = new PublicClientApplication(msalConfig);

        await msalInstance.logout();
        delete window.localStorage.msalInstance;
      }

      delete window.localStorage.driveToken;
    }
  } catch (error) {
    console.error('[OI :: Arriva] destroy ERROR: could not delete from localStorage');
    throw error;
  }

  dispatch(destroy());
});

// Make sure to set the driveToken from localstorage when resuming
// eslint-disable-next-line no-async-promise-executor
registerHook('resume', (args, dispatch) => new Promise(async (resolve, reject) => {
  const { driveToken } = window.localStorage;

  if (!driveToken) {
    console.log('[OI :: Arriva] resume no driveToken...');
    await dispatch(destroy());
    reject();
  }

  dispatch(setDriveAccessToken(driveToken));
  dispatch(fetchDrivePermissions());
  resolve();
}));
