import React from 'react';

import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import Jumbotron from 'react-bootstrap/lib/Jumbotron';
import ListGroup from 'react-bootstrap/lib/ListGroup';
import Row from 'react-bootstrap/lib/Row';

import CommonIssue from '../components/CommonIssue';

import './SupportLayout.less';

const SupportLayout = () => (
  <Row>
    <Col sm={8} smOffset={2}>
      <Jumbotron>
        <h1>Arriva Drive! Support</h1>

        <p>
          Op deze pagina kun je verschillende oplossingen vinden voor veel
          voorkomende problemen. Als je een probleem hebt wat hier niet tussen
          staat, of je komt er niet uit, neem dan contact met de Drive! support-
          afdeling van Arriva middels onderstaande knop.
        </p>

        <p>
          <Button bsStyle="primary" href="mailto:drive@arriva.nl">
            E-mail support
          </Button>
        </p>
      </Jumbotron>

      <div className="support-container">
        <h3>Inloggen</h3>

        <p>
          Je kunt in de Drive!-app inloggen met de gegevens die je ontvangen
          hebt. Na de eerste succesvolle inlogpoging dien je een
          toegangscode in te stellen, welke in het vervolg gebruikt zal worden
          bij het openen van de app.
        </p>

        <h4>Inlogproblemen oplossen</h4>

        <ListGroup>
          <CommonIssue title="Ik ben mijn wachtwoord vergeten">
            Gebruik de &lsquo;Wachtwoord vergeten?&rsquo;-optie op het
            inlogscherm om een wachtwoordherstel aan te vragen. Vul op het
            volgende scherm je e-mailadres in en vraag wachtwoordherstel aan.
            Als je e-mailadres bij ons bekend is ontvang je een herstelcode,
            waarmee je een nieuw wachtwoord in kan stellen.
          </CommonIssue>

          <CommonIssue title="Ik ben mijn toegangscode vergeten">
            Op dit moment bieden wij geen mogelijkheden aan in de app om een
            vergeten toegangscode te herstellen. Dit komt omdat de toegangscode
            alleen op jouw toestel is opgeslagen. Om dit probleem op te lossen
            dient alle app-data verwijdert te worden van je telefoon, waarna je
            opnieuw kunt inloggen.
          </CommonIssue>

          <CommonIssue title="Ik krijg telkens 'Er ging iets mis bij het inloggen!'">
            <p>
              Er kunnen verschillende problemen aan de hand zijn bij deze
              melding. Je account kan (abusievelijk) uitgeschakeld zijn, je
              ingevulde gebruikersnaam kan onbekend zijn, je verbinding naar
              onze servers kan haperen, et cetera. Om geen accountgegevens te
              lekken aan derden geven wij in zulke gevallen deze algemene
              foutmelding. Hierdoor kan iemand met malafide intenties niet
              achterhalen of iemand wel of geen account bij ons heeft.
            </p>

            <p>
              Mocht je van mening zijn dat dit niet klopt, neem dan contact met
              ons op via de knop bovenaan de pagina.
            </p>
          </CommonIssue>
        </ListGroup>
      </div>

      <div className="support-container">
        <h3>QR-codes scannen</h3>

        <p>
          Verschillende modules in de de Drive!-app gebruiken de camera van je
          telefoon om QR-codes te kunnen scannen. Deze codes zijn te vinden op
          onder andere voertuigen, zodat je makkelijk bijvoorbeeld de storingen
          van een voertuig kan inzien door deze QR-code te scannen.
        </p>

        <h4>QR-code-problemen oplossen</h4>

        <ListGroup>
          <CommonIssue title="Ik krijg een zwart scherm als ik een QR-code wil scannen">
            <p>
              Dit kan voorkomen als je camerapermissies hebt uitgeschakeld voor
              de Drive!-app. Deze kun je toekennen vanuit de juiste instelling-
              schermen van je mobiele OS.
            </p>

            <p>
              Voor iOS bereik je dit via Instellingen/Settings &gt; Drive! &gt;
              Camera.
            </p>

            <p>
              Voor Android bereik je dit via Instellingen/Settings &gt; Apps
              &gt; Drive! &gt; Machtigingen/Permissions &gt; Camera.
            </p>
          </CommonIssue>
        </ListGroup>
      </div>
    </Col>
  </Row>
);

SupportLayout.propTypes = {};

SupportLayout.defaultProps = {};

export default SupportLayout;
