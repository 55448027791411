/* eslint-disable */
import React, { Component } from 'react';
import ReactBootstrapSlider from 'react-bootstrap-slider';
import PropTypes from 'prop-types';
import { find, propEq } from 'ramda';

import HeaderBar from '../header-bar';

export default class Range extends Component {
  static propTypes = {
    _onDestroy: PropTypes.func,
    data: PropTypes.shape({
      field_name: PropTypes.string.isRequired,
      label: PropTypes.string,
      pageBreakBefore: PropTypes.bool,
      inline: PropTypes.bool,
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
      step: PropTypes.number,
      defaultValue: PropTypes.number,
    }),
    defaultValue: PropTypes.shape({}),
    editModeOn: PropTypes.func,
    error: PropTypes.string,
    parent: PropTypes.shape({}),
    mutable: PropTypes.bool,
    onEdit: PropTypes.func,
    readOnly: PropTypes.bool,
    handleChange: PropTypes.func,
  };

  render() {
    const {
      _onDestroy,
      data,
      defaultValue = '',
      editModeOn,
      error,
      handleChange,
      parent,
      mutable,
      onEdit,
      readOnly,
    } = this.props;

    let props = {
      type: 'range',
      name: data.field_name,
      list: `tickmarks_${data.field_name}`,
      min: data.minValue,
      max: data.maxValue,
      step: data.step,
      defaultValue: defaultValue !== undefined ? parseInt(defaultValue, 10) : parseInt(data.default_value, 10),
    };

    if (mutable) {
      props.ref = `child_ref_${data.field_name}`;
    }

    let datalist = [];
    for (var i=parseInt(data.minValue, 10); i <= parseInt(data.maxValue, 10); i += parseInt(data.step, 10)) {
      datalist.push(i);
    }

    let oneBig = 100 / (datalist.length - 1);

    let _datalist = datalist.map((d,idx) => {
      return <option key={props.list+'_'+idx}>{d}</option>
    });

    let visible_marks = datalist.map((d,idx) => {
      let option_props = {};
      let w = oneBig;
      if (idx === 0 || idx === datalist.length-1)
        w = oneBig/2;
      option_props.key = props.list+'_label_'+idx;
      option_props.style = {width: w + '%'};
      if (idx === datalist.length-1)
        option_props.style = {width: w + '%', textAlign: 'right'};
      return <label {...option_props}>{d}</label>
    });

    let baseClasses = 'rfb-item';
    if (data.pageBreakBefore) baseClasses += ' alwaysbreak';

    return (
      <div className={baseClasses}>
        {!mutable &&
          <div>
            {data.pageBreakBefore && <div className="preview-page-break">Page Break</div>}
            <HeaderBar
              parent={parent}
              editModeOn={editModeOn}
              data={data}
              onDestroy={_onDestroy}
              onEdit={onEdit}
              static={data.static}
              required={data.required}
            />
          </div>
        }
        <div className="form-group">
          <label>
            {data.label}

            {(data.hasOwnProperty('required') && data.required && !readOnly) &&
              <span className="label-required label label-danger">Verplicht</span>
            }
          </label>

          <div className="range">
            <div className="clearfix">
              <span className="pull-left">{data.min_label}</span>
              <span className="pull-right">{data.max_label}</span>
            </div>

            <ReactBootstrapSlider
              name={props.name}
              value={props.defaultValue}
              step={data.step}
              max={data.max_value}
              min={data.min_value}
            />
          </div>
          <div className="visible_marks">
            {visible_marks}
          </div>
          <datalist id={props.list}>
            {_datalist}
          </datalist>
        </div>
      </div>
    );
  }
}
